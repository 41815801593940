/**
 * Permissions config for each view/action available in the application.
 * Each route contains a list of privileges required for access by the user.
 * These permissions are checked via the testPermission method given a route
 * and the array of user privileges.
 *
 * If a user does not have the required privileges, they are either redirected
 * (in case of a route) or have specific actions/buttons locked or hidden.
 */

import { isEmpty } from 'lodash'
import ROUTES from 'routes'
import { config as API, CREATE, READ, UPDATE, DELETE } from 'api'

export const ENDPOINT = 'ENDPOINT'
export const OPERATIONS = 'OPERATIONS'

export const config = {
	[ROUTES.ROOT]: null, // public
	[ROUTES.LOGIN]: null, // public
	[ROUTES.LOGOUT]: null, // public
	[ROUTES.FORGOT_PASSWORD]: null, // public
	[ROUTES.RESET_PASSWORD]: null, // public
	// --
	// -- SIGNUP
	// --
	[ROUTES.SIGNUP.ROOT]: null, // public
	[ROUTES.SIGNUP.SIGNUP]: null, // public
	// --
	// -- VERIFY EMAIL
	[ROUTES.VERIFY_EMAIL]: null,
	// --
	// -- FAQ
	// --
	[ROUTES.FAQ]: null,
	// --
	// -- ADMIN
	// --
	[ROUTES.ADMIN.ROOT]: [
		// @todo needs more granularity around invite type
		{
			[ENDPOINT]: API.INVITES.PATH,
			[OPERATIONS]: [CREATE]
		}
	],
	[ROUTES.ADMIN.ACCOUNT_CREATE]: [
		// @todo needs more granularity around invite type
		{
			[ENDPOINT]: API.INVITES.PATH,
			[OPERATIONS]: [CREATE]
		}
	],
	[ROUTES.ADMIN.USER_CREATE]: [
		// @todo needs more granularity around invite type
		{
			[ENDPOINT]: API.INVITES.PATH,
			[OPERATIONS]: [CREATE]
		}
	],
	// --
	// -- ACCOUNT
	// --
	[ROUTES.ACCOUNT.ROOT]: null, // @todo
	[ROUTES.ACCOUNT.PROFILE]: null, // @todo
	[ROUTES.ACCOUNT.PROFILE_EDIT]: null, // @todo
	[ROUTES.ACCOUNT.UPDATE_EMAIL]: null, // @todo
	[ROUTES.ACCOUNT.RESET_PASSWORD]: null, // @todo
	// disabled for now
	[ROUTES.ACCOUNT.SETTINGS]: [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }],
	// --
	// -- TEAM
	// --
	[ROUTES.TEAM.ROOT]: [
		{
			[ENDPOINT]: API.USERS.PATH,
			[OPERATIONS]: [READ]
		}
	],
	[ROUTES.TEAM.USER_INVITE]: [
		{
			[ENDPOINT]: API.INVITES.PATH,
			[OPERATIONS]: [CREATE]
		}
	],
	[ROUTES.TEAM.USERS]: null,
	[ROUTES.TEAM.USER]: [
		{
			[ENDPOINT]: API.USERS.PATH,
			[OPERATIONS]: [READ]
		}
	],

	// --
	// -- PRODUCTS
	// --
	[ROUTES.PRODUCTS.ROOT]: [
		{
			[ENDPOINT]: API.PRODUCTS.PATH,
			[OPERATIONS]: [READ]
		},
		{
			[ENDPOINT]: API.PRODUCT_CLASSIFIER_VALS.PATH,
			[OPERATIONS]: [READ]
		},
		{
			[ENDPOINT]: API.FILTER_PRODUCTS.PATH,
			[OPERATIONS]: true
		},
		{
			[ENDPOINT]: API.PRODUCT_FILTER_OPTIONS.PATH,
			[OPERATIONS]: true
		}
	],
	[ROUTES.PRODUCTS.PRODUCT]: [
		{
			[ENDPOINT]: API.PRODUCTS.PATH,
			[OPERATIONS]: [READ]
		},
		{
			[ENDPOINT]: API.PRODUCT_CLASSIFIER_VALS.PATH,
			[OPERATIONS]: [READ]
		}
	],
	[ROUTES.PRODUCTS.PRODUCT_EDIT]: [
		{
			[ENDPOINT]: API.PRODUCTS.PATH,
			[OPERATIONS]: [READ]
		},
		{
			[ENDPOINT]: API.PRODUCT_CLASSIFIER_VALS.PATH,
			[OPERATIONS]: [CREATE, READ, UPDATE, DELETE]
		}
	],
	[ROUTES.PRODUCTS.CREATE]: [
		{
			[ENDPOINT]: API.PRODUCT_CLASSIFIER_VALS.PATH,
			[OPERATIONS]: [CREATE, READ, UPDATE, DELETE]
		}
	],
	[ROUTES.PRODUCTS.UPLOAD]: [
		{
			[ENDPOINT]: API.PRODUCT_SHEETS.PATH,
			[OPERATIONS]: [CREATE]
		}
	],
	[ROUTES.PRODUCTS.MANAGE]: [
		{
			[ENDPOINT]: API.PRODUCT_SHEETS.PATH,
			[OPERATIONS]: [READ]
		}
	],
	[ROUTES.PRODUCTS.SHEET]: [
		{
			[ENDPOINT]: API.PRODUCT_SHEETS.PATH,
			[OPERATIONS]: [READ]
		}
	],
	[ROUTES.PRODUCTS.SHEET_EDIT]: [
		{
			[ENDPOINT]: API.PRODUCT_SHEETS.PATH,
			[OPERATIONS]: [READ, DELETE] // @todo should be [READ, UPDATE, DELETE]
		}
	],
	[ROUTES.PRODUCTS.RELATORS]: [
		{
			[ENDPOINT]: API.PRODUCT_RELATORS.PATH,
			[OPERATIONS]: [READ]
		}
	],
	[ROUTES.PRODUCTS.RELATOR]: [
		{
			[ENDPOINT]: API.PRODUCT_RELATORS.PATH,
			[OPERATIONS]: [READ]
		},
		{
			[ENDPOINT]: API.PRODUCT_RELATOR_CRITERIA.PATH,
			[OPERATIONS]: [READ]
		}
	],
	[ROUTES.PRODUCTS.RELATOR_EDIT]: [
		{
			[ENDPOINT]: API.PRODUCT_RELATORS.PATH,
			[OPERATIONS]: [READ, UPDATE, DELETE]
		},
		{
			[ENDPOINT]: API.PRODUCT_RELATOR_CRITERIA.PATH,
			[OPERATIONS]: [CREATE, READ, UPDATE, DELETE]
		}
	],
	[ROUTES.PRODUCTS.RELATOR_CREATE]: [
		{
			[ENDPOINT]: API.PRODUCT_RELATORS.PATH,
			[OPERATIONS]: [CREATE, READ]
		}
	],
	[ROUTES.PRODUCTS.RESTRICTORS]: [
		{
			[ENDPOINT]: API.CLASSIFIER_RESTRICTORS.PATH,
			[OPERATIONS]: [READ, CREATE, DELETE]
		},
		{
			[ENDPOINT]: API.PRODUCT_RESTRICTORS.PATH,
			[OPERATIONS]: [READ, CREATE, DELETE]
		}
	],
	// --
	// -- RFPI
	// --
	[ROUTES.RFPIS.ROOT]: [
		{
			[ENDPOINT]: API.RFPIS.PATH,
			[OPERATIONS]: [READ]
		}
	], // @todo
	[ROUTES.RFPIS.RFPI]: [
		{
			[ENDPOINT]: API.RFPIS.PATH,
			[OPERATIONS]: [READ]
		}
	],
	// RFPI_EDIT is currently not allowed
	[ROUTES.RFPIS.RFPI_EDIT]: [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }], // @todo disabled for now
	[ROUTES.RFPIS.RFPI_CREATE]: [
		{
			[ENDPOINT]: API.RFPIS.PATH,
			[OPERATIONS]: [READ, CREATE]
		}
	],
	// --
	// -- GUIDED PURCHASES
	// --
	// [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }]
	// is disallowing these routes because the feature is incomplete
	[ROUTES.GPS.ROOT]: null, // [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }],
	[ROUTES.GPS.CREATE]: null, // [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }],
	[ROUTES.GPS.VIEW]: null, // [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }],
	[ROUTES.GPS.EDIT]: null, // [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }],
	[ROUTES.GPS.PRINT]: null, // [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }],
	[ROUTES.GPS.IRM]: null, // [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }],
	// --
	// -- ALTA
	// --
	// [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }]
	// is disallowing these routes because the feature is incomplete
	[ROUTES.ALTA.ROOT]: null, // [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }],
	[ROUTES.ALTA.CREATE]: [
		{
			[ENDPOINT]: API.ALTA_FOLDERS.PATH,
			[OPERATIONS]: [CREATE]
		}
	],
	[ROUTES.ALTA.VIEW]: null, // [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }],
	// --
	// -- TAXONOMY
	// --
	// @todo for now, everyone has access to READ taxonomy views
	[ROUTES.TAXONOMIES.ROOT]: null,
	[ROUTES.TAXONOMIES.GENERATOR]: [
		{
			[ENDPOINT]: API.DIVISIONS.PATH,
			[OPERATIONS]: [READ, UPDATE, DELETE]
		},
		{
			[ENDPOINT]: API.PRODUCT_TYPES.PATH,
			[OPERATIONS]: [READ, UPDATE, DELETE]
		},
		{
			[ENDPOINT]: API.CLASSIFIER_DEFS.PATH,
			[OPERATIONS]: [READ, UPDATE, DELETE]
		}
	],
	// [{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }]
	// is disallowing these routes because the feature is incomplete
	[ROUTES.TAXONOMIES.CONSTRUCTS]: [
		{ [ENDPOINT]: '/tbd', [OPERATIONS]: [READ] }
	], // @todo
	[ROUTES.TAXONOMIES.CONSTRUCT]: null, // @todo
	[ROUTES.TAXONOMIES.CONSTRUCT_EDIT]: null, // @todo
	[ROUTES.TAXONOMIES.CONSTRUCT_CREATE]: null, // @todo
	// --
	[ROUTES.TAXONOMIES.DIVISIONS]: null,
	[ROUTES.TAXONOMIES.DIVISION]: null,
	[ROUTES.TAXONOMIES.DIVISION_EDIT]: [
		{
			[ENDPOINT]: API.DIVISIONS.PATH,
			[OPERATIONS]: [READ, UPDATE, DELETE]
		}
	],
	[ROUTES.TAXONOMIES.DIVISION_CREATE]: [
		{
			[ENDPOINT]: API.DIVISIONS.PATH,
			[OPERATIONS]: [CREATE]
		}
	],
	[ROUTES.TAXONOMIES.PRODUCT_TYPES]: null,
	[ROUTES.TAXONOMIES.PRODUCT_TYPE]: null,
	[ROUTES.TAXONOMIES.PRODUCT_TYPE_EDIT]: [
		{
			[ENDPOINT]: API.PRODUCT_TYPES.PATH,
			[OPERATIONS]: [READ, UPDATE, DELETE]
		}
	],
	[ROUTES.TAXONOMIES.PRODUCT_TYPE_CREATE]: [
		{
			[ENDPOINT]: API.PRODUCT_TYPES.PATH,
			[OPERATIONS]: [CREATE]
		}
	],
	[ROUTES.TAXONOMIES.CLASSIFIERS]: null,
	[ROUTES.TAXONOMIES.CLASSIFIER]: null,
	[ROUTES.TAXONOMIES.CLASSIFIER_EDIT]: [
		{
			[ENDPOINT]: API.CLASSIFIER_DEFS.PATH,
			[OPERATIONS]: [READ, UPDATE, DELETE]
		}
	],
	[ROUTES.TAXONOMIES.CLASSIFIER_CREATE]: [
		{
			[ENDPOINT]: API.CLASSIFIER_DEFS.PATH,
			[OPERATIONS]: [CREATE]
		}
	],
	// --
	// -- ERROR
	// --
	[ROUTES.ERROR.NOT_FOUND]: null
}

export const ROLE_NAV_FORBIDS = {
	physician: [
		ROUTES.TAXONOMIES.ROOT,
		ROUTES.GPS.ROOT,
		ROUTES.TEAM.ROOT,
		ROUTES.FAQ
	],
	material_manager: [
		ROUTES.TAXONOMIES.ROOT,
		ROUTES.GPS.ROOT,
		ROUTES.TEAM.ROOT,
		ROUTES.FAQ
	],
	administrator_ceo: [
		ROUTES.TAXONOMIES.ROOT,
		ROUTES.GPS.ROOT,
		ROUTES.TEAM.ROOT,
		ROUTES.FAQ
	],
	regional_vice_president: [
		ROUTES.TAXONOMIES.ROOT,
		ROUTES.GPS.ROOT,
		ROUTES.TEAM.ROOT,
		ROUTES.FAQ
	],
	product_marketing_manager: [ROUTES.GPS.ROOT],
	market_president: [
		ROUTES.TAXONOMIES.ROOT,
		ROUTES.GPS.ROOT,
		ROUTES.TEAM.ROOT,
		ROUTES.FAQ
	],
	corporate: [
		ROUTES.TAXONOMIES.ROOT,
		ROUTES.GPS.ROOT,
		ROUTES.TEAM.ROOT,
		ROUTES.FAQ
	]
}

export const ROLE_SUB_NAV_FORBIDS = {
	physician: [
		ROUTES.ALTA.DELETE,
		ROUTES.PRODUCTS.CREATE,
		ROUTES.PRODUCTS.IDENTIFY,
		ROUTES.PRODUCTS.RELATORS,
		ROUTES.PRODUCTS.RESTRICTORS,
		ROUTES.PRODUCTS.REPORTED_DATA,
		ROUTES.GPS.CREATE,
		ROUTES.GPS.IRM
	],
	material_manager: [
		ROUTES.ALTA.DELETE,
		ROUTES.PRODUCTS.CREATE,
		ROUTES.PRODUCTS.IDENTIFY,
		ROUTES.PRODUCTS.RELATORS,
		ROUTES.PRODUCTS.RESTRICTORS,
		ROUTES.PRODUCTS.REPORTED_DATA,
		ROUTES.GPS.CREATE,
		ROUTES.GPS.IRM
	],
	administrator_ceo: [
		ROUTES.ALTA.DELETE,
		ROUTES.PRODUCTS.CREATE,
		ROUTES.PRODUCTS.IDENTIFY,
		ROUTES.PRODUCTS.RELATORS
	],
	regional_vice_president: [
		ROUTES.ALTA.DELETE,
		ROUTES.PRODUCTS.CREATE,
		ROUTES.PRODUCTS.IDENTIFY,
		ROUTES.PRODUCTS.RELATORS,
		ROUTES.PRODUCTS.RESTRICTORS,
		ROUTES.PRODUCTS.REPORTED_DATA,
		ROUTES.GPS.CREATE,
		ROUTES.GPS.IRM
	],
	market_president: [
		ROUTES.ALTA.DELETE,
		ROUTES.PRODUCTS.CREATE,
		ROUTES.PRODUCTS.IDENTIFY,
		ROUTES.PRODUCTS.RELATORS,
		ROUTES.PRODUCTS.RESTRICTORS,
		ROUTES.PRODUCTS.REPORTED_DATA,
		ROUTES.GPS.CREATE,
		ROUTES.GPS.IRM
	],
	corporate: [
		ROUTES.ALTA.DELETE,
		ROUTES.PRODUCTS.CREATE,
		ROUTES.PRODUCTS.IDENTIFY,
		ROUTES.PRODUCTS.RELATORS,
		ROUTES.PRODUCTS.RESTRICTORS,
		ROUTES.GPS.CREATE,
		ROUTES.GPS.IRM
	]
}

export const DASHBOARD_ROLE_NAME = ['supply_chain', 'admin', 'super_admin']
export function testPermission(userPrivileges, path) {
	if (!userPrivileges || isEmpty(userPrivileges)) {
		return false
	}

	if (!path) {
		return false
	}

	const pathPermissions = config[path]

	// no path permissions are set in the config. allow access.
	if (!pathPermissions) {
		return true
	}

	let hasPermission = true

	// loop through all access endpoints and ensure they all exist in user's privileges
	pathPermissions.forEach(permission => {
		const endpoint = permission[ENDPOINT]
		const operations = permission[OPERATIONS]

		if (!endpoint) {
			hasPermission = false
			return
		}

		if (!operations) {
			hasPermission = false
			return false
		}

		// find user privilege that matches the privilege group we are checking
		const userPrivilege = userPrivileges.find(p => p.endpoint === endpoint)

		if (!userPrivilege || isEmpty(userPrivilege)) {
			hasPermission = false
			return false
		}

		if (!userPrivilege.can) {
			hasPermission = false
			return false
		}

		// for /rpc, operations and userPrivilege.can must equal 'true'
		if (operations === true && userPrivilege.can === true) {
			return
		}

		operations.find(operation => {
			const hasPrivilege =
				userPrivilege.can[operation] && userPrivilege.can[operation].length > 0

			if (!hasPrivilege) {
				hasPermission = false
				return true
			} else {
				return false
			}
		})
	})

	return hasPermission
}
