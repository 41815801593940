import React from 'react'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

export const WithWidth = (Component) => {
	const getCurrentWidth = (theme) => {
		const keys = [...theme.breakpoints.keys].reverse()
		return (
			keys.reduce((output, key) => {
				// eslint-disable-next-line react-hooks/rules-of-hooks
				const matches = useMediaQuery(theme.breakpoints.up(key))
				return !output && matches ? key : output
			}, null) || 'xs'
		)
	}

	const WithWidth = (props) => {
		const theme = useTheme()
		const width = getCurrentWidth(theme)
		return <Component {...props} width={width} />
	}
	return WithWidth
}
