import { createTheme } from '@mui/material/styles'
import red from '@mui/material/colors/red'
import pink from '@mui/material/colors/pink'
import purple from '@mui/material/colors/purple'
import indigo from '@mui/material/colors/indigo'
import blue from '@mui/material/colors/blue'
import cyan from '@mui/material/colors/cyan'
import teal from '@mui/material/colors/teal'
import lime from '@mui/material/colors/lime'
import amber from '@mui/material/colors/amber'
import orange from '@mui/material/colors/orange'
import grey from '@mui/material/colors/grey'

// -- color utils
export function createGradientBackgroundString(colors) {
	// background: `linear-gradient(150deg, ${color1}, ${color2}, ${color3}, ${color4})`,
	let gradient = 'linear-gradient(150deg'
	colors.forEach((color) => {
		gradient += `, ${color}`
	})
	gradient += ')'
	return gradient
}

// -- widths
export const PARAGRAPH_MAX_WIDTH = 750

// closest color to RELATABLE cyan is cyan[700]
// -- colors
export const GREEN = teal.A700
export const CYAN = '#00afb9'
export const PINK = pink[400]
export const GREY = grey[600]
export const LIGHT_GREY = grey[300]
export const EXTRA_LIGHT_GREY = '#f8f8f8'
export const PURPLE = purple[300]
export const BRIGHT_GREEN = '#36c98c'

const PIE_SHADE = 300
const PIE_LIGHT_SHADE = 100
const BAR_SHADE = 400
export const PIE_COLORS = [
	cyan[PIE_SHADE],
	purple[PIE_SHADE],
	indigo[PIE_SHADE],
	blue[PIE_SHADE],
	teal[PIE_SHADE],
	lime[PIE_SHADE],
	amber[PIE_SHADE],
	pink[PIE_SHADE],
	red[PIE_SHADE],
	orange[PIE_SHADE],
	cyan[PIE_LIGHT_SHADE],
	purple[PIE_LIGHT_SHADE],
	indigo[PIE_LIGHT_SHADE],
	blue[PIE_LIGHT_SHADE],
	teal[PIE_LIGHT_SHADE],
	lime[PIE_LIGHT_SHADE],
	amber[PIE_LIGHT_SHADE],
	pink[PIE_LIGHT_SHADE],
	red[PIE_LIGHT_SHADE],
	orange[PIE_LIGHT_SHADE],
]

export const BAR_COLORS = [
	cyan[BAR_SHADE],
	purple[BAR_SHADE],
	indigo[PIE_SHADE],
	blue[PIE_SHADE],
	teal[PIE_SHADE],
	lime[PIE_SHADE],
	amber[PIE_SHADE],
	pink[PIE_SHADE],
	red[PIE_SHADE],
	orange[PIE_SHADE],
	cyan[PIE_LIGHT_SHADE],
	purple[PIE_LIGHT_SHADE],
	indigo[PIE_LIGHT_SHADE],
	blue[PIE_LIGHT_SHADE],
	teal[PIE_LIGHT_SHADE],
	lime[PIE_LIGHT_SHADE],
	amber[PIE_LIGHT_SHADE],
	pink[PIE_LIGHT_SHADE],
	red[PIE_LIGHT_SHADE],
	orange[PIE_LIGHT_SHADE],
]

// -- spacing
export const SUB_NAV_HEIGHT = 45

const theme = createTheme({
	typography: {
		// useNextVariants: true
		// h1: {
		// 	fontSize: '3rem'
		// },
		// h2: {
		// 	fontSize: '2.5rem'
		// },
		// h3: {
		// 	fontSize: '1.5rem'
		// },
		// h4: {
		// 	fontSize: '1.5rem'
		// }
		// Tell Material-UI what's the font-size on the html element is.
		// htmlFontSize: 10
	},
	palette: {
		primary: {
			// light: will be calculated from palette.primary.main,
			main: CYAN,
			// dark: will be calculated from palette.primary.main,
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: pink[400],
		},
		text: {
			primary: 'rgba(0, 0, 0, 0.85)',
		},
		// error: will use the default color
	},
	// overrides: {
	// 	MuiSelect: {
	// 		select: {
	// 			"&:focus": {
	// 				background: "$labelcolor"
	// 			}
	// 		}
	// 	}
	// }
})

export default theme
