import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import ROUTES from 'routes'
import InterstitialView from './InterstitialView'

const mapStateToProps = (state) => ({
	account: state.accounts.currentAccount,
	user: state.user,
})
export class DashboardView extends Component {
	static propTypes = {
		dispatch: PropTypes.func.isRequired,
	}

	render() {
		const { user, account } = this.props
		const isSupplier = account.type && account.type === 'supplier'
		const isIndependent = account.type && account.type === 'independent'

		if (user.roleFreemium === 1 || isIndependent) {
			return <Navigate to={ROUTES.ALTA.ROOT} />
		} else if (isSupplier) {
			return <Navigate to={ROUTES.PRODUCTS.ROOT} />
		} else {
			return <InterstitialView user={user} />
		}
	}
}

export default connect(mapStateToProps)(DashboardView)
