import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { routerActions } from 'connected-react-router'
import { Link } from 'react-router-dom'
import { withAuth0 } from '@auth0/auth0-react'

import { withStyles } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Hidden from '@mui/material/Hidden'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'

import MenuIcon from '@mui/icons-material/Menu'
import AccountCircle from '@mui/icons-material/AccountCircle'
import grey from '@mui/material/colors/grey'
import {
	testPermission,
	ROLE_NAV_FORBIDS,
	ROLE_SUB_NAV_FORBIDS,
	DASHBOARD_ROLE_NAME,
} from 'permissions'
import { actions as navActions } from 'state/modules/nav'
import ROUTES, { navigationMap } from 'routes'
import { checkSuperAdmin } from 'services/admin'
import { SUB_NAV_HEIGHT } from 'theme'
import { WithWidth } from '../../hocs/WithWidth'
import iconLogo from 'assets/logo_small_white.svg'

const mapStateToProps = (state) => ({
	privileges: state.session.privileges,
	user: state.user.currentModel,
	userRoleGroup: state.user.roleGroup,
	account: state.accounts.currentAccount,
	roleGroups: state.user.roleGroups,
	location: state.router.location,
	roleFreemium: state.user.roleFreemium,
	roleSystemName: state.user.roleSystemName,
	relatedProducts: state.relatedProducts,
})

const mapDispatchToProps = (dispatch) => ({
	navigateTo: (path) => dispatch(routerActions.push(path)),
	showNav: () => dispatch(navActions.show()),
	hideNav: () => dispatch(navActions.hide()),
})

const styles = (theme) => ({
	root: {
		flexGrow: 1,
	},
	grow: {
		flexGrow: 1,
	},
	appBar: {
		boxShadow: 'none!important',
		backgroundColor: `${grey[800]}!important`,
	},
	iconLogo: {
		width: '25px!important',
		opacity: '0.9!important',
		marginRight: `${theme.spacing(2)}!important`,
	},
	subAppBar: {
		position: 'fixed!important',
		zIndex: '1000!important',
		width: '100%!important',
		boxShadow: 'none!important',
		backgroundColor: 'rgba(255,255,255,.94)!important',
		borderBottom: `1px solid ${theme.palette.grey[300]}`,
	},
	toolbarSpacer: {
		display: 'flex!important',
		alignItems: 'center!important',
		justifyContent: 'flex-end!important',
		padding: '0 8px!important',
		...theme.mixins.toolbar,
	},
	menuButton: {
		marginLeft: -theme.spacing(2),
		marginRight: theme.spacing(1),
	},
	mainNavButon: {
		color: `${grey[50]}!important`,
		textTransform: 'none!important',
		marginLeft: `${theme.spacing(1)}!important`,
		marginRight: `${theme.spacing(1)}!important`,
	},
	subNav: {
		display: 'flex!important',
		alignItems: 'center!important',
		minHeight: `${SUB_NAV_HEIGHT}px!important`,
		paddingLeft: `${theme.spacing(5)}!important`,
	},
	subNavButton: {
		color: `${grey[800]}!important`,
		textTransform: 'none!important',
		margin: `${theme.spacing(0.5)}!important`,
		display: 'flex!important',
		alignItems: 'flex-start!important',
	},
	subNavButtonActive: {
		color: `${theme.palette.primary.main}!important`,
	},
	buttonContained: {
		color: `${grey[800]}!important`,
		backgroundColor: `${grey[50]}!important`,
		'&:hover': {
			backgroundColor: `${grey[300]}!important`,
		},
		boxShadow: 'none!important',
	},
})

class MainAppBar extends React.Component {
	state = {
		currentNavItem: null,
		anchorEl: null,
	}

	static getDerivedStateFromProps(props, state) {
		// find the current nav section based on props.location
		const { pathname } = props.location
		const navigationMatch = navigationMap.find(
			(n) => pathname.indexOf(n.path) > -1,
		)

		if (navigationMatch) {
			return {
				currentNavItem: navigationMatch,
			}
		} else {
			return {
				currentNavItem: null,
			}
		}
	}

	handleMenu = (event) => {
		this.setState({ anchorEl: event.currentTarget })
	}

	handleClose = () => {
		this.setState({ anchorEl: null })
	}

	handleLogout = (e) => {
		const { logout } = this.props.auth0

		e.preventDefault()
		this.setState({ anchorEl: null })

		logout({
			returnTo: window.location.origin + ROUTES.LOGOUT,
		})
	}

	renderMainNavItem = (navItem, index) => {
		const { classes, location, roleFreemium } = this.props
		let { icon, title, path } = navItem
		let isCurrent = !!(location.pathname.indexOf(path) > -1)
		const Icon = icon || null

		if (this.hideMenuItem(this.props, navItem)) {
			return null
		}

		if (roleFreemium === 1 && path === ROUTES.PRODUCTS.ROOT) {
			path = ''
			isCurrent = ''
		}

		return (
			<Button
				key={path}
				className={classes.mainNavButon}
				classes={{
					contained: classes.buttonContained,
				}}
				variant={isCurrent ? 'contained' : null}
				component={Link}
				to={path}
				disableRipple
			>
				{isCurrent && <Icon className="space-right-smallest" />}
				{title}
			</Button>
		)
	}

	hideFromRole = (navItem) => {
		let { userRoleGroup, roleGroups } = this.props

		let role =
			roleGroups && roleGroups.length && userRoleGroup
				? roleGroups.find((rg) => rg.id === userRoleGroup).system_name
				: ''

		if (
			ROLE_NAV_FORBIDS[role] &&
			ROLE_NAV_FORBIDS[role].includes(navItem.path)
		) {
			return true
		}
	}

	hideMenuItem = (props, navItem) => {
		const { userRoleGroup, roleGroups, privileges, roleSystemName, account } =
			props
		const { path, hideFromAppBar } = navItem
		const isSuperAdmin = checkSuperAdmin(userRoleGroup, roleGroups)
		const hasPermission = testPermission(privileges, path)

		if (account.type && account.type === 'supplier') {
			return this.handleSupplierNav(path, roleSystemName)
		} else if (account.type && account.type === 'independent') {
			if (
				!isSuperAdmin &&
				path !== ROUTES.TEAM.ROOT &&
				path !== ROUTES.ALTA.ROOT
			) {
				return true
			}
		} else {
			if (!hasPermission || hideFromAppBar || this.hideFromRole(navItem)) {
				return true
			}
		}
	}

	handleSupplierNav(path, roleSystemName) {
		if (
			roleSystemName === 'product_marketing_manager' &&
			path !== ROUTES.PRODUCTS.ROOT &&
			path !== ROUTES.TAXONOMIES.ROOT &&
			path !== ROUTES.TEAM.ROOT
		) {
			return true
		} else if (
			roleSystemName !== 'super_admin' &&
			path !== ROUTES.PRODUCTS.ROOT &&
			path !== ROUTES.TAXONOMIES.ROOT &&
			path !== ROUTES.TEAM.ROOT &&
			path !== ROUTES.GPS.ROOT
		) {
			return true
		}
	}

	hideSubNavFromRole = (navItem, roleName) => {
		if (
			ROLE_SUB_NAV_FORBIDS[roleName] &&
			ROLE_SUB_NAV_FORBIDS[roleName].includes(navItem.path)
		) {
			return true
		}
	}

	renderLogo = (roleFreemium, styles) => {
		if (roleFreemium === 1) {
			return (
				<Tooltip
					title="It looks like you are on a limited version of Relatable. Click here to let your administrator know you wish to upgrade your account to gain access to all Relatable has to offer."
					interactive
				>
					<img className={styles} src={iconLogo} alt="Relatable" />
				</Tooltip>
			)
		} else {
			return <img className={styles} src={iconLogo} alt="Relatable" />
		}
	}

	renderSubNavItem = (navItem, index) => {
		const {
			classes,
			location,
			privileges,
			userRoleGroup,
			roleGroups,
			roleFreemium,
		} = this.props
		let { icon, title, path, hideFromAppBar } = navItem
		const Icon = icon || null
		let isCurrent = Boolean(location.pathname === path)
		const hasPermission = testPermission(privileges, path)
		let roleName =
			roleGroups && roleGroups.length && userRoleGroup
				? roleGroups.find((role) => role.id === userRoleGroup).system_name
				: ''

		if (
			!hasPermission ||
			hideFromAppBar ||
			this.hideSubNavFromRole(navItem, roleName)
		) {
			return null
		}

		// freemium users have limited access to subnav items
		if (roleFreemium === 1) {
			if (path === ROUTES.ALTA.HOME) {
				path = ''
				isCurrent = ''
			}

			if (path === ROUTES.ALTA.CREATE) {
				return null
			}
		}

		//This code snippet will be removed once other dashboards are implemented.
		//Currently, Dashboard submenu should be shown only for physician role.

		if (title === 'Dashboard' && DASHBOARD_ROLE_NAME.indexOf(roleName) > -1) {
			return null
		}
		return (
			<Button
				size="small"
				key={path}
				className={classes.subNavButton}
				classes={
					isCurrent
						? {
								root: classes.subNavButtonActive,
							}
						: null
				}
				component={Link}
				to={path}
				disableRipple
			>
				{isCurrent && Icon && (
					<Icon fontSize="small" className="space-right-smallest" />
				)}
				{title}
			</Button>
		)
	}

	render() {
		const { classes, user, userRoleGroup, roleGroups, roleFreemium } =
			this.props
		const { anchorEl, currentNavItem } = this.state
		const isSuperAdmin = checkSuperAdmin(userRoleGroup, roleGroups)
		const menuIsOpen = Boolean(anchorEl)
		const hasSubNav = Boolean(currentNavItem && currentNavItem.children.length)
		let subNav = null

		if (hasSubNav) {
			subNav = currentNavItem.children
		}

		return (
			<div className={classes.root}>
				<AppBar className={classes.appBar}>
					<Toolbar>
						{userRoleGroup && (
							<>
								<Hidden mdUp>
									<React.Fragment>
										<IconButton
											className={classes.menuButton}
											color="inherit"
											aria-label="Menu"
											onClick={this.props.showNav}
										>
											<MenuIcon />
										</IconButton>
										<Link to={ROUTES.ROOT}>
											{this.renderLogo(roleFreemium, classes.iconLogo)}
										</Link>

										<Typography variant="h6" color="inherit">
											Relatable
										</Typography>
									</React.Fragment>
								</Hidden>
								<Hidden smDown>
									<React.Fragment>
										<Link to={ROUTES.ROOT}>
											{this.renderLogo(roleFreemium, classes.iconLogo)}
										</Link>
										<nav>{navigationMap.map(this.renderMainNavItem)}</nav>
									</React.Fragment>
								</Hidden>
							</>
						)}
						<div className={classes.grow}></div>
						<div>
							<Tooltip
								title={user.get('email') || ''}
								placement="left"
								disableFocusListener
							>
								<IconButton
									aria-owns={menuIsOpen ? 'menu-appbar' : undefined}
									aria-haspopup="true"
									onClick={this.handleMenu}
									color="inherit"
								>
									<AccountCircle />
								</IconButton>
							</Tooltip>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={menuIsOpen}
								onClose={this.handleClose}
							>
								<MenuItem
									onClick={this.handleClose}
									component={Link}
									to={ROUTES.ACCOUNT.ROOT}
								>
									Account
								</MenuItem>
								<MenuItem
									onClick={this.handleClose}
									component={Link}
									to={ROUTES.ACCOUNT.PROFILE}
								>
									Profile
								</MenuItem>
								{isSuperAdmin && (
									<MenuItem
										onClick={this.handleClose}
										component={Link}
										to={ROUTES.ADMIN.ROOT}
									>
										Admin
									</MenuItem>
								)}
								<MenuItem
									onClick={
										window.env.AUTH0_METHOD === 'AUTH0'
											? this.handleLogout
											: this.handleClose
									}
									component={Link}
									to={ROUTES.LOGOUT}
								>
									Sign out
								</MenuItem>
							</Menu>
						</div>
					</Toolbar>
				</AppBar>
				{hasSubNav && userRoleGroup && (
					<Hidden smDown>
						<div className={classes.subAppBar}>
							<div className={classes.toolbarSpacer} />
							<nav className={classes.subNav}>
								{subNav.map(this.renderSubNavItem)}
							</nav>
						</div>
					</Hidden>
				)}
			</div>
		)
	}
}

MainAppBar.propTypes = {
	// -- width props
	width: PropTypes.string.isRequired,
	// -- style props
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	// -- router props
	location: PropTypes.object.isRequired,
	// -- state props
	privileges: PropTypes.array.isRequired,
	user: PropTypes.object.isRequired,
	userRoleGroup: PropTypes.string,
	// -- dispatch props
	navigateTo: PropTypes.func.isRequired,
	showNav: PropTypes.func.isRequired,
	hideNav: PropTypes.func.isRequired,
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles, { withTheme: true }),
)(withAuth0(WithWidth(MainAppBar)))
