import { Model } from './base'
import { config as API } from 'api'
import * as postgrest from 'services/postgrest'

// @todo implement with flow
// export type ALTAReportType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
// 	purchase_sheet_id: ?string
// 	title: ?string
// 	client: ?string
// }

const defaultFields = {
	purchase_sheet_id: null,
	title: null,
	client: null,
}

// data for api queries
export const FETCH_SELECT = '*'
export const EMBED_PURCHASE_SHEET =
	'purchase_sheet:purchase_sheets(id,name,filename,matched_columns,created_at)'

const DELETE_ACTION_TEXT =
	'Are you sure you would like to delete this ALTA Report?'
const SAVE_ERROR_TEXT = 'Error saving ALTA Report'
const CREATE_ERROR_TEXT = 'Error creating ALTA Report'
const DELETE_ERROR_TEXT = 'Error deleting ALTA Report'

export default class ALTAReportModel extends Model {
	static endpoint = API.ALTA_REPORTS.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	constructor(fields = defaultFields) {
		super(fields)
	}

	// -- api call overrides

	async fetch(page, perPage) {
		const select = `${FETCH_SELECT},${EMBED_PURCHASE_SHEET}`

		try {
			const response = await postgrest.fetch(
				this.endpoint,
				this.id(),
				'id',
				select,
			)
			return new this.constructor(response)
		} catch (error) {
			throw error
		}
	}
}
