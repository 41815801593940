import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

function ErrorUnsupportedBrowserView({ classes }) {
	const [isOpen, setOpen] = React.useState(true)

	function handleClose() {
		setOpen(false)
	}

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="unsupported-browser"
			aria-describedby="unsupported-browser"
		>
			<DialogTitle>You are using an unsupported browser</DialogTitle>
			<DialogContent>
				<DialogContentText>
					To access Relatable, please use the Chrome Browser. If you would like
					to proceed anyway, know that features may not work as intended.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Use Relatable
				</Button>
				<Button
					color="primary"
					component="a"
					target="_blank"
					rel="noopener noreferrer"
					href="https://www.google.com/chrome"
					alt="Download Chrome"
				>
					Download Chrome
				</Button>
			</DialogActions>
		</Dialog>
	)
}

export default ErrorUnsupportedBrowserView
