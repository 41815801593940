import * as refs from 'data/classifier-refs'

// -- Taxonomy

export const PRODUCT_TYPE_TEMPLATE_INDEX = [
	refs.ID_PRODUCT_NUMBER,
	refs.ID_DIVISION,
	refs.ID_PRODUCT_TYPE,
	// @todo
]

export const PRODUCT_TYPE_TEMPLATE_LOCKED_INDEX = [refs.ID_PRODUCT_NUMBER]

// -- Search

export const PRODUCT_SEARCH_INDEX = [
	refs.ID_CATEGORY,
	refs.ID_GROUP,
	refs.ID_BUSINESS_UNIT,
	refs.ID_DIVISION,
	refs.ID_PRODUCT_TYPE,
	refs.ID_COMPANY,
	refs.ID_SYSTEM,
	refs.ID_STATUS,
]

// -- hidden classifiers on filtering option (Unidentified Division)
export const FILTER_OPTION_HIDDEN_DIVISION = []

// -- Product Comparison Table

export const PRODUCT_COMPARE_EXCLUDE = [
	// refs.ID_PRODUCT_IMAGE,
	refs.ID_WEBSITE_LINKS,
	refs.ID_IMAGE_LINKS,
	refs.ID_VIDEO_LINKS,
	refs.ID_PDF_LINKS,
]

export const PRODUCT_COMPARE_INDEX = [
	refs.ID_PRODUCT_NUMBER,
	refs.ID_CATEGORY,
	refs.ID_GROUP,
	refs.ID_BUSINESS_UNIT,
	refs.ID_DIVISION,
	refs.ID_PRODUCT_TYPE,
	refs.ID_COMPANY,
	refs.ID_DESCRIPTION,
	refs.ID_SYSTEM,
	refs.ID_CONTRACT_PRICE,
	refs.ID_QUANTITY_INCLUDED,
	refs.ID_GTIN_NUMBER,
	// refs.ID_ASSOCIATED_PROCEDURES,
	// refs.ID_ASSOCIATED_CPT_CODES
]

// -- Product Info

export const PRODUCT_INFO_UNIVERSAL_INDEX = [
	// refs.ID_CONTRACT_STATUS,
	// refs.ID_DIVISION,
	// refs.ID_PRODUCT_TYPE,
	refs.ID_CATEGORY,
	refs.ID_GROUP,
	refs.ID_BUSINESS_UNIT,
	refs.ID_COMPANY,
	refs.ID_DESCRIPTION,
	refs.ID_SYSTEM,
	refs.ID_PRODUCT_NUMBER,
	refs.ID_CONTRACT_PRICE,
	refs.ID_QUANTITY_INCLUDED,
	refs.ID_GTIN_NUMBER,
	// refs.ID_ASSOCIATED_PROCEDURES,
	// refs.ID_ASSOCIATED_CPT_CODES
]

export const PRODUCT_INFO_UNIVERSAL_EXCLUDE = [
	// refs.ID_PRODUCT_IMAGE,
	refs.ID_DIVISION,
	refs.ID_PRODUCT_TYPE,
	refs.ID_WEBSITE_LINKS,
	refs.ID_IMAGE_LINKS,
	refs.ID_VIDEO_LINKS,
	refs.ID_PDF_LINKS,
]

export const PRODUCT_INFO_MEDIA_INDEX = [
	refs.ID_WEBSITE_LINKS,
	refs.ID_IMAGE_LINKS,
	refs.ID_VIDEO_LINKS,
	refs.ID_PDF_LINKS,
]

// -- Product Classifier Edit

export const PRODUCT_EDIT_EXCLUDE = [
	// refs.ID_PRODUCT_NUMBER,
	refs.ID_DIVISION,
	refs.ID_PRODUCT_TYPE,
]

export const PRODUCT_REPORT_EXCLUDE = [
	refs.ID_PRODUCT_NUMBER,
	refs.ID_DIVISION,
	refs.ID_PRODUCT_TYPE,
]

export const PRODUCT_EDIT_INDEX = [
	refs.ID_CATEGORY,
	refs.ID_GROUP,
	refs.ID_BUSINESS_UNIT,
	refs.ID_DIVISION,
	refs.ID_PRODUCT_TYPE,
	refs.ID_PRODUCT_NUMBER,
	refs.ID_COMPANY,
	refs.ID_DESCRIPTION,
	// refs.ID_PRODUCT_IMAGE
]

// -- Purchase Info

export const PURCHASE_INFO_INDEX = [
	refs.ID_PRODUCT_NUMBER,
	refs.ID_PURCHASE_DATE,
	refs.ID_CLIENT,
	refs.ID_PURCHASE_PRICE,
	refs.ID_PURCHASE_QTY,
]

export const DEFAULT_GPS_EXPORT_UNIVERSAL_INDEX = [
	refs.ID_COMPANY,
	refs.ID_PRODUCT_NUMBER,
	refs.ID_SYSTEM,
	refs.ID_DESCRIPTION,
	refs.ID_ORIGINAL_PRODUCT_SKU,
]

export const GPS_EXPORT_CLASSIFIER_ORDER = [
	refs.ID_BUSINESS_UNIT,
	refs.ID_DIVISION,
	refs.ID_PRODUCT_TYPE,
	refs.ID_CATEGORY,
	refs.ID_GROUP,
	refs.ID_CLASSIFICATION,
	refs.ID_COMPANY,
	refs.ID_ORIGINAL_PRODUCT_SKU,
	refs.ID_PRODUCT_NUMBER,
	refs.ID_SYSTEM,
	refs.ID_DESCRIPTION,
	refs.ID_APPROVAL_PATHWAY,
	refs.ID_STATUS,
	refs.ID_QUANTITY_INCLUDED,
	refs.ID_UNIT_OF_MEASURE,
	refs.ID_IMAGE_LINKS,
	refs.ID_VIDEO_LINKS,
	refs.ID_PDF_LINKS,
	refs.ID_WEBSITE_LINKS,
]

export const GPS_EXPORT_UNIVERSAL_INDEX = [refs.ID_QUANTITY_INCLUDED]
export const INSTANT_GPS_EXPORT_USAGE_UNIVERSAL_INDEX = [
	refs.ID_ORIGINAL_PRODUCT_SKU,
	refs.ID_VERIFIED,
	refs.ID_CROSS_COMMENTS,
	refs.ID_CROSS_OPTION_2,
	refs.ID_CROSS_OPTION_3,
	refs.ID_CROSS_OPTION_4,
]

export const INSTANT_GPS_EXPORT_REC_UNIVERSAL_INDEX = [
	refs.ID_PRODUCT_TYPE,
	refs.ID_ORIGINAL_PRODUCT_SKU,
	refs.ID_MAJOR_CATEGORY,
	refs.ID_MINOR_CATEGORY,
	refs.ID_COUNTRY_OF_ORIGIN,
]

// -- Product Sheet

export const PRODUCT_SHEET_EXCLUDE = [refs.ID_DIVISION, refs.ID_PRODUCT_TYPE]

// -- Purchase Sheet

export const PURCHASE_SHEET_EXCLUDE = []

export const PURCHASE_SHEET_INCLUDE_UNIVERSAL = [
	refs.ID_UNIT_OF_MEASURE,
	refs.ID_CLASSIFICATION,
	refs.ID_PRODUCT_NUMBER,
]

// -- Price Sheet

export const PRICE_SHEET_INCLUDE = [refs.ID_PRODUCT_NUMBER]

// -- Purchase Classifiers for Suppliers

export const SUPPLIER_PURCHASE_CLASSIFIERS = {
	default: [
		refs.ID_PRODUCT_NUMBER,
		refs.ID_PURCHASE_PRICE,
		refs.ID_PURCHASE_QTY,
		refs.ID_QUANTITY_EACHES,
		refs.ID_MANUFACTURER,
		refs.ID_ITEM_DESCRIPTION,
	],
}

// -- RFPI

export const RFPI_TEMPLATE_INDEX = [
	refs.ID_PRODUCT_NUMBER,
	refs.ID_DIVISION,
	refs.ID_PRODUCT_TYPE,
	// @todo
]
