import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import BasicLoader from './BasicLoader'

const styles = (theme) => ({
	container: {
		zIndex: 1000,
		position: 'absolute',
		top: 0,
		right: 0,
		bottom: 0,
		left: 0,
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: '#fff',
		opacity: 0.6,
	},
	description: {
		marginLeft: '1.5em',
	},
	center: {
		alignItems: 'center',
	},
	top: {
		paddingTop: theme.spacing(4),
		alignItems: 'top',
	},
})

const OverlayLoader = ({
	classes,
	loading,
	position,
	hideLoader,
	description,
}) => {
	if (!loading) {
		return null
	}

	return (
		<div
			className={classNames(classes.container, {
				[classes.center]: Boolean(position === 'center'),
				[classes.top]: Boolean(position === 'top'),
			})}
		>
			{!hideLoader && <BasicLoader size={35} thickness={3} />}
			<Typography variant="h5" className={classes.description}>
				{description}
			</Typography>
		</div>
	)
}

OverlayLoader.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
	// -- passed props
	loading: PropTypes.bool,
	position: PropTypes.oneOf(['center', 'top']),
	hideLoader: PropTypes.bool,
}

OverlayLoader.defaultProps = {
	position: 'center',
}

export default withStyles(styles)(OverlayLoader)
