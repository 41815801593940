import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import Snackbar from '@mui/material/Snackbar'
import SnackbarContent from '@mui/material/SnackbarContent'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import InfoIcon from '@mui/icons-material/Info'
import teal from '@mui/material/colors/teal'
import { actions as alertActions } from 'state/modules/alerts'
import {
	DEFAULT as ALERT_DEFAULT,
	SUCCESS as ALERT_SUCCESS,
	ERROR as ALERT_ERROR,
} from 'data/alert-types'

const DEFAULT_TIMEOUT = 5000

const variantIcon = {
	[ALERT_DEFAULT]: InfoIcon,
	[ALERT_SUCCESS]: CheckCircleIcon,
	[ALERT_ERROR]: ErrorIcon,
}

const styles = (theme) => ({
	[ALERT_DEFAULT]: {
		backgroundColor: `${theme.palette.primary.dark}!important`,
	},
	[ALERT_SUCCESS]: {
		backgroundColor: `${teal.A700}!important`,
	},
	[ALERT_ERROR]: {
		backgroundColor: `${theme.palette.secondary.main}!important`,
	},
	icon: {
		fontSize: 20,
	},
	iconVariant: {
		opacity: 0.9,
		marginRight: `${theme.spacing(1)}!important`,
	},
	message: {
		display: 'flex',
		alignItems: 'center',
	},
})

const mapStateToProps = (state) => ({
	alerts: state.alerts.items,
})

const mapDispatchToProps = (dispatch) => ({
	hideAlert: (obj) => dispatch(alertActions.hideAlert(obj)),
})

class AlertContainer extends React.PureComponent {
	render() {
		const { alerts, hideAlert } = this.props

		return (
			<div>
				{alerts.map((alert, index) => (
					<AlertStyled
						key={alert.id}
						alert={alert}
						handleClose={(alert) => hideAlert(alert)}
					/>
				))}
			</div>
		)
	}
}

AlertContainer.propTypes = {
	classes: PropTypes.object.isRequired,
	alerts: PropTypes.array.isRequired,
	hideAlert: PropTypes.func.isRequired,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles)(AlertContainer))

// --

class Alert extends React.PureComponent {
	// -- event handlers
	handleClose = this.handleClose.bind(this)

	handleClose() {
		const { alert, handleClose } = this.props

		if (alert.handleClose) {
			alert.handleClose()
			handleClose(alert)
		} else {
			handleClose(alert)
		}
	}

	render() {
		const { classes, alert } = this.props
		const { timeout, message, type } = alert
		const Icon = variantIcon[type || ALERT_DEFAULT]

		return (
			<Snackbar
				open={alert.open}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				autoHideDuration={timeout || DEFAULT_TIMEOUT}
				onClose={this.handleClose}
			>
				<SnackbarContent
					className={classNames(classes[type])}
					message={
						<span id="client-snackbar" className={classes.message}>
							<Icon className={classNames(classes.icon, classes.iconVariant)} />
							{message}
						</span>
					}
					action={[
						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							onClick={this.handleClose}
						>
							<CloseIcon />
						</IconButton>,
					]}
				/>
			</Snackbar>
		)
	}
}

Alert.propTypes = {
	classes: PropTypes.object.isRequired,
	alert: PropTypes.object.isRequired,
	handleClose: PropTypes.func.isRequired,
}

const AlertStyled = withStyles(styles)(Alert)
