/**
 * Classifier Reference Strings
 *
 * These references exist for the purpose of the client to access specific
 * classifier information that is vital to the application.
 *
 * The keys in this document are a 1:1 mapping to keys that exist on the server.
 *
 * Classifiers are mapped to Classifier Reference Strings through ClassifierDefIds
 *
 * ex:
 * classifierDefId = { id: 0000, ref: 'product_description' }
 * classifier = { id: 0000 }
 * If we want to find classifier information for 'product_description', we
 * find the classifierDefId.ref === 'product_description' and grab its id.
 * We then use that id to find the correct classifier.
 *
 * These strings are mapped to classifier UUIDs in classifier-def-ids redux module
 * and available as 'refsToIds' and `idsToRefs`
 */

// Universal
export const ID_COMPANY = 'company'
export const ID_DESCRIPTION = 'product_description'
export const ID_DIVISION = 'division'
export const ID_PRODUCT_TYPE = 'product_type'
export const ID_CATEGORY = 'category'
export const ID_GROUP = 'group'
export const ID_BUSINESS_UNIT = 'business_unit'
export const ID_STATUS = 'status'
export const ID_CLASSIFICATION = 'classification'
export const ID_SYSTEM = 'system'
export const ID_PRODUCT_NUMBER = 'product_number'
export const ID_ORIGINAL_PRODUCT_SKU = 'original_product_sku'
export const ID_WEBSITE_LINKS = 'website_links'
export const ID_IMAGE_LINKS = 'image_links'
export const ID_VIDEO_LINKS = 'video_links'
export const ID_PDF_LINKS = 'pdf_links'
export const ID_QUANTITY_INCLUDED = 'qty_included'
export const ID_GTIN_NUMBER = 'gtin_number'
export const ID_CONTRACT_PRICE = 'contract_price'
export const ID_UNIT_OF_MEASURE = 'unit_of_measure'
export const ID_VERIFIED = 'verified'
export const ID_CROSS_COMMENTS = 'cross_comments'
export const ID_CROSS_OPTION_2 = 'cross_option_2'
export const ID_CROSS_OPTION_3 = 'cross_option_3'
export const ID_CROSS_OPTION_4 = 'cross_option_4'
export const ID_MAJOR_CATEGORY = 'major_category'
export const ID_MINOR_CATEGORY = 'minor_category'
export const ID_COUNTRY_OF_ORIGIN = 'country_of_origin'

// export const ID_PRODUCT_IMAGE = 'product_image'
// export const ID_PRODUCT_REF = 'product_ref'
// export const ID_RELATED_PRODUCTS = 'related_products'
// export const ID_CONTRACT_STATUS = 'contract_status'
// export const ID_ASSOCIATED_PROCEDURES = 'associated_procedures'
// export const ID_ASSOCIATED_ICD_10S = 'associated_icd_10s'
// export const ID_ASSOCIATED_CPT_CODES = 'associated_cpt_codes'

// Purchase
export const ID_PURCHASE_DATE = 'purchase_date'
export const ID_PURCHASE_PRICE = 'purchase_price'
export const ID_PURCHASE_QTY = 'purchase_quantity'
export const ID_CLIENT = 'client'
export const ID_CASE = 'case'
export const ID_FACILITY = 'facility'
export const ID_PHYSICIAN = 'physician'
export const ID_RVP = 'rvp'
export const ID_MP = 'mp'
export const ID_MANUFACTURER = 'manufacturer'
export const ID_PROCEDURE = 'procedure'
export const ID_PROCEDURE_COMBINATION = 'procedure_combination'
export const ID_VENDOR = 'vendor'
export const ID_CEO = 'ceo'
export const ID_EXISITING_USE = 'exisiting_use'
export const ID_PURCH_CLASSIFICATION = 'purch_classification'
export const ID_APPROVAL_PATHWAY = 'approval_pathway'
export const ID_QUANTITY_EACHES = 'quantity_eaches'
export const ID_ITEM_DESCRIPTION = 'item_description'

export default [
	// -- Universal
	ID_CLASSIFICATION,
	ID_COMPANY,
	ID_DESCRIPTION,
	ID_DIVISION,
	ID_PRODUCT_TYPE,
	ID_CATEGORY,
	ID_GROUP,
	ID_BUSINESS_UNIT,
	ID_STATUS,
	ID_SYSTEM,
	ID_PRODUCT_NUMBER,
	ID_ORIGINAL_PRODUCT_SKU,
	ID_WEBSITE_LINKS,
	ID_IMAGE_LINKS,
	ID_VIDEO_LINKS,
	ID_PDF_LINKS,
	ID_QUANTITY_INCLUDED,
	ID_GTIN_NUMBER,
	ID_CONTRACT_PRICE,
	ID_UNIT_OF_MEASURE,
	ID_VERIFIED,
	ID_CROSS_COMMENTS,
	ID_CROSS_OPTION_2,
	ID_CROSS_OPTION_3,
	ID_CROSS_OPTION_4,
	ID_MAJOR_CATEGORY,
	ID_MINOR_CATEGORY,
	ID_COUNTRY_OF_ORIGIN,
	// -- Purchase
	ID_PURCHASE_DATE,
	ID_PURCHASE_PRICE,
	ID_PURCHASE_QTY,
	ID_CLIENT,
	ID_CASE,
	ID_FACILITY,
	ID_PHYSICIAN,
	ID_RVP,
	ID_MP,
	ID_MANUFACTURER,
	ID_PROCEDURE,
	ID_PROCEDURE_COMBINATION,
	ID_VENDOR,
	ID_CEO,
	ID_PROCEDURE_COMBINATION,
	ID_PURCH_CLASSIFICATION,
	ID_APPROVAL_PATHWAY,
	ID_QUANTITY_EACHES,
	ID_ITEM_DESCRIPTION,
]
