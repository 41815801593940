/**
 * Classifier Types
 *
 * The keys in this document map to keys that exist on the server.
 *
 */

export const UNIVERSAL = 'universal'
export const PURCHASE = 'purchase'
export const PRODUCT = 'product'
export const IDENTIFY = 'identify'
export const CUSTOM_PRICE = 'custom_price'

// To present type as string in UI
export const map = {
	[UNIVERSAL]: 'Universal',
	[PURCHASE]: 'Purchase',
	[PRODUCT]: 'Product',
	[IDENTIFY]: 'Identify'
}

// For use in dropdown fields, etc
export const list = [
	{ value: UNIVERSAL, text: map[UNIVERSAL] },
	{ value: PURCHASE, text: map[PURCHASE] },
	{ value: PRODUCT, text: map[PRODUCT] }
	// { value: IDENTIFY, text: map[IDENTIFY] }
]
