import { sortBy } from 'lodash'
import {
	DivisionCollection,
	ProductTypeCollection,
	ClassifierCollection
} from 'models'
import classifierRefs from 'data/classifier-refs'
import { actions as alertActions } from './alerts'
import { SUCCESS as ALERT_SUCCESS } from 'data/alert-types'
import { config as API } from 'api'
import * as postgrest from 'services/postgrest'

const NAME = 'TAXONOMIES'
export const MODULE_NAME = 'taxonomies'
const ENDPOINT = API.TAXONOMY_AGGREGATE.PATH
const DIVISIONS = 'divisions'
const PRODUCT_TYPES = 'product_types'
const CLASSIFIER_DEFS = 'classifier_defs'
const CLASSIFIER_DEF_IDS = 'classifier_def_ids'

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	divisions: new DivisionCollection(),
	productTypes: new ProductTypeCollection(),
	classifiers: new ClassifierCollection(),
	customPrices: [],
	refsToIds: {},
	idsToRefs: {}
}

const RESET = `${NAME}_RESET`
const REQUESTED = `${NAME}_REQUESTED`
const FETCHED_ALL = `${NAME}_FETCHED_ALL`
const FETCHED_CUSTOM_PRICES = `${NAME}_FETCHED_CUSTOM_PRICES`
const ADD_TAXONOMY_MODEL = `ADD_TAXONOMY_MODEL`
const UPDATE_TAXONOMY_MODEL = `UPDATE_TAXONOMY_MODEL`
const REMOVE_TAXONOMY_MODEL = `REMOVE_TAXONOMY_MODEL`
const FAILED = `${NAME}_FAILED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
// receive can take FETCHED_ALL
const receive = (action, payload) => ({ type: action, payload })
const fail = error => ({ type: FAILED, payload: error })

const addDivision = model => {
	return dispatch => {
		dispatch({
			type: ADD_TAXONOMY_MODEL,
			taxonomyType: 'divisions',
			model
		})
		dispatch(
			alertActions.showAlert({
				message: `${model.get('name')} Division created`,
				type: ALERT_SUCCESS
			})
		)
	}
}
const addProductType = model => {
	return dispatch => {
		dispatch({
			type: ADD_TAXONOMY_MODEL,
			taxonomyType: 'productTypes',
			model
		})
		dispatch(
			alertActions.showAlert({
				message: `${model.get('name')} Product Type created`,
				type: ALERT_SUCCESS
			})
		)
	}
}
const addClassifier = model => {
	return dispatch => {
		dispatch({
			type: ADD_TAXONOMY_MODEL,
			taxonomyType: 'classifiers',
			model
		})
		dispatch(
			alertActions.showAlert({
				message: `${model.get('name')} Classifier created`,
				type: ALERT_SUCCESS
			})
		)
	}
}
const addCustomPrice = val => {
	return (dispatch, getState) => {
		dispatch(request())
		let customPrices = getState()[MODULE_NAME].customPrices
		return postgrest
			.create(API.PRODUCT_PRICE_TIERS.PATH, { description: val })
			.then(response => {
				customPrices = [response, ...customPrices]
				dispatch(
					receive(FETCHED_CUSTOM_PRICES, {
						customPrices
					})
				)
				dispatch(
					alertActions.showAlert({
						message: `${val} created`,
						type: ALERT_SUCCESS
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const updateCustomPrice = model => {
	return (dispatch, getState) => {
		dispatch(request())
		let customPrices = getState()[MODULE_NAME].customPrices
		return postgrest
			.save(API.PRODUCT_PRICE_TIERS.PATH, model.id, {
				description: model.description
			})
			.then(response => {
				const objIndex = customPrices.findIndex(cp => cp.id === model.id)
				customPrices[objIndex].description = model.description

				dispatch(
					receive(FETCHED_CUSTOM_PRICES, {
						customPrices: [...customPrices]
					})
				)
				dispatch(
					alertActions.showAlert({
						message: `Custom Price Updated`,
						type: ALERT_SUCCESS
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const removeCustomPrice = model => {
	return (dispatch, getState) => {
		dispatch(request())
		const customPrices = getState()[MODULE_NAME].customPrices
		return postgrest
			.destroy(API.PRODUCT_PRICE_TIERS.PATH, model.id)
			.then(response => {
				const filteredPrices = customPrices.filter(cp => cp.id !== model.id)

				dispatch(
					receive(FETCHED_CUSTOM_PRICES, {
						customPrices: [...filteredPrices]
					})
				)
				dispatch(
					alertActions.showAlert({
						message: `Custom Price Deleted`
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const updateDivision = model => ({
	type: UPDATE_TAXONOMY_MODEL,
	taxonomyType: 'divisions',
	model
})
const updateProductType = model => ({
	type: UPDATE_TAXONOMY_MODEL,
	taxonomyType: 'productTypes',
	model
})
const updateClassifier = model => ({
	type: UPDATE_TAXONOMY_MODEL,
	taxonomyType: 'classifiers',
	model
})

const removeDivision = id => {
	return dispatch => {
		dispatch({
			type: REMOVE_TAXONOMY_MODEL,
			taxonomyType: 'divisions',
			id
		})
		dispatch(
			alertActions.showAlert({
				message: `Division deleted`
			})
		)
	}
}

const removeProductType = id => {
	return dispatch => {
		dispatch({
			type: REMOVE_TAXONOMY_MODEL,
			taxonomyType: 'productTypes',
			id
		})
		dispatch(
			alertActions.showAlert({
				message: `Product Type deleted`
			})
		)
	}
}

const removeClassifier = id => {
	return dispatch => {
		dispatch({
			type: REMOVE_TAXONOMY_MODEL,
			taxonomyType: 'classifiers',
			id
		})
		dispatch(
			alertActions.showAlert({
				message: `Classifier deleted`
			})
		)
	}
}

const fetchCustomPrice = () => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.fetchAll(API.PRODUCT_PRICE_TIERS.PATH)
			.then(response => {
				dispatch(
					receive(FETCHED_CUSTOM_PRICES, {
						customPrices: response ? response : []
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const fetchAll = (id, force = false) => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.fetchAll(ENDPOINT)
			.then(response => {
				const body = response[0]
				const divisions = sortBy(body[DIVISIONS], division => division.name)
				const productTypes = sortBy(
					body[PRODUCT_TYPES],
					productType => productType.name
				)
				const classifiers = sortBy(
					body[CLASSIFIER_DEFS],
					classifier => classifier.name
				)
				const classifierDefIds = body[CLASSIFIER_DEF_IDS]
				const referenceMaps = createClassifierReferenceMaps(classifierDefIds)

				dispatch(
					receive(FETCHED_ALL, {
						divisions,
						productTypes,
						classifiers,
						classifierDefIds,
						...referenceMaps // idsToRefs and refsToIds objects
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

function createClassifierReferenceMaps(classifierDefIds) {
	let refsToIds = {}
	let idsToRefs = {}

	if (classifierDefIds && classifierDefIds.length) {
		classifierDefIds.forEach(classifierDefId => {
			const { ref, id } = classifierDefId
			const match = classifierRefs.find(c => c === ref)
			if (match) {
				refsToIds[ref] = id
				idsToRefs[id] = ref
			}
		})
	}

	return { refsToIds, idsToRefs }
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED_ALL]: (state, { payload }) => ({
		...state,
		divisions: new DivisionCollection(payload.divisions),
		productTypes: new ProductTypeCollection(payload.productTypes),
		classifiers: new ClassifierCollection(payload.classifiers),
		refsToIds: payload.refsToIds,
		idsToRefs: payload.idsToRefs,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED_CUSTOM_PRICES]: (state, { payload }) => ({
		...state,
		customPrices: payload.customPrices,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[ADD_TAXONOMY_MODEL]: (state, { taxonomyType, model }) => ({
		...state,
		[taxonomyType]: state[taxonomyType].addModel(model)
	}),
	[UPDATE_TAXONOMY_MODEL]: (state, { taxonomyType, model }) => ({
		...state,
		[taxonomyType]: state[taxonomyType].updateModel(model)
	}),
	[REMOVE_TAXONOMY_MODEL]: (state, { taxonomyType, id }) => ({
		...state,
		[taxonomyType]: state[taxonomyType].removeModel(id)
	}),
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	reset,
	fetchAll,
	fetchCustomPrice,
	addDivision,
	addProductType,
	addClassifier,
	addCustomPrice,
	updateDivision,
	updateProductType,
	updateClassifier,
	updateCustomPrice,
	removeDivision,
	removeProductType,
	removeClassifier,
	removeCustomPrice
}
