import { ProductCollection } from 'models'
import * as postgrest from 'services/postgrest'
import { config as API } from 'api'
// import { actions as alertActions } from './alerts'
import { getContentRangeTotal } from 'utils/headers'
import { withClassifierValsPromise } from 'state/modules/products'
import { MODULE_NAME as PRODUCT_FILTERS_MODULE_NAME } from './product-filters'
import { YES, NO, ALL } from 'data/product-sort-types'

const COLLECTION_NAME = 'RELATED_PRODUCTS'
// const MODEL_NAME = 'RELATED_PRODUCT'
const MODULE_NAME = 'relatedProducts' // this module's name in the rootReducer
const PRODUCTS_MODULE_NAME = 'products'
const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 5

const FETCH_PAGE_SELECT =
	'number, system, is_direct, is_restricted, is_manual, match_type, relator_group'

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	results: new ProductCollection(),
	relatedProductStats: {},
	fetchStatsRequest: null,
	totalResults: 0,
	perPage: DEFAULT_PER_PAGE,
	page: DEFAULT_PAGE
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const FETCHED_PAGE = `${COLLECTION_NAME}_PAGE_FETCHED`
const FAILED = `${COLLECTION_NAME}_FAILED`
const REQUESTED_STATS = `${COLLECTION_NAME}_STATS_REQUESTED`
const FETCHED_STATS = `${COLLECTION_NAME}_STATS_FETCHED`

export const reset = () => ({ type: RESET })
export const request = () => ({ type: REQUESTED })
// receive can take FETCHED actions
export const receive = (action, fields) => ({ type: action, payload: fields })
export const receivePage = (results, page, totalResults) => ({
	type: FETCHED_PAGE,
	payload: { results, page, totalResults }
})
export const fail = error => ({ type: FAILED, payload: error })

/**
 * Fetch resources using limit-offset pagination
 * thunk
 *
 * @see https://postgrest.com/en/v4.3/api.html#limits-and-pagination
 */
const fetchPage = (newPage, productNumber) => {
	return (dispatch, getState) => {
		dispatch(request())

		const { page, perPage } = getState()[MODULE_NAME]
		const _page = newPage !== undefined ? newPage : page
		const _productNumber =
			productNumber !== undefined
				? productNumber
				: getState()[PRODUCTS_MODULE_NAME].currentModel.id()
		const query = { select: FETCH_PAGE_SELECT }
		const payload = { in_product_number: _productNumber }

		let totalResults = 0
		return postgrest
			.fetchPageWithPost(
				API.RELATED_PRODUCTS.PATH,
				_page,
				perPage,
				query,
				payload
			)
			.then(response => {
				totalResults = getContentRangeTotal(response.headers)
				return withClassifierValsPromise(response.body)
			})
			.then(responseBody => {
				dispatch(receivePage(responseBody, _page, totalResults))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * This method does not affect the results state.
 * It is only used to fetch all related products for product comparison table and return them directly.
 *
 * @see ProductInfoRelated.js
 */
const fetchAll = (productNumber, ids = []) => {
	return (dispatch, getState) => {
		const _productNumber =
			productNumber !== undefined
				? productNumber
				: getState()[PRODUCTS_MODULE_NAME].currentModel.id()
		const query = { select: FETCH_PAGE_SELECT }
		const payload = { in_product_number: _productNumber }

		return postgrest
			.fetchAllWithPost(API.RELATED_PRODUCTS.PATH, query, payload)
			.then(responseBody => {
				responseBody =
					ids && ids.length
						? responseBody.filter(rb => ids.indexOf(rb.number) > -1)
						: responseBody
				return withClassifierValsPromise(responseBody)
			})
			.then(responseBody => {
				return new ProductCollection(responseBody)
			})
			.catch(error => {
				return error
			})
	}
}

/**
 * Cancel Related Products Stats Request
 */
const cancelFetchStatsRequest = () => {
	return (dispatch, getState) => {
		const { fetchStatsRequest } = getState()[MODULE_NAME]
		if (fetchStatsRequest && fetchStatsRequest.xhr.readyState === 1) {
			fetchStatsRequest.abort()
		}
	}
}

/**
 * Get Related Products Stats
 * thunk
 */
const fetchRelatedProductStats = () => {
	return (dispatch, getState) => {
		dispatch(cancelFetchStatsRequest())
		dispatch(request())
		const { filters, searchQuery, existingUse } = getState()[
			PRODUCT_FILTERS_MODULE_NAME
		]
		const payload = {
			fts_string: searchQuery,
			filters
		}
		switch (existingUse) {
			case YES:
				payload['p_existing_use'] = true
				break
			case NO:
				payload['p_existing_use'] = false
				break
			case ALL:
				delete payload.p_existing_use
				break
			default:
				delete payload.p_existing_use
				break
		}
		let reqObj = postgrest.fetchAllWithPost(
			API.PRODUCT_RELATED_PRODUCTS_STATS.PATH,
			'',
			payload,
			true,
			false,
			false,
			true
		)

		dispatch(receive(REQUESTED_STATS, { fetchStatsRequest: reqObj.req }))

		return reqObj.promise
			.then(response => {
				dispatch(
					receive(FETCHED_STATS, {
						relatedProductStats: response && response.length ? response[0] : {}
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED_PAGE]: (state, { payload }) => ({
		...state,
		results: new ProductCollection(payload.results),
		page: payload.page,
		totalResults: payload.totalResults,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[REQUESTED_STATS]: (state, { payload }) => ({
		...state,
		fetchStatsRequest: payload.fetchStatsRequest
	}),
	[FETCHED_STATS]: (state, { payload }) => ({
		...state,
		relatedProductStats: payload.relatedProductStats,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetch,
	fetchAll,
	fetchPage,
	reset,
	fetchRelatedProductStats,
	cancelFetchStatsRequest
}
