import { cloneDeep } from 'lodash'
// @todo replace with AccountCollection / AccountModel
import { UserCollection, UserModel } from 'models'
import { config as API } from 'api'
import { getContentRangeTotal } from 'utils/headers'
import * as postgrest from 'services/postgrest'

const ENDPOINT = API.ACCOUNTS.PATH
const TYPES_ENDPOINT = API.ACCOUNT_TYPES.PATH
const TYPE_ROLES_ENDPOINT = API.ACCOUNT_TYPES_ROLES.PATH
const COLLECTION_NAME = 'ACCOUNTS'
const MODEL_NAME = 'ACCOUNT'
const MODULE_NAME = 'accounts' // this module's name in the rootReducer
const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 20

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	pendingFields: {},
	types: [],
	typeRoles: [],
	currentAccount: {},
	currentModel: new UserModel(),
	results: new UserCollection(),
	totalResults: 0,
	perPage: DEFAULT_PER_PAGE,
	page: DEFAULT_PAGE
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const FETCHED = `${MODEL_NAME}_FETCHED`
const FETCHED_PAGE = `${COLLECTION_NAME}_PAGE_FETCHED`
const FETCHED_TYPES = `${COLLECTION_NAME}_TYPES_FETCHED`
const FETCHED_TYPE_ROLES = `${COLLECTION_NAME}_TYPE_ROLES_FETCHED`
const EDIT_SAVED = `${MODEL_NAME}_EDIT_SAVED`
const EDIT_CLEARED = `${MODEL_NAME}_EDIT_CLEARED`
const REPLACED = `${MODEL_NAME}_REPLACED`
const CREATED = `${MODEL_NAME}_CREATED`
const SAVED = `${MODEL_NAME}_SAVED`
const DESTROYED = `${MODEL_NAME}_DESTROYED`
const FAILED = `${COLLECTION_NAME}_FAILED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
// receive can take FETCHED, CREATED, SAVED, DESTROYED, REPLACED actions
const receive = (action, fields) => ({ type: action, payload: fields })
const receivePage = (results, page, totalResults) => ({
	type: FETCHED_PAGE,
	payload: { results, page, totalResults }
})
const saveEdit = fields => ({ type: EDIT_SAVED, payload: fields })
const clearEdit = fields => ({ type: EDIT_CLEARED })
const fail = error => ({ type: FAILED, payload: error })

/**
 * Fetch single resource
 * thunk
 */
const fetch = (id, force = false) => {
	return (dispatch, getState) => {
		// if this model already exists in the results array, no need to fetch again
		// just set it as the currentModel
		if (!force) {
			const { results } = getState()[MODULE_NAME]
			const existingModel =
				results && results.size() ? results.findById(id) : null

			if (existingModel) {
				return new Promise(resolve => {
					dispatch(receive(REPLACED, existingModel))
					resolve()
				})
			}
		}

		dispatch(request())

		return postgrest
			.fetch(ENDPOINT, id)
			.then(response => {
				dispatch(receive(FETCHED, response))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Fetch resources using limit-offset pagination
 * thunk
 *
 * @see https://postgrest.com/en/v4.3/api.html#limits-and-pagination
 */
const fetchPage = page => {
	return (dispatch, getState) => {
		dispatch(request())

		const { perPage } = getState()[MODULE_NAME]
		const query = { order: 'created_at.desc' }

		return postgrest
			.fetchPage(ENDPOINT, page, perPage, query)
			.then(response => {
				const totalResults = getContentRangeTotal(response.headers)
				dispatch(receivePage(response.body, page, totalResults))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const fetchTypes = () => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.fetchAll(TYPES_ENDPOINT)
			.then(response => {
				dispatch(receive(FETCHED_TYPES, response[0].account_types))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const fetchTypeRoles = () => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.fetchAll(TYPE_ROLES_ENDPOINT)
			.then(response => {
				dispatch(receive(FETCHED_TYPE_ROLES, response))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Create single resource
 * thunk
 */
const create = () => {
	return (dispatch, getState) => {
		dispatch(request())

		const fields = getState()[MODULE_NAME].pendingFields

		return postgrest
			.create(ENDPOINT, fields)
			.then(response => {
				dispatch(receive(CREATED, response))
				return response
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Save single resource
 * thunk
 */
const save = fields => {
	return (dispatch, getState) => {
		dispatch(request())

		const id = getState()[MODULE_NAME].currentModel.id()

		return postgrest
			.save(ENDPOINT, id, fields)
			.then(response => {
				dispatch(receive(SAVED, response))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Destroy single resource
 * thunk
 */
const destroy = id => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.destroy(ENDPOINT, id)
			.then(() => {
				dispatch(receive(DESTROYED, id))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[EDIT_SAVED]: (state, { payload }) => {
		const fields = Object.assign(state.pendingFields, payload)

		return {
			...state,
			pendingFields: cloneDeep(fields)
		}
	},
	[EDIT_CLEARED]: (state, action) => ({
		...state,
		pendingFields: {}
	}),
	[FETCHED_PAGE]: (state, { payload }) => ({
		...state,
		results: new UserCollection(payload.results),
		page: payload.page,
		totalResults: payload.totalResults,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED_TYPES]: (state, { payload }) => ({
		...state,
		types: payload,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED_TYPE_ROLES]: (state, { payload }) => ({
		...state,
		typeRoles: payload,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		currentModel: new UserModel(payload),
		currentAccount: payload,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[REPLACED]: (state, { payload }) => ({
		...state,
		currentModel: payload
	}),
	[CREATED]: (state, { payload }) => ({
		...state,
		currentModel: new UserModel(payload),
		pendingFields: {},
		fetchError: null,
		isFetching: false
	}),
	[SAVED]: (state, { payload }) => ({
		...state,
		currentModel: new UserModel(payload),
		fetchError: null,
		isFetching: false
	}),
	[DESTROYED]: (state, action) => ({
		...state,
		currentModel: new UserModel(),
		fetchError: null,
		isFetching: false
	}),
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetch,
	fetchPage,
	fetchTypes,
	fetchTypeRoles,
	saveEdit,
	clearEdit,
	create,
	destroy,
	save,
	reset
}
