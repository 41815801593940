/**
 * Heroes correspond to ViewHero components and
 * store the open/collapsed state of the content
 */

// const PDC = 'PDC'
export const GPS = 'GPS'
export const ALTA = 'ALTA'
export const RFPI = 'RFPI'
export const TAXONOMY = 'TAXONOMY'
export const TEAM = 'TEAM'

const initialState = {
	[GPS]: {
		open: true
	},
	[ALTA]: {
		open: true
	},
	[RFPI]: {
		open: true
	},
	[TAXONOMY]: {
		open: true
	},
	[TEAM]: {
		open: true
	}
}

const OPEN_HERO = 'OPEN_HERO'
const CLOSE_HERO = 'CLOSE_HERO'

const openHero = key => ({
	type: OPEN_HERO,
	key
})

const closeHero = key => ({
	type: CLOSE_HERO,
	key
})

const ACTION_HANDLERS = {
	[OPEN_HERO]: (state, { key }) => ({
		...state,
		[key]: Object.assign(state[key], { open: true })
	}),
	[CLOSE_HERO]: (state, { key }) => ({
		...state,
		[key]: Object.assign(state[key], { open: false })
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	openHero,
	closeHero
}
