/**
 * Services for formatting dates using moment.js
 *
 * @see http://momentjs.com/docs
 */

import Moment from 'moment'

/**
 * Format timeago for timestamps
 * Currently set to use different formats if timeago is older than two hours
 *
 * @param {date} timestamp
 */
export const getTimeago = timestamp => {
	if (!timestamp) {
		return null
	}

	const twoHoursAgo = Moment()
		.subtract(2, 'hours')
		.toDate()
	const time = Moment(timestamp)

	if (time.toDate() <= twoHoursAgo) {
		return time.calendar()
	} else {
		return time.fromNow()
	}
}

/**
 * format: MM-DD-YYYY
 */
export const getALTAFormat = date => {
	if (!date) {
		return null
	}

	return Moment.utc(date).format('MM-DD-YYYY')
}

/**
 * format: 01/27/2016
 */
export const getSheetFormat = date => {
	if (!date) {
		return null
	}

	return Moment(date).format('MM/DD/YYYY')
}

/**
 * JSON ISO 8601
 * format: 2012-12-10T14:50:12.123+02:00
 */
export const getISOFormat = date => {
	if (!date) {
		return null
	}

	return Moment(date).toISOString()
}

/**
 * standard format: 01/27/2016
 */
export const getRangeFormat = date => {
	if (!date) {
		return null
	}

	// return Moment(timestamp).format('MM/DD/YYYY')
	return date.toISOString()
}

/**
 * 2019-04-11
 */
export const getDatePickerFormat = timestamp => {
	if (!timestamp) {
		return null
	}

	return Moment(timestamp).format('YYYY-MM-DD')
}

/**
 * standard format: 1/27/16
 */
export const getStandardFormat = timestamp => {
	if (!timestamp) {
		return null
	}

	return Moment.utc(timestamp).format('M/DD/YY')
}

/**
 * full format: 11/27/16 at 5:30 PM
 */
export const getFullFormat = (timestamp, isUTC = true) => {
	if (!timestamp) {
		return null
	}

	return isUTC
		? Moment.utc(timestamp).format('M/DD/YY [at] h:mmA')
		: Moment(timestamp).format('M/DD/YY [at] h:mmA')
}

/**
 * full format: November 27, 2018
 */
export const getWordFormat = timestamp => {
	if (!timestamp) {
		return null
	}

	return Moment.utc(timestamp).format('MMMM D, YYYY')
}

/**
 * filename format: 01_27_2017
 */
export const getFilenameFormat = timestamp => {
	if (!timestamp) {
		return null
	}

	return Moment.utc(timestamp).format('MM_DD_YYYY')
}

/**
 * @param {Date} date
 * @return {Number}
 */
export const getDaysElapsed = date => {
	if (!date) {
		return null
	}

	return Moment().diff(date, 'days')
}
