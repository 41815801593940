import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import iconLogo from 'assets/logo.svg'
import nameLogo from 'assets/logo_relatable_black.svg'

const styles = (theme) => ({
	iconLogo: {
		marginLeft: theme.spacing(1),
		width: 30,
	},
	nameLogo: {
		marginLeft: theme.spacing(1),
		width: 180,
	},
})

const PrintFooter = ({ classes, disclaimerText }) => (
	<div className="space-top">
		{disclaimerText && (
			<div className="space-bottom">
				<Typography variant="body1">
					<em>{disclaimerText}</em>
				</Typography>
			</div>
		)}
		<div className="flex align-center space-bottom">
			<Typography variant="body1">Powered by</Typography>
			<img className={classes.iconLogo} src={iconLogo} alt="" />
			<img className={classes.nameLogo} src={nameLogo} alt="Relatable" />
		</div>
		<Typography variant="body1">
			Learn more at <a href="https://relatable.io">https://relatable.io</a>
		</Typography>
	</div>
)

PrintFooter.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
	// -- passed props
	disclaimerText: PropTypes.string,
}

export default withStyles(styles)(PrintFooter)
