// @flow

import { routerActions } from 'connected-react-router'
import { actions as userActions, MODULE_NAME as USER_MODULE_NAME } from './user'
import { actions as taxonomyActions } from './taxonomies'
import { actions as restrictorActions } from './restrictors'
import { actions as alertActions } from './alerts'
import { actions as accountActions } from './accounts'
import { config as API } from 'api'
import { history } from 'state'
import ROUTES, { HOME_ROUTE } from 'routes'
import { ERROR as ALERT_ERROR } from 'data/alert-types'
import * as postgrest from 'services/postgrest'
import * as cacheService from 'services/cache'
import { trackEvent, trackUserProps, identifyUser } from 'services/analytics'
import { registerSuperProps } from '../../services/analytics'

export const STORAGE_KEY = 'session'
export const MODULE_NAME = 'session' // this module's name in the rootReducer
// let intervalTimer = null
const ENDPOINT = API.LOGIN.PATH

// const RESET_PASSWORD_ENDPOINT = API.RESET_PASSWORD.PATH
const PRIVILEGES_ENDPOINT = API.PRIVILEGES.PATH

// -- redux flow

const RESET = 'RESET'
const REQUESTING = 'REQUESTING'
const FAILED = 'FAILED'
const ADD_CREDENTIALS = 'ADD_CREDENTIALS'
const ADD_PRIVILEGES = 'ADD_PRIVILEGES'
const SET_STARTUP_STATE = 'SET_STARTUP_STATE'

// -- action creators

function request() {
	return { type: REQUESTING }
}
function reset() {
	return { type: RESET }
}
function fail(error) {
	return { type: FAILED, error }
}
function addCredentials(credentials) {
	return { type: ADD_CREDENTIALS, credentials }
}
function updateCredentialsEmail(email) {
	let credentials = cacheService.get(STORAGE_KEY)
	credentials.email = email
	// save privileges array to localstorage
	cacheService.store(STORAGE_KEY, {
		...credentials,
	})
	return { type: ADD_CREDENTIALS, credentials }
}
function addPrivileges(privileges) {
	return { type: ADD_PRIVILEGES, privileges }
}
function setStartupState(startupState) {
	return { type: SET_STARTUP_STATE, startupState }
}

// initial state

const initialState = {
	isFetching: false,
	hasFetched: false,
	fetchError: null,
	startupState: 'waiting',
	credentials: {
		token: null,
		email: null,
	},
	privileges: [],
}

// --

function start() {
	return async (dispatch, getState) => {
		const { location } = getState().router
		const { credentials } = getState().session

		// if user attempted to hit an authenticated path while logged out
		// the path is is saved here for rerouting after login is complete
		const loginRedirectPath =
			location.state && location.state.loginRedirect
				? location.state.loginRedirect
				: HOME_ROUTE
		try {
			await Promise.all([
				dispatch(fetchPrivileges()),
				dispatch(userActions.fetchByEmail(credentials.email)),
				dispatch(taxonomyActions.fetchAll()),
				dispatch(restrictorActions.fetchClassifierRestrictors()),
				dispatch(restrictorActions.fetchProductRestrictors()),
			])
			// -- analytics
			const user = getState()[USER_MODULE_NAME].currentModel
			const roleGroup = getState()[USER_MODULE_NAME].roleGroup
			const roleGroups = getState()[USER_MODULE_NAME].roleGroups
			const currentRole = roleGroups.find((role) => role.id === roleGroup)
			const roleName = currentRole ? currentRole.name : ''

			// for auth0 user registeration

			registerSuperProps({
				first_name: user.get('first_name'),
				last_name: user.get('last_name'),
				email: user.get('email'),
				role_group_name: roleName,
			})
			trackEvent('Logged In', { session_type: 'new' })
			identifyUser(user.id())

			// critical to get account to hide certain features
			dispatch(accountActions.fetch(user.get('account_id')))

			trackUserProps({
				$first_name: user.get('first_name'),
				$last_name: user.get('last_name'),
				$email: user.get('email'),
				account_name: user.get('account_name'),
				user_id: user.id(),
				role_group_name: roleName,
			})

			// --
			// a session only becomes isActive once the user data has been fetched
			dispatch(setStartupState('active'))
			dispatch(routerActions.replace(loginRedirectPath))
		} catch (error) {
			// -- analytics
			trackEvent('Login Failed', {
				session_type: 'new',
				error_message: 'Error fetching data.',
			})
			// --
			dispatch(logout())
			setTimeout(() => {
				dispatch(
					alertActions.showAlert({
						message: 'Error logging in',
						type: ALERT_ERROR,
						timeout: 10000,
					}),
				)
			}, 500)
		}
	}
}

function restart(credentials) {
	return async (dispatch, getState) => {
		// if restart fails, save this path so the app can redirect to it
		// after successful re-login
		const { location } = history
		const loginRedirectPath = `${location.pathname}${location.search}${location.hash}`

		// save credentials object to localstorage
		cacheService.store(STORAGE_KEY, credentials)
		dispatch(addCredentials(credentials))
		dispatch(setStartupState('restarting'))

		try {
			await Promise.all([
				dispatch(fetchPrivileges()),
				dispatch(userActions.fetchByEmail(credentials.email)),
				dispatch(taxonomyActions.fetchAll()),
				dispatch(restrictorActions.fetchClassifierRestrictors()),
				dispatch(restrictorActions.fetchProductRestrictors()),
			])
			// -- analytics
			const user = getState()[USER_MODULE_NAME].currentModel
			trackEvent('Logged In', { session_type: 'existing' })
			identifyUser(user.id())
			trackUserProps({
				$first_name: user.get('first_name'),
				$last_name: user.get('last_name'),
				$email: user.get('email'),
				account_name: user.get('account_name'),
				user_id: user.id(),
			})

			// critical to get account to hide certain features
			dispatch(accountActions.fetch(user.get('account_id')))

			// a session only becomes isActive once the user data has been fetched
			dispatch(setStartupState('active'))
			dispatch(routerActions.replace(loginRedirectPath))
		} catch (error) {
			// -- analytics
			trackEvent('Login Failed', {
				session_type: 'existing',
				error_message: 'Error fetching data.',
			})
			dispatch(logout(loginRedirectPath))
			setTimeout(() => {
				dispatch(
					alertActions.showAlert({
						message: 'You have been logged out',
						type: ALERT_ERROR,
						timeout: 10000,
					}),
				)
			}, 500)
		}
	}
}

function fetchPrivileges() {
	return async (dispatch, getState) => {
		dispatch(request())

		try {
			let response = await postgrest.fetchAllWithPost(PRIVILEGES_ENDPOINT)
			// save privileges array to localstorage
			cacheService.store(STORAGE_KEY, {
				...getState().session.credentials,
				privileges: response,
			})
			dispatch(addPrivileges(response))
			return true
		} catch (error) {
			dispatch(fail(error))
			throw error
		}
	}
}

function create(email, pass, token = '', first_name = '', last_name = '') {
	return async (dispatch, getState) => {
		dispatch(request())
		dispatch(setStartupState('starting'))
		if (window.env.AUTH0_METHOD === 'AUTH0') {
			const storageCredentials = {
				token,
				email,
				first_name,
				last_name,
				privileges: [],
			}
			cacheService.store(STORAGE_KEY, storageCredentials) // save to localstorage
			dispatch(addCredentials(storageCredentials))
			dispatch(start())
		} else {
			const normalizedEmail = email.toLowerCase()
			const payload = { email: normalizedEmail, pass }

			try {
				let response = await postgrest.create(ENDPOINT, payload, false)
				const storageCredentials = {
					token: response.token,
					email: normalizedEmail,
					privileges: [],
				}
				cacheService.store(STORAGE_KEY, storageCredentials) // save to localstorage
				dispatch(addCredentials(storageCredentials))
				dispatch(start())
			} catch (error) {
				// -- analytics
				trackEvent('Login Failed', {
					session_type: 'new',
					error_message: error.message,
				})
				// --
				dispatch(setStartupState('waiting'))
				dispatch(fail(error))
				dispatch(
					alertActions.showAlert({
						message: 'Invalid email or password',
						type: ALERT_ERROR,
						timeout: 5000,
					}),
				)
			}
		}
	}
}

// function checkSession(email) {
// 	return (dispatch, getState) => {
// 		return postgrest.fetch(API.USERS.PATH, email, 'email').catch(error => {
// 			// check if the token is expired
// 			if (+error.response.statusCode === 401) {
// 				const { location } = history
// 				const loginRedirectPath = `${location.pathname}${location.search}${location.hash}`
// 				clearInterval(intervalTimer)
// 				dispatch(logout(loginRedirectPath))
// 			}
// 			throw error
// 		})
// 	}
// }

/**
 * @return {Promise}
 */
function logout(loginRedirect) {
	return (dispatch, getState) => {
		cacheService.clear()

		setTimeout(() => {
			// A SESSION_'RESET' will clear the entire state
			// @see rootReducer
			dispatch(reset())
			dispatch(
				routerActions.replace({
					pathname: ROUTES.ROOT,
					state: loginRedirect ? { loginRedirect } : {},
				}),
			)
		}, 250)
	}
}

export default (state = initialState, action) => {
	switch (action.type) {
		case RESET:
			return { ...initialState }
		case REQUESTING:
			return {
				...state,
				fetchError: null,
				isFetching: true,
			}
		case SET_STARTUP_STATE:
			return {
				...state,
				startupState: action.startupState,
			}
		case ADD_CREDENTIALS:
			return {
				...state,
				credentials: action.credentials,
				fetchError: null,
				isFetching: false,
				hasFetched: true,
			}
		case ADD_PRIVILEGES:
			return {
				...state,
				privileges: action.privileges,
				fetchError: null,
				isFetching: false,
				hasFetched: true,
			}
		case FAILED:
			return {
				...state,
				fetchError: action.error,
				isFetching: false,
			}
		default:
			// (action: empty)
			return state
	}
}

export const actions = {
	reset,
	restart,
	create,
	updateCredentialsEmail,
	logout,
}
