import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowLeftIcon from '@mui/icons-material/ArrowBack'
import { ViewContainer } from 'containers/view'
import ROUTES from 'routes'

// React Memoized Component
const ErrorNotFoundView = (props) => (
	<ViewContainer>
		<div className="space-bottom">
			<Typography variant="h5" component="h1" color="textSecondary">
				There is nothing here.
			</Typography>
		</div>
		<Button component={Link} to={ROUTES.ROOT}>
			<ArrowLeftIcon className="space-right-smallest" />
			Back home
		</Button>
	</ViewContainer>
)

export default ErrorNotFoundView
