// @flow

import React from 'react'
import PropTypes from 'prop-types'
import { HistoryRouter as Router } from 'redux-first-history/rr6'
import { connect } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { history } from 'state'
import OverlayLoader from 'components/loader/OverlayLoader'
import AuthenticatedLayout from 'layouts/AuthenticatedLayout'
import PublicLayout from 'layouts/PublicLayout'
import {
	actions as sessionActions,
	STORAGE_KEY as SESSION_STORAGE_KEY,
} from 'state/modules/session'
import * as cacheService from 'services/cache'
import ROUTES from 'routes'
import { withAuth0 } from '@auth0/auth0-react'

const mapStateToProps = (state) => ({
	credentials: state.session.credentials,
	privileges: state.session.privileges,
	startupState: state.session.startupState,
})

const mapDispatchToProps = (dispatch) => ({
	createSession: (email, password, token, first_name, last_name) =>
		dispatch(
			sessionActions.create(email, password, token, first_name, last_name),
		),
	restartSession: (credentials) =>
		dispatch(sessionActions.restart(credentials)),
	logoutSession: () => dispatch(sessionActions.logout()),
})

class AppContainer extends React.Component {
	/**
	 * This will first check to see if a session exists in local storage.
	 * If a session exists, the app will show a loader while waiting to confirm
	 * with the server that this session is still valid.
	 * If valid, AuthenticatedLayout will render and user will have access to app.
	 * If not valid, local storage will be cleared and user will be redirected to
	 * the PublicLayout->LoginView. The first attempted route will be stored
	 * and redirected to after a successful re-login.
	 */
	constructor(props) {
		super(props)
		const pathname = history.location.pathname
		const session = cacheService.get(SESSION_STORAGE_KEY)

		if (!isEmpty(session) && pathname !== ROUTES.LOGOUT) {
			props.restartSession(session)
		} else if (pathname === ROUTES.LOGOUT) {
			props.logoutSession()
		} else {
			// --
		}
	}

	render() {
		const startupState = this.props.startupState
		const credentials = this.props.credentials
		const { isAuthenticated, user, isLoading, getAccessTokenSilently } =
			this.props.auth0
		const isAuthSuccess =
			window.env.AUTH0_METHOD === 'AUTH0'
				? isAuthenticated && credentials.token && startupState === 'active'
				: credentials.token && startupState === 'active'
		if (
			startupState === 'restarting' ||
			startupState === 'starting' ||
			isLoading
		) {
			return <OverlayLoader loading />
		}

		if (
			window.env.AUTH0_METHOD === 'AUTH0' &&
			isAuthenticated &&
			user &&
			!credentials.token &&
			history.location.pathname !== ROUTES.LOGOUT
		) {
			getAccessTokenSilently().then((resToken) => {
				this.props.createSession(
					user.email,
					'',
					resToken,
					user.given_name,
					user.family_name,
				)
			})
		}
		return (
			<Router history={history}>
				<div className="AppConstainer">
					<Routes>
						<Route
							path="*"
							element={
								isAuthSuccess ? <AuthenticatedLayout /> : <PublicLayout />
							}
						/>
					</Routes>
				</div>
			</Router>
		)
	}
}

AppContainer.propTypes = {
	// -- state props,
	credentials: PropTypes.object,
	privileges: PropTypes.array,
	startupState: PropTypes.string,
	// -- dispatch props
	createSession: PropTypes.func,
	restartSession: PropTypes.func,
	logoutSession: PropTypes.func,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withAuth0(AppContainer))
