import { cloneDeep, isNil } from 'lodash'
import { config as API } from 'api'
import * as postgrest from 'services/postgrest'

const CLASSIFIER_RESTRICTORS_ENDPOINT = API.CLASSIFIER_RESTRICTORS.PATH
const PRODUCT_RESTRICTORS_ENDPOINT = API.PRODUCT_RESTRICTORS.PATH
const MODULE_NAME = 'restrictors' // this module's name in the rootReducer

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	pendingFields: {},
	users: [],
	invites: [],
	currentClassifierRestrictors: [],
	currentProductRestrictors: []
}

const REQUESTED = `REQUESTED`
const FETCHED_DATA = `DATA_FETCHED`
const CREATED_CLASSIFIER_RESTRICTOR = `CREATED_CLASSIFIER_RESTRICTOR`
const DESTROYED_CLASSIFIER_RESTRICTOR = `DESTROYED_CLASSIFIER_RESTRICTOR`
const CREATED_PRODUCT_RESTRICTOR = `CREATED_PRODUCT_RESTRICTOR`
const UPDATED_PRODUCT_RESTRICTOR = `UPDATED_PRODUCT_RESTRICTOR`
const DESTROYED_PRODUCT_RESTRICTOR = `DESTROYED_PRODUCT_RESTRICTOR`
const FAILED = `FAILED`

const request = () => ({ type: REQUESTED })
const receive = (action, fields) => ({ type: action, payload: fields })
const fail = error => ({ type: FAILED, payload: error })

// Product Restrictors

const fetchProductRestrictors = () => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.fetchAll(PRODUCT_RESTRICTORS_ENDPOINT, null, null)
			.then(response => {
				const productFilters = response || []

				dispatch(
					receive(FETCHED_DATA, {
						currentProductRestrictors: productFilters
					})
				)

				return productFilters
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const updateProductRestrictor = (def_id, val, from, to, selected) => {
	return (dispatch, getState) => {
		let currentRestrictors = getState()[MODULE_NAME].currentProductRestrictors
		let productRestrictor = { def_id }
		const isRange = !!(from || to)

		if (isRange) {
			if (!isNil(from)) {
				productRestrictor.from = from
			}
			if (!isNil(to)) {
				productRestrictor.to = to
			}
		} else if (!isNil(val)) {
			productRestrictor.val = val
		}

		if (isNil(val) && isNil(from) && isNil(to)) {
			return dispatch(destroyProductRestrictor(productRestrictor))
		} else if (isRange) {
			const index = currentRestrictors.findIndex(f => f.def_id === def_id)
			if (index > -1) {
				return dispatch(updateProductRestrictorRange(productRestrictor))
			} else {
				return dispatch(createProductRestrictor(productRestrictor))
			}
		} else {
			if (selected) {
				return dispatch(createProductRestrictor(productRestrictor))
			} else {
				return dispatch(destroyProductRestrictor(productRestrictor))
			}
		}
	}
}

// @todo need to test this
const updateProductRestrictorRange = productRestrictor => {
	return (dispatch, getState) => {
		dispatch(request())

		const query = {
			user_id: `eq.${productRestrictor.user_id}`,
			def_id: `eq.${productRestrictor.def_id}`
		}

		return postgrest
			.save(PRODUCT_RESTRICTORS_ENDPOINT, null, productRestrictor, query)
			.then(response => {
				dispatch(receive(UPDATED_PRODUCT_RESTRICTOR, productRestrictor))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const createProductRestrictor = productRestrictor => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.create(PRODUCT_RESTRICTORS_ENDPOINT, productRestrictor)
			.then(response => {
				dispatch(receive(CREATED_PRODUCT_RESTRICTOR, productRestrictor))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const destroyProductRestrictor = productRestrictor => {
	return (dispatch, getState) => {
		dispatch(request())

		let query = {
			def_id: `eq.${productRestrictor.def_id}`
		}

		if (!isNil(productRestrictor.val)) {
			query.val = `eq.${JSON.stringify(productRestrictor.val)}`
		}

		return postgrest
			.destroy(PRODUCT_RESTRICTORS_ENDPOINT, null, null, query)
			.then(response => {
				dispatch(receive(DESTROYED_PRODUCT_RESTRICTOR, productRestrictor))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const resetProductRestrictors = () => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.destroy(PRODUCT_RESTRICTORS_ENDPOINT, null, null)
			.then(response => {
				dispatch(
					receive(FETCHED_DATA, {
						currentProductRestrictors: []
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

// Classifier Restrictors

const fetchClassifierRestrictors = () => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.fetchAll(CLASSIFIER_RESTRICTORS_ENDPOINT, null, null)
			.then(response => {
				let ids = []

				if (response && Array.isArray(response)) {
					ids = response.map(def => def.def_id)
				}

				dispatch(
					receive(FETCHED_DATA, {
						currentClassifierRestrictors: ids
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const updateClassifierRestrictor = (classifierId, selected) => {
	return (dispatch, getState) => {
		let restrictedClassifierIds = getState()[
			MODULE_NAME
		].currentClassifierRestrictors.slice()
		const index = restrictedClassifierIds.indexOf(classifierId)

		// !selected means it's been toggled off and should be restricted
		if (!selected && index === -1) {
			// add this new classifierId if its not yet included in the array
			dispatch(createClassifierRestrictor(classifierId))
		} else if (selected && index > -1) {
			// remove this classifierId only if it currently exists in the array
			dispatch(destroyClassifierRestrictor(classifierId))
		}
	}
}

const createClassifierRestrictor = classifierId => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.create(CLASSIFIER_RESTRICTORS_ENDPOINT, {
				def_id: classifierId
			})
			.then(response => {
				dispatch(receive(CREATED_CLASSIFIER_RESTRICTOR, classifierId))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const destroyClassifierRestrictor = classifierId => {
	return (dispatch, getState) => {
		dispatch(request())

		const query = {
			def_id: `eq.${classifierId}`
		}

		return postgrest
			.destroy(CLASSIFIER_RESTRICTORS_ENDPOINT, null, null, query)
			.then(response => {
				dispatch(receive(DESTROYED_CLASSIFIER_RESTRICTOR, classifierId))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const resetClassifierRestrictors = () => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.destroy(CLASSIFIER_RESTRICTORS_ENDPOINT, null, null)
			.then(response => {
				dispatch(
					receive(FETCHED_DATA, {
						currentClassifierRestrictors: []
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED_DATA]: (state, { payload }) => ({
		...state,
		...payload,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[CREATED_CLASSIFIER_RESTRICTOR]: (state, { payload }) => {
		return {
			...state,
			currentClassifierRestrictors: [
				...state.currentClassifierRestrictors,
				payload
			],
			fetchError: null,
			isFetching: false
		}
	},
	[UPDATED_PRODUCT_RESTRICTOR]: (state, { payload }) => {
		const restrictors = cloneDeep(state.currentProductRestrictors)
		const { def_id } = payload
		const index = restrictors.findIndex(f => f.def_id === def_id)

		if (index > -1) {
			// add the filter if it doesnt exist
			restrictors.push(payload)
		} else {
			// update the filter if it already exists
			restrictors[index] = Object.assign({}, payload)
		}

		return {
			...state,
			currentProductRestrictors: restrictors,
			fetchError: null,
			isFetching: false
		}
	},
	[CREATED_PRODUCT_RESTRICTOR]: (state, { payload }) => {
		return {
			...state,
			currentProductRestrictors: [...state.currentProductRestrictors, payload],
			fetchError: null,
			isFetching: false
		}
	},
	[DESTROYED_CLASSIFIER_RESTRICTOR]: (state, { payload }) => {
		let ids = state.currentClassifierRestrictors.slice()
		ids.splice(ids.indexOf(payload), 1)

		return {
			...state,
			currentClassifierRestrictors: [...ids],
			fetchError: null,
			isFetching: false
		}
	},
	[DESTROYED_PRODUCT_RESTRICTOR]: (state, { payload }) => {
		let restrictors = cloneDeep(state.currentProductRestrictors)
		const existingIndex = restrictors.findIndex(
			r => r.val === payload.val && r.def_id === payload.def_id
		)
		restrictors.splice(existingIndex, 1)

		return {
			...state,
			currentProductRestrictors: [...restrictors],
			fetchError: null,
			isFetching: false
		}
	},
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetch,
	fetchClassifierRestrictors,
	updateClassifierRestrictor,
	resetClassifierRestrictors,
	fetchProductRestrictors,
	updateProductRestrictor,
	resetProductRestrictors
}
