/**
 * Headers
 */

export function getContentRangeTotal(headers) {
	if (!headers || !headers['content-range']) {
		return null
	}
	const cr = headers['content-range']
	const totalResultsString = cr.substr(cr.indexOf('/') + 1, cr.length)
	return parseInt(totalResultsString, 10) || null
}
