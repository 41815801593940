import { config as API } from 'api'
import { MODULE_NAME as GUIDED_PURCHASES_MODULE_NAME } from './guided-purchases'
import { MODULE_NAME as SESSION_MODULE_NAME } from './session'
import * as postgrest from 'services/postgrest'

export const MODULE_NAME = 'guidedPurchaseResults' // this module's name in the rootReducer
const COLLECTION_NAME = 'GUIDED_PURCHASE_RESULTS'
const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 50

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	results: {},
	perPage: DEFAULT_PER_PAGE,
	page: DEFAULT_PAGE
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const FETCHED = `${COLLECTION_NAME}_FETCHED`
const FETCHED_PAGE = `${COLLECTION_NAME}_PAGE_FETCHED`
const FAILED = `${COLLECTION_NAME}_FAILED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })

export const receivePage = (results, page, totalResults) => ({
	type: FETCHED_PAGE,
	payload: { results, page, totalResults }
})
const fail = error => ({ type: FAILED, payload: error })

const fetchRelators = async productTypes => {
	const query = {
		product_type_id: `in.(${productTypes
			.filter(item => item === '')
			.toString()})`,
		select: 'id,division_id,product_type_id,product_relator_criteria(*)'
	}
	return postgrest
		.fetchWithCustomQuery(API.PRODUCT_RELATORS.PATH, query)
		.then(response => {
			let ret = {}
			response.forEach(r => {
				ret[r.product_type_id] = r
			})
			return ret
		})
}

const addRelators = async isolated_products => {
	// '/products?select=number,product_type_id&number=in.(p27)'
	// '/product_relators?select=product_relator_criteria(*)&product_type_id=in.(e667cbe9-2566-11e9-974b-00262df26636)'
	const productNumbers = isolated_products.map(ip => ip.id)
	let productDetailsByNumbers = {}
	let uniqueProductTypes = new Set()
	let query = {
		number: `in.(${productNumbers.toString()})`,
		select: 'product_type_id,number'
	}
	let relatorsByProductType = {}
	await postgrest
		.fetchWithCustomQuery(API.PRODUCTS.PATH, query)
		.then(productsResponse => {
			productsResponse.forEach(productDetails => {
				uniqueProductTypes.add(productDetails.product_type_id)
				productDetailsByNumbers[productDetails.number] = productDetails
			})
		})
		.catch(() => {
			const error = new Error('Could not load product data')
			throw error
			// throw error
		})
	const ptsAsArray = Array.from(uniqueProductTypes)
	relatorsByProductType = await fetchRelators(ptsAsArray)

	return isolated_products.map(ip => {
		ip.relator =
			relatorsByProductType[productDetailsByNumbers[ip.id].product_type_id]
		return ip
	})
}

const fetchPagePromise = (page, perPage, options, token, gpsId = null) => {
	let _options = options || {}

	const offset = perPage * page
	const single = true

	if (_options.step === 2) {
		return postgrest.fetchAllWithPost(
			'/rpc/gps_filter_products',
			null,
			{
				p_gps_id: gpsId,
				p_filter_type: 'usage'
			},
			true,
			single
		)
	} else if (_options.step === 3) {
		return postgrest.fetchAllWithPost(
			'/rpc/gps_filter_products',
			null,
			{
				p_gps_id: gpsId,
				p_filter_type: 'rec'
			},
			true,
			single
		)
	} else if (_options.step === 4) {
		return postgrest.fetchAllWithPost(
			'/rpc/gps_select_step_results',
			null,
			{
				p_gps_id: gpsId,
				p_limit: perPage,
				p_offset: offset,
				p_only_with_recs: _options.only_with_recs
			},
			true,
			single
		)
	} else if (_options.step === 5) {
		return postgrest.fetchAllWithPost(
			'/rpc/gps_result_step_results',
			null,
			{
				p_gps_id: gpsId,
				p_limit: perPage,
				p_offset: offset
			},
			true,
			single
		)
	}
	throw new Error('unrecognized step number')
}

/**
 * @param {number} page
 * @param {object} options:
 * 	{ applyEdits: @param {bool}, includeFilterOptions: @param {bool} }
 */
const fetchPage = (page, options, gpsId = null) => {
	return (dispatch, getState) => {
		dispatch(request())

		const { perPage } = getState()[MODULE_NAME]
		const { currentModel } = getState()[GUIDED_PURCHASES_MODULE_NAME]
		const { token } = getState()[SESSION_MODULE_NAME]
		let _options = options || {}

		const id = gpsId ? gpsId : currentModel.id()

		return fetchPagePromise(page, perPage, options, token, id)
			.then(response => {
				if (_options.step === 4) {
					if (
						response &&
						response.isolated_product_count > 0 &&
						response.isolated_products
					) {
						addRelators(response.isolated_products).then(ips => {
							response.isolated_products = ips
							const totalResults = response.isolatedProductCount
							dispatch(receivePage(response, page, totalResults))
						})
					} else {
						dispatch(receivePage({}, page, 0))
					}
				} else {
					const totalResults = response.isolatedProductCount
					dispatch(receivePage(response, page, totalResults))
				}
			})
			.catch(() => {
				const error = new Error('Could not load Guided Purchase Results')
				dispatch(fail(error))
				// throw error
			})
	}
}

/**
 * this does not alter the module's state, the data is returned directly
 *
 */
const fetchAll = forExport => {
	return (dispatch, getState) => {
		const { currentModel } = getState()[GUIDED_PURCHASES_MODULE_NAME]

		return postgrest
			.fetchAllWithPost(
				'/rpc/gps_select_step_results',
				null,
				{
					p_gps_id: `${currentModel.id()}`,
					p_limit: 0,
					p_offset: 0,
					p_only_with_recs: false,
					p_for_export: forExport ? forExport : false
				},
				true
			)
			.then(response => {
				return response
			})
			.catch(() => {
				const error = new Error('Could not load Guided Purchase data')
				dispatch(fail(error))
				throw error
			})
	}
}

const fetchUsagePhysicianData = () => {
	return (dispatch, getState) => {
		const { currentModel } = getState()[GUIDED_PURCHASES_MODULE_NAME]
		return postgrest.fetchAll(
			API.GUIDED_PURCHASES_USAGE_PHYSICIANS_DATA.PATH,
			currentModel.id(),
			'gps_id',
			true
		)
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		results: payload || {},
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED_PAGE]: (state, { payload }) => ({
		...state,
		results: payload.results,
		page: payload.page,
		totalResults: payload.totalResults,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetchPage,
	fetchAll,
	fetchUsagePhysicianData,
	reset,
	fetchPagePromise
}
