/**
 * Guided Purchase Solution Price Calculation Options
 *
 * The keys in this document map to keys that exist on the server.
 *
 */

export const PRICE_BY_CASE = 'case'
export const PRICE_HIGHEST = 'highest'
export const PRICE_LOWEST = 'lowest'
export const PRICE_LIST = 'list'
export const PRICE_CONRACT = 'contract'
export const PRICE_RECENT = 'recent'
export const PRICE_AVERAGE = 'average'
export const PRICE_MEDIAN = 'median'
export const PRICE_RFP_RESPONSE = 'rfp_response'
export const PRICE_HIGHEST_RECENT_YEAR = 'highest_recent_year'
export const PRICE_LOWEST_RECENT_YEAR = 'lowest_recent_year'
export const PRICE_AVERAGE_RECENT_YEAR = 'average_recent_year'
export const PRICE_MEDIAN_RECENT_YEAR = 'median_recent_year'

// Price Group
export const NO_PRICING = 'No Pricing'
export const PDC = 'PDC'
export const NATIONAL_BENCHMARK = 'National Benchmark'
export const THIS_UPLOADED_FILE = 'This Uploaded File'
export const ALL_TRANSACTION_DATA = 'All Transaction Data'

export const SOURCE_RPF = 'rfp'
export const SOURCE_PDC = 'pdc'
export const SOURCE_NAT = 'nat_benchmark'
export const SOURCE_FILE = 'file'
export const SOURCE_ALL = 'all'

export const map = {
	[PRICE_BY_CASE]: 'Price by Case',
	[PRICE_HIGHEST]: 'Highest price',
	[PRICE_LOWEST]: 'Lowest price',
	[PRICE_LIST]: 'Contract price',
	[PRICE_RECENT]: 'Latest price',
	[PRICE_AVERAGE]: 'Average price',
	[PRICE_MEDIAN]: 'Median price',
	[PRICE_RFP_RESPONSE]: 'RFP response',
	[PRICE_CONRACT]: 'Contract Price',
	[PRICE_HIGHEST_RECENT_YEAR]: 'Highest Price(Most Recent Year)',
	[PRICE_LOWEST_RECENT_YEAR]: 'Lowest Price(Most Recent Year)',
	[PRICE_AVERAGE_RECENT_YEAR]: 'Average Price(Most Recent Year)',
	[PRICE_MEDIAN_RECENT_YEAR]: 'Median Price(Most Recent Year)'
}

// For use in dropdown fields, etc
export const usageList = [
	{ value: PRICE_HIGHEST, text: map[PRICE_HIGHEST] },
	{ value: PRICE_LOWEST, text: map[PRICE_LOWEST] },
	{ value: PRICE_RECENT, text: map[PRICE_RECENT] },
	{ value: PRICE_AVERAGE, text: map[PRICE_AVERAGE] },
	{ value: PRICE_MEDIAN, text: map[PRICE_MEDIAN] },
	{ value: PRICE_RFP_RESPONSE, text: map[PRICE_RFP_RESPONSE] }
]

export const priceSourceList = [
	{ value: SOURCE_RPF, text: NO_PRICING },
	{ value: SOURCE_PDC, text: PDC },
	{ value: SOURCE_FILE, text: THIS_UPLOADED_FILE }
]

export const priceCalcOptionList = [
	{
		value: PRICE_RFP_RESPONSE,
		text: map[PRICE_RFP_RESPONSE],
		source: SOURCE_RPF
	},
	{ value: PRICE_CONRACT, text: map[PRICE_CONRACT], source: SOURCE_PDC },
	{ value: PRICE_HIGHEST, text: map[PRICE_HIGHEST], source: SOURCE_FILE },
	{ value: PRICE_LOWEST, text: map[PRICE_LOWEST], source: SOURCE_FILE },
	{ value: PRICE_AVERAGE, text: map[PRICE_AVERAGE], source: SOURCE_FILE },
	{ value: PRICE_MEDIAN, text: map[PRICE_MEDIAN], source: SOURCE_FILE },
	{ value: PRICE_RECENT, text: map[PRICE_RECENT], source: SOURCE_FILE },
	{
		value: SOURCE_ALL + '_' + PRICE_HIGHEST,
		text: map[PRICE_HIGHEST],
		source: SOURCE_ALL
	},
	{
		value: SOURCE_ALL + '_' + PRICE_LOWEST,
		text: map[PRICE_LOWEST],
		source: SOURCE_ALL
	},
	{
		value: SOURCE_ALL + '_' + PRICE_AVERAGE,
		text: map[PRICE_AVERAGE],
		source: SOURCE_ALL
	},
	{
		value: SOURCE_ALL + '_' + PRICE_MEDIAN,
		text: map[PRICE_MEDIAN],
		source: SOURCE_ALL
	},
	{
		value: SOURCE_ALL + '_' + PRICE_RECENT,
		text: map[PRICE_RECENT],
		source: SOURCE_ALL
	},
	{
		value: SOURCE_ALL + '_' + PRICE_HIGHEST_RECENT_YEAR,
		text: map[PRICE_HIGHEST_RECENT_YEAR],
		source: SOURCE_ALL
	},
	{
		value: SOURCE_ALL + '_' + PRICE_LOWEST_RECENT_YEAR,
		text: map[PRICE_LOWEST_RECENT_YEAR],
		source: SOURCE_ALL
	},
	{
		value: SOURCE_ALL + '_' + PRICE_AVERAGE_RECENT_YEAR,
		text: map[PRICE_AVERAGE_RECENT_YEAR],
		source: SOURCE_ALL
	},
	{
		value: SOURCE_ALL + '_' + PRICE_MEDIAN_RECENT_YEAR,
		text: map[PRICE_MEDIAN_RECENT_YEAR],
		source: SOURCE_ALL
	},
	{
		value: SOURCE_NAT + '_' + PRICE_HIGHEST,
		text: map[PRICE_HIGHEST],
		source: SOURCE_NAT
	},
	{
		value: SOURCE_NAT + '_' + PRICE_LOWEST,
		text: map[PRICE_LOWEST],
		source: SOURCE_NAT
	},
	{ value: SOURCE_NAT + '_' + PRICE_AVERAGE, text: map[PRICE_AVERAGE] },
	{
		value: SOURCE_NAT + '_' + PRICE_MEDIAN,
		text: map[PRICE_MEDIAN],
		source: SOURCE_NAT
	},
	{
		value: SOURCE_NAT + '_' + PRICE_RECENT,
		text: map[PRICE_RECENT],
		source: SOURCE_NAT
	},
	{
		value: SOURCE_NAT + '_' + PRICE_HIGHEST_RECENT_YEAR,
		text: map[PRICE_HIGHEST_RECENT_YEAR],
		source: SOURCE_NAT
	},
	{
		value: SOURCE_NAT + '_' + PRICE_LOWEST_RECENT_YEAR,
		text: map[PRICE_LOWEST_RECENT_YEAR],
		source: SOURCE_NAT
	},
	{
		value: SOURCE_NAT + '_' + PRICE_AVERAGE_RECENT_YEAR,
		text: map[PRICE_AVERAGE_RECENT_YEAR],
		source: SOURCE_NAT
	},
	{
		value: SOURCE_NAT + '_' + PRICE_MEDIAN_RECENT_YEAR,
		text: map[PRICE_MEDIAN_RECENT_YEAR],
		source: SOURCE_NAT
	}
]

export const recList = [
	{ value: PRICE_HIGHEST, text: map[PRICE_HIGHEST] },
	{ value: PRICE_LOWEST, text: map[PRICE_LOWEST] },
	{ value: PRICE_RECENT, text: map[PRICE_RECENT] },
	{ value: PRICE_AVERAGE, text: map[PRICE_AVERAGE] },
	{ value: PRICE_MEDIAN, text: map[PRICE_MEDIAN] },
	{ value: PRICE_LIST, text: map[PRICE_LIST] }
]
