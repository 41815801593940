/**
 * ColorButton
 *
 * Available colors:
 * teal
 * purple
 *
 *
 */

import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import grey from '@mui/material/colors/grey'
import purple from '@mui/material/colors/purple'
import blueGrey from '@mui/material/colors/blueGrey'
import lightBlue from '@mui/material/colors/lightBlue'
import teal from '@mui/material/colors/teal'

const styles = (theme) => ({
	white: {
		color: grey[50],
		// backgroundColor: grey[50],
		// '&:hover': {
		// 	backgroundColor: grey[300]
		// }
	},
	teal: {
		color: teal.A700,
	},
	purple: {
		color: purple[300],
	},
	blueGrey: {
		color: blueGrey[300],
	},
	lightBlue: {
		color: lightBlue[300],
	},
})

const ColorIconButton = ({ children, color, classes, ...rest }) => (
	<IconButton {...rest} className={classNames(classes[color])}>
		{children}
	</IconButton>
)

ColorIconButton.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf([
		'white',
		'teal',
		'purple',
		'blueGrey',
		'lightBlue',
		'teal',
	]),
	// + @mui/material/Button props
}

export default withStyles(styles)(ColorIconButton)
