import { cloneDeep } from 'lodash'
import request from 'superagent'
import { Model } from './base'
import { config as API } from 'api'
import { validateEmail } from 'utils/validation'
import * as postgrest from 'services/postgrest'

const defaultFields = {
	title: '',
	note: '',
	product_types: [],
	recipients: [],
	file: null,
	attachment: null,
	start_date: '',
	end_date: '',
}

// data for api queries
// const FETCH_SELECT = 'id,title,note,template_location'
export const FETCH_SELECT = '*'
export const EMBED_RECIPIENT_MESSAGES = 'messages:rfpi_recipient_messages(*)'
export const EMBED_RECIPIENT_RESPONSES =
	'responses:rfpi_responses(id,note,file_location)'
export const EMBED_RECIPIENTS = `recipients:rfpi_recipients(id,first_name,last_name,company_name,email,${EMBED_RECIPIENT_RESPONSES},${EMBED_RECIPIENT_MESSAGES})`
export const EMBED_PRODUCT_TYPES = 'product_types:rfpi_product_types(id)'
export const EMBED_ATTACHMENT = 'attachment:rfpi_request_attachments(name)'

// data for ui display
const SAVE_TEXT = 'Update RFPI'
const CREATE_TEXT = 'Issue RFPI'
const DELETE_ACTION_TEXT = 'Are you sure you would like to delete this RFPI?'
const SAVE_ERROR_TEXT = 'Error updating RFPI'
const CREATE_ERROR_TEXT = 'Error creating RFPI'
const DELETE_ERROR_TEXT = 'Error deleting RFPI'

export default class RFPIModel extends Model {
	static endpoint = API.RFPIS.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	static validateRecipient(recipient) {
		if (!recipient.first_name) {
			return false
		} else if (!recipient.last_name) {
			return false
		} else if (!recipient.company_name) {
			return false
		} else if (!validateEmail(recipient.email)) {
			return false
		} else {
			return true
		}
	}

	constructor(fields = defaultFields) {
		super(fields)
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	validate() {
		const { fields } = this

		if (!fields.title) {
			return false
		} else if (!fields.product_types || !fields.product_types.length) {
			return false
		} else if (!fields.recipients || !fields.recipients.length) {
			return false
		} else {
			return true
		}
	}

	addRecipient(recipient) {
		// @todo validate
		const recipients = this.get('recipients') || []
		let newRecipients = cloneDeep(recipients)
		newRecipients.push(recipient)
		this.set({ recipients: newRecipients })
	}

	removeRecipient(recipient) {
		const recipients = this.get('recipients') || []
		let newRecipients = cloneDeep(recipients)
		const index = newRecipients.findIndex(
			(r) => JSON.stringify(r) === JSON.stringify(recipient),
		)

		if (index > -1) {
			newRecipients.splice(index, 1)
			this.set({ recipients: newRecipients })
		}
	}

	addProductType(productTypeId) {
		const productTypes = this.get('product_types') || []
		let newProductTypes = cloneDeep(productTypes)
		newProductTypes.push(productTypeId)
		this.set({ product_types: newProductTypes })
	}

	removeProductType(productTypeId) {
		const productTypes = this.get('product_types') || []
		let newProductTypes = cloneDeep(productTypes)
		const index = newProductTypes.indexOf(productTypeId)

		if (index > -1) {
			newProductTypes.splice(index, 1)
			this.set({ product_types: newProductTypes })
		}
	}

	removeUnsavable() {
		delete this.fields.attachment
		delete this.fields.product_types
		delete this.fields.recipients
	}

	get saveActionText() {
		return this.id() ? SAVE_TEXT : CREATE_TEXT
	}

	// -- api call overrides

	async fetch() {
		const querySelect = `${FETCH_SELECT},${EMBED_RECIPIENTS},${EMBED_PRODUCT_TYPES},${EMBED_ATTACHMENT}`

		try {
			const response = await postgrest.fetch(
				this.endpoint,
				this.id(),
				'id',
				querySelect,
			)
			return new this.constructor(response)
		} catch (error) {
			throw error
		}
	}

	async create(file) {
		if (!file) {
			throw new Error('RFPIModel create() requires a file.')
		}

		try {
			const response = await new Promise((resolve, reject) => {
				request
					.post(postgrest.createUrl(this.endpoint))
					.set('Authorization', postgrest.createAuthHeader(true))
					.attach('file', file)
					.attach('attachment', this.fields.attachment)
					.field('title', this.fields.title)
					.field('note', this.fields.note)
					.field('start_date', this.fields.start_date)
					.field('end_date', this.fields.end_date)
					.field('recipients', JSON.stringify(this.fields.recipients))
					.field('product-types', JSON.stringify(this.fields.product_types))
					.then(postgrest.handleErrors)
					.then((response) => {
						resolve(response.body)
					})
			})
			return response
		} catch (error) {
			throw error
		}
	}
}

// export type RFPIRecipientType = {
// 	first_name: String,
// 	last_name: String,
// 	email: String,
// 	company_name: String
// }

// export type RFPIModelType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
// 	title: string,
//	note: string,
// 	product_types: Array<String>
//	recipients: Array<RFPIRecipientType>,
// 	file: File (binary)
// }

// @todo fields
// const TITLE = 'title'
// const FILE = 'file'
// const NOTE = 'note'
// const RECIPIENTS = 'recipients'
// const PRODUCT_TYPES = 'product_types'
// const PRODUCT_TYPE_ID = 'product_type_id'
// const FIRST_NAME = 'first_name'
// const LAST_NAME = 'last_name'
// const EMAIL = 'email'
// const COMPANY_NAME = 'company_name'
