import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'

export default class BasicTextField extends Component {
	static propTypes = {
		handleUpdate: PropTypes.func.isRequired,
		name: PropTypes.string.isRequired,
		width: PropTypes.number,
		defaultFocused: PropTypes.bool,
		defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		// + material-ui TextField attributes
	}

	handleChange = (event) => {
		const { value } = event.target
		this.props.handleUpdate(this.props.name, value)
	}

	render() {
		const {
			handleUpdate, // eslint-disable-line no-unused-vars
			name, // eslint-disable-line no-unused-vars
			defaultFocused, // eslint-disable-line no-unused-vars
			width,
			...rest
		} = this.props

		return (
			<TextField
				{...rest}
				variant="standard"
				autoFocus={defaultFocused}
				style={width ? { width: `${width}px` } : null}
				onChange={this.handleChange}
			/>
		)
	}
}
