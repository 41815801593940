import { cloneDeep, isNil, isEmpty } from 'lodash'
import { Model, Collection } from 'models/base'
import { config as API } from 'api'
import * as go from 'services/go'

const ENDPOINT = API.GUIDED_PURCHASE_SEGMENTERS.PATH
const COLLECTION_NAME = 'GUIDED_PURCHASE_SEGMENTERS'
const MODEL_NAME = 'GUIDED_PURCHASE_SEGMENTER'
export const MODULE_NAME = 'guidedPurchaseSegmenters' // this module's name in the rootReducer
const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 6

const DEFAULT_FIELDS = {
	parent_gps_id: '',
	roles: [],
	procedures: []
}

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	pendingFields: cloneDeep(DEFAULT_FIELDS),
	currentModel: new Model(DEFAULT_FIELDS),
	results: new Collection(),
	totalResults: 0,
	perPage: DEFAULT_PER_PAGE,
	page: DEFAULT_PAGE
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const FETCHED = `${MODEL_NAME}_FETCHED`
const FETCHED_PAGE = `${COLLECTION_NAME}_PAGE_FETCHED`
const EDIT_SAVED = `${MODEL_NAME}_EDIT_SAVED`
const EDIT_CLEARED = `${MODEL_NAME}_EDIT_CLEARED`
const REPLACED = `${MODEL_NAME}_REPLACED`
const SET_DATA = `${MODEL_NAME}_SET_DATA`
const CREATED = `${MODEL_NAME}_CREATED`
const SAVED = `${MODEL_NAME}_SAVED`
const DESTROYED = `${MODEL_NAME}_DESTROYED`
const FAILED = `${COLLECTION_NAME}_FAILED`
//const GENERATED = `${COLLECTION_NAME}_GENERATED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
// receive can take FETCHED, SAVED, CREATED, DESTROYED, REPLACED actions
const receive = (action, fields) => ({ type: action, payload: fields })
const receivePage = (results, page, totalResults) => ({
	type: FETCHED_PAGE,
	payload: { results, page, totalResults }
})
const setData = fields => ({ type: SET_DATA, payload: fields })
const saveEdit = fields => ({ type: EDIT_SAVED, payload: fields })
const clearEdit = fields => ({ type: EDIT_CLEARED })
const fail = error => ({ type: FAILED, payload: error })

/**
 * Generate segments
 * thunk
 */
const generateSegments = segmenterId => {
	return (dispatch, getState) => {
		return go
			.create(API.GUIDED_PURCHASE_GEN_SEGMENTS.PATH + '/' + segmenterId, {})
			.then(response => {})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Fetch single resource
 * thunk
 */
const fetch = (id, force = false) => {
	return (dispatch, getState) => {
		// if this model already exists in the results array of the product filters module,
		// no need to fetch again. just set it as the currentModel
		if (!force) {
			const { results } = getState()[MODULE_NAME]
			const existingModel =
				results && results.size() ? results.findById(id) : null

			if (existingModel) {
				return new Promise(resolve => {
					dispatch(receive(REPLACED, existingModel))
					resolve()
				})
			}
		}

		dispatch(request())

		return go
			.fetch(ENDPOINT, id)
			.then(response => {
				if (!isNil(response) && !isEmpty(response)) {
					dispatch(receive(FETCHED, response))
				} else {
					const error = new Error('Guided Purchase Segmenter not found.')
					dispatch(fail(error))
					throw error
				}
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Fetch resources using limit-offset pagination
 * thunk
 *
 * @see https://postgrest.com/en/v4.3/api.html#limits-and-pagination
 */
const fetchAll = page => {
	return (dispatch, getState) => {
		dispatch(request())

		return go
			.fetchAll(ENDPOINT)
			.then(response => {
				dispatch(receivePage(response.results, 0, response.totalResults))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const create = () => {
	return (dispatch, getState) => {
		dispatch(request())

		const fields = getState()[MODULE_NAME].pendingFields

		return go
			.create(ENDPOINT, fields)
			.then(response => {
				dispatch(receive(CREATED, response))
				return response
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const save = fields => {
	return (dispatch, getState) => {
		dispatch(request())

		const currentModel = getState()[MODULE_NAME].currentModel

		// @todo temporary solution for all data getting cleared
		let fieldsCopy = cloneDeep(fields)

		if (!fieldsCopy['roles']) {
			fieldsCopy['roles'] = currentModel.get('roles')
		}

		if (!fieldsCopy['parent_gps_id']) {
			fieldsCopy['parent_gps_id'] = currentModel.get('parent_gps_id')
		}

		if (!fieldsCopy['procedures']) {
			fieldsCopy['procedures'] = currentModel.get('procedures')
		}

		if (!fieldsCopy['greatest_savings']) {
			fieldsCopy['greatest_savings'] = currentModel.get('greatest_savings')
		}

		return go
			.save(ENDPOINT, currentModel.id(), fieldsCopy)
			.then(response => {
				dispatch(receive(SAVED, response[0]))
				return true
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const destroy = id => {
	return (dispatch, getState) => {
		dispatch(request())

		return go
			.destroy(ENDPOINT, id)
			.then(() => {
				dispatch(receive(DESTROYED, id))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[EDIT_SAVED]: (state, { payload }) => {
		const fields = Object.assign(state.pendingFields, payload)

		return {
			...state,
			pendingFields: cloneDeep(fields)
		}
	},
	[EDIT_CLEARED]: (state, action) => ({
		...state,
		pendingFields: cloneDeep(DEFAULT_FIELDS)
	}),
	[FETCHED_PAGE]: (state, { payload }) => ({
		...state,
		results: new Collection(payload.results),
		page: payload.page,
		totalResults: payload.totalResults,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[SET_DATA]: (state, { payload }) => ({
		...state,
		currentModel: new Model(Object.assign(state.currentModel.fields, payload))
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		currentModel: new Model(payload),
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[REPLACED]: (state, { payload }) => ({
		...state,
		currentModel: payload
	}),
	[CREATED]: (state, { payload }) => {
		const collection = state.results

		return {
			...state,
			currentModel: new Model(payload),
			results: new Collection([payload, ...collection.models]),
			pendingFields: cloneDeep(DEFAULT_FIELDS),
			fetchError: null,
			isFetching: false
		}
	},
	[SAVED]: (state, { payload }) => {
		let collection = state.results
		const model = new Model(payload)
		collection = collection.update(model)

		return {
			...state,
			currentModel: model,
			results: new Collection([...collection.models]),
			fetchError: null,
			isFetching: false
		}
	},
	[DESTROYED]: (state, { payload }) => {
		let collection = state.results
		collection = collection.remove([payload])

		return {
			...state,
			currentModel: new Model(DEFAULT_FIELDS),
			results: new Collection([...collection.models]),
			fetchError: null,
			isFetching: false
		}
	},
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetch,
	fetchAll,
	create,
	save,
	destroy,
	saveEdit,
	clearEdit,
	setData,
	reset,
	generateSegments
}
