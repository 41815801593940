/**
 * Classifier service
 */
import { sortBy } from 'lodash'

/**
 * @todo doc
 * @todo refactor
 */
export function formatClassifierDefsList(
	classifierDefs, // entire classifier defs collection
	classifierIdsToRefs,
	type,
	productTypeDefId,
	excludeIds,
	includeIds,
	sortOrder
) {
	let defs = []

	if (productTypeDefId) {
		defs = classifierDefs.filterByProductTypeId(productTypeDefId).models
	} else if (type) {
		defs = classifierDefs.filterByClassifierType(type).models
	}

	if (includeIds && includeIds.length) {
		const includeDefs = classifierDefs.filter(
			def => includeIds.indexOf(classifierIdsToRefs[def.id()]) > -1
		).models
		defs = defs.concat(includeDefs)
	}

	if (excludeIds) {
		defs = defs.filter(
			def => excludeIds.indexOf(classifierIdsToRefs[def.id()]) === -1
		)
	}

	if (sortOrder) {
		defs = sortBy(defs, def => {
			let index = sortOrder.indexOf(classifierIdsToRefs[def.id()])

			if (index === -1) {
				index = sortOrder.length
			}
			return index
		})
	}

	return defs
}
