/**
 * Made with help from Material-UI docs
 * @see https://github.com/mui-org/material-ui/blob/master/docs/src/modules/components/AppDrawer.js
 */

import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import ListItem from '@mui/material/ListItem'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'

const styles = (theme) => ({
	item: {
		display: 'block',
		paddingTop: 0,
		paddingBottom: 0,
	},
	itemLeaf: {
		display: 'flex',
	},
	button: {
		letterSpacing: 0,
		justifyContent: 'flex-start',
		textTransform: 'none',
		width: '100%',
	},
	buttonLeaf: {
		fontWeight: theme.typography.fontWeightRegular,
		paddingLeft: theme.spacing(4),
	},
	buttonLeafActive: {
		color: theme.palette.primary.main,
	},
})

class AppDrawerNavItem extends React.Component {
	state = {
		open: this.props.openImmediately,
	}

	componentDidMount() {
		// So we only run this logic once.
		if (!this.props.openImmediately) {
			return
		}
	}

	handleClick = () => {
		this.setState((state) => ({ open: !state.open }))
	}

	render() {
		const {
			title,
			path,
			icon: Icon,
			classes,
			hideNav,
			children,
			isActive,
			isLeaf,
		} = this.props

		if (path) {
			return (
				<ListItem
					className={classNames(classes.item, {
						[classes.itemLeaf]: isLeaf,
					})}
					disableGutters
				>
					<Button
						disableRipple
						classes={{ root: classes.button }}
						className={classNames({
							[classes.buttonLeaf]: isLeaf,
							[classes.buttonLeafActive]: isLeaf && isActive,
						})}
						component={Link}
						to={path}
						onClick={hideNav}
					>
						{Icon && <Icon className="space-right-smallest" fontSize="small" />}
						{title}
					</Button>
				</ListItem>
			)
		} else {
			return (
				<ListItem className={classes.item} disableGutters>
					<Button classes={{ root: classes.button }} onClick={this.handleClick}>
						<Icon className="space-right-smallest" fontSize="small" />
						{title}
					</Button>
					<Collapse in={this.state.open} timeout="auto" unmountOnExit>
						{children}
					</Collapse>
				</ListItem>
			)
		}
	}
}

AppDrawerNavItem.propTypes = {
	title: PropTypes.string.isRequired,
	hideNav: PropTypes.func.isRequired,
	path: PropTypes.string,
	// icon: PropTypes.func,
	openImmediately: PropTypes.bool,
	isActive: PropTypes.bool,
	isLeaf: PropTypes.bool,
	children: PropTypes.node,
}

AppDrawerNavItem.defaultProps = {
	openImmediately: false,
}

export default withStyles(styles)(AppDrawerNavItem)
