/**
 * Data Types
 *
 * The keys in this document map to keys that exist on the server.
 *
 */

export const STRING = 'string'
export const NUMBER = 'number'
export const URL = 'url'
export const BOOL = 'bool'
export const DATE = 'date'
export const ID = 'id'

// To present type as string in UI
export const map = {
	[STRING]: 'Text',
	[NUMBER]: 'Numeric',
	[URL]: 'URL',
	[BOOL]: 'True or False',
	[DATE]: 'Date',
	[ID]: 'ID Reference'
}

// For use in dropdown fields, etc
export const list = [
	{ value: STRING, text: map[STRING] },
	{ value: NUMBER, text: map[NUMBER] },
	{ value: URL, text: map[URL] },
	{ value: BOOL, text: map[BOOL] },
	{ value: DATE, text: map[DATE] },
	{ value: ID, text: map[ID] }
]
