import { cloneDeep } from 'lodash'
// import { Model, Collection } from 'models/base'
import { RelatorModel, RelatorCollection } from 'models'
import { config as API } from 'api'
import * as postgrest from 'services/postgrest'
import { actions as alertActions } from './alerts'
// import { SUCCESS as ALERT_SUCCESS } from 'data/alert-types'
import { getContentRangeTotal } from 'utils/headers'

const ENDPOINT = API.PRODUCT_RELATORS.PATH
const RELATOR_LOG_ENDPOINT = API.PRODUCT_RELATORS_HISTORY.PATH
const RELATOR_STATS_ENDPOINT = API.PRODUCT_RELATOR_STATS.PATH
const RELATOR_APPLY_VERSION = API.PRODUCT_RELATOR_APPLY_VERSION.PATH
const CRITERIA_ENDPOINT = API.PRODUCT_RELATOR_CRITERIA.PATH
const COLLECTION_NAME = 'PRODUCT_RELATORS'
const MODEL_NAME = 'PRODUCT_RELATOR'
const MODULE_NAME = 'productRelators' // this module's name in the rootReducer
const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 20
const FETCH_SELECT = '*'
const EMBED_CRITERIA = 'product_relator_criteria(*)'

const initialState = {
	hasFetched: false,
	isFetching: false,
	isFetchingRelatorSupData: false,
	fetchError: null,
	pendingFields: {},
	currentModel: new RelatorModel(),
	results: new RelatorCollection(),
	totalResults: 0,
	relationshipLog: [],
	relatorStats: [],
	allRelators: new RelatorCollection(),
	perPage: DEFAULT_PER_PAGE,
	page: DEFAULT_PAGE
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const DATA_REQUESTED = `${COLLECTION_NAME}_DATA_REQUESTED`
const FETCHED = `${MODEL_NAME}_FETCHED`
const SUP_DATA_FETCHED = `${MODEL_NAME}_SUP_DATA_FETCHED`
const FETCHED_PAGE = `${COLLECTION_NAME}_PAGE_FETCHED`
// const FETCHED_CRITERIA = `${MODEL_NAME}_CRITERIA_FETCHED`
const EDIT_SAVED = `${MODEL_NAME}_EDIT_SAVED`
const EDIT_CLEARED = `${MODEL_NAME}_EDIT_CLEARED`
const REPLACED = `${MODEL_NAME}_REPLACED`
const CREATED = `${MODEL_NAME}_CREATED`
const DESTROYED = `${MODEL_NAME}_DESTROYED`
const CREATED_CRITERIA = `${MODEL_NAME}_CREATED_CRITERIA`
const SAVED_CRITERIA = `${MODEL_NAME}_SAVED_CRITERIA`
const DESTROYED_CRITERIA = `${MODEL_NAME}_DESTROYED_CRITERIA`
const FAILED = `${COLLECTION_NAME}_FAILED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
const data_request = () => ({ type: DATA_REQUESTED })
// receive can take FETCHED, CREATED, DESTROYED, REPLACED actions
const receive = (action, fields) => ({
	type: action,
	payload: fields
})
const receiveSupData = (action, data, fieldName) => ({
	type: action,
	payload: { data, fieldName }
})
const receivePage = (results, page, totalResults) => ({
	type: FETCHED_PAGE,
	payload: { results, page, totalResults }
})
const saveEdit = fields => ({ type: EDIT_SAVED, payload: fields })
const clearEdit = fields => ({ type: EDIT_CLEARED })
const fail = error => ({ type: FAILED, payload: error })

/**
 * Fetch single resource
 * thunk
 */
const fetch = (id, force = false) => {
	return (dispatch, getState) => {
		// if this model already exists in the results array of the product filters module,
		// no need to fetch again. just set it as the currentModel
		if (!force) {
			const { results } = getState()[MODULE_NAME]
			const existingModel =
				results && results.size() ? results.findById(id) : null

			if (existingModel) {
				return new Promise(resolve => {
					dispatch(receive(REPLACED, existingModel))
					resolve()
				})
			}
		}

		dispatch(request())

		return postgrest
			.fetch(ENDPOINT, id, 'id', `${FETCH_SELECT},${EMBED_CRITERIA}`)
			.then(response => {
				dispatch(receive(FETCHED, response))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const fetchAll = () => {
	return (dispatch, getState) => {
		dispatch(data_request())
		const query = { select: `${FETCH_SELECT},${EMBED_CRITERIA}` }

		return postgrest
			.fetchWithCustomQuery(ENDPOINT, query)
			.then(response => {
				return response
			})
			.catch(error => {
				return error
			})
	}
}

const fetchListByIds = ids => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.fetchList(ENDPOINT, ids, 'id', `${FETCH_SELECT},${EMBED_CRITERIA}`)
			.then(response => {
				if (response && response.length > 0) {
					dispatch(
						receiveSupData(
							SUP_DATA_FETCHED,
							new RelatorCollection(response),
							'allRelators'
						)
					)
					return true
				} else {
					return false
				}
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Fetch resources with a query
 * thunk
 */
const fetchByDivisionProductType = (divisionId, productTypeId) => {
	return (dispatch, getState) => {
		dispatch(request())

		const query = {
			division_id: `eq.${divisionId}`,
			product_type_id: `eq.${productTypeId}`,
			select: `${FETCH_SELECT},${EMBED_CRITERIA}`
		}

		return postgrest
			.fetchWithCustomQuery(ENDPOINT, query)
			.then(response => {
				// if (response && response.length > 0) {
				// 	relator = response[0]
				// 	dispatch(receive(FETCHED, relator))
				// 	return true
				// } else {
				// 	dispatch(receive(FETCHED, {}))
				// 	return false
				// }
				if (response && response.length > 0) {
					dispatch(
						receiveSupData(
							SUP_DATA_FETCHED,
							new RelatorCollection(response),
							'allRelators'
						)
					)
					return true
				} else {
					return false
				}
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Fetch relator edit history
 * thunk
 */
const fetchRelationshipLog = id => {
	return (dispatch, getState) => {
		dispatch(data_request())

		return postgrest
			.fetchAll(RELATOR_LOG_ENDPOINT, id, 'relator_id')
			.then(response => {
				if (response && response.length > 0) {
					dispatch(
						receiveSupData(
							SUP_DATA_FETCHED,
							transformLogData(response),
							'relationshipLog'
						)
					)
					return true
				} else {
					dispatch(receiveSupData(SUP_DATA_FETCHED, [], 'relationshipLog'))
					return false
				}
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const fetchRelatorStats = () => {
	return (dispatch, getState) => {
		dispatch(data_request())

		return postgrest
			.fetchAll(RELATOR_STATS_ENDPOINT)
			.then(response => {
				if (response && response.length > 0) {
					dispatch(receiveSupData(SUP_DATA_FETCHED, response, 'relatorStats'))
					return true
				} else {
					dispatch(receiveSupData(SUP_DATA_FETCHED, [], 'relatorStats'))
					return false
				}
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Apply Relator Version
 */
const applyRelatorVersion = version => {
	return (dispatch, getState) => {
		const { currentModel } = getState()[MODULE_NAME]
		return postgrest
			.fetchAllWithPost(RELATOR_APPLY_VERSION, null, {
				p_version: version,
				p_relator_id: currentModel.id()
			})
			.then(response => {
				if (response && response.length > 0) {
					dispatch(fetch(currentModel.id(), true))
					dispatch(fetchRelationshipLog(currentModel.id()))
					return true
				} else {
					return false
				}
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * convert raw log data into the table data
 */
const transformLogData = rawData => {
	const returnData = []

	if (rawData && rawData.length) {
		const vers_arr = [...new Set(rawData.map(el => el.version))]
		vers_arr.forEach(va => {
			let tmp_arr = rawData.filter(rd => rd.version === va)
			const first_row = tmp_arr[0]
			tmp_arr = tmp_arr.map(el => {
				return {
					def_id: el.def_id,
					formula: el.formula,
					val: el.val
				}
			})
			returnData.push({
				version: va,
				version_timestamp: first_row.version_timestamp,
				email: first_row.email ? first_row.email : '',
				edit_detail: tmp_arr
			})
		})
	}
	return returnData
}

/**
 * Fetch resources using limit-offset pagination
 * thunk
 *
 * @see https://postgrest.com/en/v4.3/api.html#limits-and-pagination
 */
const fetchPage = (page, divisionId = '') => {
	return (dispatch, getState) => {
		dispatch(request())

		const { perPage } = getState()[MODULE_NAME]
		const query = {
			select: `${FETCH_SELECT},${EMBED_CRITERIA}`,
			// select: `${FETCH_SELECT}`,
			order: 'updated_at.desc'
		}
		if (divisionId.length > 0) {
			query['division_id'] = `eq.${divisionId}`
		}

		return postgrest
			.fetchPage(ENDPOINT, page, perPage, query)
			.then(response => {
				const totalResults = getContentRangeTotal(response.headers)
				dispatch(receivePage(response.body, page, totalResults))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Create single resource
 * thunk
 */
const create = () => {
	return (dispatch, getState) => {
		dispatch(request())

		const fields = getState()[MODULE_NAME].pendingFields

		return postgrest
			.create(ENDPOINT, fields)
			.then(response => {
				dispatch(receive(CREATED, response))
				return response.id
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Save single resource
 * thunk
 */
const save = (fields, relatorId = null) => {
	return (dispatch, getState) => {
		dispatch(request())

		const id = relatorId ? relatorId : getState()[MODULE_NAME].currentModel.id()
		const product_relator_criteria = getState()[MODULE_NAME]
			.product_relator_criteria
		return postgrest
			.save(ENDPOINT, id, fields)
			.then(response => {
				const payload = {
					...response,
					product_relator_criteria
				}
				dispatch(receive(FETCHED, payload))
				return true
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Destroy single resource
 * thunk
 *
 * to avoid a cascading delete issue on the server
 * first delete ALL relator criteria
 * then delete the relator
 */
const destroy = id => {
	return (dispatch, getState) => {
		dispatch(request())

		// this will delete all criteria with { relator_id: id }
		// then delete relator with { id: id }
		return postgrest
			.destroy(CRITERIA_ENDPOINT, id, 'relator_id')
			.then(response => {
				return postgrest
					.destroy(ENDPOINT, id)
					.then(() => {
						dispatch(receive(DESTROYED, id))
						dispatch(
							alertActions.showAlert({
								message: 'Product Relationship has been deleted'
							})
						)
					})
					.catch(error => {
						dispatch(fail(error))
						throw error
					})
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const updateCriteria = criteria => {
	return (dispatch, getState) => {
		// search for this item within the current criteria
		const { currentModel } = getState()[MODULE_NAME]
		const currentCriteria = cloneDeep(
			currentModel.get('product_relator_criteria')
		)
		const { relator_id: relatorId, def_id: defId, formula } = criteria
		const criteriaExists = currentCriteria
			? currentCriteria.find(cr => cr.def_id === defId)
			: null

		if (criteriaExists) {
			const query = {
				relator_id: `eq.${relatorId}`,
				def_id: `eq.${defId}`
			}
			// criteria already exists, but has been updated: save
			if (formula) {
				dispatch(request())

				return postgrest
					.save(CRITERIA_ENDPOINT, null, criteria, query)
					.then(response => {
						dispatch(receive(SAVED_CRITERIA, response))
					})
					.catch(error => {
						dispatch(fail(error))
						throw error
					})
			} else {
				// criteria already exists, but formula has been nulled: delete
				dispatch(request())

				return postgrest
					.destroy(CRITERIA_ENDPOINT, null, null, query)
					.then(() => {
						dispatch(receive(DESTROYED_CRITERIA, criteria))
					})
					.catch(error => {
						dispatch(fail(error))
						throw error
					})
			}
		} else {
			// criteria does not exist yet: create
			dispatch(request())

			return postgrest
				.create(CRITERIA_ENDPOINT, criteria)
				.then(response => {
					dispatch(receive(CREATED_CRITERIA, response))
				})
				.catch(error => {
					dispatch(fail(error))
					throw error
				})
		}
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[DATA_REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetchingRelatorSupData: true
	}),
	[EDIT_SAVED]: (state, { payload }) => {
		const fields = Object.assign(state.pendingFields, payload)

		return {
			...state,
			pendingFields: cloneDeep(fields)
		}
	},
	[EDIT_CLEARED]: (state, action) => ({
		...state,
		pendingFields: {}
	}),
	[FETCHED_PAGE]: (state, { payload }) => ({
		...state,
		results: new RelatorCollection(payload.results),
		page: payload.page,
		totalResults: payload.totalResults,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		currentModel: new RelatorModel(payload),
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[SUP_DATA_FETCHED]: (state, { payload }) => ({
		...state,
		[payload.fieldName]: payload.data,
		isFetchingRelatorSupData: false,
		isFetching: false,
		hasFetched: true
	}),
	[REPLACED]: (state, { payload }) => ({
		...state,
		currentModel: payload
	}),
	[CREATED]: (state, { payload }) => ({
		...state,
		currentModel: new RelatorModel({ ...payload }),
		pendingFields: {},
		fetchError: null,
		isFetching: false
	}),
	[DESTROYED]: (state, action) => ({
		...state,
		currentModel: new RelatorModel(),
		fetchError: null,
		isFetching: false
	}),
	[CREATED_CRITERIA]: (state, { payload }) => {
		let currentModel = state.currentModel.clone()
		currentModel.addCriteria(payload)

		return {
			...state,
			currentModel,
			fetchError: null,
			isFetching: false
		}
	},
	[SAVED_CRITERIA]: (state, { payload }) => {
		let currentModel = state.currentModel.clone()
		currentModel.updateCriteria(payload)

		return {
			...state,
			currentModel,
			fetchError: null,
			isFetching: false
		}
	},
	[DESTROYED_CRITERIA]: (state, { payload }) => {
		let currentModel = state.currentModel.clone()
		currentModel.removeCriteria(payload)

		return {
			...state,
			currentModel,
			fetchError: null,
			isFetching: false
		}
	},
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetch,
	fetchAll,
	fetchListByIds,
	fetchPage,
	fetchByDivisionProductType,
	fetchRelationshipLog,
	fetchRelatorStats,
	applyRelatorVersion,
	save,
	saveEdit,
	clearEdit,
	create,
	destroy,
	updateCriteria,
	reset
}
