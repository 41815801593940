import { config as API } from 'api'
import { MODULE_NAME as GUIDED_PURCHASES_MODULE_NAME } from './guided-purchases'
import * as go from 'services/go'
import * as postgrest from 'services/postgrest'

export const MODULE_NAME = 'guidedPurchaseCompanyResults' // this module's name in the rootReducer
const ENDPOINT = API.GUIDED_PURCHASE_COMPANY_RESULTS.PATH
const COLLECTION_NAME = 'GUIDED_PURCHASE_COMPANY_RESULTS'
const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 5

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	results: {},
	totalResults: 0,
	perPage: DEFAULT_PER_PAGE,
	page: DEFAULT_PAGE
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const FETCHED = `${COLLECTION_NAME}_FETCHED`
const FETCHED_PAGE = `${COLLECTION_NAME}_PAGE_FETCHED`
const FAILED = `${COLLECTION_NAME}_FAILED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
// receive can take FETCHED_OPTIONS, UPDATED_FILTERS
// const receive = (action, results) => ({
// 	type: action,
// 	payload: results
// })
export const receivePage = (results, page, totalResults) => ({
	type: FETCHED_PAGE,
	payload: { results, page, totalResults }
})
const fail = error => ({ type: FAILED, payload: error })

/**
 * Fetch filter options.
 *
 * Filter options are the individual selectable values that appear in Product Search
 * for the user to apply filtering criteria
 */
const fetchPage = page => {
	return (dispatch, getState) => {
		dispatch(request())

		const { perPage } = getState()[MODULE_NAME]
		const { currentModel } = getState()[GUIDED_PURCHASES_MODULE_NAME]

		let offset = perPage * page

		return postgrest
			.fetchAllWithPost(
				'/rpc/gps_result_step_results',
				null,
				{
					p_gps_id: `${currentModel.id()}`,
					p_limit: perPage,
					p_offset: offset
				},
				true
			)
			.then(response => {
				const totalResults = response.total_results
				dispatch(receivePage(response, page, totalResults))
			})
			.catch(() => {
				const error = new Error('Could not load Guided Purchase data')
				dispatch(fail(error))
			})
	}
}

/**
 * this does not alter the module's state, the data is returned directly
 *
 */
const fetchAll = () => {
	return (dispatch, getState) => {
		const { currentModel } = getState()[GUIDED_PURCHASES_MODULE_NAME]
		let params = { 'apply-edits': true }

		return go
			.fetchAll(ENDPOINT + `/${currentModel.id()}/company-results`, params)
			.then(response => {
				return response
			})
			.catch(() => {
				const error = new Error('Could not load Guided Purchase data')
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		results: payload || {},
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED_PAGE]: (state, { payload }) => ({
		...state,
		results: payload.results,
		page: payload.page,
		totalResults: payload.totalResults,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetchPage,
	fetchAll,
	reset
}
