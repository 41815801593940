import { Collection } from './base'
import ClassifierModel from './ClassifierModel'
import { SUPPLIER_PURCHASE_CLASSIFIERS } from 'data/classifier-lists'

/**
 * @todo cleanup extra methods here brought over from old Classifiers collection
 */
export default class ClassifierCollection extends Collection {
	constructor(data) {
		super(data, ClassifierModel)
	}

	/**
	 * @todo fix this in UploadCreateview and RelatorEditView to now allow
	 * true return for non-matches
	 *
	 * @param {String} productTypeId
	 * @return {ClassifiersCollection}
	 */
	filterByProductTypeId(id, forced) {
		// @todo remove "forced"
		let filtered =
			this.models.filter(model => {
				const productTypeId = model.get('product_type_id')

				if (productTypeId === id) {
					return true
				} else if (!productTypeId && forced) {
					return true
				} else {
					return false
				}
			}) || []

		return new ClassifierCollection(filtered)
	}

	/**
	 * @param {String} classifierType
	 * @return {ClassifiersCollection}
	 */
	filterByClassifierType(classifierType) {
		let filtered =
			this.models.filter(model => {
				if (model.get('type') === classifierType) {
					return true
				} else {
					return false
				}
			}) || []

		return new ClassifierCollection(filtered)
	}

	filterForSupplier(classifierIdsToRefs) {
		const supplier_classifiers = SUPPLIER_PURCHASE_CLASSIFIERS['default']
		let filtered =
			this.models.filter(cl => {
				if (supplier_classifiers.includes(classifierIdsToRefs[cl.id()])) {
					return true
				}
				return false
			}) || []

		return new ClassifierCollection(filtered)
	}

	/**
	 * @param {Array} classifierDefIds
	 * @return {ClassifiersCollection}
	 */
	filterByIds(classifierDefIds) {
		let filtered =
			this.models.filter(model => {
				if (classifierDefIds.indexOf(model.id()) > -1) {
					return true
				} else {
					return false
				}
			}) || []

		return new ClassifierCollection(filtered)
	}

	/**
	 * @param {Array} refsIndex
	 * @param {Object} classifierIdsToRefs
	 * @return {ClassifiersCollection}
	 *
	 * @see data/classifier-lists
	 */
	sortByRefsIndex(refsIndex, classifierIdsToRefs) {
		return this.sortBy(model => {
			let index = refsIndex.indexOf(classifierIdsToRefs[model.id()])
			if (index === -1) {
				index = refsIndex.length
			}
			return index
		})
	}

	/**
	 * @param {String} name
	 * @return {Classifier}
	 */
	findByName(name) {
		return this.models.find(model => model.get('name') === name) || null
	}
}
