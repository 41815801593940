import { v4 as uuidv4 } from 'uuid'
import { delay } from 'lodash'

const REMOVAL_DELAY = 300

const initialState = {
	items: []
}

const OPEN_MODAL = 'OPEN_MODAL'
const CLOSE_MODAL = 'CLOSE_MODAL'
const REMOVE_MODAL = 'REMOVE_MODAL'

const openModal = obj => ({
	type: OPEN_MODAL,
	obj: obj.id ? obj : Object.assign({ id: uuidv4() }, obj)
})

const closeModal = obj => {
	return dispatch => {
		dispatch({
			type: CLOSE_MODAL,
			obj
		})

		delay(() => {
			dispatch({
				type: REMOVE_MODAL,
				obj
			})
		}, REMOVAL_DELAY)
	}
}

const ACTION_HANDLERS = {
	[OPEN_MODAL]: (state, action) => {
		return {
			...state,
			items: state.items.concat(Object.assign({ open: true }, action.obj))
		}
	},
	[CLOSE_MODAL]: (state, action) => {
		const items = state.items.map((item, index) => {
			if (item.id !== action.obj.id) {
				return item
			}

			return {
				...item,
				open: false
			}
		})

		return {
			...state,
			items
		}
	},
	[REMOVE_MODAL]: (state, action) => {
		return {
			...state,
			items: state.items.filter(item => item.id !== action.obj.id)
		}
	}
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	openModal,
	closeModal
}
