/**
 * @see https://stackoverflow.com/questions/35622588/how-to-reset-the-state-of-a-redux-store/35641992#35641992
 */

import { combineReducers } from 'redux'
import session from './modules/session'
import accounts from './modules/accounts'
import user from './modules/user'
import roles from './modules/roles'
import rfpis from './modules/rfpis'
import invites from './modules/invites'
import taxonomies from './modules/taxonomies'
import accountUsers from './modules/account-users'
import productFilters from './modules/product-filters'
import productFilterOptions from './modules/product-filter-options'
import products from './modules/products'
import relatedProducts from './modules/related-products'
import productRelators from './modules/product-relators'
import productRelatorGroups from './modules/product-relator-groups'
import benchmarkMetrics from './modules/product-benchmark-metrics'
import productSheets from './modules/product-sheets'
import purchaseSheets from './modules/purchase-sheets'
import pricingSheets from './modules/pricing-sheets'
import guidedPurchases from './modules/guided-purchases'
import guidedPurchaseSegmenters from './modules/guided-purchase-segmenters'
import guidedPurchaseResults from './modules/guided-purchase-results'
import guidedPurchaseCompanyResults from './modules/guided-purchase-company-results'
import productRestrictorFilters from './modules/product-restrictor-filters'
import restrictors from './modules/restrictors'
import nav from './modules/nav'
import alerts from './modules/alerts'
import modals from './modules/modals'
import drawer from './modules/drawer'
import heroes from './modules/heroes'
import alta from './modules/alta'

const appReducer = (routerReducer) =>
	combineReducers({
		router: routerReducer,
		session,
		accounts,
		user,
		roles,
		rfpis,
		invites,
		taxonomies,
		accountUsers,
		productFilters,
		productFilterOptions,
		products,
		relatedProducts,
		productRelators,
		productRelatorGroups,
		benchmarkMetrics,
		productSheets,
		purchaseSheets,
		pricingSheets,
		guidedPurchases,
		guidedPurchaseSegmenters,
		guidedPurchaseResults,
		guidedPurchaseCompanyResults,
		productRestrictorFilters,
		restrictors,
		nav,
		alerts,
		modals,
		drawer,
		heroes,
		alta,
	})

export default (routerReducer, state, action) => {
	// clear entire state when session is reset (logout)
	if (action && action.type === 'SESSION_RESET') {
		state = undefined
	}

	return appReducer(routerReducer, state, action)
}
