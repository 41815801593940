import classNames from 'classnames'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import LockIcon from '@mui/icons-material/Lock'
import SecurityIcon from '@mui/icons-material/Security'
import { ColorButton } from 'components/button'
import { ViewContainer } from 'containers/view'
import Dropzone from 'react-dropzone'
import OverlayLoader from 'components/loader/OverlayLoader'
import { RFPIResponseModel } from 'models'
import { publicViewStyles } from 'styles'
import logo from 'assets/logo_relatable.svg'
import { withRouter } from '../../hocs/WithRouter'

// @todo accept other filetypes?
const XLSX_MIME =
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const UPLOAD_GUIDE_URL = 'https://relatable.io/docs/rfpi-upload-guide/'
const SUCCESS_MESSAGE = 'Thanks! Your file has been uploaded.'

const styles = (theme) => ({
	dropzone: {
		margin: '0 auto',
		maxWidth: 400,
		padding: theme.spacing(2),
		border: `1px dotted ${theme.palette.primary.main}`,
		cursor: 'pointer',
		textAlign: 'center',
	},
	footer: {
		marginTop: theme.spacing(4),
		textAlign: 'center',
		color: theme.palette.text.secondary,
	},
	footerNoteContainer: {
		display: 'flex',
		alignContent: 'top',
		justifyContent: 'center',
		marginBottom: theme.spacing(2),
		[theme.breakpoints.down('sm')]: {
			display: 'block!important',
		},
	},
	footerNote: {
		padding: theme.spacing(2),
		maxWidth: 300,
		[theme.breakpoints.down('sm')]: {
			maxWidth: 'none!important',
		},
	},
	hide: {
		display: 'none',
	},
	hideByPosition: {
		position: 'absolute',
		left: -5000,
	},
	signupForm: {
		margin: '0 auto',
		maxWidth: 800,
	},
	...publicViewStyles(theme),
})

class RFPIUploadView extends Component {
	// -- state
	state = {
		error: null,
		isLoading: false,
		completeMessage: null,
		recipientId: null,
		file: null,
		filename: null,
	}

	// -- event handlers
	handleDrop = this.handleDrop.bind(this)
	handleCancelFile = this.handleCancelFile.bind(this)
	handleUploadFile = this.handleUploadFile.bind(this)

	// -- render methods
	renderDropzone = this.renderDropzone.bind(this)

	componentDidMount() {
		const recipientId = this.props.params.id

		if (!recipientId) {
			this.setState({
				error: 'No valid ID found',
			})
		} else {
			this.setState({
				recipientId: recipientId,
			})
		}
	}

	renderCompleteState() {
		return (
			<ViewContainer>
				<img src={logo} className={this.props.classes.logo} alt="Relatable" />
				<div className="space-bottom space-top-large centered">
					<Typography variant="h5">{this.state.completeMessage}</Typography>
				</div>
			</ViewContainer>
		)
	}

	render() {
		const { classes } = this.props
		const { error, isLoading, completeMessage, file } = this.state

		if (error) {
			return (
				<Typography variant="h5" className="centered">
					{error}
				</Typography>
			)
		}

		return (
			<ViewContainer>
				<img src={logo} className={classes.logo} alt="Relatable" />
				<div className="space-bottom space-top-large">
					{completeMessage && (
						<div className="centered">
							<Typography variant="h5">{completeMessage}</Typography>
						</div>
					)}
					{!completeMessage && (
						<React.Fragment>
							<OverlayLoader loading={isLoading} />
							<div className="centered">
								<Typography variant="body1">
									Before uploading your file, make sure you've followed the{' '}
									<a
										className="link"
										href={UPLOAD_GUIDE_URL}
										target="_blank"
										rel="noopener noreferrer"
									>
										RFPI Template Instructions
									</a>
								</Typography>
							</div>
							{!file && this.renderDropzone()}
							{file && (
								<div className="space-top-large centered">
									<Typography variant="h5" component="h2" color="textSecondary">
										{file.name} is ready to upload
									</Typography>
									<div className="space-top">
										<span className="space-right-small">
											<ColorButton
												variant="contained"
												color="teal"
												onClick={this.handleUploadFile}
											>
												Upload File
												<CloudUploadIcon className="space-left-smallest" />
											</ColorButton>
										</span>
										<Button onClick={this.handleCancelFile}>
											Select a different file
										</Button>
									</div>
								</div>
							)}
							<div className={classes.footer}>
								<div className={classes.footerNoteContainer}>
									<div className={classes.footerNote}>
										<LockIcon className="space-bottom-smallest" />
										<Typography variant="body1" color="inherit">
											Your confidential data, especially pricing data, is not
											shared with any other entity except the requester.
										</Typography>
									</div>
									<div className={classes.footerNote}>
										<SecurityIcon className="space-bottom-smallest" />
										<Typography variant="body1" color="inherit">
											We take your data seriously, we encrypt your data at rest
											and in transit.
										</Typography>
									</div>
								</div>
								<Typography variant="body1" color="inherit">
									Something wrong?
									<Button color="primary" href="mailto:support@relatable.io">
										Contact Us
									</Button>
								</Typography>
							</div>
						</React.Fragment>
					)}
					<React.Fragment>
						<hr />
						<div className={classes.signupForm}>
							<Typography variant="h5" gutterBottom>
								Supplier Early Access Program
							</Typography>
							<Typography variant="body1" gutterBottom>
								Want to learn about our Supplier Early Access Program? In
								addition to being the first to know about relevant sales
								opportunities, we give you a powerful set of digital tools to
								manage, communicate and apply your product data across your
								organization and beyond.
							</Typography>
							<link
								href="//cdn-images.mailchimp.com/embedcode/classic-10_7.css"
								rel="stylesheet"
								type="text/css"
							/>
							<div id="mc_embed_signup">
								<form
									action="https://relatable.us16.list-manage.com/subscribe/post?u=9e8702daf36e0681bfba6e566&amp;id=71a1d900e0"
									method="post"
									id="mc-embedded-subscribe-form"
									name="mc-embedded-subscribe-form"
									className="validate"
									target="_blank"
									noValidate
								>
									<div id="mc_embed_signup_scroll">
										<div className="indicates-required">
											<span className="asterisk">*</span> indicates required
										</div>
										<div className="mc-field-group">
											<label htmlFor="mce-FNAME">
												First Name <span className="asterisk">*</span>
											</label>
											<input
												type="text"
												defaultValue=""
												name="FNAME"
												className="required"
												id="mce-FNAME"
											/>
										</div>
										<div className="mc-field-group">
											<label htmlFor="mce-LNAME">
												Last Name <span className="asterisk">*</span>
											</label>
											<input
												type="text"
												defaultValue=""
												name="LNAME"
												className="required"
												id="mce-LNAME"
											/>
										</div>
										<div className="mc-field-group">
											<label htmlFor="mce-EMAIL">
												Email Address <span className="asterisk">*</span>
											</label>
											<input
												type="email"
												defaultValue=""
												name="EMAIL"
												className="required email"
												id="mce-EMAIL"
											/>
										</div>
										<div className="mc-field-group size1of2">
											<label htmlFor="mce-PHONE">
												Phone Number <span className="asterisk">*</span>
											</label>
											<input
												type="text"
												name="PHONE"
												className="required"
												defaultValue=""
												id="mce-PHONE"
											/>
										</div>
										<div className="mc-field-group">
											<label htmlFor="mce-MMERGE3">
												Company Name <span className="asterisk">*</span>
											</label>
											<input
												type="text"
												defaultValue=""
												name="MMERGE3"
												className="required"
												id="mce-MMERGE3"
											/>
										</div>
										<div className="mc-field-group">
											<label htmlFor="mce-MMERGE5">
												Company Website <span className="asterisk">*</span>
											</label>
											<input
												type="text"
												defaultValue=""
												name="MMERGE5"
												className="required"
												id="mce-MMERGE5"
											/>
										</div>
										<div className="mc-field-group input-group">
											<strong>
												What Service Lines Are Relevant To Your Company?{' '}
											</strong>
											<ul>
												<li>
													<input
														type="checkbox"
														value="4"
														name="group[4805][4]"
														id="mce-group[4805]-4805-0"
													/>
													<label htmlFor="mce-group[4805]-4805-0">
														Cardiovascular
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														value="8"
														name="group[4805][8]"
														id="mce-group[4805]-4805-1"
													/>
													<label htmlFor="mce-group[4805]-4805-1">
														Extremities
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														value="16"
														name="group[4805][16]"
														id="mce-group[4805]-4805-2"
													/>
													<label htmlFor="mce-group[4805]-4805-2">
														Orthopedics
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														value="32"
														name="group[4805][32]"
														id="mce-group[4805]-4805-3"
													/>
													<label htmlFor="mce-group[4805]-4805-3">Spine</label>
												</li>
												<li>
													<input
														type="checkbox"
														value="64"
														name="group[4805][64]"
														id="mce-group[4805]-4805-4"
													/>
													<label htmlFor="mce-group[4805]-4805-4">Other</label>
												</li>
											</ul>
										</div>
										<div className="mc-field-group input-group">
											<strong>
												Interested in the Supplier Early Access Program?{' '}
											</strong>
											<ul>
												<li>
													<input
														type="checkbox"
														value="1"
														name="group[4801][1]"
														id="mce-group[4801]-4801-0"
													/>
													<label htmlFor="mce-group[4801]-4801-0">
														Yes, let's chat.
													</label>
												</li>
												<li>
													<input
														type="checkbox"
														value="2"
														name="group[4801][2]"
														id="mce-group[4801]-4801-1"
													/>
													<label htmlFor="mce-group[4801]-4801-1">
														No, thanks.
													</label>
												</li>
											</ul>
										</div>
										<div id="mce-responses" className="clear">
											<div
												id="mce-error-response"
												className={classNames([classes.hide, 'response'])}
											/>
											<div
												id="mce-success-response"
												className={classNames([classes.hide, 'response'])}
											/>
										</div>
										<div className={classes.hideByPosition} aria-hidden="true">
											<input
												type="text"
												name="b_9e8702daf36e0681bfba6e566_71a1d900e0"
												tabIndex="-1"
												defaultValue=""
											/>
										</div>
										<div className="clear">
											<input
												type="submit"
												value="Subscribe"
												name="subscribe"
												id="mc-embedded-subscribe"
												className="button"
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</React.Fragment>
				</div>
			</ViewContainer>
		)
	}

	renderDropzone() {
		return (
			<div className="space-top-large">
				<Dropzone
					className={this.props.classes.dropzone}
					multiple={false}
					accept={XLSX_MIME}
					onDrop={this.handleDrop}
				>
					<div>
						<CloudUploadIcon color="primary" fontSize="large" />
						<Typography variant="h6" component="p">
							Drop an XLSX file right here
						</Typography>
						<Typography variant="body1" color="textSecondary">
							(or click to select one)
						</Typography>
					</div>
				</Dropzone>
			</div>
		)
	}

	handleDrop(files, rejected) {
		if (!files || !files.length) {
			return
		}

		const file = files[0]

		this.setState({ file })
	}

	handleCancelFile() {
		this.setState({ file: null })
	}

	handleUploadFile() {
		const model = new RFPIResponseModel({ id: this.state.recipientId })

		this.setState({ isLoading: true })

		model
			.create(this.state.file)
			.then((response) => {
				this.setState({ completeMessage: SUCCESS_MESSAGE, isLoading: false })
			})
			.catch((error) => {
				this.setState({ completeMessage: error.message, isLoading: false })
			})
	}
}

RFPIUploadView.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(RFPIUploadView))
