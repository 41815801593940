import React from 'react'
import * as Sentry from '@sentry/browser'
// import { Button } from '@mui/material'
export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { eventId: null }
	}

	static getDerivedStateFromError(error) {
		return { hasError: true }
	}

	componentDidCatch(error, errorInfo) {
		Sentry.withScope((scope) => {
			scope.setExtras(errorInfo)
			const eventId = Sentry.captureException(error)
			this.setState({ eventId })
		})
	}

	render() {
		// if (this.state.hasError) {
		// 	//render fallback UI
		// 	return (
		// 		<>
		// 			<h1>
		// 				We are sorry - something is gone wrong. Our team has been notified,
		// 				but the below button to fill out a report.
		// 			</h1>
		// 			<Button
		// 				color="secondary"
		// 				size="large"
		// 				onClick={() =>
		// 					Sentry.showReportDialog({ eventId: this.state.eventId })
		// 				}
		// 			>
		// 				Report feedback
		// 			</Button>
		// 		</>
		// 	)
		// }

		//when there's not an error, render children untouched
		return this.props.children
	}
}
