import { isNil } from 'lodash'

export function getBooleanValue(value) {
	if (!isNil(value)) {
		return value
	} else {
		return null
	}
}

export function getStringFromBoolean(value) {
	if (typeof value === 'string') {
		return value.toLowerCase() === 'true' ? 'TRUE' : 'FALSE'
	}
	if (value === true) {
		return 'TRUE'
	} else {
		return 'FALSE'
	}
}
