// @see https://github.com/facebook/create-react-app/releases/tag/v2.0.3
import 'react-app-polyfill/ie9' // For IE 9-11 support

import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { Provider } from 'react-redux'
import { store, history } from 'state'
import AppContainer from './containers/app/AppContainer'
import ErrorUnsupportedBrowserView from 'views/error/ErrorUnsupportedBrowserView'
import {
	initAnalytics,
	trackEvent,
	registerSuperProps,
} from 'services/analytics'
import { isChrome } from 'services/browser'
import theme from 'theme'
import './index.css'
import { initSentry } from './services/sentry'
import ErrorBoundary from './components/sentry/ErrorBoundary'
import { Auth0Provider } from '@auth0/auth0-react'

// -- init analytics
initAnalytics(window.env.MIXPANEL_ID)
registerSuperProps({
	app_version: window.env.VERSION,
	app_env: window.env.ORIGIN,
})
trackEvent('Application Loaded')
// --
// init Sentry
initSentry()

const onRedirectCallback = (appState) => {
	history.push(
		appState && appState.returnTo
			? appState.returnTo
			: window.location.pathname,
	)
}

const providerConfig = {
	domain: window.env.AUTH0_DOMAIN,
	clientId: window.env.AUTH0_CLIENT_ID,
	...(window.env.AUTH0_AUDIENCE
		? { audience: window.env.AUTH0_AUDIENCE }
		: null),
	redirectUri: window.location.origin,
	onRedirectCallback,
	cacheLocation: 'localstorage',
}
const container = document.getElementById('root')
const root = createRoot(container)

root.render(
	<ErrorBoundary>
		<Auth0Provider {...providerConfig}>
			<Provider store={store}>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<AppContainer />
					{!isChrome() && <ErrorUnsupportedBrowserView />}
				</ThemeProvider>
			</Provider>
		</Auth0Provider>
	</ErrorBoundary>,
)

// @todo registerServiceWorker()
