import { cloneDeep } from 'lodash'
import { Model } from './base'
import { config as API } from 'api'

// @todo implement with flow
// export type ConstructProductTypeType = {
// 	product_type_id: string,
// 	quantity: number
// }

// export type ConstructModelType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
// 	name: string
// 	product_types: Array<ConstructProductTypeType>
// }

const defaultFields = {
	name: '',
	product_types: []
}

const SAVE_TEXT = 'Save Construct'
const CREATE_TEXT = 'Create Construct'
const DELETE_ACTION_TEXT =
	'Are you sure you would like to delete this Construct?'
const SAVE_ERROR_TEXT = 'Error saving Construct'
const CREATE_ERROR_TEXT = 'Error creating Construct'
const DELETE_ERROR_TEXT = 'Error deleting Construct'

export default class ConstructModel extends Model {
	static endpoint = API.CONSTRUCTS.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	constructor(fields = defaultFields) {
		super(fields)
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	validate() {
		const { fields } = this

		if (!fields.name) {
			return false
		} else if (!fields.product_types || !fields.product_types.length) {
			return false
		} else {
			return true
		}
	}

	addProductType(productTypeId, quantity) {
		const productTypes = this.get('product_types') || []
		let newProductTypes = cloneDeep(productTypes)
		newProductTypes.push({
			product_type_id: productTypeId,
			quantity
		})
		this.set({ product_types: newProductTypes })
	}

	removeProductType(productTypeId, quantity) {
		const productTypes = this.get('product_types') || []
		let newProductTypes = cloneDeep(productTypes)
		const index = newProductTypes.findIndex(
			pt => pt.product_type_id === productTypeId && pt.quantity === quantity
		)

		if (index > -1) {
			newProductTypes.splice(index, 1)
			this.set({ product_types: newProductTypes })
		}
	}

	get saveActionText() {
		return this.id() ? SAVE_TEXT : CREATE_TEXT
	}
}
