/**
 * Product Sort Types
 */

export const PRODUCT_NUMBER = 'number'
export const COMPANY = 'company'
export const SYSTEM = 'system'
export const YES = 'Yes'
export const NO = 'No'
export const ALL = 'All'

// To present type as string in UI
export const map = {
	[PRODUCT_NUMBER]: 'Product Number',
	[COMPANY]: 'Company',
	[SYSTEM]: 'System'
}

// For use in dropdown fields, etc
// PRODUCT_NUMBER not included because it is secondary and included by default
export const list = [
	{ value: null, text: null },
	{ value: COMPANY, text: map[COMPANY] },
	{ value: SYSTEM, text: map[SYSTEM] }
]

export const select_list = [
	{ value: ALL, text: 'All' },
	{ value: YES, text: 'Yes' },
	{ value: NO, text: 'No' }
]
