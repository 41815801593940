import { cloneDeep } from 'lodash'
import { Model } from './base'
import { PRODUCT } from 'data/classifier-types'
import { BOOL as BOOL_TYPE } from 'data/data-types'
import { config as API } from 'api'

// @todo implement with flow
// export type ClassifierType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
//	type: ?string // one of ClassifierDefType
//	product_type_id: ?string,
// 	name: ?string
// 	data_type: ?string // one of DataTypeType
//	description: ?string
//	multi_value: ?boolean
//	recommended: ?boolean
//	required_val: ?boolean
// }

const defaultFields = {
	name: null,
	type: null,
	data_type: null,
	description: null,
	product_type_id: null,
	multi_value: false,
	recommended: false,
	required_val: false,
	deleted: false
}

export const DESCRIPTION_MAX_LENGTH = 100
const SAVE_TEXT = 'Save'
const CREATE_TEXT = 'Create'
const DELETE_ACTION_TEXT =
	'Are you sure you would like to delete this Classifier?'
const SAVE_ERROR_TEXT = 'Error saving Classifier'
const CREATE_ERROR_TEXT = 'Error creating Classifier'
const DELETE_ERROR_TEXT = 'Error deleting Classifier'

export default class ClassifierModel extends Model {
	static endpoint = API.CLASSIFIER_DEFS.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	constructor(fields = defaultFields) {
		super(fields)
	}

	/**
	 * if changing from PRODUCT type to UNIVERSAL/PURCHASE type
	 * clear the product_type_id
	 *
	 * if changing to BOOL data_type
	 * change multi_value to false
	 */

	set(fields) {
		let newFields = cloneDeep(fields)

		if (fields['type'] && fields['type'] !== PRODUCT) {
			newFields['product_type_id'] = null
		} else if (fields['data_type'] === BOOL_TYPE) {
			newFields['multi_value'] = false
		}

		this.fields = Object.assign(this.fields, newFields)
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	validate() {
		const { fields } = this

		if (!fields.name) {
			return false
		} else if (!fields.type) {
			return false
		} else if (!fields.data_type) {
			return false
		} else if (fields.type === PRODUCT && !fields.product_type_id) {
			return false
		} else {
			return true
		}
	}

	get saveActionText() {
		return this.id() ? SAVE_TEXT : CREATE_TEXT
	}
}
