/**
 * Roles
 */

// export const WEB_ANON = 'web_anon'
// export const AUTHENTICATOR = 'authenticator'
// const COMMON = 'common'

// these map to role ids on the server
// export const SUPER_ADMIN = 'super_admin'
export const ADMIN = 'admin'
export const SUPER_ADMIN = 'super_admin'
export const SUPPLY_CHAIN = 'supply_chain'
export const USER_READER = 'user_reader'
export const USER_MANAGER = 'user_manager'
export const ACCOUNT_READER = 'account_reader'
export const ACCOUNT_MANAGER = 'account_manager'
export const INVITE_READER = 'invite_reader'
export const INVITE_MANAGER = 'invite_manager'
export const TAXONOMY_READER = 'taxonomy_reader'
export const TAXONOMY_MANAGER = 'taxonomy_manager'
export const PRODUCT_READER = 'product_reader'
export const PRODUCT_MANAGER = 'product_manager'
export const PURCHASE_READER = 'purchase_reader'
export const PURCHASE_MANAGER = 'purchase_manager'
export const PRODUCT_SHEET_READER = 'product_sheet_reader'
export const PRODUCT_SHEET_MANAGER = 'product_sheet_manager'
export const PURCHASE_SHEET_READER = 'purchase_sheet_reader'
export const PURCHASE_SHEET_MANAGER = 'purchase_sheet_manager'
export const RELATOR_MANAGER = 'relator_manager'
export const RFPI_ISSUER = 'rfpi_issuer'

// To present type as string in UI
export const map = {
	[SUPER_ADMIN]: {
		displayName: 'Super Admin',
		description: 'A Super Admin.'
	},
	[USER_READER]: {
		displayName: 'User Reader',
		description: 'A User Reader.'
	},
	[USER_MANAGER]: {
		displayName: 'User Manager',
		description: 'A User Manager.'
	},
	[ACCOUNT_READER]: {
		displayName: 'Account Reader',
		description: 'An Account Reader.'
	},
	[ACCOUNT_MANAGER]: {
		displayName: 'Account Manager',
		description: 'An Account Manager.'
	},
	[INVITE_READER]: {
		displayName: 'Invite Reader',
		description: 'An Invite Reader.'
	},
	[INVITE_MANAGER]: {
		displayName: 'Invite Manager',
		description: 'An Invite Manager.'
	},
	[TAXONOMY_READER]: {
		displayName: 'Taxonomy Reader',
		description: 'A Taxonomy Reader.'
	},
	[TAXONOMY_MANAGER]: {
		displayName: 'Taxonomy Manager',
		description: 'A Taxonomy Manager.'
	},
	[PRODUCT_READER]: {
		displayName: 'Product Reader',
		description: 'A Product Reader.'
	},
	[PRODUCT_MANAGER]: {
		displayName: 'Product Manager',
		description: 'A Product Manager.'
	},
	[PURCHASE_READER]: {
		displayName: 'Purchase Reader',
		description: 'A Purchase Reader.'
	},
	[PURCHASE_MANAGER]: {
		displayName: 'Purchase Manager',
		description: 'A Purchase Manager.'
	},
	[PRODUCT_SHEET_READER]: {
		displayName: 'Product Sheet Reader',
		description: 'A Product Sheet Reader.'
	},
	[PRODUCT_SHEET_MANAGER]: {
		displayName: 'Product Sheet Manager',
		description: 'A Product Sheet Manager.'
	},
	[PURCHASE_SHEET_READER]: {
		displayName: 'Purchase Sheet Reader',
		description: 'A Purchase Sheet Reader.'
	},
	[PURCHASE_SHEET_MANAGER]: {
		displayName: 'Purchase Sheet Manager',
		description: 'A Purchase Sheet Manager.'
	},
	[RELATOR_MANAGER]: {
		displayName: 'Relator Manager',
		description: 'A Relator Manager.'
	},
	[RFPI_ISSUER]: {
		displayName: 'RFPI Issuer',
		description: 'An RFPI Issuer.'
	}
}

export const NO_LINKAGE_USER = [
	'Admin',
	'Product Marketing Manager',
	'Analyst',
	'Contracting Manager'
]
