import React, { Component, lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'connected-react-router'
import { Route, Routes } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import Hidden from '@mui/material/Hidden'
import { actions as navActions } from 'state/modules/nav'
import { actions as drawerActions } from 'state/modules/drawer'
import AsyncContainerLoader from 'components/loader/AsyncContainerLoader'
import AuthenticatedRoute from 'routes/AuthenticatedRoute'
import MainAppBar from 'components/app/MainAppBar'
import AppDrawer from 'components/app/AppDrawer'
import AlertContainer from 'containers/alert/AlertContainer'
import ModalContainer from 'containers/modal/ModalContainer'
import DrawerContainer from 'containers/drawer/DrawerContainer'
import DashboardView from 'views/dashboard/DashboardView'
import LogoutView from 'views/login/LogoutView'
import ProductComparePrintView from 'views/product/ProductComparePrintView'
import AccountVerifyEmailView from 'views/account/AccountVerifyEmailView'
import ErrorNotFoundView from 'views/error/ErrorNotFoundView'
import ROUTES from 'routes'
import { SUB_NAV_HEIGHT } from 'theme'
import SystemComparePrintView from '../views/product/SystemComparePrintView'

// -- Async Routes for code splitting
const AsyncAdminIndexContainer = lazy(
	() => import('containers/admin/AdminIndexContainer'),
)
const AsyncAccountIndexContainer = lazy(
	() => import('containers/account/AccountIndexContainer'),
)
const AsyncProductIndexContainer = lazy(
	() => import('containers/product/ProductIndexContainer'),
)
const AsyncRFPIIndexContainer = lazy(
	() => import('containers/rfpi/RFPIIndexContainer'),
)
const AsyncGPSIndexContainer = lazy(
	() => import('containers/gps/GPSIndexContainer'),
)
const AsyncALTAIndexContainer = lazy(
	() => import('containers/alta/ALTAIndexContainer'),
)
const AsyncTaxonomyIndexContainer = lazy(
	() => import('containers/taxonomy/TaxonomyIndexContainer'),
)
const AsyncTeamIndexContainer = lazy(
	() => import('containers/team/TeamIndexContainer'),
)
const AsyncFAQIndexContainer = lazy(
	() => import('containers/faq/FAQIndexContainer'),
)

const PRINT_QUERY = '?print=true'

const mapStateToProps = (state) => ({
	account: state.accounts.currentAccount,
	privileges: state.session.privileges,
	userRoleGroup: state.user.roleGroup,
	roleGroups: state.user.roleGroups,
	roleFreemium: state.user.roleFreemium,
	nav: state.nav,
	drawer: state.drawer,
	location: state.router.location,
})

const mapDispatchToProps = (dispatch) => ({
	showNav: () => dispatch(navActions.show()),
	hideNav: () => dispatch(navActions.hide()),
	showDrawer: () => dispatch(drawerActions.show()),
	hideDrawer: () => dispatch(drawerActions.hide()),
	navigateTo: (path) => {
		dispatch(navActions.hide())
		dispatch(routerActions.push(path))
	},
})

const styles = (theme) => ({
	toolbarSpacer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar,
	},
	subNavSpacer: {
		minHeight: SUB_NAV_HEIGHT,
	},
})

class AuthenticatedLayout extends Component {
	state = {
		printMode: Boolean(this.props.location.search.indexOf(PRINT_QUERY) > -1),
	}

	static getDerivedStateFromProps(props, state) {
		// if the route contains the `?print=true' search query, this will
		// prevent display of unneeded app components (TopNav, AlertBox, etc..)
		if (
			props.location.search.indexOf(PRINT_QUERY) > -1 &&
			state.printMode === false
		) {
			document.getElementById('body').className = 'print'
			return {
				printMode: true,
			}
		} else if (
			props.location.search.indexOf(PRINT_QUERY) === -1 &&
			state.printMode === true
		) {
			document.getElementById('body').className = ''
			return {
				printMode: false,
			}
		}

		return null
	}

	render() {
		const { classes } = this.props

		return (
			<div className="AuthenticatedLayout">
				{!this.state.printMode && (
					<React.Fragment>
						<MainAppBar />
						<AppDrawer
							account={this.props.account}
							privileges={this.props.privileges}
							isOpen={this.props.nav.showing}
							hideNav={this.props.hideNav}
							showNav={this.props.showNav}
							userRoleGroup={this.props.userRoleGroup}
							roleGroup={this.props.roleGroup}
							roleGroups={this.props.roleGroups}
							roleFreemium={this.props.roleFreemium}
						/>
						<DrawerContainer
							drawer={this.props.drawer}
							hideDrawer={this.props.hideDrawer}
						/>
						<ModalContainer />
						<AlertContainer />
					</React.Fragment>
				)}

				<div>
					{!this.state.printMode && (
						<React.Fragment>
							<div className={classes.toolbarSpacer} />
							<Hidden smDown>
								<div className={classes.subNavSpacer} />
							</Hidden>
						</React.Fragment>
					)}
					<Suspense fallback={<AsyncContainerLoader />}>
						<Routes>
							<Route path={ROUTES.ROOT} element={<DashboardView />} />
							<Route
								path={ROUTES.PRODUCT_COMPARE.PRINT_GPS}
								element={<ProductComparePrintView />}
							/>
							<Route
								path={ROUTES.PRODUCT_COMPARE.PRINT}
								element={<ProductComparePrintView />}
							/>
							<Route
								path={ROUTES.SYSTEM_COMPARE.PRINT}
								element={<SystemComparePrintView />}
							/>

							<Route
								path={ROUTES.VERIFY_EMAIL}
								element={
									<AuthenticatedRoute path={ROUTES.VERIFY_EMAIL}>
										<AccountVerifyEmailView />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={ROUTES.FAQ + '/*'}
								element={<AsyncFAQIndexContainer />}
							/>
							<Route
								path={ROUTES.ADMIN.ROOT + '/*'}
								element={
									<AuthenticatedRoute path={ROUTES.VERIFY_EMAIL}>
										<AsyncAdminIndexContainer />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={ROUTES.ACCOUNT.ROOT + '/*'}
								element={<AsyncAccountIndexContainer />}
							/>
							<Route
								path={ROUTES.TEAM.ROOT + '/*'}
								element={<AsyncTeamIndexContainer />}
							/>
							<Route
								path={ROUTES.TAXONOMIES.ROOT + '/*'}
								element={<AsyncTaxonomyIndexContainer />}
							/>
							<Route
								path={ROUTES.PRODUCTS.ROOT + '/*'}
								element={<AsyncProductIndexContainer />}
							/>
							<Route
								path={ROUTES.PRODUCTS.SHEET_ERRORS}
								element={
									<AuthenticatedRoute path={ROUTES.PRODUCTS.SHEET_ERRORS}>
										<AsyncProductIndexContainer />
									</AuthenticatedRoute>
								}
							/>
							<Route
								path={ROUTES.RFPIS.ROOT + '/*'}
								element={<AsyncRFPIIndexContainer />}
							/>
							<Route
								path={ROUTES.GPS.ROOT + '/*'}
								element={<AsyncGPSIndexContainer />}
							/>
							<Route
								path={ROUTES.ALTA.ROOT + '/*'}
								element={<AsyncALTAIndexContainer />}
							/>
							<Route
								path={ROUTES.LOGOUT}
								element={
									<AuthenticatedRoute path={ROUTES.LOGOUT}>
										<LogoutView />
									</AuthenticatedRoute>
								}
							/>
							<Route element={<ErrorNotFoundView />} />
						</Routes>
					</Suspense>
				</div>
			</div>
		)
	}
}

AuthenticatedLayout.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
	theme: PropTypes.object.isRequired,
	// -- state props
	userRoleGroup: PropTypes.string,
	nav: PropTypes.object.isRequired,
	privileges: PropTypes.array.isRequired,
	// -- dispatch props
	showNav: PropTypes.func.isRequired,
	hideNav: PropTypes.func.isRequired,
	navigateTo: PropTypes.func.isRequired,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(AuthenticatedLayout))
