const initialState = {
	isPCDataExist: false
}

export const HAS_FETCHED = 'HAS_FETCHED'

export const hidePPTbl = (isPCDataExist = true) => ({
	type: HAS_FETCHED,
	payload: { isPCDataExist }
})
export const showPPTbl = (isPCDataExist = false) => ({
	type: HAS_FETCHED,
	payload: { isPCDataExist }
})

const ACTION_HANDLERS = {
	[HAS_FETCHED]: (state, action) => ({
		...state,
		...action.payload
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	hidePPTbl,
	showPPTbl
}
