/**
 * Invite Types
 *
 * The keys in this document map to keys that exist on the server.
 *
 */

export const RELATABLE = 'relatable' // relatable invites a new account with a new user
export const TEAM = 'team' // an existing account invites a new user to the team
export const EXISTING_MEMBER = 'existing_member' // an existing account invites an existing member account
export const EXISTING_VENDOR = 'existing_vendor' // an existing account invites an existing vendor account
export const NEW_MEMBER = 'new_member' // an existing account invites a new member account
export const NEW_VENDOR = 'new_vendor' // an existing account invites a new vendor account

// To present type as string in UI
export const map = {
	[RELATABLE]: 'Relatable GPO Account',
	[TEAM]: 'Team Member',
	[EXISTING_MEMBER]: 'Relatable Member Account',
	[EXISTING_VENDOR]: 'Relatable Vendor Account',
	[NEW_MEMBER]: 'New Member Account',
	[NEW_VENDOR]: 'New Vendor Account'
}

// For use in dropdown fields, etc
export const list = [
	{ value: RELATABLE, text: map[RELATABLE] },
	{ value: TEAM, text: map[TEAM] },
	{ value: EXISTING_MEMBER, text: map[EXISTING_MEMBER] },
	{ value: EXISTING_VENDOR, text: map[EXISTING_VENDOR] },
	{ value: NEW_MEMBER, text: map[NEW_MEMBER] },
	{ value: NEW_VENDOR, text: map[NEW_VENDOR] }
]
