export const publicViewStyles = (theme) => ({
	logo: {
		margin: '0 auto',
		marginBottom: theme.spacing(4),
		maxWidth: 400,
		display: 'block',
		[theme.breakpoints.down('sm')]: {
			maxWidth: '280px!important',
		},
	},
	form: {
		margin: `${theme.spacing(2)} auto`,
		maxWidth: 400,
	},
})
