/**
 * @see https://reacttraining.com/react-router/web/api
 * @see https://www.npmjs.com/package/path-to-regexp
 */

import SettingsIcon from '@mui/icons-material/Settings'
import AccountIcon from '@mui/icons-material/AccountCircle'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import TeamIcon from '@mui/icons-material/Group'
import MoneyIcon from '@mui/icons-material/AttachMoney'
import GridIcon from '@mui/icons-material/ViewQuilt'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AddPersonIcon from '@mui/icons-material/PersonAdd'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import InfinityIcon from '@mui/icons-material/AllInclusive'
import PersonIcon from '@mui/icons-material/Person'
import KeyIcon from '@mui/icons-material/VpnKey'
import MailIcon from '@mui/icons-material/Mail'
import HelpIcon from '@mui/icons-material/Help'
import StorageIcon from '@mui/icons-material/Storage'
import BlockIcon from '@mui/icons-material/Block'
// import InfoIcon from '@mui/icons-material/Info'
// import MailIcon from '@mui/icons-material/Mail'
import BarChartIcon from '@mui/icons-material/BarChart'
import LineChartIcon from '@mui/icons-material/MultilineChart'
import AssignmentIcon from '@mui/icons-material/Assignment'
import LinesIcon from '@mui/icons-material/LineStyle'
import ReportIcon from '@mui/icons-material/Report'

export const TITLE = 'TITLE'
export const ICON = 'ICON'
export const PATH = 'PATH'

// -- Routes Config

const ROUTES = {
	ROOT: '/',
	LOGIN: '/login',
	LOGOUT: '/logout',
	FORGOT_PASSWORD: '/forgot-password',
	RESET_PASSWORD: '/reset-password/:id',
	VERIFY_EMAIL: '/verify-email/:id',
	RFPI_UPLOAD: '/rfpi-upload/:id',
	FAQ: '/faq',
	SIGNUP: {
		ROOT: '/signup',
		VERIFY: '/signup/:id',
		USER: '/signup/user',
		ACCOUNT: '/signup/account',
	},
	ADMIN: {
		ROOT: '/admin',
		ACCOUNT_CREATE: '/admin/new-acccount',
		USER_CREATE: '/admin/new-user',
	},
	ACCOUNT: {
		ROOT: '/account',
		PROFILE: '/account/profile',
		PROFILE_EDIT: '/account/profile/edit',
		UPDATE_EMAIL: '/account/update-email',
		RESET_PASSWORD: '/account/reset-password',
		SETTINGS: '/account/settings',
	},
	TEAM: {
		ROOT: '/team',
		USER_INVITE: '/team/users/invite',
		USERS: '/team/users',
		USER: '/team/users/:id',
		USER_EDIT: '/team/users/:id/edit',
		USER_EDIT_RESTRICTORS: '/team/users/:id/edit-restrictors',
	},
	PRODUCT_COMPARE: {
		ROOT: '/products/compare',
		PRINT: '/products/compare/:hash?',
		PRINT_GPS: '/products/compare/gps/:gpsId/:page/:perPage/:hash?',
	},
	SYSTEM_COMPARE: {
		ROOT: '/systems/compare',
		PRINT:
			'/systems/compare/:productTypeIds/:selectedSystems/:excludeClassifierDefIds?',
	},
	PRODUCTS: {
		ROOT: '/products',
		PRODUCT: '/products/:id',
		PRODUCT_EDIT: '/products/:id/edit',
		CREATE: '/products/new',
		UPLOAD: '/products/upload',
		IDENTIFY: '/products/identify',
		// SHEETS: '/products/sheets',
		UPLOAD_PRICES: '/products/upload-prices',
		MANAGE: '/products/data-management',
		SHEET: '/products/data-management/:id',
		SHEET_EDIT: '/products/data-management/:id/edit',
		SHEET_ERRORS: '/products/data-management/:id/errors',
		RELATORS: '/products/relators',
		RELATOR: '/products/relators/:id',
		RELATOR_EDIT: '/products/relators/:id/edit',
		RELATOR_CREATE: '/products/relators/new',
		RELATOR_GROUP_CREATE: '/products/relators/new-relator-group',
		RELATOR_GROUPS: '/products/relator-groups',
		RELATOR_GROUP: '/products/relator-groups/:id',
		RELATOR_GROUP_EDIT: '/products/relator-groups/:id/edit',
		CASCADING_RELATOR: '/products/relators/cascading-relator',
		RESTRICTORS: '/products/restrictors',
		REPORTED_DATA: '/products/reported-data',
	},
	// RESTRICTORS: {
	// 	ROOT: '/restrictors'
	// },
	RFPIS: {
		ROOT: '/rfpi',
		RFPI: '/rfpi/:id',
		RFPI_EDIT: '/rfpi/:id/edit',
		RFPI_CREATE: '/rfpi/new',
	},
	ALTA: {
		ROOT: '/alta',
		HOME: '/alta/home',
		CREATE: '/alta/new',
		DELETE: '/alta/delete',
		VIEW: '/alta/:id',
		PDF: '/alta/:id/pdf',
		SAMPLE: '/alta-sample',
		DASHBOARD: '/alta/dashboard',
	},
	GPS: {
		ROOT: '/guided-purchases',
		CREATE: '/guided-purchases/new',
		VIEW: '/guided-purchases/:id',
		EDIT: '/guided-purchases/:id/edit',
		EDIT_FILTER_USAGE: '/guided-purchases/:id/edit/filter-usage-products',
		EDIT_FILTER_RECS: '/guided-purchases/:id/edit/filter-recommended-products',
		EDIT_CALCULATE: '/guided-purchases/:id/edit/calculate',
		SEGMENT: '/guided-purchases/:id/segment',
		IRM: '/guided-purchases/instant-recommendation-management',
	},
	TAXONOMIES: {
		ROOT: '/taxonomies',
		GENERATOR: '/taxonomies/generator',
		CONSTRUCTS: '/taxonomies/constructs',
		CONSTRUCT: '/taxonomies/constructs/:id',
		CONSTRUCT_EDIT: '/taxonomies/constructs/:id/edit',
		CONSTRUCT_CREATE: '/taxonomies/constructs/new',
		DIVISIONS: '/taxonomies/divisions', // used only for permissions
		DIVISION: '/taxonomies/divisions/:id', // used only for permissions
		DIVISION_EDIT: '/taxonomies/divisions/:id/edit', // used only for permissions
		DIVISION_CREATE: '/taxonomies/divisions/new', // used only for permissions
		PRODUCT_TYPES: '/taxonomies/product-types', // used only for permissions
		PRODUCT_TYPE: '/taxonomies/product-types/:id', // used only for permissions
		PRODUCT_TYPE_EDIT: '/taxonomies/product-types/:id/edit', // used only for permissions
		PRODUCT_TYPE_CREATE: '/taxonomies/product-types/new', // used only for permissions
		CLASSIFIERS: '/taxonomies/classifiers', // used only for permissions
		CLASSIFIER: '/taxonomies/classifiers/:id', // used only for permissions
		CLASSIFIER_EDIT: '/taxonomies/classifiers/:id/edit', // used only for permissions
		CLASSIFIER_CREATE: '/taxonomies/classifiers/new', // used only for permissions
	},
	ERROR: {
		NOT_FOUND: '/404',
	},
}

export const HOME_ROUTE = ROUTES.ROOT

// -- Sections

export const ADMIN = {
	[TITLE]: 'Admin',
	[ICON]: SettingsIcon,
	[PATH]: ROUTES.ADMIN.ROOT,
}

export const ACCOUNT = {
	[TITLE]: 'Account',
	[ICON]: AccountIcon,
	[PATH]: ROUTES.ACCOUNT.ROOT,
}

export const TEAM = {
	[TITLE]: 'Team',
	[ICON]: TeamIcon,
	[PATH]: ROUTES.TEAM.ROOT,
}

export const PRODUCTS = {
	[TITLE]: 'Product Data Catalog',
	[ICON]: GridIcon,
	[PATH]: ROUTES.PRODUCTS.ROOT,
}

export const GPS = {
	[TITLE]: 'GPS',
	[ICON]: MoneyIcon,
	[PATH]: ROUTES.GPS.ROOT,
}

export const ALTA = {
	[TITLE]: 'ALTA',
	[ICON]: LineChartIcon,
	[PATH]: ROUTES.ALTA.ROOT,
}

export const TAXONOMY = {
	[TITLE]: 'Taxonomies',
	[ICON]: LinesIcon,
	[PATH]: ROUTES.TAXONOMIES.ROOT,
}

// -- List of Navigation Items

export const navItems = [ADMIN, ACCOUNT, TEAM, PRODUCTS, GPS, TAXONOMY]

export default ROUTES

export const navigationMap = [
	// -- Product Data Catalog
	{
		path: ROUTES.PRODUCTS.ROOT,
		title: 'Product Data Catalog',
		icon: GridIcon,
		children: [
			{
				path: ROUTES.PRODUCTS.ROOT,
				title: 'Catalog',
				icon: GridIcon,
			},
			{
				path: ROUTES.PRODUCTS.CREATE,
				title: 'Add Product',
				icon: AddCircleIcon,
			},
			{
				path: ROUTES.PRODUCTS.UPLOAD,
				title: 'Upload Products',
				icon: CloudUploadIcon,
			},
			{
				path: ROUTES.PRODUCTS.IDENTIFY,
				title: 'Identify Products',
				icon: CloudUploadIcon,
			},
			{
				path: ROUTES.PRODUCTS.UPLOAD_PRICES,
				title: 'Upload Prices',
				icon: CloudUploadIcon,
			},
			{
				path: ROUTES.PRODUCTS.RELATORS,
				title: 'Relators',
				icon: InfinityIcon,
			},
			{
				path: ROUTES.PRODUCTS.MANAGE,
				title: 'Data Management',
				icon: StorageIcon,
			},
			{
				path: ROUTES.PRODUCTS.RESTRICTORS,
				title: 'Restriction',
				icon: BlockIcon,
			},
			{
				path: ROUTES.PRODUCTS.REPORTED_DATA,
				title: 'Reported Data',
				icon: ReportIcon,
			},
		],
	},
	// -- ALTA
	{
		path: ROUTES.ALTA.ROOT,
		title: 'ALTA',
		icon: LineChartIcon,
		children: [
			{
				path: ROUTES.ALTA.HOME,
				title: 'ALTA Home',
				icon: LineChartIcon,
			},
			{
				path: ROUTES.ALTA.CREATE,
				title: 'Start New ALTA',
				icon: AddCircleIcon,
			},
			{
				path: ROUTES.ALTA.DASHBOARD,
				title: 'Dashboard',
				icon: LineChartIcon,
			},
			{
				path: ROUTES.ALTA.DELETE,
				title: 'ALTA Management',
				icon: StorageIcon,
			},
		],
	},
	// -- GPS
	{
		path: ROUTES.GPS.ROOT,
		title: 'GPS',
		icon: BarChartIcon,
		children: [
			{
				path: ROUTES.GPS.ROOT,
				title: 'GPS Home',
				icon: BarChartIcon,
			},
			{
				path: ROUTES.GPS.CREATE,
				title: 'Start New GPS',
				icon: AddCircleIcon,
			},
			{
				path: ROUTES.GPS.IRM,
				title: 'Instant Recommendation Management',
				icon: AddCircleIcon,
			},
		],
	},
	// -- RFPI
	{
		path: ROUTES.RFPIS.ROOT,
		title: 'RFPI',
		icon: AssignmentIcon,
		children: [
			{
				path: ROUTES.RFPIS.ROOT,
				title: 'RFPI History',
				icon: AssignmentIcon,
			},
			{
				path: ROUTES.RFPIS.RFPI_CREATE,
				title: 'Issue New RFPI',
				icon: AddCircleIcon,
			},
		],
	},
	// -- Taxonomy
	{
		path: ROUTES.TAXONOMIES.ROOT,
		title: 'Taxonomy',
		icon: LinesIcon,
		children: [
			{
				path: ROUTES.TAXONOMIES.ROOT,
				title: 'Taxonomy Home',
				icon: LinesIcon,
			},
		],
	},
	// -- Team
	{
		path: ROUTES.TEAM.ROOT,
		title: 'Team',
		icon: TeamIcon,
		children: [
			{
				path: ROUTES.TEAM.ROOT,
				title: 'Your Team',
				icon: TeamIcon,
			},
			{
				path: ROUTES.TEAM.USER_INVITE,
				title: 'Invite New Members',
				icon: AddPersonIcon,
			},
		],
	},
	// -- FAQ
	{
		path: ROUTES.FAQ,
		title: 'FAQ',
		icon: HelpIcon,
		children: [
			{
				path: ROUTES.FAQ,
				title: 'FAQ Home',
				icon: HelpIcon,
			},
		],
	},
	{
		path: ROUTES.ACCOUNT.ROOT,
		title: 'Account',
		icon: AccountIcon,
		hideFromAppBar: true,
		children: [
			{
				path: ROUTES.ACCOUNT.ROOT,
				title: 'Your Account',
				icon: AccountBoxIcon,
			},
			{
				path: ROUTES.ACCOUNT.PROFILE,
				title: 'Your Profile',
				icon: PersonIcon,
			},
			{
				path: ROUTES.ACCOUNT.UPDATE_EMAIL,
				title: 'Update Email Address',
				icon: MailIcon,
			},
			{
				path: ROUTES.ACCOUNT.RESET_PASSWORD,
				title: 'Reset Password',
				icon: KeyIcon,
			},
		],
	},
]

export const adminNavigationMap = [
	{
		path: ROUTES.ADMIN.ROOT,
		title: 'Admin',
		icon: SettingsIcon,
		hideFromAppBar: true,
		children: [
			{
				path: ROUTES.ADMIN.ROOT,
				title: 'Admin Home',
				icon: SettingsIcon,
			},
			{
				path: ROUTES.ADMIN.ACCOUNT_CREATE,
				title: 'Add Account',
				icon: AddCircleIcon,
			},
			{
				path: ROUTES.ADMIN.USER_CREATE,
				title: 'Add User',
				icon: AddCircleIcon,
			},
		],
	},
]
