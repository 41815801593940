import { config as API } from 'api'
import * as postgrest from 'services/postgrest'

const ROLES_ENDPOINT = API.USER_ROLES.PATH
const ROLE_GROUPS_ENDPOINT = API.ROLE_GROUPS.PATH
const ROLE_VALS_ENDPOINT = API.ROLE_VALS.PATH
const USER_APP_ROLE_GROUP_ENDPOINT = API.USER_ROLE_GROUP.PATH
const SET_USER_ROLE_ENDPOINT = API.USER_ROLE_SET.PATH
export const MODEL_NAME = 'roles'

const initialState = {
	isFetching: false,
	hasFetched: false,
	hasFetchedRoleVals: false,
	hasFetchedUserRole: false,
	hasFetchedAllUserAppRoles: false,
	isFetchingAllUserAppRoles: false,
	fetchError: null,
	roles: [],
	roleGroup: null,
	userRole: {},
	userAppRoles: [],
	roleGroups: [],
	roleVals: {},
	roleFreemium: null,
	roleSystemName: null
}

const RESET = `${MODEL_NAME}_RESET`
const REQUESTED = `${MODEL_NAME}_REQUESTED`
const FETCHED = `${MODEL_NAME}_FETCHED`
// const SAVED = `${MODEL_NAME}_SAVED`
const FAILED = `${MODEL_NAME}_FAILED`
const ROLE_VALS_FETCHED = `${MODEL_NAME}_VALS_FETCHED`
const USER_ROLE_FETCHED = `USER_ROLE_FETCHED`
const ALL_USER_APP_ROLES_FETCHED = `ALL_USER_APP_ROLES_FETCHED`
const ALL_USER_APP_ROLES_REQUESTED = `ALL_USER_APP_ROLES_REQUESTED`

const reset = () => ({ type: RESET })
const request = (reqType = REQUESTED) => ({ type: reqType })
// receive can take FETCHED
const receive = (action, fields) => ({ type: action, payload: fields })
// const fail = error => ({ type: FAILED, payload: error })

/**
 * User's Roles
 * @param {String} userId
 */

const fetchRoles = userId => {
	return postgrest.fetch(ROLES_ENDPOINT, userId, 'user_id').then(response => {
		return fetchRoleGroup(userId).then(roleGroup => {
			return fetchRoleGroups().then(roleGroups => {
				const roles = response.roles ? response.roles : {}

				return {
					roles: Object.keys(roles),
					roleGroup: roleGroup.role_id,
					roleGroups: roleGroups,
					roleData: roleGroup.role_data,
					roleFreemium: roleGroup.is_freemium,
					roleSystemName: roleGroup.role_system_name
				}
			})
		})
	})
}

const fetchUserRole = userId => {
	return (dispatch, getState) => {
		dispatch(request())

		return fetchRoleGroup(userId).then(roleGroup => {
			dispatch(receive(USER_ROLE_FETCHED, roleGroup))
		})
	}
}

const fetchRoleGroup = userId => {
	return postgrest
		.fetch(USER_APP_ROLE_GROUP_ENDPOINT, userId, 'user_id')
		.then(response => {
			return response
		})
		.catch(error => {
			throw error
		})
}

const setUserRole = userId => {
	return postgrest
		.fetchAllWithPost(SET_USER_ROLE_ENDPOINT, {}, { user_id: userId })
		.then(response => {
			return response
		})
		.catch(error => {
			throw error
		})
}

const fetchAllUerAppRoles = () => {
	return (dispatch, getState) => {
		dispatch(request(ALL_USER_APP_ROLES_REQUESTED))

		return postgrest
			.fetchAll(USER_APP_ROLE_GROUP_ENDPOINT)
			.then(response => {
				dispatch(receive(ALL_USER_APP_ROLES_FETCHED, response))
				return response
			})
			.catch(error => {
				throw error
			})
	}
}

const fetchRoleGroups = () => {
	return postgrest
		.fetchAll(ROLE_GROUPS_ENDPOINT)
		.then(response => {
			return response
		})
		.catch(error => {
			throw error
		})
}

const fetchRoleVals = () => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.fetchAll(ROLE_VALS_ENDPOINT)
			.then(response => {
				dispatch(receive(ROLE_VALS_FETCHED, { roleVals: response[0] }))
				return response
			})
			.catch(error => {
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		...payload,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[USER_ROLE_FETCHED]: (state, { payload }) => ({
		...state,
		...payload,
		userRole: payload,
		fetchError: null,
		isFetching: false,
		hasFetchedUserRole: true
	}),
	[ALL_USER_APP_ROLES_REQUESTED]: (state, action) => ({
		...state,
		isFetchingAllUserAppRoles: true
	}),
	[ALL_USER_APP_ROLES_FETCHED]: (state, { payload }) => ({
		...state,
		userAppRoles: payload,
		hasFetchedAllUserAppRoles: true,
		isFetchingAllUserAppRoles: false
	}),
	[ROLE_VALS_FETCHED]: (state, { payload }) => ({
		...state,
		...payload,
		fetchError: null,
		isFetching: false,
		hasFetchedRoleVals: true
	}),
	[FAILED]: (state, action) => ({
		...state,
		fetchError: action.payload,
		isFetching: false
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetchRoleGroups,
	fetchRoleVals,
	fetchUserRole,
	fetchRoles,
	fetchAllUerAppRoles,
	reset,
	setUserRole
}
