import { v4 as uuidv4 } from 'uuid'
import { delay } from 'lodash'

const REMOVAL_DELAY = 300

const initialState = {
	items: []
}

const SHOW_ALERT = 'SHOW_ALERT'
const HIDE_ALERT = 'HIDE_ALERT'
const REMOVE_ALERT = 'REMOVE_ALERT'

const showAlert = obj => ({
	type: SHOW_ALERT,
	obj: obj.id ? obj : Object.assign({ id: uuidv4() }, obj)
})

const hideAlert = obj => {
	return dispatch => {
		dispatch({
			type: HIDE_ALERT,
			obj
		})

		delay(() => {
			dispatch({
				type: REMOVE_ALERT,
				obj
			})
		}, REMOVAL_DELAY)
	}
}

const ACTION_HANDLERS = {
	[SHOW_ALERT]: (state, action) => ({
		...state,
		items: state.items.concat(Object.assign({ open: true }, action.obj))
	}),
	[HIDE_ALERT]: (state, action) => {
		const items = state.items.map((item, index) => {
			if (item.id !== action.obj.id) {
				return item
			}

			return {
				...item,
				open: false
			}
		})

		return {
			...state,
			items
		}
	},
	[REMOVE_ALERT]: (state, action) => {
		return {
			...state,
			items: state.items.filter(item => item.id !== action.obj.id)
		}
	}
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	showAlert,
	hideAlert
}
