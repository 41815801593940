/**
 * Excel JS
 *
 * Read/Write for XLSX files
 *
 * @see https://github.com/guyonroche/exceljs
 * @see https://github.com/guyonroche/exceljs/issues/354
 */

// @see https://github.com/guyonroche/exceljs/blob/master/spec/browser/exceljs.spec.js
import FileSaver from 'file-saver'
import { getISOFormat } from 'services/date'

const ExcelJS = require('exceljs/dist/exceljs')
const OCTET_STREAM_FILE_TYPE = 'application/octet-stream'
const EXCEL_FILE_TYPE =
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
const EXCEL_FILE_EXTENSION = 'xlsx'

export function createWorkbook() {
	return new ExcelJS.Workbook()
}

/**
 * Writes file and uses FileSaver lib to auto-download
 * @todo move FileSaver method here into the new file service
 * @param {ExcelJS.Workbook} workbook
 * @param {String} filename
 */
export function saveWorkbookToExcel(workbook, filename) {
	return new Promise(resolve => {
		workbook.xlsx.writeBuffer({ useStyles: true }).then(data => {
			var blob = new Blob([data], { type: EXCEL_FILE_TYPE })
			FileSaver.saveAs(blob, `${filename}.${EXCEL_FILE_EXTENSION}`)
			resolve()
		})
	})
}

/**
 * Writes file and returns binary
 * @param {ExcelJS.Workbook} workbook
 * @param {String} filename
 */
export function saveWorkbookToBinary(workbook, filename) {
	return new Promise(resolve => {
		workbook.xlsx.writeBuffer({ useStyles: true }).then(data => {
			var blob = new Blob([data], { type: OCTET_STREAM_FILE_TYPE })
			blob.name = `${filename}.${EXCEL_FILE_EXTENSION}`
			resolve(blob)
		})
	})
}

/**
 * Parses XLSX file into readable JSON
 * @param {File} file
 */
export function readFile(file) {
	let workbook = createWorkbook()

	// let options = {
	// 	dateFormats: ['MM/DD/YYYY']
	// }

	return new Promise((resolve, reject) => {
		let reader = new FileReader()

		// @todo can read data processing state here and show loader

		reader.onload = event => {
			const data = event.target.result

			workbook.xlsx
				.load(data)
				.then(response => {
					resolve(workbook)
				})
				.catch(error => {
					reject(error)
				})
		}

		// @todo handle error
		reader.onerror = error => {
			reject(error)
		}

		// reader.readAsBinaryString(file)
		reader.readAsArrayBuffer(file)
	})
}

/**
 * takes a workbook and returns array of all sheets within the book
 *
 * @param {object} workbook
 * @return {array}
 */
export function getSheetsFromWorkbook(workbook) {
	let sheets = []

	if (!workbook || !workbook.worksheets.length) {
		return sheets
	}

	return workbook.worksheets
}

/**
 * @param {object} worksheet
 * @return {array}
 */
export function getColumnNamesFromWorksheet(worksheet) {
	let columnNames = []

	if (!worksheet) {
		return columnNames
	}

	const firstRow = worksheet.getRow(1)

	firstRow.eachCell({ includeEmpty: true }, cell => {
		// @note need to allow null header values here
		// so we can correctly match corresponding row cells
		columnNames.push(cell.value)
	})

	return columnNames
}

/**
 * takes a worksheet and returns array of all formatted cell values
 *
 * @param {object} worksheet
 * @return {array}
 */
export const getRowsFromWorksheet = worksheet => {
	const columnNames = getColumnNamesFromWorksheet(worksheet)
	let rows = []

	worksheet.eachRow((row, rowNumber) => {
		let rowValues = {}

		row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
			let value = cell.value
			let link = null
			let type = cell.type
			let skip = false
			let headerTitle = columnNames[colNumber - 1]

			switch (type) {
				case ExcelJS.ValueType.String:
					// do nothing (already string)
					break
				case ExcelJS.ValueType.RichText:
					// do nothing (already string)
					break
				case ExcelJS.ValueType.Hyperlink:
					link = value.hyperlink
					value = getHyperlinkText(value)
					break
				case ExcelJS.ValueType.Date:
					// format to MM/DD/YYYY
					value = getISOFormat(value)
					break
				case ExcelJS.ValueType.Boolean:
					// do nothing (already boolean)
					break
				case ExcelJS.ValueType.Number:
					// do nothing (already number)
					break
				case ExcelJS.ValueType.Null:
					skip = true
					break
				case ExcelJS.ValueType.Error:
					skip = true
					break
				case ExcelJS.ValueType.Formula:
					// check if string 'HYPERLINK' exists, if so, we need to set
					// val and link will be parts of this HYPERLINK string
					if (cell.formula && cell.formula.indexOf('HYPERLINK') > -1) {
						// return link val found between first set of quotes
						// ex: HYPERLINK("THE_LINK_WE_WANT", "THE_VAL_WE_WANT")
						link = cell.formula.match(/"(.*?)"/)[1]
						value = cell.value.result || link
					} else {
						// if it's not a hyperlink, we don't fully support it yet
						value = cell.value.result
						// skip = true
					}
					break
				default:
					// --
					break
			}

			if (!skip) {
				if (rowValues[headerTitle]) {
					rowValues[headerTitle].push({
						val: value,
						link
					})
				} else {
					rowValues[headerTitle] = [
						{
							val: value,
							link
						}
					]
				}
			}
		})

		if (rowNumber !== 1) {
			rows.push(rowValues) // don't want the header row
		}
	})

	return rows
}

function getHyperlinkText(value) {
	const { text } = value

	if (text && text.richText) {
		return text.richText[0].text
	} else {
		return value.hyperlink
	}
}

/**
 * Returns array of columnName:rowValue pairings for a single row in the spreadsheet.
 * If the row has a blank cell, it will search the next rows for a cell containing a value.
 * Supplies "example" values to users when mapping Classifiers to column names.
 */
export function getExampleValuesFromWorksheet(worksheet) {
	let exampleValues = {}
	const columnNames = getColumnNamesFromWorksheet(worksheet)
	const rows = getRowsFromWorksheet(worksheet)

	columnNames.forEach(header => {
		rows.find(row => {
			if (row[header]) {
				exampleValues[header] = row[header]
				return true
			} else {
				return false
			}
		})
	})

	return exampleValues
}

// --------------------
// ** old methods below **
// these are used for legacy sheet uploading (Purchase Sheets for GPS)
// --------------------

/**
 * takes a workbook and returns array of all column titles (first row)
 *
 * @param {object} workbook
 * @return {array}
 */
export const getHeaders = workbook => {
	if (!workbook || !workbook.worksheets.length) {
		return false
	}

	const worksheet = workbook.worksheets[0]
	const headerRow = worksheet.getRow(1)
	let headers = []

	headerRow.eachCell({ includeEmpty: true }, (cell, colNumber) => {
		// @note / @todo need to allow null header values here
		// so we can correctly match corresponding row cells
		headers.push(cell.value)
	})

	return headers
}

/**
 * takes a workbook and returns array of all rows
 *
 * @todo this assumes only one sheet (or the first sheet) is used
 * @todo remove arrayEnabled - currently only used for old
 * 			 purchase sheet row multivalue formatting
 * @see https://github.com/relatableio/web-app/issues/448
 *
 * @param {object} workbook
 * @return {array}
 */
export const getRows = (workbook, arrayEnabled = true) => {
	if (!workbook || !workbook.worksheets.length) {
		return false
	}

	const headers = getHeaders(workbook)
	const worksheet = workbook.worksheets[0]
	let rows = []

	worksheet.eachRow((row, rowNumber) => {
		let rowValues = {}

		row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
			let value = cell.value
			let link = null
			let type = cell.type
			let skip = false
			let headerTitle = headers[colNumber - 1]

			switch (type) {
				case ExcelJS.ValueType.String:
					// do nothing (already string)
					break
				case ExcelJS.ValueType.RichText:
					// do nothing (already string)
					break
				case ExcelJS.ValueType.Hyperlink:
					link = value.hyperlink
					value = getHyperlinkText(value)
					break
				case ExcelJS.ValueType.Date:
					// format to MM/DD/YYYY
					value = getISOFormat(value)
					break
				case ExcelJS.ValueType.Boolean:
					// do nothing (already boolean)
					break
				case ExcelJS.ValueType.Number:
					// do nothing (already number)
					break
				case ExcelJS.ValueType.Null:
					skip = true
					break
				case ExcelJS.ValueType.Error:
					skip = true
					break
				case ExcelJS.ValueType.Formula:
					// check if string 'HYPERLINK' exists, if so, we need to set
					// val and link will be parts of this HYPERLINK string
					const formula = cell.value.formula
					if (formula.indexOf('HYPERLINK') > -1) {
						// return link val found between first set of quotes
						// ex: HYPERLINK("THE_LINK_WE_WANT", "THE_VAL_WE_WANT")
						link = formula.match(/"(.*?)"/)[1]
						value = cell.value.result
					} else {
						// if it's not a hyperlink, we don't support it yet
						skip = true
					}
					break
				default:
					// --
					break
			}

			if (!skip) {
				if (rowValues[headerTitle]) {
					// @todo remove arrayEnabled on purchase sheet formatting update
					// @see https://github.com/relatableio/web-app/issues/448
					if (!arrayEnabled) {
						rowValues[headerTitle] = value
					} else {
						rowValues[headerTitle].push({
							val: value,
							link
						})
					}
				} else {
					// @todo remove arrayEnabled on purchase sheet formatting update
					// @see https://github.com/relatableio/web-app/issues/448
					if (!arrayEnabled) {
						rowValues[headerTitle] = value
					} else {
						rowValues[headerTitle] = [
							{
								val: value,
								link
							}
						]
					}
				}
			}
		})

		if (rowNumber !== 1) {
			rows.push(rowValues) // don't want the header row
		}
	})

	return rows
}

/**
 * Returns array of columnName:rowValue pairings for a single row in the spreadsheet.
 * If the row has a blank cell, it will search the next rows for a cell containing a value.
 * Supplies "example" values to users when mapping Classifiers to column names.
 */
export const getExampleRow = (headers, rows) => {
	let exampleRow = {}

	headers.forEach(header => {
		rows.find(row => {
			if (row[header]) {
				exampleRow[header] = row[header]
				return true
			} else {
				return false
			}
		})
	})

	return exampleRow
}
