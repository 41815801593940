import { truncate } from 'lodash'
import classNames from 'classnames'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import { Collection } from 'models/base'
import BasicLoader from 'components/loader/BasicLoader'
import { ViewContainer } from 'containers/view'
import PrintFooter from 'components/print/PrintFooter'
import { actions, withClassifierValsPromise } from 'state/modules/products'
import {
	ID as TYPE_ID,
	URL as TYPE_URL,
	NUMBER as TYPE_NUMBER,
} from 'data/data-types'
import {
	PRODUCT_COMPARE_INDEX,
	PRODUCT_COMPARE_EXCLUDE,
} from 'data/classifier-lists'
import { UNIVERSAL } from 'data/classifier-types'
import { ID_DIVISION, ID_PRODUCT_TYPE } from 'data/classifier-refs'
import { formatClassifierDefsList } from 'services/classifier'
import { LIGHT_GREY } from 'theme'
import ProductCollection from '../../models/ProductCollection'
import * as postgrest from 'services/postgrest'
import { config as API } from 'api'
import { withRouter } from '../../hocs/WithRouter'

const TITLE_PREFIX = 'RELATABLE System Comparison'

const mapStateToProps = (state) => ({
	divisions: state.taxonomies.divisions,
	productTypes: state.taxonomies.productTypes,
	classifiers: state.taxonomies.classifiers,
	classifierRefsToIds: state.taxonomies.refsToIds,
	classifierIdsToRefs: state.taxonomies.idsToRefs,
})

const mapDispatchToProps = (dispatch) => ({
	fetchProductList: (ids) => dispatch(actions.fetchList(ids)),
})

const styles = (theme) => ({
	row: {
		position: 'relative',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
	},
	cell: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minWidth: 100,
		whiteSpace: 'normal',
		padding: theme.spacing(1),
		lineHeight: 1.2,
		borderBottom: `1px solid ${LIGHT_GREY}`,
		borderRight: `1px solid ${LIGHT_GREY}`,
	},
	headerCell: {
		textAlign: 'center',
		borderTop: `1px solid ${LIGHT_GREY}`,
	},
	rowTitleCell: {
		borderLeft: `1px solid ${LIGHT_GREY}`,
	},
	value: {
		fontSize: 12,
		wordBreak: 'break-word',
		paddingTop: theme.spacing(1),
	},
	valueHighlight: {
		color: theme.palette.secondary.main,
	},
	title: {
		fontSize: 12,
		textAlign: 'center',
	},
	logo: {
		width: 50,
		margin: `${theme.spacing(0.5)} 0`,
	},
	image: {
		display: 'block',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		height: 60,
		width: 60,
		margin: `${theme.spacing(0.5)} auto`,
	},
})

class SystemComparePrintView extends Component {
	// -- state
	constructor(props) {
		super(props)
		this.state = {
			isLoading: true,
			isError: false,
			products: null,
			comparisonSystems: null,
			companySystemString: null,
			selectedProductTypeIds: this.props.params.productTypeIds.split(','),
			selectedSystems: this.props.params.selectedSystems.split(','),
			excludeClassifierDefIds: this.props.params.excludeClassifierDefIds
				? this.props.params.excludeClassifierDefIds.split(',')
				: [],
		}

		// -- render methods
		this.renderHeaders = this.renderHeaders.bind(this)
		this.renderTable = this.renderTable.bind(this)
		this.renderClassifier = this.renderClassifier.bind(this)

		this.universalDefs = formatClassifierDefsList(
			this.props.classifiers,
			this.props.classifierIdsToRefs,
			UNIVERSAL,
			null,
			PRODUCT_COMPARE_EXCLUDE,
			null,
			PRODUCT_COMPARE_INDEX,
		)
	}

	componentDidMount() {
		const { selectedSystems, selectedProductTypeIds } = this.state
		const fetchPromises = []
		const select = 'number,system,product_type_id,is_restricted'
		selectedSystems.forEach((system) => {
			fetchPromises.push(
				new Promise((resolve, reject) => {
					postgrest
						.fetchWithCustomQuery(API.PRODUCTS.PATH, {
							select: select,
							system: `eq.${system}`,
							product_type_id: `in.(${selectedProductTypeIds.join(',')})`,
						})
						.then((responseBody) => withClassifierValsPromise(responseBody))
						.then((responseBody) => resolve(responseBody))
						.catch((error) => reject(error))
				}),
			)
		})

		Promise.all(fetchPromises)
			.then((responses) => {
				const systemProductCollection = []
				responses.forEach((response) => {
					const productCollection = new ProductCollection(response)
					systemProductCollection.push(productCollection)
				})

				this.setState({
					comparisonSystems: systemProductCollection,
					isLoading: false,
				})
				// set the document title so Print/PDF exports have detailed filenames
				document.title = `${TITLE_PREFIX} - ${selectedSystems.join(',')}`
			})
			.catch((error) => {
				this.setState({ isLoading: false, isError: true })
			})
	}

	render() {
		if (this.state.isError) {
			return (
				<ViewContainer>
					<Typography variant="body2">
						There has been an error loading this page.
					</Typography>
				</ViewContainer>
			)
		} else if (this.state.isLoading) {
			return (
				<ViewContainer>
					<BasicLoader />
				</ViewContainer>
			)
		}
		const { companySystemString, selectedProductTypeIds } = this.state

		return (
			<ViewContainer>
				<div className="space-bottom">
					<Typography component="h1" variant="h5" gutterBottom>
						System Comparison Table
					</Typography>
					{companySystemString && (
						<Typography component="h2" variant="body2">
							{companySystemString}
						</Typography>
					)}
				</div>
				{selectedProductTypeIds.map((productTypeId, index) => {
					const productType = this.props.productTypes.models.find(
						(model) => model.id() === productTypeId,
					)

					return (
						<div className="space-bottom" key={index}>
							<Typography variant="subtitle1" gutterBottom>
								{`System Comparison Table for Product Type : `}
								<strong>{productType ? productType.get('name') : ''}</strong>
							</Typography>
							{this.renderHeaders()}
							{this.renderTable(productTypeId)}
						</div>
					)
				})}

				<PrintFooter disclaimerText="Please refer to the package insert for complete instructions, indications, contraindications, warnings and precautions." />
			</ViewContainer>
		)
	}

	renderHeaders() {
		const { classes } = this.props
		const { selectedSystems } = this.state

		return (
			<div className={classes.row}>
				<div className={classes.cell} />
				{selectedSystems.map((system, index) => {
					return <ColumnHeader key={index} system={system} classes={classes} />
				})}
			</div>
		)
	}

	renderTable(productTypeId) {
		const productDefs = formatClassifierDefsList(
			this.props.classifiers,
			this.props.classifierIdsToRefs,
			null,
			productTypeId,
		)
		return (
			<div>
				{this.universalDefs.map((item, index) =>
					this.renderClassifier(item, index, productTypeId),
				)}
				{productDefs.map((item, index) =>
					this.renderClassifier(item, index, productTypeId),
				)}
			</div>
		)
	}

	renderClassifier(classifierDef, index, productTypeId) {
		const defId = classifierDef.id()
		const name = classifierDef.get('name')
		const dataType = classifierDef.get('data_type')

		const { classes, classifierRefsToIds } = this.props
		const { selectedSystems, excludeClassifierDefIds, comparisonSystems } =
			this.state
		const isExcludedClassifier = !!(excludeClassifierDefIds.indexOf(defId) > -1)

		if (
			dataType === TYPE_URL ||
			name === 'Description' ||
			isExcludedClassifier
		) {
			return null
		}

		return (
			<div className={classes.row} key={index}>
				<div className={classNames([classes.cell, classes.rowTitleCell])}>
					<span className={classes.value}>
						{name === 'Product Number' ? 'Products Count' : name}
					</span>
				</div>
				{selectedSystems.map((system, index) => {
					let values
					let valueClasses = [classes.value]

					const systemCollection = comparisonSystems[index].filter(
						(item) => item.get('product_type_id') === productTypeId,
					)

					if (dataType === TYPE_ID) {
						const product = systemCollection.getAt(0)
						if (!product) values = '-'
						else if (defId === classifierRefsToIds[ID_DIVISION]) {
							values = product.getFormattedVals(
								defId,
								dataType,
								this.props.divisions,
							)
						} else if (defId === classifierRefsToIds[ID_PRODUCT_TYPE]) {
							values = product.getFormattedVals(
								defId,
								dataType,
								this.props.productTypes,
							)
						}
						values = values ? Array.from(new Set(values)).join(', ') : '-'
					} else {
						values = systemCollection.getClassifierValueList(defId, dataType)
						if (name === 'Product Number') {
							values =
								values.length + (values.length > 1 ? ' products' : ' product')
						} else if (dataType === TYPE_NUMBER) {
							values = Array.from(new Set(values))
							let nullIndex = values.indexOf('null')
							if (nullIndex >= 0) values.splice(nullIndex, 1)
							nullIndex = values.indexOf(null)
							if (nullIndex >= 0) values.splice(nullIndex, 1)

							if (values.length) {
								if (values.length === 1) {
									values = values[0]
								} else {
									values = values.sort((a, b) => {
										return parseFloat(a) - parseFloat(b)
									})
									values = `${values[0]} ~ ${values[values.length - 1]}`
								}
							} else {
								values = '-'
							}
						} else {
							values = Array.from(new Set(values))
							let nullIndex = values.indexOf('null')
							if (nullIndex >= 0) values.splice(nullIndex, 1)
							nullIndex = values.indexOf(null)
							if (nullIndex >= 0) values.splice(nullIndex, 1)
							values = values ? values.join(', ') : '-'
						}
					}

					return (
						<div className={classes.cell} key={index}>
							<span className={classNames(valueClasses)}>
								{truncate(values, { length: 100, separator: ' ' })}
							</span>
						</div>
					)
				})}
			</div>
		)
	}
}

SystemComparePrintView.propTypes = {
	// -- style classes
	classes: PropTypes.object.isRequired,
	// -- state props
	divisions: PropTypes.instanceOf(Collection).isRequired,
	productTypes: PropTypes.instanceOf(Collection).isRequired,
	classifiers: PropTypes.instanceOf(Collection).isRequired,
	classifierRefsToIds: PropTypes.object.isRequired,
	classifierIdsToRefs: PropTypes.object.isRequired,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles)(withRouter(SystemComparePrintView)))

// ----------

class ColumnHeader extends React.Component {
	// -- state

	render() {
		const { system, classes, isHeader } = this.props
		const cellClasses = [classes.cell, classes.headerCell]
		if (isHeader) cellClasses.push(classes.rowTitleCell)
		return (
			<div className={classNames(cellClasses)}>
				<div />
				<p className={classes.title}>
					{truncate(system.slice(1, system.length - 1), {
						length: 50,
						separator: ' ',
					})}
				</p>
			</div>
		)
	}
}

ColumnHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	system: PropTypes.string.isRequired,
}
