import { SUPER_ADMIN, ADMIN, SUPPLY_CHAIN } from 'data/roles'

export function checkSuperAdmin(userRoleGroup, roleGroups) {
	return roleGroups && roleGroups.length && userRoleGroup
		? roleGroups.find(rg => rg.id === userRoleGroup).system_name === SUPER_ADMIN
		: false
}

export function checkUnrestrictedUser(user) {
	let currentRole
	if (user && user.roleGroups && user.roleGroup) {
		currentRole = user.roleGroups.find(role => role.id === user.roleGroup)
	} else {
		return true
	}
	return (
		currentRole &&
		(currentRole.system_name === SUPER_ADMIN ||
			currentRole.system_name === ADMIN ||
			currentRole.system_name === SUPPLY_CHAIN)
	)
}
