import { cloneDeep } from 'lodash'
import { UserCollection, UserModel } from 'models'
import { config as API } from 'api'
import * as postgrest from 'services/postgrest'
import { getContentRangeTotal } from 'utils/headers'
import { trackEvent } from 'services/analytics'
import { getRoleGroup } from 'services/role'

const ENDPOINT = API.USERS.PATH
const INVITE_ENDPOINT = API.INVITES.PATH
const USER_ROLES_ENDPOINT = API.USER_ROLES.PATH
const COLLECTION_NAME = 'ACCOUNT_USERS'
const MODEL_NAME = 'ACCOUNT_USER'
const MODULE_NAME = 'accountUsers' // this module's name in the rootReducer
const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 20

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	pendingFields: {},
	users: [],
	invites: [],
	currentModel: new UserModel(),
	currentUserRoles: [],
	currentUserRoleGroup: null,
	currentUserClassifierRestrictors: [],
	currentUserProductRestrictors: [],
	results: new UserCollection(),
	totalResults: 0,
	perPage: DEFAULT_PER_PAGE,
	page: DEFAULT_PAGE,
	searchQuery: '',
	searchType: 'name'
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const FETCHED = `${MODEL_NAME}_FETCHED`
const FETCHED_PAGE = `${COLLECTION_NAME}_PAGE_FETCHED`
const FETCHED_USERS = `${COLLECTION_NAME}_USERS_FETCHED`
const FETCHED_USER_DATA = `${COLLECTION_NAME}_USER_DATA_FETCHED`
const FETCHED_INVITES = `${COLLECTION_NAME}_INVITES_FETCHED`
const QUERY_UPDATED = `${COLLECTION_NAME}_QUERY_UPDATED`
const EDIT_SAVED = `${MODEL_NAME}_EDIT_SAVED`
const EDIT_CLEARED = `${MODEL_NAME}_EDIT_CLEARED`
const REPLACED = `${MODEL_NAME}_REPLACED`
const CREATED = `${MODEL_NAME}_CREATED`
const SAVED = `${MODEL_NAME}_SAVED`
const DESTROYED = `${MODEL_NAME}_DESTROYED`
const FAILED = `${COLLECTION_NAME}_FAILED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
const receive = (action, fields) => ({ type: action, payload: fields })
const receivePage = (results, page, totalResults) => ({
	type: FETCHED_PAGE,
	payload: { results, page, totalResults }
})
const saveEdit = fields => ({ type: EDIT_SAVED, payload: fields })
const clearEdit = fields => ({ type: EDIT_CLEARED })
const fail = error => ({ type: FAILED, payload: error })

/**
 * Fetch single resource
 * thunk
 */
const fetch = (id, force = false) => {
	return (dispatch, getState) => {
		// if this model already exists in the results array, no need to fetch again
		// just set it as the currentModel
		if (!force) {
			const { results } = getState()[MODULE_NAME]
			const existingModel =
				results && results.size() ? results.findById(id) : null

			if (existingModel) {
				return new Promise(resolve => {
					dispatch(receive(REPLACED, existingModel))
					resolve()
				})
			}
		}

		dispatch(request())

		return postgrest
			.fetch(ENDPOINT, id)
			.then(response => {
				dispatch(receive(FETCHED, response))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Fetch resources using limit-offset pagination
 * thunk
 *
 * @see https://postgrest.com/en/v4.3/api.html#limits-and-pagination
 */
const fetchPage = newPage => {
	return (dispatch, getState) => {
		dispatch(request())

		const { page, perPage, searchQuery, searchType } = getState()[MODULE_NAME]
		const _page = newPage !== undefined ? newPage : page
		let query

		if (searchQuery) {
			switch (searchType) {
				case 'name':
					query = {
						or: `(first_name.fts.${searchQuery}, last_name.fts.${searchQuery})`
					}
					break
				case 'email':
					query = { email: `fts.${searchQuery}` }
					break
				default:
					query = null
			}
		}

		return postgrest
			.fetchPage(ENDPOINT, _page, perPage, query)
			.then(response => {
				const totalResults = getContentRangeTotal(response.headers)
				dispatch(receivePage(response.body, _page, totalResults))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const fetchAll = () => {
	return (dispatch, getState) => {
		dispatch(request())

		const { searchQuery, searchType } = getState()[MODULE_NAME]
		let query

		if (searchQuery) {
			switch (searchType) {
				case 'name':
					query = {
						or: `(first_name.fts.${searchQuery}, last_name.fts.${searchQuery})`
					}
					break
				case 'email':
					query = { email: `fts.${searchQuery}` }
					break
				default:
					query = null
			}
		}

		return postgrest
			.fetchWithCustomQuery(ENDPOINT, query)
			.then(response => {
				dispatch(receive(FETCHED_USERS, { users: response }))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const fetchInvites = accountName => {
	return (dispatch, getState) => {
		dispatch(request())

		let query = 'invitee_account_name=eq.' + accountName + '&claimed=eq.false'

		return postgrest
			.fetchWithCustomQuery(INVITE_ENDPOINT, query)
			.then(response => {
				dispatch(receive(FETCHED_INVITES, { invites: response }))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const fetchUserRoles = userId => {
	return (dispatch, getState) => {
		let _userId = userId || getState()[MODULE_NAME].currentModel.id()

		dispatch(request())

		return postgrest
			.fetch(USER_ROLES_ENDPOINT, _userId, 'user_id')
			.then(response => {
				// roles come back as an object in
				// response: { user_id: <USER_ID>, roles: { role: {...roleInfo }} }
				const roles = response.roles ? response.roles : {}
				const rolesArray = Object.keys(roles)

				dispatch(
					receive(FETCHED_USER_DATA, {
						currentUserRoles: rolesArray,
						currentUserRoleGroup: getRoleGroup(rolesArray)
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const updateSearch = (searchQuery, searchType) => {
	return (dispatch, getState) => {
		dispatch(receive(QUERY_UPDATED, { searchQuery, searchType }))
		dispatch(fetchAll())
	}
}

/**
 * Create single resource
 * thunk
 */
const create = fields => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.create(INVITE_ENDPOINT, fields)
			.then(response => {
				// -- analytics
				trackEvent('Created User Invite', {
					...fields
				})
				// --

				dispatch(receive(CREATED, response))
			})
			.catch(error => {
				// -- analytics
				trackEvent('Failed User Invite', {
					message: error.message,
					...fields
				})
				// --

				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Save single resource
 * thunk
 */
const save = fields => {
	return (dispatch, getState) => {
		dispatch(request())

		const id = getState()[MODULE_NAME].currentModel.id()

		return postgrest
			.save(ENDPOINT, id, fields)
			.then(response => {
				dispatch(receive(SAVED, response))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Destroy single resource
 * thunk
 */
const destroy = id => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.destroy(ENDPOINT, id)
			.then(() => {
				dispatch(receive(DESTROYED, id))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[EDIT_SAVED]: (state, { payload }) => {
		const fields = Object.assign(state.pendingFields, payload)

		return {
			...state,
			pendingFields: cloneDeep(fields)
		}
	},
	[EDIT_CLEARED]: (state, action) => ({
		...state,
		pendingFields: {}
	}),
	[QUERY_UPDATED]: (state, { payload }) => ({
		...state,
		...payload // searchQuery and searchType
	}),
	[FETCHED_PAGE]: (state, { payload }) => ({
		...state,
		results: new UserCollection(payload.results),
		page: payload.page,
		totalResults: payload.totalResults,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		currentModel: new UserModel(payload),
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED_USER_DATA]: (state, { payload }) => ({
		...state,
		...payload,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED_USERS]: (state, { payload }) => ({
		...state,
		...payload,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED_INVITES]: (state, { payload }) => ({
		...state,
		...payload,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[REPLACED]: (state, { payload }) => ({
		...state,
		currentModel: payload
	}),
	[CREATED]: (state, { payload }) => ({
		...state,
		currentModel: new UserModel(payload),
		pendingFields: {},
		fetchError: null,
		isFetching: false
	}),
	[SAVED]: (state, { payload }) => ({
		...state,
		currentModel: new UserModel(payload),
		fetchError: null,
		isFetching: false
	}),
	[DESTROYED]: (state, action) => ({
		...state,
		currentModel: new UserModel(),
		fetchError: null,
		isFetching: false
	}),
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetch,
	fetchAll,
	fetchPage,
	fetchInvites,
	fetchUserRoles,
	updateSearch,
	saveEdit,
	clearEdit,
	create,
	destroy,
	save,
	reset
}
