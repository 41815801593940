/**
 * Cache Service
 *
 * @todo
 * Handles localstorage data
 *
 * @see https://github.com/pamelafox/lscache
 */

import lscache from 'lscache'

/**
 * clears all data stored in cache
 */
export const clear = () => {
	lscache.flush()
}

/**
 * @param {string} key - cache key
 * @param {*} data - data to be stored
 */
export const store = (key, data) => {
	lscache.set(key, data)
}

/**
 * @param {string} key - key of cached data to return
 * @return {*} cached data
 */
export const get = key => {
	return lscache.get(key) || null
}
