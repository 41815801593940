export const CREATE = 'create'
export const READ = 'read'
export const UPDATE = 'update'
export const DELETE = 'delete'
export const PATH = 'PATH'
export const RPC = 'RPC'
export const AUTH = 'AUTH'

export const config = {
	// --
	// -- ADMIN
	// --
	INVITES: {
		[PATH]: '/invites',
		[RPC]: false,
		[AUTH]: true,
	},
	RESEND_INVITE: {
		[PATH]: '/resend_invite',
		[RPC]: false,
		[AUTH]: true,
	},
	ROLES: {
		[PATH]: '/rpc/all_roles',
		[RPC]: true,
		[AUTH]: true,
	},
	ROLE_HIERARCHIES: {
		[PATH]: '/role_hierarchies',
		[RPC]: false,
		[AUTH]: false,
	},
	ROLE_GROUPS: {
		[PATH]: '/app_roles',
		[RPC]: false,
		[AUTH]: true,
	},
	ROLE_PERMISSIONS: {
		[PATH]: '/role_permissions',
		[RPC]: false,
		[AUTH]: true,
	},
	ROLE_COMPONENTS: {
		[PATH]: '/components',
		[RPC]: false,
		[AUTH]: true,
	},
	ROLE_VALS: {
		[PATH]: '/all_user_role_vals',
		[RPC]: false,
		[AUTH]: true,
	},
	USER_ROLE_SET: {
		[PATH]: '/rpc/set_role',
		[RPC]: true,
		[AUTH]: true,
	},
	// --
	// -- USERS
	// --
	LOGIN: {
		[PATH]: '/rpc/login',
		[RPC]: true,
		[AUTH]: false,
	},
	GET_INVITE: {
		[PATH]: '/rpc/get_invite',
		[RPC]: true,
		[AUTH]: false,
	},
	ACCEPT_INVITE: {
		[PATH]: '/rpc/accept_invite',
		[RPC]: true,
		[AUTH]: false,
	},
	VERIFY_EMAIL: {
		[PATH]: '/rpc/verify_email',
		[RPC]: true,
		[AUTH]: true,
	},
	USERS: {
		[PATH]: '/users',
		[RPC]: false,
		[AUTH]: true,
	},
	USER_CREATE: {
		[PATH]: '/rpc/create_users',
		[RPC]: true,
		[AUTH]: true,
	},
	USERS_ENRICHED: {
		[PATH]: '/users_full_profile',
		[RPC]: false,
		[AUTH]: true,
	},
	USER_ROLES: {
		[PATH]: '/user_roles',
		[RPC]: false,
		[AUTH]: true,
	},
	USER_ROLE_GROUP: {
		[PATH]: '/user_app_roles',
		[RPC]: false,
		[AUTH]: true,
	},

	// --
	// -- Restrictors
	// --
	CLASSIFIER_RESTRICTORS: {
		[PATH]: '/global_classifier_restrictors',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_RESTRICTORS: {
		[PATH]: '/global_product_restrictors',
		[RPC]: false,
		[AUTH]: true,
	},

	PRIVILEGES: {
		[PATH]: '/rpc/privileges',
		[RPC]: true,
		[AUTH]: true,
	},
	// --
	// -- PASSWORD RESET
	// --
	PASSWORD_RESET_REQUEST: {
		[PATH]: '/password_reset_requests',
		[RPC]: true,
		[AUTH]: false,
	},
	RESET_PASSWORD_USING_TOKEN: {
		[PATH]: '/rpc/reset_password_using_token',
		[RPC]: true,
		[AUTH]: false,
	},
	RESET_PASSWORD: {
		[PATH]: '/rpc/reset_password',
		[RPC]: true,
		[AUTH]: true,
	},
	// --
	// -- ACCOUNT
	// --
	ACCOUNTS: {
		[PATH]: '/accounts',
		[RPC]: false,
		[AUTH]: true,
	},
	ACCOUNT_TYPES: {
		[PATH]: '/account_types',
		[RPC]: false,
		[AUTH]: true,
	},
	ACCOUNT_TYPES_ROLES: {
		[PATH]: '/account_types_app_roles',
		[RPC]: false,
		[AUTH]: true,
	},
	// --
	// -- TAXONOMY
	// --
	TAXONOMY_AGGREGATE: {
		[PATH]: '/taxonomy_classifier_aggregate',
		[RPC]: false,
		[AUTH]: true,
	},
	CONSTRUCTS: {
		[PATH]: '/product_type_constructs',
		[RPC]: false,
		[AUTH]: true,
	},
	DIVISIONS: {
		[PATH]: '/divisions',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_TYPES: {
		[PATH]: '/product_types',
		[RPC]: false,
		[AUTH]: true,
	},
	CLASSIFIER_DEFS: {
		[PATH]: '/classifier_defs',
		[RPC]: false,
		[AUTH]: true,
	},
	CLASSIFIER_DEF_IDS: {
		[PATH]: '/classifier_def_ids',
		[RPC]: false,
		[AUTH]: true,
	},
	// --
	// -- PRODUCTS
	// --
	PRODUCTS: {
		[PATH]: '/products',
		[RPC]: false,
		[AUTH]: true,
	},
	FILTER_PRODUCTS: {
		[PATH]: '/rpc/filter_products',
		[RPC]: true,
		[AUTH]: true,
	},
	PRODUCT_FILTER_OPTIONS: {
		[PATH]: '/rpc/product_filter_options',
		[RPC]: true,
		[AUTH]: true,
	},
	PRODUCT_SHEETS: {
		[PATH]: '/product_sheets',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_UPLOAD_FAILURES: {
		[PATH]: '/product_upload_failures',
		[RPC]: true,
		[AUTH]: true,
	},
	PRODUCT_CLASSIFIER_VALS: {
		[PATH]: '/product_classifier_vals',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_BULK_CLASSIFIERS_UPDATE: {
		[PATH]: '/rpc/bulk_update_classifiers',
		[RPC]: true,
		[AUTH]: true,
	},
	PRODUCT_CLASSIFIER_VALS_WRITE: {
		[PATH]: '/product_classifier_vals_write',
		[RPC]: false,
		[AUTH]: true,
	},
	NEW_PRODUCT: {
		[PATH]: '/rpc/new_product',
		[RPC]: true,
		[AUTH]: true,
	},
	PRODUCT_RELATORS: {
		[PATH]: '/product_relators',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_RELATOR_GROUPS: {
		[PATH]: '/relator_groups',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_RELATOR_FOLDERS: {
		[PATH]: '/product_relator_folders',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_RELATOR_CRITERIA: {
		[PATH]: '/product_relator_criteria',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_RELATORS_HISTORY: {
		[PATH]: '/relators_criteria_history',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_RELATOR_APPLY_VERSION: {
		[PATH]: '/rpc/relators_apply_relator_version',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_RELATOR_STATS: {
		[PATH]: '/relators_stats',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_RELATOR_REFRESH_CACHE: {
		[PATH]: '/rpc/refresh_related_cache',
		[RPC]: false,
		[AUTH]: true,
	},
	RELATED_PRODUCTS: {
		[PATH]: '/rpc/related_products',
		[RPC]: true,
		[AUTH]: true,
	},
	RELATED_PRODUCTS_BULK: {
		[PATH]: '/rpc/related_products_bulk',
		[RPC]: true,
		[AUTH]: true,
	},
	PRODUCT_MANUAL_RELATED_PRODUCTS: {
		[PATH]: '/product_manual_related_products',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_TRANSACTIONS: {
		[PATH]: '/transactional_data',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_CLASSIFIER_VALS_FOR_NUMBER: {
		[PATH]: '/rpc/product_classifier_vals_for',
		[RPC]: true,
		[AUTH]: true,
	},
	PRODUCT_COMPARISON_TABLE_HASH_FOR: {
		[PATH]: '/rpc/product_comparison_table_hash_for',
		[RPC]: true,
		[AUTH]: true,
	},
	PRODUCT_RELATED_PRODUCTS_STATS: {
		[PATH]: '/rpc/get_related_products_stats',
		[RPC]: true,
		[AUTH]: true,
	},
	PRODUCT_COMPARISON_TABLE_DATA: {
		[PATH]: '/rpc/product_comparison_table_data',
		[RPC]: true,
		[AUTH]: false,
	},
	PRODUCT_REPORTED_PRODUCTS: {
		[PATH]: '/reported_products',
		[RPC]: false,
		[AUTH]: false,
	},
	PRODUCT_REPORTED_PRODUCT_LINKS: {
		[PATH]: '/reported_product_links',
		[RPC]: false,
		[AUTH]: false,
	},
	PRODUCT_PRICE_TIERS: {
		[PATH]: '/product_price_tiers',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_TIERED_PRICES: {
		[PATH]: '/product_tiered_prices',
		[RPC]: false,
		[AUTH]: true,
	},
	PRODUCT_PRICING_SHEETS: {
		[PATH]: '/pricing_sheets',
		[RPC]: false,
		[AUTH]: true,
	},

	// --

	IDENTIFY_SHEETS: {
		[PATH]: '/identify_sheets',
		[RPC]: false,
		[AUTH]: true,
	},

	PRODUCT_SHEET_UPLOADED_RECORDS: {
		[PATH]: '/product_sheet_uploaded_records',
		[RPC]: false,
		[AUTH]: true,
	},

	PRODUCT_SHEET_PDC_STATS: {
		[PATH]: '/product_sheet_pdc_stats',
		[RPC]: false,
		[AUTH]: true,
	},
	// ALTA
	// --
	ALTA_REPORTS: {
		[PATH]: '/alta_report',
		[RPC]: false,
		[AUTH]: true,
	},
	ALTA_FOLDERS: {
		[PATH]: '/alta_folders',
		[RPC]: false,
		[AUTH]: true,
	},
	ALTA_ROLE_REPORTS: {
		[PATH]: '/rpc/get_role_alta_reports',
		[RPC]: true,
		[AUTH]: true,
	},
	ALTA_BULK_DELETE: {
		[PATH]: '/rpc/bulk_delete_alta_reports',
		[RPC]: false,
		[AUTH]: true,
	},

	ALTA_EXPORT: {
		[PATH]: '/rpc/get_alta_export',
		[RPC]: true,
		[AUTH]: true,
	},

	ALTA_AUTO_SEGMENT: {
		[PATH]: '/rpc/alta_auto_segment',
		[RPC]: true,
		[AUTH]: true,
	},

	ALTA_CLEAR_CACHE: {
		[PATH]: '/rpc/remove_alta_cache',
		[RPC]: true,
		[AUTH]: true,
	},

	// --
	// RFPI
	// --
	RFPIS: {
		[PATH]: '/rfpis',
		[RPC]: false,
		[AUTH]: true,
	},
	RFPI_RESPONSES: {
		[PATH]: '/rfpi_responses',
		[RPC]: false,
		[AUTH]: true,
	},
	RFPI_RECIPIENTS: {
		[PATH]: '/rfpi_recipients',
		[RPC]: false,
		[AUTH]: true,
	},
	RFPI_PRODUCT_TYPES: {
		[PATH]: '/rfpi_product_types',
		[RPC]: false,
		[AUTH]: true,
	},
	RFPI_RECIPIENT_MESSAGES: {
		[PATH]: '/rfpi_recipient_messages',
		[RPC]: false,
		[AUTH]: true,
	},
	// --
	// -- PURCHASES
	// --
	PURCHASE_SHEETS: {
		[PATH]: '/purchase_sheets',
		[RPC]: false,
		[AUTH]: true,
	},
	PURCHASE_UPLOAD_FAILURES: {
		[PATH]: '/purchase_upload_failures',
		[RPC]: true,
		[AUTH]: true,
	},
	BENCHMARK_METRICS: {
		[PATH]: '/benchmark_data_metrics',
		[RPC]: false,
		[AUTH]: true,
	},
	// --
	// -- GUIDED PURCHASES
	// -- @todo
	GUIDED_PURCHASES_FILTER_GPSS: {
		[PATH]: '/rpc/filter_gpss',
		[RPC]: true,
		[AUTH]: true,
	},
	GUIDED_PURCHASES: {
		[PATH]: '/impact-analyses', // @todo update
		[RPC]: false,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_SEGMENTERS: {
		[PATH]: '/impact-analysis-segmenters', // @todo update
		[RPC]: false,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_GEN_SEGMENTS: {
		[PATH]: '/gen-segmented-reports',
		[RPC]: false,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_RESULTS: {
		[PATH]: '/impact-analyses', // @todo update
		[RPC]: false,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_COMPANY_RESULTS: {
		[PATH]: '/impact-analyses', // @todo update
		[RPC]: false,
		[AUTH]: false,
	},
	GUIDED_PURCHASES_USAGE_PHYSICIANS_DATA: {
		[PATH]: '/gps_usage_physicians_data', // @todo update
		[RPC]: true,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_NEW: {
		[PATH]: '/rpc/new_gps', // @todo update
		[RPC]: true,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_ALL: {
		[PATH]: '/gpss', // @todo update
		[RPC]: false,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_IR_FILTER: {
		[PATH]: '/ir_filters', // @Istant Recommenation Filters
		[RPC]: true,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_GET_COLLABORATORS: {
		[PATH]: '/rpc/gps_collaborators', // @Get GPS Collaborators
		[RPC]: false,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_SAVE_COLLABORATORS: {
		[PATH]: '/rpc/gps_replace_collaborators ', // @Save GPS Collaborators
		[RPC]: true,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_POTENTIAL_COLLABORATORS: {
		[PATH]: '/rpc/gps_potential_collaborators ', // @GET Potential GPS Collaborators
		[RPC]: true,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_ANALYTICS_SUMMARY: {
		[PATH]: '/rpc/gps_analytics ', // @GET Analytics and Summary
		[RPC]: true,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_USAGE_FILTER_ENDPOINT: {
		[PATH]: '/gps_usage_filters', // @todo update
		[RPC]: false,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_REC_FILTER_ENDPOINT: {
		[PATH]: '/gps_rec_filters', // @todo update
		[RPC]: false,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_STEP_VIEW_FILTERS: {
		[PATH]: '/gps_select_step_view_filters', // @todo update
		[RPC]: false,
		[AUTH]: false,
	},
	GUIDED_PURCHASE_SET_REC_SELECTION: {
		[PATH]: '/rpc/gps_set_rec_selections',
		[RPC]: true,
		[AUTH]: true,
	},
	GUIDED_PURCHASE_SELECTIONS: {
		[PATH]: '/gps_selections',
		[RPC]: true,
		[AUTH]: true,
	},
}
