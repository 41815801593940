/**
 * This component will check the user's privileges against
 * the 'permissions/config' and redirect if user does not
 * have the required privileges to access given route.
 */

import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Navigate } from 'react-router-dom'
import { testPermission } from 'permissions'
import ROUTES from 'routes'
import { withRouter } from '../hocs/WithRouter'

const mapStateToProps = (state) => ({
	privileges: state.session.privileges,
	location: state.router.location,
})

class AuthenticatedRoute extends React.Component {
	static propTypes = {
		path: PropTypes.string,
		privileges: PropTypes.array.isRequired,
		componentProps: PropTypes.object,
		location: PropTypes.object,
		navigate: PropTypes.func,
		params: PropTypes.object,
	}

	render() {
		const { children, location, navigate, params } = this.props
		let hasAccess = true

		if (this.props.path) {
			hasAccess = testPermission(this.props.privileges, this.props.path)
		}

		if (!hasAccess) {
			return <Navigate to={ROUTES.ROOT} />
		}
		return React.Children.map(children, (child) => {
			return cloneElement(child, {
				location: location,
				navigate: navigate,
				params: params,
			})
		})
	}
}

export default connect(mapStateToProps)(withRouter(AuthenticatedRoute))
