/**
 * @see https://stackoverflow.com/questions/4565112/javascript-how-to-find-out-if-the-user-browser-is-chrome/13348618#13348618
 */

// please note,
// that IE11 now returns undefined again for window.chrome
// and new Opera 30 outputs true for window.chrome
// but needs to check if window.opr is not undefined
// and new IE Edge outputs to true now for window.chrome
// and if not iOS Chrome check
// so use the below updated condition
var isChromium = window.chrome
var winNav = window.navigator
var vendorName = winNav.vendor
var isOpera = typeof window.opr !== 'undefined'
var isIEedge = winNav.userAgent.indexOf('Edge') > -1
var isIOSChrome = winNav.userAgent.match('CriOS')

export function isChrome() {
	if (isIOSChrome) {
		// is Google Chrome on IOS
		return true
	} else if (
		isChromium !== null &&
		typeof isChromium !== 'undefined' &&
		vendorName === 'Google Inc.' &&
		isOpera === false &&
		isIEedge === false
	) {
		// is Google Chrome
		return true
	} else {
		// not Google Chrome
		return false
	}
}
