import { Model } from 'models/base'
import { RFPIModel } from 'models'
import { config as API } from 'api'
import { actions as alertActions } from './alerts'
import {
	SUCCESS as ALERT_SUCCESS,
	ERROR as ALERT_ERROR
} from 'data/alert-types'
import { trackEvent } from 'services/analytics'
import * as postgrest from 'services/postgrest'

const RFPI_RECIPIENT_MESSAGES_ENDPOINT = API.RFPI_RECIPIENT_MESSAGES.PATH
const MODEL_NAME = 'RFPIS'
const MODULE_NAME = 'rfpis'

const initialState = {
	isFetching: false,
	hasFetched: false,
	fetchError: null,
	currentModel: new Model()
}

export const RESET = `${MODEL_NAME}_RESET`
export const REQUESTED = `${MODEL_NAME}_REQUESTED`
export const FETCHED = `${MODEL_NAME}_FETCHED`
export const SAVED = `${MODEL_NAME}_SAVED`
export const FAILED = `${MODEL_NAME}_FAILED`
export const MESSAGE_SENT = `${MODEL_NAME}_MESSAGE_SENT`

export const reset = () => ({ type: RESET })
export const request = () => ({ type: REQUESTED })
// receive can take FETCHED
export const receive = (action, fields) => ({ type: action, payload: fields })
export const fail = error => ({ type: FAILED, payload: error })

const sendMessage = payload => {
	return (dispatch, getState) => {
		dispatch(request())

		const currentModel = getState()[MODULE_NAME].currentModel

		return postgrest
			.create(RFPI_RECIPIENT_MESSAGES_ENDPOINT, payload)
			.then(response => {
				dispatch(receive(MESSAGE_SENT, currentModel))

				dispatch(
					alertActions.showAlert({
						message: 'Message successfully sent',
						type: ALERT_SUCCESS,
						timeout: 6000
					})
				)
			})
			.catch(error => {
				// -- analytics
				trackEvent('Failed RFPI Message Delivery', {
					error_message: error.message,
					...currentModel.fields
				})
				// --

				dispatch(
					alertActions.showAlert({
						message: 'Error sending message to recipient',
						type: ALERT_ERROR,
						timeout: 6000
					})
				)
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		currentModel: new Model(payload),
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[SAVED]: (state, { payload }) => ({
		...state,
		currentModel: new Model(payload),
		fetchError: null,
		isFetching: false
	}),
	[FAILED]: (state, action) => ({
		...state,
		fetchError: action.payload,
		isFetching: false
	}),
	[MESSAGE_SENT]: (state, { payload }) => ({
		...state,
		currentModel: new RFPIModel({ ...payload }),
		pendingFields: {},
		fetchError: null,
		isFetching: false
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	reset,
	sendMessage
}
