import { isEmpty } from 'lodash'
import { UserModel } from 'models'
import { config as API } from 'api'
import { actions as roleActions } from 'state/modules/roles'
import { actions as alertActions } from './alerts'
import * as postgrest from 'services/postgrest'
import {
	SUCCESS as ALERT_SUCCESS,
	ERROR as ALERT_ERROR
} from 'data/alert-types'
import { get as getCacheInfoByKey } from 'services/cache'

const ENDPOINT = API.USERS.PATH
const CREATE_ENDPOINT = API.USER_CREATE.PATH
const MODEL_NAME = 'USER'
export const MODULE_NAME = 'user'

const initialState = {
	isFetching: false,
	hasFetched: false,
	fetchError: null,
	currentModel: new UserModel(),
	roles: [],
	roleGroup: '',
	roleGroups: [],
	roleData: null,
	roleSystemName: ''
}

const RESET = `${MODEL_NAME}_RESET`
const REQUESTED = `${MODEL_NAME}_REQUESTED`
const FETCHED = `${MODEL_NAME}_FETCHED`
const SAVED = `${MODEL_NAME}_SAVED`
const FAILED = `${MODEL_NAME}_FAILED`
const DESTROYED = `${MODEL_NAME}_FAILED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
// receive can take FETCHED
const receive = (action, fields) => ({ type: action, payload: fields })
const fail = error => ({ type: FAILED, payload: error })
const SET_USER_ROLE_ENDPOINT = API.USER_ROLE_SET.PATH
/**
 * This will fetch the User and the User's Roles
 * @param {String} email
 */
const fetchByEmail = email => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.fetch(ENDPOINT, email, 'email')
			.then(user => {
				if (user.id) {
					return roleActions
						.fetchRoles(user.id)
						.then(
							({
								roles,
								roleGroup,
								roleGroups,
								roleData,
								roleFreemium,
								roleSystemName
							}) => {
								dispatch(
									receive(FETCHED, {
										currentModel: new UserModel(user),
										roles,
										roleGroup,
										roleGroups,
										roleData,
										roleFreemium,
										roleSystemName
									})
								)

								if (roleGroup && window.env.AUTH0_METHOD === 'AUTH0')
									postgrest.fetchAllWithPost(
										SET_USER_ROLE_ENDPOINT,
										{},
										{ in_role: user.id }
									)
							}
						)
				} else {
					// create user if it does not exist
					const tmpFields = {
						p_email: getCacheInfoByKey('session').email,
						p_first_name: getCacheInfoByKey('session').first_name,
						p_last_name: getCacheInfoByKey('session').last_name,
						p_password:
							getCacheInfoByKey('session').first_name +
							'+' +
							getCacheInfoByKey('session').last_name
					}
					dispatch(create(tmpFields))
				}
				return true
			})
			.catch(error => {
				throw error
			})
	}
}

/**
 * @param {String} email
 */
const fetchById = id => {
	return (dispatch, getState) => {
		dispatch(request())

		let _id = id

		if (!_id) {
			_id = getState()[MODULE_NAME].currentModel.get('id')
		}

		return postgrest
			.fetch(ENDPOINT, _id)
			.then(response => {
				dispatch(receive(FETCHED, { currentModel: new UserModel(response) }))
				return response
			})
			.catch(error => {
				throw error
			})
	}
}

const create = fields => {
	if (isEmpty(fields)) {
		return
	}

	return dispatch => {
		dispatch(request())

		return postgrest
			.create(CREATE_ENDPOINT, fields)
			.then(response => {
				dispatch(
					receive(FETCHED, {
						...initialState,
						currentModel: new UserModel(response)
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const save = fields => {
	if (isEmpty(fields)) {
		return
	}

	return (dispatch, getState) => {
		dispatch(request())

		const id = getState()[MODULE_NAME].currentModel.id()

		return postgrest
			.save(ENDPOINT, id, fields)
			.then(response => {
				dispatch(receive(SAVED, response))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const destroy = id => {
	return (dispatch, getState) => {
		dispatch(request())

		const fields = { deleted: true }

		return postgrest
			.save(ENDPOINT, id, fields)
			.then(response => {
				dispatch(receive(DESTROYED, response))
				dispatch(
					alertActions.showAlert({
						message: `User successfully deleted`,
						type: ALERT_SUCCESS
					})
				)
			})
			.catch(error => {
				dispatch(
					alertActions.showAlert({
						message: 'Error removing value.',
						type: ALERT_ERROR
					})
				)
				dispatch(fail(error))
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		...payload,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[SAVED]: (state, { payload }) => ({
		...state,
		currentModel: new UserModel(payload),
		fetchError: null,
		isFetching: false
	}),
	[DESTROYED]: (state, { payload }) => ({
		...state,
		currentModel: new UserModel(),
		fetchError: null,
		isFetching: false
	}),
	[FAILED]: (state, action) => ({
		...state,
		fetchError: action.payload,
		isFetching: false
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetchByEmail,
	fetchById,
	destroy,
	save,
	reset
}
