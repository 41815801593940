import {
	compose,
	legacy_createStore as createStore,
	applyMiddleware,
} from 'redux'
import thunk from 'redux-thunk'
import { createBrowserHistory } from 'history'
import { createReduxHistoryContext } from 'redux-first-history'
import createRootReducer from './rootReducer'

let initialState = {}
const { createReduxHistory, routerMiddleware, routerReducer } =
	createReduxHistoryContext({ history: createBrowserHistory() })
const composeEnhancers =
	typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
	window.env.NODE_ENV === 'development'
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
			})
		: compose

const enhancer = composeEnhancers(applyMiddleware(thunk, routerMiddleware))
const store = createStore(
	createRootReducer(routerReducer),
	initialState,
	enhancer,
)
const history = createReduxHistory(store)

export { store, history }
