import { map as roleGroupsMap } from 'data/role-groups'
import { isEqual } from 'lodash'

export const getRoleGroup = rolesArray => {
	return Object.keys(roleGroupsMap).find(key => {
		const roleGroupRoles = roleGroupsMap[key].roles
		if (isEqual(rolesArray.sort(), roleGroupRoles.sort())) {
			return true
		} else {
			return false
		}
	})
}

export const getRoleGroupName = (roleId, roleGroups) => {
	if (roleId != null) {
		return roleGroups.find(roleGroup => {
			return roleGroup.id === roleId
		}).name
	} else {
		return ''
	}
}
