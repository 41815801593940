import { config as API } from 'api'
import * as postgrest from 'services/postgrest'
import { MODULE_NAME as PRODUCT_FILTERS_MODULE_NAME } from './product-filters'

export const MODULE_NAME = 'productFilterOptions' // this module's name in the rootReducer
const ENDPOINT = API.PRODUCT_FILTER_OPTIONS.PATH
const COLLECTION_NAME = 'PRODUCT_FILTER_OPTIONS'

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	results: {}
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const FETCHED = `${COLLECTION_NAME}_FETCHED`
const FAILED = `${COLLECTION_NAME}_FAILED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
// receive can take FETCHED_OPTIONS, UPDATED_FILTERS
const receive = (action, results) => ({
	type: action,
	payload: results
})
const fail = error => ({ type: FAILED, payload: error })

/**
 * Fetch filter options.
 *
 * Filter options are the individual selectable values that appear in Product Search
 * for the user to apply filtering criteria
 */
const fetch = () => {
	return (dispatch, getState) => {
		dispatch(request())

		const {
			filters
			// searchQuery
		} = getState()[PRODUCT_FILTERS_MODULE_NAME]
		const payload = {
			filters,
			// fts_string: searchQuery,
			excludes: []
		}
		const query = null

		return postgrest
			.fetchAllWithPost(ENDPOINT, query, payload)
			.then(response => {
				dispatch(receive(FETCHED, response))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		results: payload || {},
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetch,
	reset
}
