/**
 * Collection Base
 *
 * @todo description
 *
 * @see http://k33g.github.io/2014/07/05/ES6-IN-ACTION-WITH-MODELS.html
 */

import { sortBy } from 'lodash'
import * as cacheService from 'services/cache'
import Model from './Model'
import * as postgrest from 'services/postgrest'
import { getContentRangeTotal } from 'utils/headers'

export default class Collection {
	constructor(data, model, totalResults) {
		this.className = this.constructor.name
		this.model = model || Model
		this.endpoint = this.model.endpoint
		this.totalResults = totalResults || 0

		if (data) {
			this.createModels(data)
		} else {
			this.models = []
		}
	}

	add(models) {
		if (models instanceof Array) {
			this.models.concat(models)
		} else {
			this.models.push(models)
		}

		if (cacheService.get(this.className)) {
			cacheService.store(this.className, this.models)
		}

		return this
	}

	addModel(newModel) {
		return new this.constructor([newModel, ...this.models])
	}

	updateModel(updatedModel) {
		const models = this.models.map((model) => {
			return model.id() === updatedModel.id() ? updatedModel : model
		})

		return new this.constructor(models)
	}

	updateModelAtIndex(index, updatedModel) {
		const models = this.models.map((model, i) => {
			return index === i ? updatedModel : model
		})

		return new this.constructor(models)
	}

	removeModel(id) {
		const model = this.findById(id)
		const index = this.models.indexOf(model)

		if (index > -1) {
			this.models.splice(index, 1)
		}

		return new this.constructor(this.models)
	}

	size() {
		return this.models.length
	}

	ids() {
		return this.models.map((model) => model.id())
	}

	getAt(index) {
		if (!this.size()) {
			return null
		} else {
			if (!this.models[index]) {
				return null
			} else {
				return this.models[index]
			}
		}
	}

	save(id, data) {
		const model = this.findById(id)

		return new Promise((resolve, reject) => {
			model
				.save(data)
				.then((response) => {
					resolve(this)
				})
				.catch((error) => {
					reject(error)
				})
		})
	}

	// @todo remove this in place of 'updateModel'
	update(updatedModel) {
		this.models = this.models.map((model) => {
			return model.id() === updatedModel.id() ? updatedModel : model
		})

		return this
	}

	// @todo remove this in place of 'removeModel'
	remove(ids) {
		ids.forEach((id) => {
			const model = this.findById(id)
			const index = this.models.indexOf(model)

			if (index > -1) {
				this.models.splice(index, 1)
			}
		})

		return this
	}

	destroy(id) {
		const model = this.findById(id)

		return new Promise((resolve, reject) => {
			model
				.destroy()
				.then((response) => {
					const index = this.models.indexOf(model)
					this.models.splice(index, 1)
					resolve(this)
				})
				.catch((error) => {
					reject(error)
				})
		})
	}

	createModels(models) {
		this.models = []

		if (models && models.length) {
			models.forEach((data) => {
				if (data.className) {
					this.add(data)
				} else {
					this.add(new this.model(data))
				}
			})
		}
	}

	/**
	 * Returns new Collection with models filtered based on filterMethod
	 *
	 * @param {Function} filterMethod
	 * @returns {Collection}
	 */
	filter(filterMethod) {
		// @todo
		if (!filterMethod) {
			return this
		}

		const filteredModels = this.models.filter(filterMethod)

		return new this.constructor(filteredModels, this.model)
	}

	// gets

	/**
	 * Returns array of all model ids
	 *
	 * @returns {Array}
	 */
	getIds() {
		if (!this.models || !this.models.length) {
			return []
		} else {
			return this.models.map((model) => model.id())
		}
	}

	getFirst() {
		if (!this.size()) {
			return null
		} else {
			return this.models[0]
		}
	}

	/**
	 * Returns new Collection with models filtered based on filterMethod
	 *
	 * @param {Function} filterMethod
	 * @returns {Collection}
	 */
	sortBy(sortMethod) {
		// @todo
		if (!sortMethod) {
			return this
		}

		const sortedModels = sortBy(this.models, sortMethod)

		return new this.constructor(sortedModels, this.model)
	}

	/**
	 * @param {String} | id
	 * @return {Classifier}
	 */
	findById(id) {
		return this.models.find((model) => model.id() === id) || null
	}

	/**
	 * @param {String} | type
	 * @return {Classifier}
	 */
	findByType(str) {
		return this.models.find((model) => model.type() === str) || null
	}

	/**
	 * Maps attributes of models in Collection to { value, text }
	 * to be used within a SelectField component
	 *
	 * @return {Array}
	 */
	formatForSelect() {
		if (!this.models.length) {
			return []
		}

		return this.models.map((model) => ({
			value: model.id(),
			text: model.get('name'),
		}))
	}

	// -- api calls

	async fetchAll(query) {
		try {
			const response = await postgrest.fetchAll(this.endpoint, query)
			return new this.constructor(response)
		} catch (error) {
			throw error
		}
	}

	async fetchPage(page, perPage) {
		try {
			const response = await postgrest.fetchPage(this.endpoint, page, perPage)
			const totalResults = getContentRangeTotal(response.headers)
			return new this.constructor(response.body, totalResults)
		} catch (error) {
			throw error
		}
	}
}
