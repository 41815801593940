import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'connected-react-router'
import { ViewContainer } from 'containers/view'
import { BasicLoader } from 'components/loader'
import { actions as alertActions } from 'state/modules/alerts'
import { actions as sessionActions } from 'state/modules/session'
import { actions as userActions } from 'state/modules/user'
import {
	SUCCESS as ALERT_SUCCESS,
	ERROR as ALERT_ERROR,
} from 'data/alert-types'
import { config as API } from 'api'
import ROUTES from 'routes'
import * as postgrest from 'services/postgrest'

/* payload
{
	"verify_id": string
}
*/

const ENDPOINT = API.VERIFY_EMAIL.PATH

const mapDispatchToProps = (dispatch) => ({
	fetchUser: () => dispatch(userActions.fetchById()),
	updateCredentialsEmail: (email) =>
		dispatch(sessionActions.updateCredentialsEmail(email)),
	replacePath: (path) => dispatch(routerActions.replace(path)),
	showAlert: (obj) => dispatch(alertActions.showAlert(obj)),
})

class AccountVerifyEmailView extends React.Component {
	componentDidMount() {
		const payload = { verify_id: this.props.params.id }

		postgrest
			.create(ENDPOINT, payload)
			.then((response) => {
				this.props.fetchUser().then((response) => {
					this.props.updateCredentialsEmail(response.email)
				})
				this.props.showAlert({
					type: ALERT_SUCCESS,
					message: `Email address successfully updated.`,
				})
				this.props.replacePath(ROUTES.ACCOUNT.PROFILE)
			})
			.catch((error) => {
				this.props.showAlert({
					type: ALERT_ERROR,
					message: 'Error updating your email address.',
				})
				this.props.replacePath(ROUTES.ACCOUNT.PROFILE)
			})
	}

	render() {
		return (
			<ViewContainer>
				<BasicLoader />
				<span className="space-left">Verifying your new email...</span>
			</ViewContainer>
		)
	}
}

AccountVerifyEmailView.propTypes = {
	// -- dispatch props
	fetchUser: PropTypes.func.isRequired,
	replacePath: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
}

export default connect(null, mapDispatchToProps)(AccountVerifyEmailView)
