import { config as API } from 'api'
import * as postgrest from 'services/postgrest'
import { cloneDeep, isNil } from 'lodash'
import { getContentRangeTotal } from 'utils/headers'

export const MODULE_NAME = 'productRestrictorFilters'
const COLLECTION_NAME = 'PRODUCT_RESTRICTOR_FILTERS'
const MODEL_NAME = 'PRODUCT_RESTRICTOR_FILTER'
const PRODUCT_FILTERS_ENDPOINT = API.PRODUCT_FILTER_OPTIONS.PATH
const FILTER_PRODUCTS_ENDPOINT = API.FILTER_PRODUCTS.PATH

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	filters: [],
	filterOptions: {},
	totalProductResults: 0
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const FETCHED_PRODUCT_RESULTS = `${COLLECTION_NAME}_PRODUCT_RESULTS_FETCHED`
const FETCHED_FILTER_OPTIONS = `${COLLECTION_NAME}_FILTER_OPTIONS_FETCHED`
const ADDED_FILTER = `${MODEL_NAME}_ADDED`
const UPDATED_FILTER = `${MODEL_NAME}_UPDATED`
const REMOVED_FILTER = `${MODEL_NAME}_REMOVED`
const SET_FILTERS = `${MODEL_NAME}_SET`
const FAILED = `${COLLECTION_NAME}_FAILED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
// receive can take ADDED_FILTER, REMOVED_FILTER
const receive = (action, results) => ({
	type: action,
	payload: results
})
const fail = error => ({ type: FAILED, payload: error })

const reloadFilters = () => {
	return (dispatch, getState) => {
		dispatch(fetchFilterOptions())
		dispatch(fetchProductResults(0))
	}
}

const clearFilters = () => {
	return (dispatch, getState) => {
		dispatch(receive(RESET))
		dispatch(fetchFilterOptions())
		dispatch(fetchProductResults(0))
	}
}

/**
 * Filter objects are designed as follows:
 * @param {String} def_id | A Classifier Def Id
 * @param {String|Number} val | A value
 * @param {String|Number} from | A value
 * @param {String|Number} to | A value
 *
 * @note if either a "from" or "to" value is present with the other bound omitted,
 * it will be treated as unbounded on the missing bound.
 *
 * @todo figure out if a "val" is present, will it take precedence over "from" / "to"?
 */
const updateFilters = (def_id, val, from, to, selected) => {
	const isRange = !!(from || to)
	let filter = { def_id }

	if (isRange) {
		if (!isNil(from)) {
			filter.from = from
		}
		if (!isNil(to)) {
			filter.to = to
		}
	} else if (!isNil(val)) {
		filter.val = val
	}

	return (dispatch, getState) => {
		if (isNil(val) && isNil(from) && isNil(to)) {
			dispatch(receive(REMOVED_FILTER, filter))
		} else if (isRange) {
			dispatch(receive(UPDATED_FILTER, filter))
		} else {
			if (selected) {
				dispatch(receive(ADDED_FILTER, filter))
			} else {
				dispatch(receive(REMOVED_FILTER, filter))
			}
		}

		dispatch(fetchFilterOptions())
		dispatch(fetchProductResults(0))
	}
}

const setFilters = filters => {
	return dispatch => {
		dispatch(receive(SET_FILTERS, filters))
		dispatch(fetchFilterOptions())
		dispatch(fetchProductResults(0))
	}
}

const fetchProductResults = page => {
	return (dispatch, getState) => {
		dispatch(request())

		// we just want to get the "total results count" of products
		// to show, not any product data
		const perPage = 1
		const _page = page || 0
		const { filters } = getState()[MODULE_NAME]
		const payload = { filters }

		return postgrest
			.fetchPageWithPost(
				FILTER_PRODUCTS_ENDPOINT,
				_page, // page
				perPage, // per-page
				null,
				payload
			)
			.then(response => {
				const totalProductResults = getContentRangeTotal(response.headers)
				dispatch(receive(FETCHED_PRODUCT_RESULTS, totalProductResults))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const fetchFilterOptions = () => {
	return (dispatch, getState) => {
		dispatch(request())

		const { filters } = getState()[MODULE_NAME]
		const payload = {
			filters,
			excludes: []
		}
		const query = null

		return postgrest
			.fetchAllWithPost(PRODUCT_FILTERS_ENDPOINT, query, payload)
			.then(response => {
				dispatch(receive(FETCHED_FILTER_OPTIONS, response))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED_FILTER_OPTIONS]: (state, { payload }) => ({
		...state,
		filterOptions: payload || {},
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[FETCHED_PRODUCT_RESULTS]: (state, { payload }) => ({
		...state,
		totalProductResults: payload,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[SET_FILTERS]: (state, { payload }) => {
		return {
			...state,
			filters: payload
		}
	},
	[ADDED_FILTER]: (state, { payload }) => {
		let filters = cloneDeep(state.filters)
		filters.push(payload)

		return {
			...state,
			filters
		}
	},
	[REMOVED_FILTER]: (state, { payload }) => {
		let filters = cloneDeep(state.filters)
		const { def_id, val } = payload
		let index = null

		if (isNil(val)) {
			index = filters.findIndex(f => f.def_id === def_id)
		} else {
			index = filters.findIndex(f => f.def_id === def_id && f.val === val)
		}

		if (index > -1) {
			filters.splice(index, 1)
		}

		return {
			...state,
			filters
		}
	},
	[UPDATED_FILTER]: (state, { payload }) => {
		let filters = cloneDeep(state.filters)
		const { def_id } = payload
		const index = filters.findIndex(f => f.def_id === def_id)

		if (index === -1) {
			// add the filter if it doesnt exist
			filters.push(payload)
		} else {
			// update the filter if it already exists
			filters[index] = Object.assign({}, payload)
		}

		return {
			...state,
			filters
		}
	},
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	reloadFilters,
	clearFilters,
	setFilters,
	updateFilters,
	reset
}
