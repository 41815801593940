import { Collection } from './base'
import RFPIModel, {
	FETCH_SELECT,
	EMBED_RECIPIENTS,
	EMBED_PRODUCT_TYPES,
} from './RFPIModel'
import * as postgrest from 'services/postgrest'
import { getContentRangeTotal } from 'utils/headers'

export default class RFPICollection extends Collection {
	constructor(data, totalResults) {
		super(data, RFPIModel, totalResults)
	}

	// -- api call overrides

	async fetchPage(page, perPage) {
		const query = {
			select: `${FETCH_SELECT},${EMBED_RECIPIENTS},${EMBED_PRODUCT_TYPES}`,
			order: 'created_at.desc',
		}

		try {
			const response = await postgrest.fetchPage(
				this.endpoint,
				page,
				perPage,
				query,
			)
			const totalResults = getContentRangeTotal(response.headers)
			return new this.constructor(response.body, totalResults)
		} catch (error) {
			throw error
		}
	}
}
