const initialState = {
	showing: false
}

export const NAV_TOGGLED = 'NAV_TOGGLED'

export const show = (showing = true) => ({
	type: NAV_TOGGLED,
	payload: { showing }
})
export const hide = (showing = false) => ({
	type: NAV_TOGGLED,
	payload: { showing }
})

const ACTION_HANDLERS = {
	[NAV_TOGGLED]: (state, action) => ({
		...state,
		...action.payload
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	show,
	hide
}
