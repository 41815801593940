/**
 * Product Sheet
 *
 * @see https://github.com/relatableio/micro/wiki/Product-Sheet
 */

import { cloneDeep } from 'lodash'
import { Model, Collection } from 'models/base'
import { config as API } from 'api'
import { getContentRangeTotal } from 'utils/headers'
import * as postgrest from 'services/postgrest'

const ENDPOINT = API.PRODUCT_PRICING_SHEETS.PATH
const COLLECTION_NAME = 'PRICING_SHEETS'
const MODEL_NAME = 'PRICING_SHEET'
const MODULE_NAME = 'pricingSheets' // this module's name in the rootReducer
const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 20
const FETCH_SELECT = 'id,created_at,created_by,name,filename'

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	pendingFields: {},
	currentModel: new Model(),
	results: new Collection(),
	totalResults: 0,
	perPage: DEFAULT_PER_PAGE,
	page: DEFAULT_PAGE
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const FETCHED_PAGE = `${COLLECTION_NAME}_PAGE_FETCHED`
const EDIT_SAVED = `${MODEL_NAME}_EDIT_SAVED`
const EDIT_CLEARED = `${MODEL_NAME}_EDIT_CLEARED`
const CREATED = `${MODEL_NAME}_CREATED`
const SAVED = `${MODEL_NAME}_SAVED`
const DESTROYED = `${MODEL_NAME}_DESTROYED`
const FAILED = `${COLLECTION_NAME}_FAILED`

const reset = () => ({ type: RESET })
const request = () => ({ type: REQUESTED })
// receive can take FETCHED, CREATED, SAVED, DESTROYED, REPLACED actions
const receive = (action, fields) => {
	return { type: action, payload: fields }
}
const receivePage = (results, page, totalResults) => {
	return (dispatch, getState) => {
		var errorsByPricingSheetId = {}
		results.map(r => {
			r.errors = errorsByPricingSheetId[r.id]
			return r
		})
		dispatch(receive(FETCHED_PAGE, { results, page, totalResults }))
	}
}
const saveEdit = fields => ({ type: EDIT_SAVED, payload: fields })
const clearEdit = fields => ({ type: EDIT_CLEARED })
const fail = error => ({ type: FAILED, payload: error })

/**
 * Fetch resources using limit-offset pagination
 * thunk
 *
 * @see https://postgrest.com/en/v4.3/api.html#limits-and-pagination
 */
const fetchPage = page => {
	return (dispatch, getState) => {
		dispatch(request())

		const { perPage } = getState()[MODULE_NAME]
		const query = {
			select: FETCH_SELECT,
			order: 'created_at.desc'
		}

		return postgrest
			.fetchPage(ENDPOINT, page, perPage, query)
			.then(response => {
				const totalResults = getContentRangeTotal(response.headers)
				dispatch(receivePage(response.body, page, totalResults))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Create single resource
 * thunk
 */
const create = () => {
	return (dispatch, getState) => {
		dispatch(request())

		const fields = getState()[MODULE_NAME].pendingFields
		const payload = cloneDeep(fields)

		// @todo fix this stuff
		// need to delete 'headers' and 'exampleRow'
		delete payload.headers
		delete payload.exampleRow

		return postgrest
			.create(ENDPOINT, payload)
			.then(response => {
				dispatch(receive(CREATED, response))
				return response
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Save single resource
 * thunk
 */
const save = fields => {
	return (dispatch, getState) => {
		dispatch(request())

		const id = getState()[MODULE_NAME].currentModel.id()

		return postgrest
			.save(ENDPOINT, id, fields)
			.then(response => {
				dispatch(receive(SAVED, response))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Destroy single resource
 * thunk
 */
const destroy = id => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.destroy(ENDPOINT, id)
			.then(() => {
				dispatch(fetchPage(0))
			})
			.catch(error => {
				dispatch(fail(error))
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[EDIT_SAVED]: (state, { payload }) => {
		const fields = Object.assign(state.pendingFields, payload)

		return {
			...state,
			pendingFields: cloneDeep(fields)
		}
	},
	[EDIT_CLEARED]: (state, action) => ({
		...state,
		pendingFields: {}
	}),
	[FETCHED_PAGE]: (state, { payload }) => ({
		...state,
		results: new Collection(payload.results),
		page: payload.page,
		totalResults: payload.totalResults,
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[CREATED]: (state, { payload }) => ({
		...state,
		currentModel: new Model(payload),
		pendingFields: {},
		fetchError: null,
		isFetching: false
	}),
	[DESTROYED]: (state, action) => ({
		...state,
		currentModel: new Model(),
		fetchError: null,
		isFetching: false
	}),
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		hasFetched: true
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetchPage,
	saveEdit,
	clearEdit,
	create,
	destroy,
	save,
	reset
}
