import request from 'superagent'
import { Model } from './base'
import { config as API } from 'api'
import * as postgrest from 'services/postgrest'

const defaultFields = {
	file: null,
	note: ''
}

// data for api queries
// --

// data for ui display
// --
const SAVE_ERROR_TEXT = 'Error uploading RFPI response'

export default class RFPIResponseModel extends Model {
	static endpoint = API.RFPI_RESPONSES.PATH
	static saveErrorText = SAVE_ERROR_TEXT

	constructor(fields = defaultFields) {
		super(fields)
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	validate() {
		const { fields } = this

		if (!fields.file) {
			return false
		} else {
			return true
		}
	}

	async create(file) {
		if (!file) {
			throw new Error('RFPIResponseModel create() requires a file.')
		}

		try {
			const response = await new Promise((resolve, reject) => {
				request
					.post(postgrest.createUrl(this.endpoint))
					.query({ token: this.id() })
					.attach('file', file)
					.field('note', this.fields.note || '')
					.then(postgrest.handleErrors)
					.then(response => {
						resolve(response)
					})
			})
			return response
		} catch (error) {
			throw error
		}
	}
}

// export type RFPIResponseModelType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
//	note: string,
// 	file: File (binary)
// }
