import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@mui/material/CircularProgress'

const BasicLoader = ({ style, color, size, thickness }) => (
	<CircularProgress
		style={style}
		color={color}
		size={size}
		thickness={thickness}
	/>
)

BasicLoader.propTypes = {
	size: PropTypes.number,
	thickness: PropTypes.number,
	color: PropTypes.string,
	style: PropTypes.object,
}

BasicLoader.defaultProps = {
	size: 30,
	thickness: 3,
	color: 'primary',
	style: null,
}

export default BasicLoader
