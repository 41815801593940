import { cloneDeep, isEmpty, isNil } from 'lodash'
import { Model } from './base'
import { config as API } from 'api'
import { STRING as TYPE_STRING } from 'data/data-types'
import { formatDataType } from 'utils/formatDataType'
import { stripProtocol } from 'utils/formatString'

// @todo implement with flow
// export type ProductModelType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
//  number: ?string
// 	division_id: ?string
// 	product_type_id: ?string
//  product_classifier_vals: ?Array
// }

const SAVE_TEXT = 'Save Product'
const CREATE_TEXT = 'Create Product'
const DELETE_ACTION_TEXT = 'Are you sure you would like to delete this Product?'
const SAVE_ERROR_TEXT = 'Error saving Product'
const CREATE_ERROR_TEXT = 'Error creating Product'
const DELETE_ERROR_TEXT = 'Error deleting Product'

export default class ProductModel extends Model {
	static endpoint = API.PRODUCTS.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	/**
	 * "GET /products" is a custom view that does not return references straight from
	 * the database. Therefore, they do not have ids. We'll be using the 'number'
	 * attribtue instead.
	 */
	id() {
		return this.get('id') || this.get('number')
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	// validate() {
	// 	const { fields } = this
	//
	// 	if (!fields.name) {
	// 		return false
	// 	} else {
	// 		return true
	// 	}
	// }

	// -- get product classifier vals

	getAllVals() {
		return (
			this.get('product_classifier_vals') ||
			this.get('classifiers') || // remove this when GPS has been updated
			[]
		)
	}

	getSingleVal(classifierDefId) {
		if (!classifierDefId) {
			return null
		}

		return this.getAllVals().find(val => val.def_id === classifierDefId)
	}

	getVals(classifierDefId) {
		if (!classifierDefId) {
			return []
		}

		return this.getAllVals().filter(val => val.def_id === classifierDefId)
	}

	getSingleFormattedVal(defId, dataType = TYPE_STRING, referenceCollection) {
		if (!defId) {
			return null
		}

		// @todo remove this vals array check after GPS update
		// temporary shim to set vals[0] as val on product classifier values
		const classifierVal = this.getSingleVal(defId)

		if (!classifierVal) {
			return null
		}

		let val = null
		if (classifierVal.vals && Array.isArray(classifierVal.vals)) {
			val = classifierVal.vals[0]
		} else {
			val = classifierVal.val
		}

		return val ? formatDataType(val, dataType, referenceCollection) : null
	}

	getFormattedVals(defId, dataType = TYPE_STRING, referenceCollection) {
		if (!defId) {
			return []
		}

		return this.getVals(defId).map(classifierVal => {
			// @todo remove this vals array check after GPS update
			// temporary shim to set vals[0] as val on product classifier values
			let val = null
			if (classifierVal.vals && Array.isArray(classifierVal.vals)) {
				val = classifierVal.vals[0]
			} else {
				val = classifierVal.val
			}
			return formatDataType(val, dataType, referenceCollection)
		})
	}

	// -- special classifier val get

	// get the first IMAGE_LINK 'link' or 'val'
	// @todo this is a temp fix for https://github.com/relatableio/web-app/issues/590
	getImageUrl(imageLinkClassifierId) {
		const imageVal = this.getSingleVal(imageLinkClassifierId)

		if (!imageVal || isEmpty(imageVal)) {
			return null
		}

		// fix for image vals stored differently for GPS products
		// @see https://github.com/relatableio/relatable/issues/191
		let url = null
		if (!isNil(imageVal.vals)) {
			url = imageVal.vals[0]
		} else {
			url = !isNil(imageVal.link) ? imageVal.link : imageVal.val
		}

		return stripProtocol(url)
	}

	// -- update product classifier vals

	addVal(classifierVal) {
		if (!classifierVal) {
			return
		}

		const classifierVals = this.get('product_classifier_vals') || []
		let newClassifierVals = cloneDeep(classifierVals)

		newClassifierVals.push(classifierVal)

		this.set({ product_classifier_vals: newClassifierVals })
	}

	updateVal(classifierVal) {
		if (!classifierVal) {
			return
		}

		const classifierVals = this.get('product_classifier_vals') || []
		let newClassifierVals = cloneDeep(classifierVals)

		newClassifierVals = newClassifierVals.map(val => {
			if (val.id === classifierVal.id) {
				return classifierVal
			} else {
				return val
			}
		})

		this.set({ product_classifier_vals: newClassifierVals })
	}

	removeVal(classifierValId) {
		if (!classifierValId) {
			return
		}

		const classifierVals = this.get('product_classifier_vals') || []
		let newClassifierVals = cloneDeep(classifierVals)

		const index = newClassifierVals.findIndex(val => val.id === classifierValId)

		if (index > -1) {
			newClassifierVals.splice(index, 1)
			this.set({ product_classifier_vals: newClassifierVals })
		}
	}

	// --

	get saveActionText() {
		return this.id() ? SAVE_TEXT : CREATE_TEXT
	}
}
