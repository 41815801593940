import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { Link } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import { ViewContainer } from 'containers/view'
import { actions as sessionActions } from 'state/modules/session'
import { validateEmail } from 'utils/validation'
import ROUTES from 'routes'
import { publicViewStyles } from 'styles'
import logo from 'assets/logo_relatable.svg'
import { withAuth0 } from '@auth0/auth0-react'

const KEY_ENTER = 13

const mapStateToProps = (state) => ({
	session: state.session,
})

const mapDispatchToProps = (dispatch) => ({
	createSession: (email, password) =>
		dispatch(sessionActions.create(email, password)),
})

const styles = (theme) => ({
	...publicViewStyles(theme),
})

const FIELD_EMAIL = 'email'
const FIELD_PASSWORD = 'password'

class LoginView extends Component {
	state = {
		[FIELD_EMAIL]: '',
		[FIELD_PASSWORD]: '',
	}

	// -- event handlers
	handleInputUpdate = this.handleInputUpdate.bind(this)
	handleKeyPress = this.handleKeyPress.bind(this)
	handleLoginClick = this.handleLoginClick.bind(this)

	componentDidMount() {
		if (window.env.AUTH0_METHOD === 'AUTH0') {
			const { loginWithRedirect, isAuthenticated } = this.props.auth0
			if (!isAuthenticated) {
				loginWithRedirect()
			}
		}
	}

	validate() {
		if (!this.state[FIELD_EMAIL]) {
			return false
		} else if (!validateEmail(this.state[FIELD_EMAIL])) {
			return false
		} else if (!this.state[FIELD_PASSWORD]) {
			return false
		} else {
			return true
		}
	}

	handleInputUpdate(event) {
		this.setState({ [event.target.name]: event.target.value })
	}
	render() {
		const { classes, session } = this.props
		const isValid = this.validate()

		return window.env.AUTH0_METHOD === 'AUTH0' ? (
			<></>
		) : (
			<ViewContainer>
				<img src={logo} className={classes.logo} alt="Relatable" />
				<div className={classes.form}>
					<div className="space-bottom-small">
						<TextField
							margin="dense"
							label="Email"
							name={FIELD_EMAIL}
							onChange={this.handleInputUpdate}
							onKeyUp={this.handleKeyPress}
							variant="standard"
							fullWidth
						/>
					</div>
					<TextField
						margin="dense"
						label="Password"
						type="password"
						name={FIELD_PASSWORD}
						autoComplete="current-password"
						onChange={this.handleInputUpdate}
						onKeyUp={this.handleKeyPress}
						variant="standard"
						fullWidth
					/>

					<div className="space-top">
						<Button
							variant="contained"
							color="primary"
							size="large"
							style={{ width: '100%' }}
							onClick={this.handleLoginClick}
							disabled={!isValid || session.startupState === 'starting'}
						>
							Log in
						</Button>
					</div>

					<div className="space-top-large centered">
						<Button size="small" component={Link} to={ROUTES.FORGOT_PASSWORD}>
							Forgot your password?
						</Button>
					</div>
				</div>
			</ViewContainer>
		)
	}
	handleKeyPress(event) {
		const key = event.keyCode || event.charCode

		if (key === KEY_ENTER) {
			this.handleLoginClick()
		}
	}

	handleLoginClick() {
		const envFile = window.env.ENV_FILE
		const autoLogin = window.env.AUTO_LOGIN

		if (
			envFile === '.env.development.local' &&
			autoLogin === 'true' &&
			!this.state[FIELD_EMAIL] &&
			!this.state[FIELD_PASSWORD]
		) {
			const email = window.env.LOGIN_EMAIL
			const pw = window.env.LOGIN_PASSWORD
			this.props.createSession(email, pw)
			return
		}

		const isValid = this.validate()

		if (isValid) {
			this.props.createSession(
				this.state[FIELD_EMAIL],
				this.state[FIELD_PASSWORD],
			)
		}
	}
}

LoginView.propTypes = {
	classes: PropTypes.object.isRequired,
	// -- state props
	session: PropTypes.object.isRequired,
	// -- dispatch props
	createSession: PropTypes.func.isRequired,
	loginWithRedirect: PropTypes.func,
	logout: PropTypes.func,
}

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(styles),
	withAuth0,
)(LoginView)
