import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@mui/styles'

const styles = (theme) => ({
	root: {
		position: 'relative',
	},
	top: {
		paddingTop: theme.spacing(4),
	},
	right: {
		paddingRight: theme.spacing(6),
		[theme.breakpoints.down('md')]: {
			// paddingRight: theme.spacing(5)
		},
		[theme.breakpoints.down('sm')]: {
			paddingRight: `${theme.spacing(2)}!important`,
		},
	},
	bottom: {
		paddingBottom: theme.spacing(10),
	},
	left: {
		paddingLeft: theme.spacing(6),
		[theme.breakpoints.down('md')]: {
			// paddingLeft: theme.spacing(5)
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: `${theme.spacing(2)}!important`,
		},
	},
})

const ViewContainer = ({
	classes,
	children,
	all,
	top,
	right,
	bottom,
	left,
}) => {
	let paddingClasses = [classes.root]

	// @todo definitely a smarter way to do this but i don't have the time!
	if (all || (!top && !right && !bottom && !left)) {
		paddingClasses.push([
			classes.top,
			classes.right,
			classes.bottom,
			classes.left,
		])
	} else {
		if (top) {
			paddingClasses.push(classes.top)
		}
		if (right) {
			paddingClasses.push(classes.right)
		}
		if (bottom) {
			paddingClasses.push(classes.bottom)
		}
		if (left) {
			paddingClasses.push(classes.left)
		}
	}

	return (
		<div className={classNames(paddingClasses)}>{children && children}</div>
	)
}

ViewContainer.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
	// -- passed props
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	all: PropTypes.bool,
	top: PropTypes.bool,
	right: PropTypes.bool,
	bottom: PropTypes.bool,
	left: PropTypes.bool,
}

export default withStyles(styles)(ViewContainer)
