import React, { Component } from 'react'
import { Route, Navigate, Routes } from 'react-router-dom'
import AlertContainer from 'containers/alert/AlertContainer'
import ModalContainer from 'containers/modal/ModalContainer'
import { ViewContainer } from 'containers/view'
import LoginView from 'views/login/LoginView'
import LoginForgotPasswordView from 'views/login/LoginForgotPasswordView'
import LoginResetPasswordView from 'views/login/LoginResetPasswordView'
import SignupView from 'views/signup/SignupView'
import RFPIUploadView from 'views/rfpi/RFPIUploadView'
import ROUTES from 'routes'
import { history } from 'state'
import ProductComparePrintView from 'views/product/ProductComparePrintView'

class PublicLayout extends Component {
	render() {
		return (
			<div className="PublicLayout">
				<ModalContainer />
				<AlertContainer />

				<ViewContainer>
					<Routes>
						<Route
							path={ROUTES.PRODUCT_COMPARE.PRINT_GPS}
							element={<ProductComparePrintView />}
						/>
						<Route
							path={ROUTES.PRODUCT_COMPARE.PRINT}
							element={<ProductComparePrintView />}
						/>
						<Route path={ROUTES.ROOT} element={<LoginView />} />
						<Route
							path={ROUTES.FORGOT_PASSWORD}
							element={<LoginForgotPasswordView />}
						/>
						<Route
							path={ROUTES.RESET_PASSWORD}
							element={<LoginResetPasswordView />}
						/>
						<Route path={ROUTES.SIGNUP.VERIFY} element={<SignupView />} />
						<Route path={ROUTES.RFPI_UPLOAD} element={<RFPIUploadView />} />
						<Route
							path="*"
							Component={() => {
								const location = history.location
								const loginRedirect = `${location.pathname}${location.search}${location.hash}`
								return (
									<Navigate
										to={ROUTES.ROOT}
										state={{ loginRedirect: loginRedirect }}
									/>
								)
							}}
						/>
					</Routes>
				</ViewContainer>
			</div>
		)
	}
}

export default PublicLayout
