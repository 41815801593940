/**
 * Model Base
 *
 * @see http://k33g.github.io/2014/07/05/ES6-IN-ACTION-WITH-MODELS.html
 */

import { cloneDeep } from 'lodash'
import * as postgrest from 'services/postgrest'
import { ID_PRODUCT_NUMBER } from 'data/classifier-refs'

export default class Model {
	constructor(data = {}) {
		this.className = this.constructor.name || null
		this.endpoint = this.constructor.endpoint || null
		this.fields = data ? cloneDeep(data) : {}
		this.cacheData = {}
	}

	clone() {
		return cloneDeep(this)
	}

	id() {
		return this.get('id')
	}

	type() {
		return this.get('type')
	}

	get(fieldName) {
		return this.fields[fieldName]
	}

	update(fieldName, val) {
		this.fields = {
			...this.fields,
			[fieldName]: val,
		}
	}

	set(fields) {
		this.fields = Object.assign(this.fields, fields)
	}

	removeField(field) {
		delete this.fields[field]
	}

	cache() {
		this.cacheData = cloneDeep(this.fields)
	}

	applyCache() {
		this.fields = cloneDeep(this.cacheData)
	}

	// -- api calls

	async fetch() {
		try {
			const response = await postgrest.fetch(this.endpoint, this.id())
			return new this.constructor(response)
		} catch (error) {
			throw error
		}
	}

	async create() {
		try {
			const response = await postgrest.create(this.endpoint, this.fields)
			return new this.constructor(response)
		} catch (error) {
			throw error
		}
	}

	async createCustomModel(
		priceDataEndpoint,
		priceSheetEndpoint,
		classifierRefsToIds,
	) {
		const payload = []
		const fieldData = cloneDeep(this.fields)
		const matched_columns = cloneDeep(fieldData.matched_columns)
		const column_data = cloneDeep(fieldData.rows)
		const productNumberDefID = classifierRefsToIds[ID_PRODUCT_NUMBER]

		try {
			const sheetCreateRes = await postgrest.create(
				priceSheetEndpoint,
				fieldData,
			)

			const index = matched_columns.findIndex(
				(mc) => mc.def_id === productNumberDefID,
			)
			const productNoKeyName = matched_columns[index]['column']

			matched_columns.splice(index, 1)

			column_data.forEach((cd) => {
				if (
					cd[productNoKeyName] &&
					cd[productNoKeyName].length &&
					cd[productNoKeyName][0].val
				) {
					matched_columns.forEach((mc) => {
						let payloadItem = {
							product_number: cd[productNoKeyName][0].val,
							sheet_id: sheetCreateRes.id,
						}
						payloadItem = {
							...payloadItem,
							price_tier_id: mc.def_id,
							price:
								cd[mc.column] && cd[mc.column].length && cd[mc.column][0].val
									? cd[mc.column][0].val
									: 0,
						}
						payload.push(payloadItem)
					})
				}
			})

			try {
				await postgrest.create(priceDataEndpoint, payload)
				return sheetCreateRes
			} catch (error) {
				throw error
			}
		} catch (error) {
			throw error
		}
	}

	// @todo only patch updated fields
	async save() {
		try {
			const response = await postgrest.save(
				this.endpoint,
				this.id(),
				this.fields,
			)
			return new this.constructor(response)
		} catch (error) {
			throw error
		}
	}

	async destroy() {
		try {
			await postgrest.destroy(this.endpoint, this.id())
			return this.id()
		} catch (error) {
			throw error
		}
	}
}
