import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Collapse from '@mui/material/Collapse'
import ArrowDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowUpIcon from '@mui/icons-material/ArrowDropUp'
import { createGradientBackgroundString } from 'theme'

const styles = (theme) => ({
	background: {
		padding: theme.spacing(6),
		marginBottom: theme.spacing(3),
		[theme.breakpoints.down('sm')]: {
			paddingTop: `${theme.spacing(3)}!important`,
			paddingBottom: `${theme.spacing(3)}!important`,
			paddingLeft: `${theme.spacing(2)}!important`,
			paddingRight: `${theme.spacing(2)}!important`,
		},
	},
	header: {
		display: 'flex',
		alignItems: 'center',
		[theme.breakpoints.down('sm')]: {
			// justifyContent: 'space-between'
			// justifyContent: 'flex-end',
			// flexDirection: 'row-reverse'
		},
	},
	container: {
		position: 'relative',
		color: '#fff',
	},
	title: {
		[theme.breakpoints.down('sm')]: {
			fontSize: theme.typography.h5.fontSize,
			fontWeight: 500,
		},
	},
	content: {
		marginTop: theme.spacing(2),
		maxWidth: 800,
	},
	iconButton: {
		color: '#fff',
		marginLeft: theme.spacing(1),
	},
})

const ViewHero = (props) => {
	const { classes, children, title, colors, open, handleOpen, handleClose } =
		props

	return (
		<div
			className={classes.background}
			style={{
				background: createGradientBackgroundString(colors),
			}}
		>
			<div className={classes.container}>
				<div className={classes.header}>
					<Typography
						component="h1"
						variant="h4"
						color="inherit"
						className={classes.title}
					>
						{title}
					</Typography>
					<IconButton
						aria-label={open ? 'Show less' : 'Show more'}
						className={classes.iconButton}
						onClick={open ? handleClose : handleOpen}
					>
						{open ? (
							<ArrowUpIcon fontSize="large" />
						) : (
							<ArrowDownIcon fontSize="large" />
						)}
					</IconButton>
				</div>
				<Collapse in={open}>
					<div className={classes.content}>{children}</div>
				</Collapse>
			</div>
		</div>
	)
}

ViewHero.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
	// -- passed props
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	title: PropTypes.string.isRequired,
	colors: PropTypes.array.isRequired,
	open: PropTypes.bool,
	handleOpen: PropTypes.func,
	handleClose: PropTypes.func,
}

export default withStyles(styles)(ViewHero)
