import { Collection } from './base'
import ProductModel from './ProductModel'

export default class ProductCollection extends Collection {
	constructor(data) {
		super(data, ProductModel)
	}

	getClassifierValueList(defId, dataType) {
		const list = []
		this.models.forEach(product => {
			list.push(...product.getFormattedVals(defId, dataType))
		})
		return list
	}
}
