export const APPEND = 'append'
export const REPLACE = 'replace'

// To present type as string in UI
export const map = {
	[APPEND]: 'Append Data',
	[REPLACE]: 'Replace Data'
}

// For use in dropdown fields, etc
export const list = [
	{ value: APPEND, text: map[APPEND] },
	{ value: REPLACE, text: map[REPLACE] }
]
