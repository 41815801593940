import { Model } from './base'
import { config as API } from 'api'

const defaultFields = {
	id: null,
	first_name: null,
	last_name: null,
	email: null,
	phone: null,
	npid: null
}

export default class UserModel extends Model {
	static endpoint = API.USERS.PATH

	constructor(fields = defaultFields) {
		super(fields)
	}

	get fullName() {
		return `${this.get('first_name')} ${this.get('last_name')}`
	}
}
