/**
 * Validation
 */

export const PASSWORD_MIN_LENGTH = 6

export function validatePassword(password) {
	if (!password || password.length < PASSWORD_MIN_LENGTH) {
		return false
	} else {
		return true
	}
}

export function validateEmail(email) {
	// eslint-disable-next-line
	const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(email)
}
