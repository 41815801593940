import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'

const DEFAULT_WIDTH_PERCENTAGE = '80%'

const styles = (theme) => ({
	drawerPaper: {
		width: DEFAULT_WIDTH_PERCENTAGE,
	},
	container: {
		// width: DEFAULT_WIDTH_PERCENTAGE
	},
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		// justifyContent: 'flex-end',
		padding: `0 ${theme.spacing(1)}`,
		...theme.mixins.toolbar,
	},
	content: {
		padding: theme.spacing(2),
		paddingBottom: theme.spacing(10),
	},
})

function DrawerContainer({ classes, drawer, hideDrawer }) {
	// @todo add drawer.widthPercentage back in
	const { title, showing, component } = drawer

	return (
		<Drawer
			open={showing}
			onClose={hideDrawer}
			classes={{
				paper: classes.drawerPaper,
			}}
			anchor="right"
		>
			<div className={classes.container}>
				<div className={classes.toolbar}>
					<IconButton
						className={classes.backIcon}
						color="inherit"
						aria-label="Close drawer"
						onClick={hideDrawer}
					>
						<ArrowForwardIcon />
					</IconButton>
					<Typography variant="h6" color="inherit">
						{title}
					</Typography>
				</div>
				<Divider />
				<div className={classes.content}>{component && component}</div>
			</div>
		</Drawer>
	)
}

DrawerContainer.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
	// -- sent props
	drawer: PropTypes.object.isRequired,
	hideDrawer: PropTypes.func.isRequired,
}

export default withStyles(styles)(DrawerContainer)
