/**
 * Made with help from Material-UI docs
 * @see https://github.com/mui-org/material-ui/blob/master/docs/src/modules/components/AppDrawer.js
 */

import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import IconButton from '@mui/material/IconButton'
import { useLocation } from 'react-router-dom'
import AppDrawerNavItem from './AppDrawerNavItem'
import ROUTES, { navigationMap, adminNavigationMap } from 'routes'
import { checkSuperAdmin } from 'services/admin'
import {
	testPermission,
	ROLE_NAV_FORBIDS,
	ROLE_SUB_NAV_FORBIDS,
	DASHBOARD_ROLE_NAME,
} from 'permissions'

const DRAWER_WIDTH = 250
const styles = (theme) => ({
	toolbar: {
		display: 'flex',
		alignItems: 'center',
		padding: `0 ${theme.spacing(1)}`,
		...theme.mixins.toolbar,
	},
	list: {
		margin: `${theme.spacing(1)} 0`,
		width: DRAWER_WIDTH,
	},
})

function renderChildNavItems({
	children,
	privileges,
	location,
	classes,
	hideNav,
	userRoleGroup,
	roleGroups,
}) {
	let roleName =
		roleGroups && roleGroups.length && userRoleGroup
			? roleGroups.find((rg) => rg.id === userRoleGroup).system_name
			: ''

	const hideSubNavFromRole = (navItem) => {
		if (
			ROLE_SUB_NAV_FORBIDS[roleName] &&
			ROLE_SUB_NAV_FORBIDS[roleName].includes(navItem.path)
		) {
			return true
		}
	}

	return (
		<List>
			{children.map((child) => {
				const { title, path, hideFromAppBar } = child
				const hasPermission = testPermission(privileges, path)
				if (!hasPermission || hideFromAppBar || hideSubNavFromRole(child)) {
					return null
				}

				if (
					title === 'Dashboard' &&
					DASHBOARD_ROLE_NAME.indexOf(roleName) > -1
				) {
					return null
				}
				const isActive = Boolean(location.pathname === path)

				return (
					<AppDrawerNavItem
						key={path}
						path={path}
						title={title}
						hideNav={hideNav}
						isActive={isActive}
						isLeaf
					/>
				)
			})}
		</List>
	)
}

function renderNavItems(props, location, items) {
	const { userRoleGroup, roleGroups, privileges, classes, hideNav } = props

	const hideFromRole = (navItem) => {
		let role =
			roleGroups && roleGroups.length && userRoleGroup
				? roleGroups.find((rg) => rg.id === userRoleGroup).system_name
				: ''

		if (
			ROLE_NAV_FORBIDS[role] &&
			ROLE_NAV_FORBIDS[role].includes(navItem.path)
		) {
			return true
		}
	}

	return (
		<List className={props.classes.list}>
			{items.map((item, index) => {
				const { title, path, icon, children, hideFromAppBar } = item
				const hasPermission = testPermission(privileges, path)

				if (!hasPermission || hideFromAppBar || hideFromRole(item)) {
					return null
				}

				const isSuperAdmin = checkSuperAdmin(userRoleGroup, roleGroups)
				const isSupplier =
					props.account.type && props.account.type === 'supplier'

				if (isSupplier && path !== ROUTES.PRODUCTS.ROOT && !isSuperAdmin) {
					return null
				}

				const isActive = !!(location.pathname.indexOf(path) > -1)

				if (children && children.length > 1) {
					return (
						<AppDrawerNavItem
							key={path}
							title={title}
							icon={icon}
							hideNav={hideNav}
							openImmediately={isActive}
						>
							{renderChildNavItems({
								children,
								privileges,
								location,
								classes,
								hideNav,
								userRoleGroup,
								roleGroups,
							})}
						</AppDrawerNavItem>
					)
				} else {
					return (
						<AppDrawerNavItem
							key={path}
							title={title}
							path={path}
							icon={icon}
							hideNav={hideNav}
						/>
					)
				}
			})}
		</List>
	)
}

function AppDrawer(props) {
	const location = useLocation()
	const { classes, isOpen, hideNav, showNav, userRoleGroup, roleGroups } = props
	const isSuperAdmin = checkSuperAdmin(userRoleGroup, roleGroups)

	return (
		<nav>
			<SwipeableDrawer open={isOpen} onClose={hideNav} onOpen={showNav}>
				<div className={classes.toolbar}>
					<IconButton onClick={hideNav}>
						<ArrowBackIcon />
					</IconButton>
				</div>
				<Divider />
				{renderNavItems(props, location, navigationMap)}
				{isSuperAdmin && (
					<React.Fragment>
						<Divider />
						{renderNavItems(props, location, adminNavigationMap)}
					</React.Fragment>
				)}
			</SwipeableDrawer>
		</nav>
	)
}

AppDrawer.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
	// --
	isOpen: PropTypes.bool,
	userRoleGroup: PropTypes.string,
	privileges: PropTypes.array.isRequired,
	hideNav: PropTypes.func.isRequired,
	showNav: PropTypes.func.isRequired,
}

export default withStyles(styles)(AppDrawer)
