//refer to https://serverless-stack.com/chapters/setup-error-reporting-in-react.html

import * as Sentry from '@sentry/browser'

const isLocal = window.env.NODE_ENV === 'development'
// const isLocal = false

export const initSentry = () => {
	if (isLocal) {
		return
	}
	Sentry.init({
		environment: window.env.NODE_ENV,
		release: `Relatable@${window.env.VERSION}`,
		dsn:
			'https://9adbf10d6e4d4c10b0954d3bae18d68a@o39022.ingest.sentry.io/5224547'
		// beforeSend(event, hint) {
		// 	// Check if it is an exception, and if so, show the report dialog
		// 	if (event.exception) {
		// 		Sentry.showReportDialog({ eventId: event.event_id })
		// 	}
		// 	return event
		// }
	})
}

export const logError = (error, errorInfo = null) => {
	if (isLocal) {
		return
	}

	Sentry.withScope(scope => {
		errorInfo && scope.setExtras(errorInfo)
		Sentry.captureException(error)
	})
}
