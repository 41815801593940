import { orderBy } from 'lodash'
export function normalize(string) {
	if (!string) return ''
	if (string.richText) {
		return string.richText
			.map(item => item.text)
			.join('')
			.replace(/\s/g, '')
			.replace(/-/g, '')
			.toLowerCase()
	}
	return string
		.replace(/\s/g, '')
		.replace(/-/g, '')
		.toLowerCase()
}

export function textToHash(text) {
	return text
		.toLowerCase()
		.replace(/=&gt;|&lt;| \/&gt;|<code>|<\/code>/g, '')
		.replace(/\W/g, '-')
}

export function truncate(string, length) {
	if (string.length > length) {
		return `${string.substring(0, length)}...`
	} else {
		return string
	}
}

export function stripProtocol(string) {
	if (!string) {
		return null
	}

	return string.replace(/^https?:\/\//i, '//')
}

export function removeSpecialCharacters(string) {
	return string.replace(/[^\w\s]/gi, '')
}

export function pluralize(value, word) {
	let pluralizedWord

	if (value === 1) {
		pluralizedWord = word
	} else {
		pluralizedWord = word + 's'
	}

	return value + ' ' + pluralizedWord
}

export function truncateString(str, num) {
	if (!str) {
		return ''
	}
	if (str.length <= num) {
		return str
	}
	return String(str).slice(0, num) + '...'
}

export function sortModelByBoolean(array, property) {
	return array && array.length
		? orderBy(
				array,
				[
					el => {
						return !el.fields.hasOwnProperty(property)
							? ''
							: el.fields[property]
							? 1
							: 2
					}
				],
				['asc']
		  )
		: []
}
