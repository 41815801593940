/**
 * ColorButton
 *
 * Available colors:
 * teal
 * purple
 *
 *
 */

import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Button from '@mui/material/Button'
import teal from '@mui/material/colors/teal'
import purple from '@mui/material/colors/purple'
import grey from '@mui/material/colors/grey'

const styles = (theme) => ({
	teal: {
		color: '#fff',
		backgroundColor: teal.A700,
		'&:hover': {
			backgroundColor: teal[600],
		},
	},
	purple: {
		color: '#fff',
		backgroundColor: purple.A400,
		'&:hover': {
			backgroundColor: purple.A700,
		},
	},
	white: {
		color: grey[800],
		backgroundColor: grey[50],
		'&:hover': {
			backgroundColor: grey[300],
		},
	},
})

const ColorButton = ({ children, color, classes, ...rest }) => (
	<Button {...rest} className={classNames(classes[color])}>
		{children}
	</Button>
)

ColorButton.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf(['teal', 'purple', 'white']),
	// + @mui/material/Button props
}

export default withStyles(styles)(ColorButton)
