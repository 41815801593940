import { Model } from './base'
import { config as API } from 'api'

// @todo implement with flow
// export type DivisionModelType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
// 	name: ?string
// 	description: ?string
// }

const defaultFields = {
	name: null
}

const SAVE_TEXT = 'Save'
const CREATE_TEXT = 'Create'
const DELETE_ACTION_TEXT =
	'Are you sure you would like to delete this Division?'
const SAVE_ERROR_TEXT = 'Error saving Division'
const CREATE_ERROR_TEXT = 'Error creating Division'
const DELETE_ERROR_TEXT = 'Error deleting Division'

export default class DivisionModel extends Model {
	static endpoint = API.DIVISIONS.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	constructor(fields = defaultFields) {
		super(fields)
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	validate() {
		const { fields } = this

		if (!fields.name) {
			return false
		} else {
			return true
		}
	}

	get saveActionText() {
		return this.id() ? SAVE_TEXT : CREATE_TEXT
	}
}
