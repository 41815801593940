import { cloneDeep, isNil } from 'lodash'
import { Model } from './base'
import { config as API } from 'api'

// @todo implement with flow
// export type RelatorModelType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
// 	division_id: ?string
// 	product_type_id: ?string
//  product_relator_criteria: ?Array
// }

const defaultFields = {
	division_id: '',
	product_type_id: '',
	product_relator_criteria: []
}

const SAVE_TEXT = 'Save Relator'
const CREATE_TEXT = 'Create Relator'
const DELETE_ACTION_TEXT = 'Are you sure you would like to delete this Relator?'
const SAVE_ERROR_TEXT = 'Error saving Relator'
const CREATE_ERROR_TEXT = 'Error creating Relator'
const DELETE_ERROR_TEXT = 'Error deleting Relator'

export default class RelatorModel extends Model {
	static endpoint = API.PRODUCT_RELATORS.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	constructor(fields = defaultFields) {
		if (isNil(fields.product_relator_criteria)) {
			fields.product_relator_criteria = []
		}
		super(fields)
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	// validate() {
	// 	const { fields } = this
	//
	// 	if (!fields.name) {
	// 		return false
	// 	} else {
	// 		return true
	// 	}
	// }

	// -- update relator criteria

	addCriteria(criteriaItem) {
		this.set({
			product_relator_criteria: [
				...this.get('product_relator_criteria'),
				criteriaItem
			]
		})
	}

	updateCriteria(criteriaItem) {
		let currentCriteria = cloneDeep(this.get('product_relator_criteria'))
		currentCriteria = currentCriteria.map(cr => {
			if (cr.def_id === criteriaItem.def_id) {
				return criteriaItem
			} else {
				return cr
			}
		})

		this.set({
			product_relator_criteria: currentCriteria
		})
	}

	removeCriteria(criteriaItem) {
		let currentCriteria = cloneDeep(this.get('product_relator_criteria'))
		const index = currentCriteria.findIndex(
			cr => cr.def_id === criteriaItem.def_id
		)

		if (index > -1) {
			currentCriteria.splice(index, 1)
		}

		this.set({
			product_relator_criteria: currentCriteria
		})
	}

	// --

	get saveActionText() {
		return this.id() ? SAVE_TEXT : CREATE_TEXT
	}
}
