import {
	ID_PRODUCT_NUMBER,
	ID_CLASSIFICATION,
	ID_DESCRIPTION,
	ID_MANUFACTURER,
	ID_VENDOR,
	ID_PURCHASE_QTY
} from './classifier-refs'

export const ProductIdentifyColumn = [
	ID_PRODUCT_NUMBER,
	ID_CLASSIFICATION,
	ID_DESCRIPTION,
	ID_MANUFACTURER,
	ID_VENDOR
]

export const PurcahseIdentifyColumn = [
	ID_PRODUCT_NUMBER,
	ID_DESCRIPTION,
	ID_MANUFACTURER,
	ID_VENDOR,
	ID_PURCHASE_QTY
]

const HEADER_BG_COLOR = 'ffdedede'
const HEADER_CELL_FILL = {
	type: 'pattern',
	pattern: 'solid',
	fgColor: { argb: HEADER_BG_COLOR }
}

const IDENTIFY_PRODUCT_NUMBER_COL = 'IDENTIFY_PRODUCT_NUMBER_COL'
const IDENTIFY_DIVISION_COL = 'IDENTIFY_DIVISION_COL'
const IDENTIFY_PRODUCT_TYPE_COL = 'IDENTIFY_PRODUCT_TYPE_COL'
const IDENTIFY_CLASSIFICATION_COL = 'IDENTIFY_CLASSIFICATION_COL'
const IDENTIFY_DESCRIPTION_COL = 'IDENTIFY_DESCRIPTION_COL'
const IDENTIFY_MANUFACTURER_COL = 'IDENTIFY_MANUFACTURER_COL'
const IDENTIFY_VENDOR_COL = 'IDENTIFY_VENDOR_COL'
const IDENTIFY_QUANTITY_COL = 'IDENTIFY_QUANTITY_COL'

export const uniqueProductIdentifyCols = {
	[IDENTIFY_PRODUCT_NUMBER_COL]: {
		index: 1,
		text: 'Product Number',
		width: 20,
		key: 'product_number',
		fill: HEADER_CELL_FILL
	},
	[IDENTIFY_DIVISION_COL]: {
		index: 2,
		text: 'Division',
		key: 'division',
		width: 20,
		fill: HEADER_CELL_FILL
	},
	[IDENTIFY_PRODUCT_TYPE_COL]: {
		index: 3,
		text: 'Product Type',
		key: 'product_type',
		width: 20,
		fill: HEADER_CELL_FILL
	},
	[IDENTIFY_CLASSIFICATION_COL]: {
		index: 4,
		text: 'Classification',
		key: 'classification',
		width: 20,
		fill: HEADER_CELL_FILL
	},
	[IDENTIFY_DESCRIPTION_COL]: {
		index: 5,
		text: 'Description',
		key: 'description',
		width: 20,
		fill: HEADER_CELL_FILL
	},
	[IDENTIFY_MANUFACTURER_COL]: {
		index: 6,
		text: 'Manufacturer',
		key: 'manufacturer',
		width: 20,
		fill: HEADER_CELL_FILL
	},
	[IDENTIFY_VENDOR_COL]: {
		index: 7,
		text: 'Vendor',
		key: 'vendor',
		width: 20,
		fill: HEADER_CELL_FILL
	}
}
export const gpsProductUnidentifyCols = {
	[IDENTIFY_MANUFACTURER_COL]: {
		index: 1,
		text: 'Manufacturer (Usage)',
		key: 'manufacturer',
		width: 30,
		fill: HEADER_CELL_FILL
	},
	[IDENTIFY_PRODUCT_NUMBER_COL]: {
		index: 2,
		text: 'Product Number (Usage)',
		width: 30,
		key: 'product_number',
		fill: HEADER_CELL_FILL
	},
	[IDENTIFY_QUANTITY_COL]: {
		index: 3,
		text: 'Quantity TYPE (Usage)',
		key: 'quantity',
		width: 40,
		fill: HEADER_CELL_FILL,
		format: '#,##0.00'
	}
}
