import {
	STRING as TYPE_STRING,
	NUMBER as TYPE_NUMBER,
	BOOL as TYPE_BOOL,
	DATE as TYPE_DATE,
	URL as TYPE_URL,
	ID as TYPE_ID,
} from 'data/data-types'
import { getStandardFormat } from 'services/date'
import { getStringFromBoolean } from 'utils/formatBoolean'

export function formatDataType(
	value,
	dataType = TYPE_STRING,
	referenceCollection,
) {
	if (!value && value !== false && value !== 0) {
		return null
	}

	switch (dataType) {
		// uses a "reference collection" like DivisionCollection
		// or ProductTypeCollection to find the id and replace with a name
		case TYPE_ID:
			if (referenceCollection && referenceCollection.size()) {
				const match = referenceCollection.findById(value)
				return match ? match.get('name') : null
			} else {
				return value
			}
		case TYPE_BOOL:
			return getStringFromBoolean(value)
		case TYPE_DATE:
			return getStandardFormat(value)
		case TYPE_URL:
		case TYPE_STRING:
		case TYPE_NUMBER:
		default:
			return value
	}
}

export function getDataTypePlaceholder(dataType) {
	switch (dataType) {
		case TYPE_ID:
			return 'RELATABLE UUID'
		case TYPE_BOOL:
			return 'TRUE / FALSE'
		case TYPE_DATE:
			return 'MM/DD/YYYY'
		case TYPE_URL:
			return {
				formula: `HYPERLINK("https://relatable.io", "Hyperlinked Text")`,
				result: 'Hyperlinked Text',
			}
		case TYPE_STRING:
			return 'Text'
		case TYPE_NUMBER:
			return '10'
		default:
			return dataType
	}
}

export const getCValFromClassifierVals = (
	productClassifierVals,
	productId,
	refsToIds,
	refId,
	dataType,
	referenceCollection,
) => {
	const productCVal = productClassifierVals.find(
		(rpc) => rpc.product_number === productId,
	)
	if (productCVal && productCVal.product_classifier_vals) {
		const cVals = productCVal.product_classifier_vals.filter(
			(pcv) => pcv.def_id === refsToIds[refId],
		)
		return cVals.length
			? formatDataType(cVals[0].val, dataType, referenceCollection)
			: null
	}
	return null
}
