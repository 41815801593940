import { cloneDeep, isNil, compact, uniq, sortBy } from 'lodash'
import { Model } from './base'

import { config as API } from 'api'
import * as excelService from 'services/excel'
import { normalize } from 'utils/formatString'
import * as postgrest from 'services/postgrest'
import { ProductIdentifyColumn } from '../data/pdc-product-identify-config'

const defaultFields = {
	name: '',
	filename: '',
	rows: [],
	matched_columns: [],
}

const SAVE_TEXT = 'Save Identify Sheet'
const CREATE_TEXT = 'Upload Identify Sheet'
const DELETE_ACTION_TEXT =
	'Are you sure you would like to delete this Identify Sheet?'
const SAVE_ERROR_TEXT = 'Error saving Identify Sheet'
const CREATE_ERROR_TEXT = 'Error uploading Identify Sheet'
const DELETE_ERROR_TEXT = 'Error deleting Identify Sheet'

const IDENTIFY_ENDPOINT = API.PRODUCT_SHEET_UPLOADED_RECORDS.PATH

export default class IdentifySheetModel extends Model {
	static endpoint = API.IDENTIFY_SHEETS.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	/**
	 * This will filter the Classifier Defs set to only include
	 * Purchase Classifiers + Product Number classifier (which is Universal)
	 *
	 * Classifier Defs in predetermined exclusions list are removed
	 *
	 * @param {Collection} classifierDefs
	 * @param {Object} classifierIdsToRefs
	 */
	static filterClassifierDefs(classifierDefs, classifierIdsToRefs) {
		return classifierDefs.filter((cl) => {
			if (ProductIdentifyColumn.indexOf(classifierIdsToRefs[cl.id()]) > -1) {
				return true
			} else {
				return false
			}
		})
	}

	constructor(fields = defaultFields) {
		super(fields)

		// these will be set in createFromWorksheet method
		this.worksheet = null
		this.sheetName = null
		this.rowCount = null
		this.columnCount = null
		this.columnNames = []
		this.exampleValues = []
		this.columnOptions = []
	}

	/**
	 * @see exceljs
	 *
	 * @param {object} worksheet
	 * @param {string} filename
	 */
	createFromWorksheet(worksheet, filename) {
		this.worksheet = worksheet
		// not to be confused with the "name" field which may be edited
		// this is and will always remain the name of the sheet tab
		this.sheetName = worksheet.name
		this.rowCount = worksheet.rowCount
		this.columnCount = worksheet.columnCount
		this.columnNames = excelService.getColumnNamesFromWorksheet(worksheet)

		// exampleValues and columnOptions are used in the match columns ui
		this.exampleValues = excelService.getExampleValuesFromWorksheet(worksheet)
		this.columnOptions = uniq(this.columnNames)
			.filter((c) => !isNil(c))
			.map((c) => ({ value: c, text: c }))
		// provide "unselect" / null option for row matching
		this.columnOptions = sortBy(this.columnOptions, (option) => option.value)
		this.columnOptions.unshift({
			value: null,
			text: 'Leave Unmatched',
			fontClass: 'font-italic secondary-color ',
		})

		this.set({
			filename,
			name: this.sheetName,
			rows: excelService.getRowsFromWorksheet(worksheet),
		})
	}

	/**
	 *
	 * This will attempt to find a Classifier Definition match for
	 * each Column Name in the sheet. This uses a simple string
	 * normalization before equivalency check.
	 *
	 * Classifier Defs should be filtered before using this method
	 * by using "filterClassifierDefs()"
	 *
	 * @todo implement smarter / "fuzzy" check
	 *
	 * @param {Collection} classifierDefs
	 */
	setInitialMatchedColumns(classifierDefs) {
		let columnNames = compact(this.columnNames) // compact removes nulls
		let matchedColumns = []

		// @todo optimize this loop?
		// find all initial matched columns using simple string comparison
		classifierDefs.models.forEach((cl) => {
			const defId = cl.id()
			const cleanClassifier = normalize(cl.get('name'))

			columnNames.forEach((columnName) => {
				const cleanColumnName = normalize(columnName)

				if (cleanColumnName === cleanClassifier) {
					matchedColumns.push({
						column: columnName,
						def_id: defId,
					})
				}
			})
		})

		this.set({
			matched_columns: matchedColumns,
		})
	}

	/**
	 * classifierDefs should be filtered via this models' filterClassifierDefs
	 * for use in this method
	 */
	validateRequiredClassifiers(classifierDefs) {
		const matchedColumns = this.get('matched_columns')

		return classifierDefs.filter((cl) => {
			const isRequired = cl.get('required_val')
			let match = null

			if (isRequired) {
				match = matchedColumns.find((col) => col.def_id === cl.id())

				if (!match) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		})
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	validate() {
		const { fields } = this

		if (!fields.filename) {
			return false
		} else if (!fields.rows || !fields.rows.length) {
			return false
		} else if (!fields.matched_columns || !fields.matched_columns.length) {
			return false
		} else {
			return true
		}
	}

	updateColumnMatch(defId, columnName) {
		let matchedColumns = cloneDeep(this.get('matched_columns'))

		// look for an existing matched column
		// if it exists, update it. if not, add a new one
		const existingIndex = matchedColumns.findIndex((m) => m.def_id === defId)

		if (existingIndex > -1) {
			if (columnName) {
				// column selection was not set to the blank "null" option
				matchedColumns[existingIndex].column = columnName
			} else {
				// column was set to blank option, clear it from matched columns
				matchedColumns.splice(existingIndex, 1)
			}
		} else {
			if (columnName) {
				matchedColumns.push({
					column: columnName,
					def_id: defId,
				})
			}
		}

		this.set({ matched_columns: matchedColumns })
	}

	get saveActionText() {
		return this.id() ? SAVE_TEXT : CREATE_TEXT
	}

	fetchErrors() {
		try {
			const response = postgrest.fetchAll(
				API.PURCHASE_UPLOAD_FAILURES.PATH,
				this.id(),
				'sheet_id',
				'error_message,product_number,row',
			)
			return response
		} catch (error) {
			throw error
		}
	}

	async create(classifierRefsToIds) {
		const { fields } = this
		const rowData = []
		fields.rows.forEach((row) => {
			let data = {}
			ProductIdentifyColumn.forEach((item) => {
				const matchedColumn = this.get('matched_columns').find(
					(col) => col.def_id === classifierRefsToIds[item],
				)
				if (matchedColumn) {
					data[item] = row[matchedColumn.column]
						? row[matchedColumn.column]
						: [{ val: null, link: null }]
				} else {
					data[item] = [{ val: null, link: null }]
				}
			})
			const count = rowData.filter((item) => {
				let f = true
				Object.keys(item).forEach((key) => {
					if (item[key][0].val !== data[key][0].val) {
						f = false
					}
				})
				return f
			}).length
			if (count === 0) rowData.push(data)
		})

		const payload = {
			rows: rowData,
			...(fields.gps_sheet_id && {
				gps_sheet_id: fields.gps_sheet_id,
			}),
		}

		//get unique rows
		let uniqueCount = 0
		const productNumbers = new Map()
		rowData.forEach((item, index, self) => {
			const product_number = item.product_number[0].val
				? item.product_number[0].val.toString()
				: ''
			if (!productNumbers.has(product_number)) {
				productNumbers.set(product_number, true)
				uniqueCount++
			}
		})

		try {
			const response = await postgrest.create(this.endpoint, payload)
			response.uniqueCount = uniqueCount
			return new this.constructor(response)
		} catch (error) {
			throw error
		}
	}

	async identifyProducts() {
		const query = {
			sheet_id: 'eq.' + this.get('id'),
		}
		const response = await postgrest.fetchWithCustomQuery(
			IDENTIFY_ENDPOINT,
			query,
		)
		this.identifyProducts = response
		return this
	}
}
