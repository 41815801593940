import { cloneDeep } from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Model } from 'models/base'
import BasicTextField from 'components/form/BasicTextField'

const PASSWORD_MIN_LENGTH = 6

class SignupAccount extends Component {
	state = {
		fields: {
			account_name: this.props.invite.get('invitee_account_name'),
			invitee_password: '',
			invitee_first_name: this.props.invite.get('invitee_first_name'),
			invitee_last_name: this.props.invite.get('invitee_last_name'),
		},
	}

	// -- event handlers
	handleTextFieldUpdate = this.handleTextFieldUpdate.bind(this)
	handleFieldsUpdate = this.handleFieldsUpdate.bind(this)
	handleCreateClick = this.handleCreateClick.bind(this)

	// @todo better password validation
	validate() {
		const { fields } = this.state

		if (fields.invitee_password.length < PASSWORD_MIN_LENGTH) {
			return false
		} else if (!fields.account_name) {
			return false
		} else if (!fields.invitee_first_name) {
			return false
		} else if (!fields.invitee_last_name) {
			return false
		} else {
			return true
		}
	}

	render() {
		const { invite } = this.props
		const { fields } = this.state
		const isValid = this.validate()
		const email = invite.get('invitee_email')

		return (
			<div>
				<header>
					<Typography variant="h5" component="h1" gutterBottom>
						Welcome, {fields.invitee_first_name}!
					</Typography>
					<Typography variant="body1" color="textSecondary">
						{fields.account_name} &bull; {email}
					</Typography>
				</header>

				<div className="space-top space-bottom">
					<Typography variant="body1">
						You have been invited to create a Relatable account for your
						company, {fields.account_name}. All you need to do is create a
						password and log in to start using Relatable!
					</Typography>
				</div>

				<div>
					<BasicTextField
						label="Account name"
						name="account_name"
						defaultValue={fields.account_name}
						handleUpdate={this.handleTextFieldUpdate}
						fullWidth
						margin="normal"
					/>
				</div>

				<div>
					<BasicTextField
						label="First name"
						name="invitee_first_name"
						defaultValue={fields.invitee_first_name}
						handleUpdate={this.handleTextFieldUpdate}
						fullWidth
						margin="normal"
					/>
				</div>

				<div>
					<BasicTextField
						label="Last name"
						name="invitee_last_name"
						defaultValue={fields.invitee_last_name}
						handleUpdate={this.handleTextFieldUpdate}
						fullWidth
						margin="normal"
					/>
				</div>

				<div>
					<BasicTextField
						label="Create a password"
						name="invitee_password"
						type="password"
						handleUpdate={this.handleTextFieldUpdate}
						fullWidth
						margin="normal"
					/>
				</div>

				<Typography variant="body1" color="textSecondary">
					Password must be at least 6 characters
				</Typography>

				<div className="space-top">
					<Button
						variant="contained"
						color="primary"
						onClick={this.handleCreateClick}
						disabled={!isValid}
					>
						Create Account
					</Button>
				</div>
			</div>
		)
	}

	handleTextFieldUpdate(name, value) {
		this.handleFieldsUpdate({ [name]: value })
	}

	handleFieldsUpdate(newFields) {
		let fields = cloneDeep(this.state.fields)
		fields = Object.assign(fields, newFields)
		this.setState({ fields })
	}

	handleCreateClick() {
		this.props.handleSignup(this.state.fields)
	}
}

SignupAccount.propTypes = {
	invite: PropTypes.instanceOf(Model).isRequired,
	handleSignup: PropTypes.func.isRequired,
}

export default SignupAccount
