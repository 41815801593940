import { isEmpty } from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { routerActions } from 'connected-react-router'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { withStyles } from '@mui/styles'
import { ViewContainer } from 'containers/view'
import { Model } from 'models/base'
import OverlayLoader from 'components/loader/OverlayLoader'
import BasicLoader from 'components/loader/BasicLoader'
import SignupAccount from 'components/signup/SignupAccount'
import SignupUser from 'components/signup/SignupUser'
import { RELATABLE, TEAM } from 'data/invite-types'
import { actions as sessionActions } from 'state/modules/session'
import { actions as inviteActions } from 'state/modules/invites'
import ROUTES from 'routes'
import { publicViewStyles } from 'styles'
import logo from 'assets/logo_relatable.svg'
import { withRouter } from '../../hocs/WithRouter'

const mapStateToProps = (state) => ({
	invite: state.invites.currentModel,
	isFetching: state.invites.isFetching,
	fetchError: state.invites.fetchError,
})

const mapDispatchToProps = (dispatch) => ({
	navigateTo: (path) => dispatch(routerActions.push(path)),
	createSession: (email, password) =>
		dispatch(sessionActions.create(email, password)),
	fetchInvite: (id) => dispatch(inviteActions.fetch(id)),
	acceptInvite: (payload) => dispatch(inviteActions.acceptInvite(payload)),
})

const styles = (theme) => ({
	...publicViewStyles(theme),
})

class SignupView extends Component {
	constructor(props) {
		super(props)

		this.handleSignup = this.handleSignup.bind(this)
	}

	componentDidMount() {
		this.props.fetchInvite(this.props.params.id).then((invite) => {
			// if (invite.invite_type === RELATABLE) {
			// 	this.props.navigateTo(ROUTES.SIGNUP.ACCOUNT)
			// } else if (invite.invite_type === TEAM) {
			// 	this.props.navigateTo(ROUTES.SIGNUP.USER)
			// }
		})
	}

	render() {
		const { isFetching, invite, fetchError, classes } = this.props
		let component = null

		if (fetchError) {
			return (
				<ViewContainer>
					<Typography variant="h5" className="centered">
						Error loading invite.
					</Typography>
				</ViewContainer>
			)
		}

		if (isFetching) {
			return (
				<ViewContainer>
					<BasicLoader />
				</ViewContainer>
			)
		} else if (isEmpty(invite)) {
			return <p>cannot find invite</p>
		}

		if (invite.get('invite_type') === RELATABLE) {
			component = (
				<SignupAccount invite={invite} handleSignup={this.handleSignup} />
			)
		} else if (invite.get('invite_type') === TEAM) {
			component = (
				<SignupUser invite={invite} handleSignup={this.handleSignup} />
			)
		}

		return (
			<ViewContainer>
				<img src={logo} className={classes.logo} alt="Relatable" />

				<div className={classes.form}>
					<OverlayLoader loading={isFetching} />
					{component}
					<div className="space-top-large">
						<Typography variant="subtitle2">
							Something wrong?
							<Button color="primary" href="mailto:support@relatable.io">
								Contact Us
							</Button>
						</Typography>
					</div>
				</div>
			</ViewContainer>
		)
	}

	handleInputUpdate(name, value) {
		this.setState({ passwordInput: value })
	}

	handleSignup(fields) {
		let payload = Object.assign(fields, {
			verify_id: this.props.params.id,
		})

		this.props.acceptInvite(payload).then(() => {
			this.props.navigateTo(ROUTES.ROOT)
		})
	}
}

SignupView.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
	// -- router props
	params: PropTypes.object.isRequired,
	// -- state props
	invite: PropTypes.instanceOf(Model).isRequired,
	isFetching: PropTypes.bool,
	fetchError: PropTypes.object,
	// -- dispatch props
	navigateTo: PropTypes.func.isRequired,
	createSession: PropTypes.func.isRequired,
	fetchInvite: PropTypes.func.isRequired,
	acceptInvite: PropTypes.func.isRequired,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles)(withRouter(SignupView)))
