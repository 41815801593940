/**
 * GO service
 *
 * @see superagent http://visionmedia.github.io/superagent/
 */

import request from 'superagent'
import { store } from 'state'
// import { APIError } from 'errors/api'

const API_BASE_URL = window.env.GO_ENDPOINT

// @todo update
const HEADERS_DEFAULT = {
	Accept: 'application/json',
	'Content-Type': 'application/json'
	// 'Origin': window.env.ORIGIN, // @todo
	// 'Access-Control-Request-Headers': 'Content-Type', // @todo
}
const AUTH_HEADER_PRE = 'Bearer '

// const FETCH_OPTIONS = { mode: 'cors' } // @todo

export function createUrl(endpoint) {
	return `${API_BASE_URL}${endpoint}`
}

export function createAuthHeader(needsAuth) {
	return needsAuth
		? `${AUTH_HEADER_PRE}${store.getState().session.credentials.token}`
		: null
}

export function fetch(endpoint, id) {
	return new Promise((resolve, reject) => {
		request
			.get(createUrl(endpoint) + `/${id}`)
			.set('Authorization', createAuthHeader(true))
			.set(HEADERS_DEFAULT)
			// .withCredentials() // @todo for 'cors'?
			.end((error, response) => {
				if (response.ok) {
					if (response.status === 200) {
						resolve(response.body || {}) // @todo
					} else {
						reject() // @todo
					}
				} else {
					reject() // @todo
				}
			})
	})
}

export function fetchAll(endpoint, optionalParams) {
	return new Promise((resolve, reject) => {
		request
			.get(createUrl(endpoint))
			.set('Authorization', createAuthHeader(true))
			.set(HEADERS_DEFAULT)
			.query(optionalParams)
			// .withCredentials() // @todo for 'cors'?
			.end((error, response) => {
				if (response && response.ok) {
					if (response.status === 200) {
						resolve(response.body)
					} else {
						reject() // @todo
					}
				} else {
					reject() // @todo
				}
			})
	})
}

export function fetchPage(endpoint, page, perPage, optionalParams) {
	let queryParams = {
		page: page + 1, // @todo why do things start at page 1??
		'per-page': perPage,
		...optionalParams
	}

	return new Promise((resolve, reject) => {
		request
			.get(createUrl(endpoint))
			.set('Authorization', createAuthHeader(true))
			.set('Access-Control-Allow-Origin')
			.set(HEADERS_DEFAULT)
			.query(queryParams)
			// .withCredentials() // @todo for 'cors'?
			.end((error, response) => {
				if (response.ok) {
					if (response.status === 200) {
						resolve(response.body || {}) // @todo
					} else {
						reject() // @todo
					}
				} else {
					reject() // @todo
				}
			})
	})
}

export function create(endpoint, payload) {
	return new Promise((resolve, reject) => {
		request
			.post(createUrl(endpoint))
			.set('Authorization', createAuthHeader(true))
			.set(HEADERS_DEFAULT)
			.send(JSON.stringify(payload))
			// .withCredentials() // @todo for 'cors'?
			.end((error, response) => {
				if (response.ok) {
					if (response.status === 200) {
						resolve(response.body || {}) // @todo
					} else {
						reject() // @todo
					}
				} else {
					reject() // @todo
				}
			})
	})
}

export function save(endpoint, id, payload) {
	return new Promise((resolve, reject) => {
		request
			.put(createUrl(endpoint) + `/${id}`)
			.set('Authorization', createAuthHeader(true))
			.set(HEADERS_DEFAULT)
			.send(JSON.stringify(payload))
			// .withCredentials() // @todo for 'cors'?
			.end((error, response) => {
				if (response.ok) {
					if (response.status === 200) {
						resolve(response.body || {}) // @todo
					} else {
						reject() // @todo
					}
				} else {
					reject() // @todo
				}
			})
	})
}

export function destroy(endpoint, id) {
	return new Promise((resolve, reject) => {
		request
			.delete(createUrl(endpoint) + `/${id}`)
			.set('Authorization', createAuthHeader(true))
			.set(HEADERS_DEFAULT)
			// .withCredentials() // @todo for 'cors'?
			.end((error, response) => {
				if (response && response.ok) {
					if (response.status === 200) {
						resolve(response.body || {}) // @todo
					} else {
						reject() // @todo
					}
				} else {
					reject() // @todo
				}
			})
	})
}
