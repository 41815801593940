import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Typography from '@mui/material/Typography'
import { ViewContainer } from 'containers/view'
import { BasicLoader } from 'components/loader'
import { actions as sessionActions } from 'state/modules/session'

const mapDispatchToProps = (dispatch) => ({
	logout: () => dispatch(sessionActions.logout()),
})

export class LogoutView extends Component {
	static propTypes = {
		// -- dispatch props
		logout: PropTypes.func.isRequired,
	}

	componentDidMount() {
		this.props.logout()
	}

	render() {
		return (
			<ViewContainer>
				<div className="centered space-top-large">
					<BasicLoader />
					<Typography variant="body2">logging out...</Typography>
				</div>
			</ViewContainer>
		)
	}
}

export default connect(null, mapDispatchToProps)(LogoutView)
