/**
 * Mixpanel Analytics Tracking
 *
 * @see https://developer.mixpanel.com/docs
 */

import mixpanel from 'mixpanel-browser'

export function initAnalytics(id) {
	if (!id) {
		console.error('Analytics init missing Mixpanel ID')
	} else {
		mixpanel.init(id)
	}
}

export function registerSuperProps(props) {
	mixpanel.register(props)
}

/**
 * @see https://help.mixpanel.com/hc/en-us/articles/115004497803
 */
export function aliasUser(id) {
	mixpanel.alias(id)
}

export function identifyUser(id) {
	mixpanel.identify(id)
}

export function trackEvent(value, props) {
	mixpanel.track(value, props)
}

/**
 * @see https://mixpanel.com/help/reference/javascript-full-api-reference#mixpanel.people.set
 */
export function trackUserProps(props) {
	mixpanel.people.set(props)
}
