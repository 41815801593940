/**
 * Role Groups
 */

import * as ROLES from './roles'

// @todo these **WILL** map to role_group ids on the server
export const SUPER_ADMIN = 'super_admin'
export const ADMIN = 'admin'
export const PRODUCT_CATALOG_MANAGER = 'product_catalog_manager'
export const PRODUCT_CATALOG_WORKER = 'product_catalog_worker'
export const PRODUCT_CATALOG_USER = 'product_catalog_user'

export const DATA_MATCHES = {
	admin: {
		matchRequired: false
	},
	supply_chain: {
		matchRequired: false
	},
	corporate: {
		matchRequired: false
	},
	market_president: {
		matchRequired: true,
		requirements: {
			mp: false,
			facility: false
		},
		field: 'mp',
		mapped: 'mps'
	},
	regional_vice_president: {
		matchRequired: true,
		requirements: {
			mp: true,
			facility: false
		},
		field: 'rvp',
		mapped: 'rvps'
	},
	administrator_ceo: {
		matchRequired: true,
		requirements: {
			mp: false,
			facility: true
		},
		field: 'ceo',
		mapped: 'ceo'
	},
	material_manager: {
		matchRequired: true,
		requirements: {
			mp: false,
			facility: true
		},
		field: 'ceo',
		mapped: 'ceo',
		identity: 'administrator_ceo'
	},
	physician: {
		matchRequired: true,
		requirements: {
			mp: false,
			facility: true
		},
		field: 'physician',
		mapped: 'physician'
	}
}

// To present type as string in UI
export const map = {
	[SUPER_ADMIN]: {
		displayName: 'Super Admin',
		description: 'All capabilities.',
		roles: [ROLES.SUPER_ADMIN]
	},
	[ADMIN]: {
		displayName: 'Admin',
		description: 'Full account and data management capabilities.',
		roles: [
			// +Account Management
			ROLES.ACCOUNT_MANAGER,
			ROLES.INVITE_MANAGER,
			// +Team Management
			// +Member Management
			// +Profile Management
			ROLES.USER_MANAGER,
			// +Taxonomy Management
			ROLES.TAXONOMY_MANAGER,
			// +Product Data Management
			ROLES.PRODUCT_MANAGER,
			ROLES.PRODUCT_SHEET_MANAGER,
			// +Purchase Data Management
			ROLES.PURCHASE_MANAGER,
			ROLES.PURCHASE_SHEET_MANAGER,
			// +Product Relationship Definition Management
			ROLES.RELATOR_MANAGER,
			// +RFPI Management
			ROLES.RFPI_ISSUER
		]
	},
	[PRODUCT_CATALOG_MANAGER]: {
		displayName: 'Product Data Catalog Manager',
		description: 'Data management capabilities.',
		roles: [
			// +Taxonomy Management
			ROLES.TAXONOMY_MANAGER,
			// +Product Data Management
			ROLES.PRODUCT_MANAGER,
			ROLES.PRODUCT_SHEET_MANAGER,
			// +Purchase Data Management
			ROLES.PURCHASE_MANAGER,
			ROLES.PURCHASE_SHEET_MANAGER,
			// +Product Relationship Definition Management
			ROLES.RELATOR_MANAGER,
			// +Base
			ROLES.USER_READER,
			ROLES.ACCOUNT_READER,
			// +RFPI Management
			ROLES.RFPI_ISSUER
		]
	},
	[PRODUCT_CATALOG_WORKER]: {
		displayName: 'Product Data Catalog Worker',
		description: 'Product and Purchase Data Management capabilities only.',
		roles: [
			// +Product Data Management
			ROLES.PRODUCT_MANAGER,
			ROLES.PRODUCT_SHEET_MANAGER,
			// +Purchase Data Management
			ROLES.PURCHASE_MANAGER,
			ROLES.PURCHASE_SHEET_MANAGER,
			// +Base
			ROLES.USER_READER,
			ROLES.ACCOUNT_READER,
			ROLES.TAXONOMY_READER,
			// +RFPI Management
			ROLES.RFPI_ISSUER
		]
	},
	[PRODUCT_CATALOG_USER]: {
		displayName: 'Product Data Catalog User',
		description: 'Product Data readability capabilities only.',
		roles: [
			// +Product Data Readability
			ROLES.PRODUCT_READER,
			ROLES.PURCHASE_READER,
			// +Base
			ROLES.USER_READER,
			ROLES.ACCOUNT_READER,
			ROLES.TAXONOMY_READER
		]
	}
}

// For use in dropdown fields, etc
export const list = [
	{ value: ADMIN, text: map[ADMIN].displayName },
	{
		value: PRODUCT_CATALOG_MANAGER,
		text: map[PRODUCT_CATALOG_MANAGER].displayName
	},
	{
		value: PRODUCT_CATALOG_WORKER,
		text: map[PRODUCT_CATALOG_WORKER].displayName
	},
	{ value: PRODUCT_CATALOG_USER, text: map[PRODUCT_CATALOG_USER].displayName }
]
