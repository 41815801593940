import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { actions as modalActions } from 'state/modules/modals'

const styles = (theme) => ({
	modals: {
		// --
	},
	modal: {
		[theme.breakpoints.down('sm')]: {
			width: '100%!important',
			height: '100%!important',
			margin: '0px!important',
			maxWidth: '100%!important',
			maxHeight: 'none!important',
			borderRadius: '0px!important',
		},
	},
	dialogContent: {},
})

const mapStateToProps = (state) => ({
	modals: state.modals.items,
})

const mapDispatchToProps = (dispatch) => ({
	closeModal: (obj) => dispatch(modalActions.closeModal(obj)),
})

class ModalContainer extends React.Component {
	render() {
		const { modals, classes, closeModal } = this.props

		return (
			<div className={classes.modals}>
				{modals.map((modal, index) => (
					<MobileModal
						key={modal.id}
						zIndex={index}
						modal={modal}
						handleClose={(modal) => closeModal(modal)}
						classes={classes}
					/>
				))}
			</div>
		)
	}
}

ModalContainer.propTypes = {
	classes: PropTypes.object.isRequired,
	modals: PropTypes.array.isRequired,
	closeModal: PropTypes.func.isRequired,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withStyles(styles)(ModalContainer))

// --

class Modal extends React.Component {
	// -- event handlers
	handleClose = this.handleClose.bind(this)
	handleConfirm = this.handleConfirm.bind(this)

	handleClose(event, reason) {
		const { modal, handleClose } = this.props
		if (
			(reason === 'backdropClick' && modal.disableBackdropClick) ||
			modal.disableEscapeKeyDown
		) {
			return false
		}

		if (modal.handleClose) {
			modal.handleClose()
			handleClose(modal)
		} else {
			handleClose(modal)
		}
	}

	handleConfirm() {
		const { modal, handleClose } = this.props

		if (modal.handleConfirm) {
			modal.handleConfirm()
			handleClose(modal)
		}
	}

	componentDidMount() {
		const { modal } = this.props
		if (modal.autoRun) {
			setTimeout(() => {
				modal.handleDownloadChart(this.handleClose)
			}, 1000)
		}
	}
	render() {
		const { modal, fullScreen, classes } = this.props

		if (modal.component) {
			return (
				<Dialog
					open={modal.open}
					fullScreen={fullScreen || modal.fullScreen}
					fullWidth={modal.fullWidth}
					maxWidth={modal.maxWidth || 'lg'}
					onClose={this.handleClose}
					className={classes.modal}
				>
					{modal.title && (
						<DialogTitle>
							<div className="flex space-between align-center">
								<div>{modal.title}</div>
								{modal.showCloseButton && (
									<div>
										<IconButton
											key="close"
											aria-label="Close"
											color="inherit"
											onClick={this.handleClose}
										>
											<CloseIcon />
										</IconButton>
									</div>
								)}
							</div>
						</DialogTitle>
					)}
					<DialogContent id="dialog-content" className={classes.dialogContent}>
						{modal.component}
					</DialogContent>
				</Dialog>
			)
		} else {
			return (
				<Dialog
					open={modal.open}
					fullScreen={fullScreen}
					fullWidth={modal.fullWidth}
					maxWidth={modal.maxWidth || 'lg'}
					onClose={this.handleClose}
					className={classes.modal}
				>
					{modal.title && (
						<DialogTitle>
							<div className="flex space-between align-center">
								<div>{modal.title}</div>
								{modal.showCloseButton && (
									<div>
										<IconButton
											key="close"
											aria-label="Close"
											color="inherit"
											onClick={this.handleClose}
										>
											<CloseIcon />
										</IconButton>
									</div>
								)}
							</div>
						</DialogTitle>
					)}
					<DialogContent>
						<DialogContentText>{modal.text}</DialogContentText>
					</DialogContent>
					<DialogActions>
						{modal.handleClose && (
							<Button onClick={this.handleClose}>
								{modal.cancelText || 'Cancel'}
							</Button>
						)}
						{modal.handleConfirm && (
							<Button onClick={this.handleConfirm} color="primary" autoFocus>
								{modal.confirmText || 'Agree'}
							</Button>
						)}
					</DialogActions>
				</Dialog>
			)
		}
	}
}

Modal.propTypes = {
	classes: PropTypes.object.isRequired,
	modal: PropTypes.object.isRequired,
	zIndex: PropTypes.number.isRequired,
	handleClose: PropTypes.func.isRequired,
}

const MobileModal = Modal
