import { cloneDeep, isNil, compact, uniq, sortBy } from 'lodash'
import { Model } from './base'
import { APPEND as MODE_APPEND } from 'data/product-sheet-upload-modes'
import { PRODUCT, PURCHASE, CUSTOM_PRICE } from 'data/classifier-types'
import {
	PRODUCT_SHEET_EXCLUDE,
	PRICE_SHEET_INCLUDE
} from 'data/classifier-lists'
import { config as API } from 'api'
import * as excelService from 'services/excel'
import { normalize } from 'utils/formatString'
import * as postgrest from 'services/postgrest'

// export type ProductSheetModelType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
// 	name: string
//	filename: string,
//	division_id: uuid,
//	product_type_id: uuid,
//	mode: string, // 'append' || 'replace'
//	rows: Array<ProductSheetRowType>,
//	matched_columns: Array<ProductSheetMatchedColumnType>
// }

const defaultFields = {
	name: '',
	filename: '',
	division_id: '',
	product_type_id: '',
	mode: MODE_APPEND,
	sub_mode: 'universal_only',
	create_new_products: false,
	rows: [],
	matched_columns: []
}

const SAVE_TEXT = 'Save Product Sheet'
const CREATE_TEXT = 'Upload Product Sheet'
const DELETE_ACTION_TEXT =
	'Are you sure you would like to delete this Product Sheet?'
const SAVE_ERROR_TEXT = 'Error saving Product Sheet'
const CREATE_ERROR_TEXT = 'Error uploading Product Sheet'
const DELETE_ERROR_TEXT = 'Error deleting Product Sheet'

export default class ProductSheetModel extends Model {
	static endpoint = API.PRODUCT_SHEETS.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	/**
	 * This will filter the Classifier Defs set to only include
	 * Defs that are needed for this sheet's Product Type
	 *
	 * Purchase Type Classifier Defs are removed
	 * Classifier Defs in predetermined exclusions list are removed
	 * Classifier Defs for other Product Types are removed
	 *
	 * @param {string} productTypeId
	 * @param {Collection} classifierDefs
	 * @param {Object} classifierIdsToRefs
	 */
	static filterClassifierDefs(
		productTypeId,
		classifierDefs,
		classifierIdsToRefs
	) {
		return classifierDefs.filter(cl => {
			if (cl.get('type') === PURCHASE) {
				return false
			} else if (
				PRODUCT_SHEET_EXCLUDE.indexOf(classifierIdsToRefs[cl.id()]) > -1
			) {
				return false
			} else if (
				cl.get('type') === PRODUCT &&
				cl.get('product_type_id') !== productTypeId
			) {
				return false
			} else {
				return true
			}
		})
	}

	constructor(fields = defaultFields) {
		super(fields)

		// these will be set in createFromWorksheet method
		this.worksheet = null
		this.sheetName = null
		this.rowCount = null
		this.columnCount = null
		this.columnNames = []
		this.exampleValues = []
		this.columnOptions = []
	}

	/**
	 * @see exceljs
	 *
	 * @param {object} worksheet
	 * @param {string} filename
	 */
	createFromWorksheet(worksheet, filename) {
		this.worksheet = worksheet
		// not to be confused with the "name" field which may be edited
		// this is and will always remain the name of the sheet tab
		this.sheetName = worksheet.name
		this.rowCount = worksheet.rowCount
		this.columnCount = worksheet.columnCount
		this.columnNames = excelService.getColumnNamesFromWorksheet(worksheet)

		// exampleValues and columnOptions are used in the match columns ui
		this.exampleValues = excelService.getExampleValuesFromWorksheet(worksheet)
		this.columnOptions = uniq(this.columnNames)
			.filter(c => !isNil(c))
			.map(c => ({ value: c, text: c }))
		// provide "unselect" / null option for row matching
		this.columnOptions = sortBy(this.columnOptions, option => option.value)
		this.columnOptions.unshift({
			value: null,
			text: 'Leave Unmatched',
			fontClass: 'font-italic secondary-color '
		})

		this.set({
			filename,
			name: this.sheetName,
			rows: excelService.getRowsFromWorksheet(worksheet)
		})
	}

	/**
	 * This will attempt to find an existing Product Type match
	 * based on this sheet's name, which is pulled from the sheet tab name.
	 *
	 * @param {Collection} productTypes
	 */
	setInitialMatchedProductType(productTypes) {
		const name = this.get('name')
		const cleanName = normalize(name)

		const match = productTypes.models.find(pt => {
			const cleanProductTypeName = normalize(pt.get('name'))
			if (cleanName === cleanProductTypeName) {
				return true
			} else {
				return false
			}
		})

		if (match) {
			this.set({
				division_id: match.get('division_id'),
				product_type_id: match.id()
			})
		}
	}

	/**
	 *
	 * This will attempt to find a Classifier Definition match for
	 * each Column Name in the sheet. This uses a simple string
	 * normalization before equivalency check.
	 *
	 * Classifier Defs should be filtered before using this method
	 * by using "filterClassifierDefs()"
	 *
	 * @todo implement smarter / "fuzzy" check
	 *
	 * @param {Collection} classifierDefs
	 */
	setInitialMatchedColumns(classifierDefs) {
		let columnNames = compact(this.columnNames) // compact removes nulls
		let matchedColumns = []

		// @todo optimize this loop?
		// find all initial matched columns using simple string comparison
		classifierDefs.models.forEach(cl => {
			const defId = cl.id()
			const cleanClassifier = normalize(cl.get('name'))
			columnNames.forEach(columnName => {
				const cleanColumnName = normalize(columnName)

				if (cleanColumnName === cleanClassifier) {
					matchedColumns.push({
						column: columnName,
						def_id: defId
					})
				}
			})
		})

		this.set({
			matched_columns: matchedColumns
		})
	}

	/**
	 * classifierDefs should be filtered via this models' filterClassifierDefs
	 * for use in this method
	 */
	validateRequiredClassifiers(classifierDefs) {
		const matchedColumns = this.get('matched_columns')
		return classifierDefs.filter(cl => {
			const isRequired = cl.get('required_val')
			let match = null

			if (isRequired) {
				match = matchedColumns.find(col => col.def_id === cl.id())

				if (!match) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		})
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	validate(universalOnly) {
		const { fields } = this

		if (!fields.name) {
			return false
		} else if (!fields.filename) {
			return false
		} else if (!fields.division_id && !universalOnly) {
			return false
		} else if (!fields.product_type_id && !universalOnly) {
			return false
		} else if (!fields.rows || !fields.rows.length) {
			return false
		} else if (!fields.matched_columns || !fields.matched_columns.length) {
			return false
		} else {
			return true
		}
	}

	updateColumnMatch(defId, columnName) {
		let matchedColumns = cloneDeep(this.get('matched_columns'))

		// look for an existing matched column
		// if it exists, update it. if not, add a new one
		const existingIndex = matchedColumns.findIndex(m => m.def_id === defId)

		if (existingIndex > -1) {
			if (columnName) {
				// column selection was not set to the blank "null" option
				matchedColumns[existingIndex].column = columnName
			} else {
				// column was set to blank option, clear it from matched columns
				matchedColumns.splice(existingIndex, 1)
			}
		} else {
			if (columnName) {
				matchedColumns.push({
					column: columnName,
					def_id: defId
				})
			}
		}

		this.set({ matched_columns: matchedColumns })
	}

	filterCustomPriceDefs(classifierDefs, classifierIdsToRefs) {
		return classifierDefs.filter(cl => {
			if (cl.get('type') === CUSTOM_PRICE) {
				return true
			} else if (
				PRICE_SHEET_INCLUDE.indexOf(classifierIdsToRefs[cl.id()]) > -1
			) {
				return true
			} else {
				return false
			}
		})
	}

	get saveActionText() {
		return this.id() ? SAVE_TEXT : CREATE_TEXT
	}

	fetchErrors() {
		try {
			const response = postgrest.fetchAll(
				API.PRODUCT_UPLOAD_FAILURES.PATH,
				this.id(),
				'sheet_id',
				'error_message,product_number,row'
			)
			return response
		} catch (error) {
			throw error
		}
	}
}
