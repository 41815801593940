import { cloneDeep, isNil, compact, uniq, sortBy } from 'lodash'
import { Model } from './base'
import { PURCHASE } from 'data/classifier-types'
import {
	PURCHASE_SHEET_EXCLUDE,
	PURCHASE_SHEET_INCLUDE_UNIVERSAL,
} from 'data/classifier-lists'
import { ID_PURCHASE_QTY, ID_QUANTITY_EACHES } from 'data/classifier-refs'
import { config as API } from 'api'
import * as excelService from 'services/excel'
import { normalize } from 'utils/formatString'
import * as postgrest from 'services/postgrest'

// export type ProductSheetModelType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
// 	name: string
//	filename: string,
//	rows: Array<ProductSheetRowType>,
//	matched_columns: Array<ProductSheetMatchedColumnType>
// 	is_hidden: int,
// 	is_deleted: int,
// 	is_archived: int,
// }

const defaultFields = {
	name: '',
	filename: '',
	rows: [],
	matched_columns: [],
}

const SAVE_TEXT = 'Save Purchase Sheet'
const CREATE_TEXT = 'Upload Purchase Sheet'
const DELETE_ACTION_TEXT =
	'Are you sure you would like to delete this Purchase Sheet?'
const SAVE_ERROR_TEXT = 'Error saving Purchase Sheet'
const CREATE_ERROR_TEXT = 'Error uploading Purchase Sheet'
const DELETE_ERROR_TEXT = 'Error deleting Purchase Sheet'

const ENDPOINT = API.PURCHASE_SHEETS.PATH
const FETCH_SELECT =
	'id,created_at,created_by,name,filename,stats,is_hidden, is_auto_segmented,rows, matched_columns'
export default class PurchaseSheetModel extends Model {
	static endpoint = API.PURCHASE_SHEETS.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	/**
	 * This will filter the Classifier Defs set to only include
	 * Purchase Classifiers + Product Number classifier (which is Universal)
	 *
	 * Classifier Defs in predetermined exclusions list are removed
	 *
	 * @param {string} productTypeId
	 * @param {Collection} classifierDefs
	 * @param {Object} classifierIdsToRefs
	 */
	static filterClassifierDefs(classifierDefs, classifierIdsToRefs) {
		return classifierDefs.filter((cl) => {
			if (cl.get('type') === PURCHASE) {
				return true
			} else if (
				PURCHASE_SHEET_INCLUDE_UNIVERSAL.includes(classifierIdsToRefs[cl.id()])
			) {
				return true
			} else if (
				PURCHASE_SHEET_EXCLUDE.indexOf(classifierIdsToRefs[cl.id()]) > -1
			) {
				return false
			} else {
				return false
			}
		})
	}

	constructor(fields = defaultFields) {
		super(fields)

		// these will be set in createFromWorksheet method
		this.worksheet = null
		this.sheetName = null
		this.rowCount = null
		this.columnCount = null
		this.columnNames = []
		this.exampleValues = []
		this.columnOptions = []
		this.excludedColumns = [] // Classifers Excluded from Match
	}

	/**
	 * @see exceljs
	 *
	 * @param {object} worksheet
	 * @param {string} filename
	 */
	createFromWorksheet(worksheet, filename) {
		this.worksheet = worksheet
		// not to be confused with the "name" field which may be edited
		// this is and will always remain the name of the sheet tab
		this.sheetName = worksheet.name
		this.rowCount = worksheet.rowCount
		this.columnCount = worksheet.columnCount
		this.columnNames = excelService.getColumnNamesFromWorksheet(worksheet)

		// exampleValues and columnOptions are used in the match columns ui
		this.exampleValues = excelService.getExampleValuesFromWorksheet(worksheet)
		this.columnOptions = uniq(this.columnNames)
			.filter((c) => !isNil(c))
			.map((c) => ({ value: c, text: c }))
		// provide "unselect" / null option for row matching
		this.columnOptions = sortBy(this.columnOptions, (option) => option.value)
		this.columnOptions.unshift({
			value: null,
			text: 'Leave Unmatched',
			fontClass: 'font-italic secondary-color ',
		})

		this.set({
			filename,
			name: this.sheetName,
			rows: excelService.getRowsFromWorksheet(worksheet),
		})
	}

	/**
	 *
	 * This will attempt to find a Classifier Definition match for
	 * each Column Name in the sheet. This uses a simple string
	 * normalization before equivalency check.
	 *
	 * Classifier Defs should be filtered before using this method
	 * by using "filterClassifierDefs()"
	 *
	 * @todo implement smarter / "fuzzy" check
	 *
	 * @param {Collection} classifierDefs
	 */
	setInitialMatchedColumns(classifierDefs, classifierRefsToIds) {
		let columnNames = compact(this.columnNames) // compact removes nulls
		let matchedColumns = []
		this.excludedColumns = []

		// @todo optimize this loop?
		// find all initial matched columns using simple string comparison
		classifierDefs.models.forEach((cl) => {
			const defId = cl.id()
			const cleanClassifier = normalize(cl.get('name'))

			columnNames.forEach((columnName) => {
				const cleanColumnName = normalize(columnName)

				if (cleanColumnName === cleanClassifier) {
					matchedColumns.push({
						column: columnName,
						def_id: defId,
					})
				}
			})
		})
		//disable and unmap one of QTY columns
		const qtyColsMapped = [ID_PURCHASE_QTY, ID_QUANTITY_EACHES].filter((el) =>
			matchedColumns.some((col) => col.def_id === classifierRefsToIds[el]),
		)
		if (qtyColsMapped.length === 2) {
			this.excludedColumns = [classifierRefsToIds[ID_QUANTITY_EACHES]]
		} else if (qtyColsMapped.length === 1) {
			const toExcludeColumn = [ID_PURCHASE_QTY, ID_QUANTITY_EACHES].find(
				(el) => !qtyColsMapped.includes(el),
			)
			this.excludedColumns = [classifierRefsToIds[toExcludeColumn]]
		}
		this.set({
			matched_columns: matchedColumns.filter(
				(col) => !this.excludedColumns.includes(col.def_id),
			),
		})
	}

	/**
	 * classifierDefs should be filtered via this models' filterClassifierDefs
	 * for use in this method
	 */
	validateRequiredClassifiers(classifierDefs) {
		const matchedColumns = this.get('matched_columns')

		return classifierDefs.filter((cl) => {
			const isRequired = cl.get('required_val')
			let match = null

			if (isRequired) {
				match = matchedColumns.find((col) => col.def_id === cl.id())

				if (!match) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		})
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	validate() {
		const { fields } = this

		if (!fields.filename) {
			return false
		} else if (!fields.rows || !fields.rows.length) {
			return false
		} else if (!fields.matched_columns || !fields.matched_columns.length) {
			return false
		} else {
			return true
		}
	}

	updateColumnMatch(
		defId,
		columnName,
		classifierIdsToRefs,
		classifierRefsToIds,
	) {
		let matchedColumns = cloneDeep(this.get('matched_columns'))
		let toExcludeColumn = null
		// look for an existing matched column
		// if it exists, update it. if not, add a new one
		const existingIndex = matchedColumns.findIndex((m) => m.def_id === defId)
		// If one of these classifiers(aka column) is mapped
		// Exclude/Disable the other one
		if (
			[ID_PURCHASE_QTY, ID_QUANTITY_EACHES].includes(classifierIdsToRefs[defId])
		) {
			toExcludeColumn = [ID_PURCHASE_QTY, ID_QUANTITY_EACHES].find(
				(el) => el !== classifierIdsToRefs[defId],
			)
		}

		if (existingIndex > -1) {
			if (columnName) {
				// column selection was not set to the blank "null" option
				matchedColumns[existingIndex].column = columnName
			} else {
				// column was set to blank option, clear it from matched columns
				matchedColumns.splice(existingIndex, 1)
				if (toExcludeColumn) {
					this.excludedColumns = this.excludedColumns.filter(
						(ec) => ec !== classifierRefsToIds[toExcludeColumn],
					)
				}
			}
		} else {
			if (columnName) {
				matchedColumns.push({
					column: columnName,
					def_id: defId,
				})
				if (toExcludeColumn) {
					this.excludedColumns = [
						...this.excludedColumns,
						classifierRefsToIds[toExcludeColumn],
					]
				}
			}
		}
		this.set({
			matched_columns: matchedColumns.filter(
				(col) => !this.excludedColumns.includes(col.def_id),
			),
		})
	}

	get saveActionText() {
		return this.id() ? SAVE_TEXT : CREATE_TEXT
	}

	fetchErrors() {
		try {
			const response = postgrest.fetchAll(
				API.PURCHASE_UPLOAD_FAILURES.PATH,
				this.id(),
				'sheet_id',
				'error_message,product_number,row',
			)
			return response
		} catch (error) {
			throw error
		}
	}

	async fetch(id) {
		try {
			const response = await postgrest.fetch(ENDPOINT, id, 'id', FETCH_SELECT)
			return new this.constructor(response)
		} catch (error) {
			throw error
		}
	}
}
