import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { routerActions } from 'connected-react-router'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import ArrowLeftIcon from '@mui/icons-material/ArrowBack'
import OverlayLoader from 'components/loader/OverlayLoader'
import { ViewContainer } from 'containers/view'
import { actions as alertActions } from 'state/modules/alerts'
import { PASSWORD_MIN_LENGTH, validatePassword } from 'utils/validation'
import {
	SUCCESS as ALERT_SUCCESS,
	ERROR as ALERT_ERROR,
} from 'data/alert-types'
import ROUTES from 'routes'
import { config as API } from 'api'
import * as postgrest from 'services/postgrest'
import { publicViewStyles } from 'styles'
import logo from 'assets/logo_relatable.svg'
import { withRouter } from '../../hocs/WithRouter'

/* payload
{
	"new_pass_confirm": "string",
	"new_pass": "string"
}
*/

const KEY_ENTER = 13
const ENDPOINT = API.RESET_PASSWORD_USING_TOKEN.PATH

// form fields
const NEW_PASS = 'new_pass'
const NEW_PASS_CONFIRM = 'new_pass_confirm'

const INITIAL_STATE = {
	isSaving: false,
	hasInput: false,
	validationError: null,
	fields: {
		[NEW_PASS]: '',
		[NEW_PASS_CONFIRM]: '',
	},
}

const mapDispatchToProps = (dispatch) => ({
	replacePath: (path) => dispatch(routerActions.replace(path)),
	showAlert: (obj) => dispatch(alertActions.showAlert(obj)),
})

const styles = (theme) => ({
	...publicViewStyles(theme),
})

class LoginResetPasswordView extends React.Component {
	// -- state
	state = INITIAL_STATE

	// -- event handlers
	handleKeyPress = this.handleKeyPress.bind(this)
	handleInputUpdate = this.handleInputUpdate.bind(this)
	handleResetPasswordClick = this.handleResetPasswordClick.bind(this)

	validate(fields) {
		if (!fields[NEW_PASS] || !fields[NEW_PASS_CONFIRM]) {
			return 'Please fill in all fields'
		} else if (!validatePassword(fields[NEW_PASS])) {
			return 'Password is not valid'
		} else if (!validatePassword(fields[NEW_PASS_CONFIRM])) {
			return 'Password is not valid'
		} else if (fields[NEW_PASS] !== fields[NEW_PASS_CONFIRM]) {
			return 'Passwords do not match'
		} else {
			return null
		}
	}

	handleInputUpdate(event) {
		const { name, value } = event.target
		let fields = Object.assign({}, this.state.fields)
		fields[name] = value
		const validationError = this.validate(fields)
		this.setState({ fields, validationError, hasInput: true })
	}

	handleKeyPress(event) {
		const key = event.keyCode || event.charCode

		if (key === KEY_ENTER) {
			this.handleResetPasswordClick()
		}
	}

	handleResetPasswordClick() {
		const { fields } = this.state
		const validationError = this.validate(fields)

		if (validationError) {
			return
		}

		// --

		this.setState({ isSaving: true })

		const token = this.props.params.id
		const payload = { ...fields, token }

		postgrest
			.create(ENDPOINT, payload, false) // false for no auth needed
			.then((response) => {
				this.setState(INITIAL_STATE)
				this.props.showAlert({
					type: ALERT_SUCCESS,
					message: `Password has been updated`,
				})
				this.props.replacePath(ROUTES.ROOT)
			})
			.catch((error) => {
				this.setState({ isSaving: false })
				this.props.showAlert({
					type: ALERT_ERROR,
					message: 'Error resetting your password',
				})
			})
	}

	render() {
		const { classes } = this.props
		const { fields, validationError, isSaving, hasInput } = this.state

		return (
			<ViewContainer>
				<img src={logo} className={classes.logo} alt="Relatable" />

				<div className={classes.form}>
					<OverlayLoader loading={isSaving} />
					<div className="space-bottom">
						<Typography component="h1" variant="h5" gutterBottom>
							Reset your Password
						</Typography>
					</div>
					<TextField
						margin="dense"
						label="New Password"
						type="password"
						name={NEW_PASS}
						value={fields[NEW_PASS]}
						onChange={this.handleInputUpdate}
						onKeyUp={this.handleKeyPress}
						autoFocus
						fullWidth
					/>
					<TextField
						margin="dense"
						label="Confirm new Password"
						type="password"
						name={NEW_PASS_CONFIRM}
						value={fields[NEW_PASS_CONFIRM]}
						onChange={this.handleInputUpdate}
						onKeyUp={this.handleKeyPress}
						fullWidth
					/>
					{validationError && (
						<div className="space-top">
							<Typography variant="body1" color="secondary">
								{validationError}
							</Typography>
						</div>
					)}
					<div className="space-top">
						<Typography variant="body1" color="textSecondary">
							Password must be at least {PASSWORD_MIN_LENGTH} characters
						</Typography>
					</div>
					<div className="space-top">
						<Button
							style={{ width: '100%' }}
							variant="contained"
							color="primary"
							onClick={this.handleResetPasswordClick}
							disabled={!!validationError || isSaving || !hasInput}
						>
							Reset Password
						</Button>
					</div>

					<div className="space-top-large centered">
						<Button size="small" component={Link} to={ROUTES.ROOT}>
							<ArrowLeftIcon className="space-right-smallest" />
							Back to login
						</Button>
					</div>
				</div>
			</ViewContainer>
		)
	}
}

LoginResetPasswordView.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
	// -- dispatch props
	replacePath: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
}

export default connect(
	null,
	mapDispatchToProps,
)(withStyles(styles)(withRouter(LoginResetPasswordView)))
