import { Model } from 'models/base'
import { config as API } from 'api'
import { actions as alertActions } from './alerts'
import {
	SUCCESS as ALERT_SUCCESS,
	ERROR as ALERT_ERROR
} from 'data/alert-types'
import {
	trackEvent
	// trackUserProps,
	// aliasUser
} from 'services/analytics'
import * as postgrest from 'services/postgrest'

// const ENDPOINT = API.INVITES.PATH
const GET_INVITE_ENDPOINT = API.GET_INVITE.PATH
const ACCEPT_INVITE_ENDPOINT = API.ACCEPT_INVITE.PATH
const RESEND_INVITE_ENDPOINT = API.RESEND_INVITE.PATH
const MODEL_NAME = 'INVITES'
const MODULE_NAME = 'invites'

const initialState = {
	isFetching: false,
	hasFetched: false,
	fetchError: null,
	currentModel: new Model()
}

export const RESET = `${MODEL_NAME}_RESET`
export const REQUESTED = `${MODEL_NAME}_REQUESTED`
export const FETCHED = `${MODEL_NAME}_FETCHED`
export const RESENT = `${MODEL_NAME}_RESENT`
export const SAVED = `${MODEL_NAME}_SAVED`
export const FAILED = `${MODEL_NAME}_FAILED`

export const reset = () => ({ type: RESET })
export const request = () => ({ type: REQUESTED })
// receive can take FETCHED
export const receive = (action, fields) => ({ type: action, payload: fields })
export const fail = error => ({ type: FAILED, payload: error })

/**
 * @param {String} email
 */
const fetch = id => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.fetchAllWithPost(GET_INVITE_ENDPOINT, null, { verify_id: id }, false)
			.then(response => {
				dispatch(receive(FETCHED, response))
				return response
			})
			.catch(error => {
				dispatch(fail(error))
			})
	}
}

const acceptInvite = payload => {
	return (dispatch, getState) => {
		dispatch(request())

		const currentModel = getState()[MODULE_NAME].currentModel

		return postgrest
			.create(ACCEPT_INVITE_ENDPOINT, payload, false)
			.then(response => {
				// -- analytics
				// aliasUser(<user.id>)
				// trackUserProps({
				// 	$first_name: user.get('first_name'),
				// 	$last_name: user.get('last_name'),
				// 	$email: user.get('email'),
				// 	account_name: user.get('account_name'),
				// 	user_id: user.id()
				// })
				trackEvent('Completed User Signup', {
					verify_id: payload.verify_id,
					invitee_first_name: payload.invitee_first_name,
					invitee_last_name: payload.invitee_last_name,
					invitee_email: currentModel.get('invitee_email')
				})
				// --

				dispatch(
					alertActions.showAlert({
						message: 'Signup Complete! You may now log in.',
						type: ALERT_SUCCESS,
						timeout: 6000
					})
				)
			})
			.catch(error => {
				// -- analytics
				trackEvent('Failed User Signup', {
					error_message: error.message,
					...currentModel.fields
				})
				// --

				dispatch(
					alertActions.showAlert({
						message: error.message || 'Error accepting invite',
						type: ALERT_ERROR,
						timeout: 6000
					})
				)
			})
	}
}

const resend = id => {
	return (dispatch, getState) => {
		dispatch(request())

		return postgrest
			.create(RESEND_INVITE_ENDPOINT, { verify_id: id }, true)
			.then(response => {
				dispatch(receive(RESENT, response))
				dispatch(
					alertActions.showAlert({
						message: 'Successfully resent invitation',
						type: ALERT_SUCCESS
					})
				)
			})
			.catch(error => {
				dispatch(fail(error))
				dispatch(
					alertActions.showAlert({
						message: 'Error resending invitation',
						type: ALERT_ERROR
					})
				)
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		currentModel: new Model(payload),
		fetchError: null,
		isFetching: false,
		hasFetched: true
	}),
	[RESENT]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: false
	}),
	[SAVED]: (state, { payload }) => ({
		...state,
		currentModel: new Model(payload),
		fetchError: null,
		isFetching: false
	}),
	[FAILED]: (state, action) => ({
		...state,
		fetchError: action.payload,
		isFetching: false
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetch,
	resend,
	acceptInvite,
	reset
}
