const initialState = {
	showing: false,
	title: null,
	locked: false,
	showCornerX: false,
	widthPercentage: null,
	component: null
}

const DEFAULT_LOCKED = false
export const UPDATE_DRAWER = 'UPDATE_DRAWER'

export const show = ({
	showing = true,
	locked = DEFAULT_LOCKED,
	showCornerX,
	widthPercentage,
	title,
	component
}) => ({
	type: UPDATE_DRAWER,
	payload: {
		showing,
		locked,
		showCornerX,
		widthPercentage,
		title,
		component
	}
})

export const hide = (value = initialState) => ({
	type: UPDATE_DRAWER,
	payload: value
})

const ACTION_HANDLERS = {
	[UPDATE_DRAWER]: (state, action) => ({
		...state,
		...action.payload
	})
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	show,
	hide
}
