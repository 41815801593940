import { Collection } from './base'
import ALTAReportModel, {
	FETCH_SELECT,
	EMBED_PURCHASE_SHEET,
} from './ALTAReportModel'
import * as postgrest from 'services/postgrest'
import { getContentRangeTotal } from 'utils/headers'

export default class ALTAReportCollection extends Collection {
	constructor(data, totalResults) {
		super(data, ALTAReportModel, totalResults)
	}

	// -- api call overrides

	async fetchPage(page, perPage, filterIds = []) {
		const query = {
			select: `${FETCH_SELECT},${EMBED_PURCHASE_SHEET}`,
			order: 'created_at.desc',
		}
		if (filterIds.length) {
			query['id'] = `in.(${filterIds.map((item) => `"${item}"`).join(',')})`
		}
		try {
			const response = await postgrest.fetchPage(
				this.endpoint,
				page,
				perPage,
				query,
			)

			const totalResults = getContentRangeTotal(response.headers)

			return new this.constructor(response.body, totalResults)
		} catch (error) {
			throw error
		}
	}

	async fetchByIds(filterIds) {
		const ids = filterIds
			? filterIds.map((item) => `"${item}"`).join(',')
			: null
		const select = `${FETCH_SELECT},${EMBED_PURCHASE_SHEET}`
		try {
			const response = await postgrest.fetchList(
				this.endpoint,
				ids,
				'id',
				select,
			)

			const totalResults = getContentRangeTotal(response.headers)

			return new this.constructor(response, totalResults)
		} catch (error) {
			throw error
		}
	}
}
