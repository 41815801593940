import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import ROUTES from 'routes'
import { withStyles } from '@mui/styles'
import { Typography, Grid } from '@mui/material'
// import classes from '*.module.css'
import imgLogo from '../../assets/logo_relatable.svg'
import GridIcon from '@mui/icons-material/ViewQuilt'
import LineChartIcon from '@mui/icons-material/MultilineChart'
import BarChartIcon from '@mui/icons-material/BarChart'
import { blue, deepPurple, indigo } from '@mui/material/colors'

const mailTo = 'support@relatable.io'

const linkList = [
	{
		title: 'I want to research products.',
		path: ROUTES.PRODUCTS.ROOT,
		icon: GridIcon,
		color: blue[500],
	},
	{
		title: 'I want to view my usage analytics.',
		path: ROUTES.ALTA.ROOT,
		icon: LineChartIcon,
		color: deepPurple[500],
	},
	{
		title: 'I want to view my recommendations.',
		path: ROUTES.GPS.ROOT,
		icon: BarChartIcon,
		color: indigo[500],
		gps: true,
	},
]

const styles = (theme) => ({
	mainContainer: {
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex: 1300,
		position: 'fixed',
		touchAction: 'none',
		backgroundColor: 'rgba(0, 0, 0, 0.5)',
	},
	viewModal: {
		display: 'flex',
		flexDirection: 'column',
		maxWidth: 1300,
		padding: `60px 90px`,
		boxShadow:
			'0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
		borderRadius: 4,
		backgroundColor: '#fff',

		[theme.breakpoints.down('sm')]: {
			height: '100%!important',
			width: '100%!important',
			padding: `20px 40px!important`,
		},
	},
	logo: {
		alignSelf: 'flex-start',
		cursor: 'pointer',
	},
	title: {
		textAlign: 'center',
		marginTop: '1em!important',
		marginBottom: '40px!important',
		maxWidth: '800px',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5em!important',
		},
	},

	cardContainer: {
		width: '100%!important',
		marginBottom: '50px!important',
		marginTop: '0!important',
		marginLeft: '0!important',
		maxHeight: '300px!important',
		overflow: 'auto',
		[theme.breakpoints.down('sm')]: {
			marginTop: '10px!important',
			marginBottom: '30px!important',
		},
	},
	gridCard: {
		display: 'flex',
		padding: '30px!important',
		justifyContent: 'center',
		[theme.breakpoints.down('md')]: {
			padding: '5px!important',
		},
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		alignContent: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		maxWidth: 200,
		marginBottom: 20,
		padding: '10px 10px',
		border: 'solid 2px #eee',
		borderRadius: 10,
		textDecoration: 'none',
		boxShadow:
			'0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
		cursor: 'pointer',
	},
	cardText: {
		textAlign: 'center',
		padding: '0 10px 0px 10px',
	},
})

class InterstitialView extends Component {
	render() {
		const { classes, user } = this.props
		const roleinfo = user.roleGroups.find((role) => role.id === user.roleGroup)
		const roleName = roleinfo ? roleinfo.system_name : ''
		let org_name = 'organization'
		if (window.env.ORG_NAME) {
			org_name = window.env.ORG_NAME + ' ' + org_name
		}

		return (
			<div className={classes.mainContainer}>
				<div className={classes.viewModal}>
					{user.roleGroup ? (
						<Link className={classes.logo} to={ROUTES.ALTA.ROOT}>
							<img src={imgLogo} alt="logo" style={{ height: 60 }} />
						</Link>
					) : (
						<div className={classes.logo}>
							<img src={imgLogo} alt="logo" style={{ height: 60 }} />
						</div>
					)}

					<Typography
						className={classes.title}
						variant={user.roleGroup ? 'h4' : 'h5'}
						color="textPrimary"
						gutterBottom
					>
						{user.roleGroup ? (
							<>
								Welcome to your Relatable account. <br />
								What would you like to accomplish today?
							</>
						) : (
							<>
								Congrats! You have successfully registered with Relatable.{' '}
								<br />
								Please standby while we assign the appropriate experience to
								match your role in the {org_name}.
								<br />
								You will receive an email once complete. Thank you.
							</>
						)}
					</Typography>
					{user.roleGroup && (
						<Grid
							className={classes.cardContainer}
							container
							direction="row"
							justifyContent="center"
							alignItems="center"
							spacing={8}
						>
							{linkList.map((item, index) =>
								(roleName === 'physician' ||
									roleName === 'market_president' ||
									roleName === 'regional_vice_president' ||
									roleName === 'administrator_ceo' ||
									roleName === 'material_manager' ||
									roleName === 'corporate') &&
								item.gps ? null : (
									<Grid
										className={classes.gridCard}
										item
										xs={12}
										md={4}
										key={index}
									>
										<Link to={item.path} className={classes.card}>
											<item.icon style={{ fontSize: 60, color: item.color }} />
											<Typography
												className={classes.cardText}
												variant="body2"
												color="textPrimary"
												gutterBottom
											>
												{item.title}
											</Typography>
										</Link>
									</Grid>
								),
							)}
						</Grid>
					)}

					<Typography
						variant="body1"
						color="textSecondary"
						style={{ textAlign: 'center' }}
					>
						I would like to <a href={`mailto:${mailTo}`}>speak to someone</a>{' '}
						that can help me solve an issue.
					</Typography>
				</div>
			</div>
		)
	}
}

export default withStyles(styles)(InterstitialView)
