import { cloneDeep, isNil } from 'lodash'
import { actions as productFilterOptionsActions } from './product-filter-options'
import { actions as productActions } from './products'
import { COMPANY as COMPANY_SORT_TYPE, ALL } from 'data/product-sort-types'

export const MODULE_NAME = 'productFilters'
const COLLECTION_NAME = 'PRODUCT_FILTERS'
const MODEL_NAME = 'PRODUCT_FILTER'

const initialState = {
	hasFetched: false,
	isFetching: false,
	fetchError: null,
	filters: [],
	sortType: COMPANY_SORT_TYPE,
	searchQuery: '',
	existingUse: ALL
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const UPDATED_SORT_TYPE = `${COLLECTION_NAME}_SORT_TYPE_UPDATED`
const UPDATED_SEARCH_QUERY = `${COLLECTION_NAME}_SEARCH_QUERY_UPDATED`
const ADDED_FILTER = `${MODEL_NAME}_ADDED`
const UPDATED_FILTER = `${MODEL_NAME}_UPDATED`
const REMOVED_FILTER = `${MODEL_NAME}_REMOVED`
const SET_FILTERS = `${MODEL_NAME}_SET`
const UPDATED_EXISTING_USE_FILTER = `${MODEL_NAME}_EXISTING_USE_UPDATED`

const reset = () => ({ type: RESET })
// receive can take ADDED_FILTER, REMOVED_FILTER
const receive = (action, results) => ({
	type: action,
	payload: results
})

const reloadFilters = () => {
	return (dispatch, getState) => {
		dispatch(productFilterOptionsActions.fetch())
		dispatch(productActions.fetchPage(0))
	}
}

const updateSortType = sortType => {
	return (dispatch, getState) => {
		if (sortType !== getState()[MODULE_NAME].sortType) {
			dispatch(receive(UPDATED_SORT_TYPE, sortType))
			dispatch(productFilterOptionsActions.fetch())
			dispatch(productActions.fetchPage(0))
		}
	}
}
const updateExistingUseFilter = val => {
	return (dispatch, getState) => {
		dispatch(receive(UPDATED_EXISTING_USE_FILTER, val))
		dispatch(productFilterOptionsActions.fetch())
		dispatch(productActions.fetchPage(0))
	}
}

const updateSearchQuery = (query, excludeRelatedProductId) => {
	return (dispatch, getState) => {
		dispatch(receive(UPDATED_SEARCH_QUERY, query))
		dispatch(productFilterOptionsActions.fetch())
		dispatch(productActions.fetchPage(0, excludeRelatedProductId))
	}
}

/**
 * Filter objects are designed as follows:
 * @param {String} def_id | A Classifier Def Id
 * @param {String|Number} val | A value
 * @param {String|Number} from | A value
 * @param {String|Number} to | A value
 *
 * @note if either a "from" or "to" value is present with the other bound omitted,
 * it will be treated as unbounded on the missing bound.
 *
 * @todo figure out if a "val" is present, will it take precedence over "from" / "to"?
 */
const updateFilters = (def_id, val, from, to, selected) => {
	const isRange = !!(from || to)

	let filter = { def_id }

	if (isRange) {
		if (!isNil(from)) {
			filter.from = from
		}
		if (!isNil(to)) {
			filter.to = to
		}
	}
	filter.val = isNil(val) ? null : val

	return (dispatch, getState) => {
		if (isRange) {
			dispatch(receive(UPDATED_FILTER, filter))
		} else {
			if (selected) {
				dispatch(receive(ADDED_FILTER, filter))
			} else {
				dispatch(receive(REMOVED_FILTER, filter))
			}
		}

		dispatch(productFilterOptionsActions.fetch())
		dispatch(productActions.fetchPage(0))
	}
}

const setFilters = filters => {
	return dispatch => {
		dispatch(receive(SET_FILTERS, filters))
		dispatch(productFilterOptionsActions.fetch())
		dispatch(productActions.fetchPage(0))
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true
	}),
	[UPDATED_SORT_TYPE]: (state, { payload }) => ({
		...state,
		sortType: payload
	}),
	[UPDATED_SEARCH_QUERY]: (state, { payload }) => ({
		...state,
		searchQuery: payload
	}),
	[SET_FILTERS]: (state, { payload }) => {
		return {
			...state,
			filters: payload
		}
	},
	[ADDED_FILTER]: (state, { payload }) => {
		let filters = cloneDeep(state.filters)
		filters.push(payload)

		return {
			...state,
			filters
		}
	},
	[REMOVED_FILTER]: (state, { payload }) => {
		let filters = cloneDeep(state.filters)
		const { def_id, val } = payload
		let index = null

		if (isNil(val)) {
			index = filters.findIndex(f => f.def_id === def_id)
		} else {
			index = filters.findIndex(f => f.def_id === def_id && f.val === val)
		}

		if (index > -1) {
			filters.splice(index, 1)
		}

		return {
			...state,
			filters
		}
	},
	[UPDATED_FILTER]: (state, { payload }) => {
		let filters = cloneDeep(state.filters)
		const { def_id } = payload
		const index = filters.findIndex(f => f.def_id === def_id)

		if (index === -1) {
			// add the filter if it doesnt exist
			filters.push(payload)
		} else {
			// update the filter if it already exists
			filters[index] = Object.assign({}, payload)
		}

		return {
			...state,
			filters
		}
	},
	[UPDATED_EXISTING_USE_FILTER]: (state, { payload }) => {
		return {
			...state,
			existingUse: payload
		}
	}
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	reloadFilters,
	setFilters,
	updateFilters,
	updateSortType,
	updateSearchQuery,
	updateExistingUseFilter,
	reset
}
