import { Model } from './base'
import { config as API } from 'api'

// @todo implement with flow
// export type ProductTypeType = {
// 	created_by: ?string
// 	created_at: ?Date
// 	updated_at: ?Date
// 	id: ?string
// 	name: ?string
// 	division_id: ?string
// }

const defaultFields = {
	name: null,
	division_id: null
}

const SAVE_TEXT = 'Save'
const CREATE_TEXT = 'Create'
const DELETE_ACTION_TEXT =
	'Are you sure you would like to delete this Product Type?'
const SAVE_ERROR_TEXT = 'Error saving Product Type'
const CREATE_ERROR_TEXT = 'Error creating Product Type'
const DELETE_ERROR_TEXT = 'Error deleting Product Type'

export default class ProductTypeModel extends Model {
	static endpoint = API.PRODUCT_TYPES.PATH
	static deleteActionText = DELETE_ACTION_TEXT
	static saveErrorText = SAVE_ERROR_TEXT
	static createErrorText = CREATE_ERROR_TEXT
	static deleteErrorText = DELETE_ERROR_TEXT

	constructor(fields = defaultFields) {
		super(fields)
	}

	// @todo add this.validationErrors array to populate with
	// errors caught here and to display in components
	validate() {
		const { fields } = this

		if (!fields.name) {
			return false
		} else if (!fields.division_id) {
			return false
		} else {
			return true
		}
	}

	get saveActionText() {
		return this.id() ? SAVE_TEXT : CREATE_TEXT
	}
}
