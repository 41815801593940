import { truncate } from 'lodash'
import classNames from 'classnames'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import BasicLoader from 'components/loader/BasicLoader'
import { ViewContainer } from 'containers/view'
import PrintFooter from 'components/print/PrintFooter'
import { LIGHT_GREY } from 'theme'
import logo from 'assets/logo.svg'
import * as postgrest from 'services/postgrest'
import { config as API } from 'api'
import { withRouter } from '../../hocs/WithRouter'

const TITLE_PREFIX = 'RELATABLE Product Comparison'

const styles = (theme) => ({
	row: {
		position: 'relative',
		width: '100%',
		display: 'flex',
		flexDirection: 'row',
	},
	cell: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		minWidth: 100,
		whiteSpace: 'normal',
		padding: theme.spacing(1),
		lineHeight: 1.2,
		borderBottom: `1px solid ${LIGHT_GREY}`,
		borderRight: `1px solid ${LIGHT_GREY}`,
	},
	headerCell: {
		textAlign: 'center',
		borderTop: `1px solid ${LIGHT_GREY}`,
	},
	rowTitleCell: {
		borderLeft: `1px solid ${LIGHT_GREY}`,
	},
	value: {
		fontSize: 12,
		wordBreak: 'break-word',
		paddingTop: theme.spacing(1),
	},
	valueHighlight: {
		color: theme.palette.secondary.main,
	},
	title: {
		fontSize: 12,
		textAlign: 'center',
	},
	logo: {
		width: 50,
		margin: `${theme.spacing(0.5)} 0`,
	},
	image: {
		display: 'block',
		backgroundSize: 'cover',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		height: 60,
		width: 60,
		margin: `${theme.spacing(0.5)} auto`,
	},
})

class ProductComparePrintView extends Component {
	// -- state
	mounted = false
	state = {
		isLoading: true,
		isError: false,
		products: null,
		companySystemString: null,
		headerData: [],
		bodyData: [],
	}

	// -- render methods
	renderHeaders = this.renderHeaders.bind(this)
	renderTable = this.renderTable.bind(this)
	renderTableRow = this.renderTableRow.bind(this)

	// --
	productValues = {}

	componentDidMount() {
		const hash = this.props.params.hash
		this.mounted = true
		if (this.mounted) {
			postgrest
				.fetchAllWithPost(
					API.PRODUCT_COMPARISON_TABLE_DATA.PATH,
					'',
					{
						p_hash: hash,
					},
					false,
				)
				.then((result) => {
					if (result) {
						document.title = `${TITLE_PREFIX} ${result.company_system_string}`
						this.setState({
							isLoading: false,
							companySystemString: result.company_system_string,
							headerData: result.headers ? result.headers : [],
							bodyData: result.rows ? result.rows : [],
						})
					}
				})
				.catch((error) => {
					this.setState({ isLoading: false, isError: true })
				})
		}
	}

	render() {
		if (this.state.isError) {
			return (
				<ViewContainer>
					<Typography variant="body2">
						There has been an error loading this page.
					</Typography>
				</ViewContainer>
			)
		} else if (this.state.isLoading) {
			return (
				<ViewContainer>
					<BasicLoader />
				</ViewContainer>
			)
		}

		const { companySystemString } = this.state

		return (
			<ViewContainer>
				<div className="space-bottom">
					<Typography component="h1" variant="h5" gutterBottom>
						Product Comparison Table
					</Typography>
					{companySystemString && (
						<Typography component="h2" variant="body2">
							{companySystemString}
						</Typography>
					)}
				</div>
				{this.renderHeaders()}
				{this.renderTable()}
				<PrintFooter disclaimerText="Please refer to the package insert for complete instructions, indications, contraindications, warnings and precautions." />
			</ViewContainer>
		)
	}

	renderHeaders() {
		const { classes } = this.props
		const { headerData } = this.state

		return (
			<div className={classes.row}>
				<div className={classes.cell} />
				{headerData.map((product, index) => {
					return (
						<ColumnHeader key={index} product={product} classes={classes} />
					)
				})}
			</div>
		)
	}

	renderTable() {
		const { bodyData } = this.state

		return <div>{bodyData.map(this.renderTableRow)}</div>
	}

	renderTableRow(row, index) {
		const { classes } = this.props

		if (!row.vals || !row.vals.length) {
			return null
		}

		return (
			<div className={classes.row} key={index}>
				<div className={classNames([classes.cell, classes.rowTitleCell])}>
					<span className={classes.value}>{row.name}</span>
				</div>
				{row.vals.map((data, index) => {
					let valueClasses = [classes.value]

					// if (dataType === TYPE_ID) {
					// 	if (defId === classifierRefsToIds[ID_DIVISION]) {
					// 		values = product.getSingleFormattedVal(
					// 			defId,
					// 			dataType,
					// 			this.props.divisions
					// 		)
					// 	} else if (defId === classifierRefsToIds[ID_PRODUCT_TYPE]) {
					// 		values = product.getSingleFormattedVal(
					// 			defId,
					// 			dataType,
					// 			this.props.productTypes
					// 		)
					// 	}
					// } else {
					// 	values = product.getFormattedVals(defId, dataType)
					// 	values = values.join(', ')
					// }

					// if (!values) {
					// 	values = '-'
					// }

					// if the value is differnet from the main product vale
					// highlight as differing

					if (data && data.is_different) {
						valueClasses.push(classes.valueHighlight)
					}

					return (
						<div className={classes.cell} key={index}>
							<span className={classNames(valueClasses)}>
								{truncate(data.val, { length: 100, separator: ' ' })}
							</span>
						</div>
					)
				})}
			</div>
		)
	}
}

ProductComparePrintView.propTypes = {
	// -- style classes
	classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(withRouter(ProductComparePrintView))

// ----------

class ColumnHeader extends React.Component {
	// -- state
	mounted = false
	state = {
		imageLoaded: false,
	}

	componentDidMount() {
		this.mounted = true
		this.preloadImage()
	}

	preloadImage() {
		const { product } = this.props
		const imageUrl = product.image_url
		let image = new Image()

		image.onload = () => {
			if (this.mounted) {
				this.setState({ imageLoaded: true })
			}
		}

		image.onerror = () => {
			// --
		}

		image.src = imageUrl
	}

	render() {
		const { product, classes } = this.props
		const { imageLoaded } = this.state
		const description = product.description
		let imageUrl

		if (imageLoaded) {
			imageUrl = product.image_url
		}

		return (
			<div className={classNames([classes.cell, classes.headerCell])}>
				<div>
					{!imageLoaded && (
						<img className={classes.logo} src={logo} alt="Relatable" />
					)}
					{imageLoaded && (
						<div
							className={classes.image}
							style={{ backgroundImage: `url(${imageUrl})` }}
						/>
					)}
				</div>
				<p className={classes.title}>
					{truncate(description, { length: 50, separator: ' ' })}
				</p>
			</div>
		)
	}
}

ColumnHeader.propTypes = {
	classes: PropTypes.object.isRequired,
	product: PropTypes.object.isRequired,
}
