import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { routerActions } from 'connected-react-router'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import ArrowLeftIcon from '@mui/icons-material/ArrowBack'
import { ViewContainer } from 'containers/view'
import OverlayLoader from 'components/loader/OverlayLoader'
import { actions as alertActions } from 'state/modules/alerts'
import { validateEmail } from 'utils/validation'
import {
	SUCCESS as ALERT_SUCCESS,
	ERROR as ALERT_ERROR,
} from 'data/alert-types'
import ROUTES from 'routes'
import { config as API } from 'api'
import * as postgrest from 'services/postgrest'
import { publicViewStyles } from 'styles'
import logo from 'assets/logo_relatable.svg'

/* payload
{
	"email": "string"
}
*/

const KEY_ENTER = 13
const ENDPOINT = API.PASSWORD_RESET_REQUEST.PATH

// form fields
const EMAIL = 'email'

const INITIAL_STATE = {
	isSaving: false,
	hasInput: false,
	validationError: null,
	fields: {
		[EMAIL]: '',
	},
}

const mapDispatchToProps = (dispatch) => ({
	navigateTo: (path) => dispatch(routerActions.push(path)),
	showAlert: (obj) => dispatch(alertActions.showAlert(obj)),
})

const styles = (theme) => ({
	...publicViewStyles(theme),
})

class LoginForgotPasswordView extends React.Component {
	// -- state
	state = INITIAL_STATE

	// -- event handlers
	handleKeyPress = this.handleKeyPress.bind(this)
	handleInputUpdate = this.handleInputUpdate.bind(this)
	handleSendEmailClick = this.handleSendEmailClick.bind(this)

	validate(fields) {
		if (!fields[EMAIL]) {
			return 'Please fill in your email'
		} else if (!validateEmail(fields[EMAIL])) {
			return 'Email is not valid'
		} else {
			return null
		}
	}

	handleInputUpdate(event) {
		const { name, value } = event.target
		let fields = Object.assign({}, this.state.fields)
		fields[name] = value
		const validationError = this.validate(fields)
		this.setState({ fields, validationError, hasInput: true })
	}

	handleKeyPress(event) {
		const key = event.keyCode || event.charCode

		if (key === KEY_ENTER) {
			this.handleSendEmailClick()
		}
	}

	handleSendEmailClick() {
		const { fields } = this.state
		const validationError = this.validate(fields)

		if (validationError) {
			return
		}

		// --

		this.setState({ isSaving: true })

		postgrest
			.createMinimal(ENDPOINT, fields, false) // false for no auth needed
			.then(() => {
				this.setState(INITIAL_STATE)
				this.props.showAlert({
					type: ALERT_SUCCESS,
					message: `Reset password email sent to ${fields[EMAIL]}`,
				})
				this.props.navigateTo(ROUTES.ROOT)
			})
			.catch((error) => {
				this.setState({ isSaving: false })
				this.props.showAlert({
					type: ALERT_ERROR,
					message: 'Error requesting password reset',
				})
			})
	}

	render() {
		const { classes } = this.props
		const { fields, validationError, isSaving, hasInput } = this.state

		return (
			<ViewContainer>
				<img src={logo} className={classes.logo} alt="Relatable" />

				<div className={classes.form}>
					<OverlayLoader loading={isSaving} />
					<Typography component="h1" variant="h5" gutterBottom>
						Forgot Password
					</Typography>
					<Typography variant="body1" color="textSecondary">
						We'll send you an email with a link to reset your password.
					</Typography>
					<div className="space-top">
						<TextField
							variant="standard"
							margin="dense"
							label="Email"
							name={EMAIL}
							value={fields[EMAIL]}
							onChange={this.handleInputUpdate}
							onKeyUp={this.handleKeyPress}
							fullWidth
						/>
					</div>

					{validationError && (
						<div className="space-top">
							<Typography variant="body1" color="secondary">
								{validationError}
							</Typography>
						</div>
					)}

					<div className="space-top">
						<Button
							style={{ width: '100%' }}
							variant="contained"
							color="primary"
							onClick={this.handleSendEmailClick}
							disabled={!!validationError || isSaving || !hasInput}
						>
							Send
						</Button>
					</div>

					<div className="space-top-large centered">
						<Button size="small" component={Link} to={ROUTES.ROOT}>
							<ArrowLeftIcon className="space-right-smallest" />
							Back to login
						</Button>
					</div>
				</div>
			</ViewContainer>
		)
	}
}

LoginForgotPasswordView.propTypes = {
	// -- style props
	classes: PropTypes.object.isRequired,
	// -- dispatch props
	navigateTo: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired,
}

export default connect(
	null,
	mapDispatchToProps,
)(withStyles(styles)(LoginForgotPasswordView))
