import { v4 as uuidv4 } from 'uuid'
import { cloneDeep, isEmpty } from 'lodash'
import { ProductModel, ProductCollection } from 'models'
import { config as API } from 'api'
import { getContentRangeTotal } from 'utils/headers'
import { ID_PRODUCT_NUMBER, ID_PRODUCT_TYPE } from 'data/classifier-refs'
import * as postgrest from 'services/postgrest'
import {
	SUCCESS as ALERT_SUCCESS,
	ERROR as ALERT_ERROR,
} from 'data/alert-types'
import { actions as alertActions } from './alerts'
import { MODULE_NAME as TAXONOMIES_MODULE_NAME } from './taxonomies'
import { MODULE_NAME as PRODUCT_FILTERS_MODULE_NAME } from './product-filters'
import {
	YES,
	NO,
	ALL,
	PRODUCT_NUMBER as PRODUCT_NUMBER_SORT_TYPE,
} from 'data/product-sort-types'

export const MODULE_NAME = 'products' // this module's name in the rootReducer
const VALS_ENDPOINT = API.PRODUCT_CLASSIFIER_VALS_WRITE.PATH
const COLLECTION_NAME = 'PRODUCTS'
const MODEL_NAME = 'PRODUCT'
const DEFAULT_PAGE = 0
const DEFAULT_PER_PAGE = 20

const FETCH_SELECT = 'number, system, is_restricted'
const FETCH_PAGE_SELECT =
	'number,division_id,product_type_id,system,is_restricted,company'
const CREATE_REQUIRED_ERROR_TEXT = 'Please fill in all required fields.'
const DEFAULT_CREATE_ERROR = 'Error creating Product'

const initialState = {
	hasFetched: false,
	isFetching: false,
	isModelFetching: false,
	fetchError: null,
	pendingFields: {},
	currentModel: new ProductModel(),
	results: new ProductCollection(),
	totalResults: 0,
	perPage: DEFAULT_PER_PAGE,
	page: DEFAULT_PAGE,
	sortBy: null,
}

const RESET = `${COLLECTION_NAME}_RESET`
const REQUESTED = `${COLLECTION_NAME}_REQUESTED`
const MODEL_REQUESTED = `${COLLECTION_NAME}_MODEL_REQUESTED`
const FETCHED = `${MODEL_NAME}_FETCHED`
const FETCHED_PAGE = `${COLLECTION_NAME}_PAGE_FETCHED`
const EDIT_SAVED = `${MODEL_NAME}_EDIT_SAVED`
const EDIT_CLEARED = `${MODEL_NAME}_EDIT_CLEARED`
const REPLACED = `${MODEL_NAME}_REPLACED`
const CREATED = `${MODEL_NAME}_CREATED`
const DESTROYED = `${MODEL_NAME}_DESTROYED`
const CREATED_VAL = `${MODEL_NAME}_CREATED_VAL`
const SAVED_VAL = `${MODEL_NAME}_SAVED_VAL`
const DESTROYED_VAL = `${MODEL_NAME}_DESTROYED_VAL`
const FAILED = `${COLLECTION_NAME}_FAILED`

export const reset = () => ({ type: RESET })
export const request = () => ({ type: REQUESTED })
export const model_request = () => ({ type: MODEL_REQUESTED })
// receive can take FETCHED, CREATED, DESTROYED, REPLACED actions
export const receive = (action, fields) => ({ type: action, payload: fields })
export const receivePage = (results, page, totalResults) => ({
	type: FETCHED_PAGE,
	payload: { results, page, totalResults },
})
export const clearEdit = (fields) => ({ type: EDIT_CLEARED })
export const fail = (error) => ({ type: FAILED, payload: error })

/**
 * Fetch single resource
 * thunk
 */
const fetch = (id, force = false) => {
	return (dispatch, getState) => {
		// if this model already exists in the results array of the product filters module,
		// no need to fetch again. just set it as the currentModel
		dispatch(model_request())

		if (!force) {
			const { results } = getState()[MODULE_NAME]
			const existingModel =
				results && results.size() ? results.findById(id) : null

			if (existingModel) {
				return new Promise((resolve) => {
					dispatch(receive(REPLACED, existingModel))
					resolve()
				})
			}
		}

		return postgrest
			.fetch(API.PRODUCTS.PATH, id, 'number', FETCH_SELECT)
			.then((responseBody) => {
				withClassifierValsPromise([responseBody], 1)
					.then((responseBody) => {
						dispatch(receive(FETCHED, responseBody))
					})
					.catch((error) => {
						dispatch(fail(error))
						throw error
					})
			})
			.catch((error) => {
				dispatch(fail(error))
				throw error
			})
	}
}

export const withClassifierValsPromise = (responseBody, mode = 0) =>
	new Promise((resolve, reject) => {
		// grab classifier values
		let payload = {}
		payload['product_numbers'] =
			responseBody && responseBody.length
				? responseBody.map((p) => p.number)
				: []
		postgrest
			.fetchAllWithPost(
				API.PRODUCT_CLASSIFIER_VALS_FOR_NUMBER.PATH,
				{},
				payload,
			)
			.then((cvalsResponse) => {
				let cvals = {}
				cvalsResponse.forEach((v) => {
					cvals[v.product_number] = v.product_classifier_vals
				})
				responseBody = responseBody.map((p) => {
					p['product_classifier_vals'] = cvals[p.number]
					return p
				})
				resolve(mode ? (responseBody ? responseBody[0] : {}) : responseBody)
			})
			.catch((error) => {
				reject(error)
			})
	})

/**
 * Fetch resources using limit-offset pagination
 * thunk
 *
 * @see https://postgrest.com/en/v4.3/api.html#limits-and-pagination
 */
const fetchPage = (page, excludeRelatedProductId) => {
	return (dispatch, getState) => {
		dispatch(request())

		let payload
		const { perPage } = getState()[MODULE_NAME]
		const { filters, searchQuery, sortType, existingUse } =
			getState()[PRODUCT_FILTERS_MODULE_NAME]

		if (excludeRelatedProductId) {
			payload = {
				fts_string: searchQuery,
				filters: [],
				p_excl_product_number_relateds: excludeRelatedProductId,
			}
		} else {
			payload = {
				fts_string: searchQuery,
				filters,
			}
			switch (existingUse) {
				case YES:
					payload['p_existing_use'] = true
					break
				case NO:
					payload['p_existing_use'] = false
					break
				case ALL:
					delete payload.p_existing_use
					break
				default:
					delete payload.p_existing_use
					break
			}
		}

		let query = { select: FETCH_PAGE_SELECT }

		if (searchQuery === '') {
			if (sortType) {
				query['order'] = `${sortType},${PRODUCT_NUMBER_SORT_TYPE}`
			} else {
				query['order'] = PRODUCT_NUMBER_SORT_TYPE
			}
		}

		let totalResults = 0

		return postgrest
			.fetchPageWithPost(
				API.FILTER_PRODUCTS.PATH,
				page,
				perPage,
				query,
				payload,
			)
			.then((response) => {
				totalResults = getContentRangeTotal(response.headers)
				return withClassifierValsPromise(response.body)
			})
			.then((responseBody) => {
				dispatch(receivePage(responseBody, page, totalResults))
				return responseBody
			})
			.catch((error) => {
				dispatch(fail(error))
				// throw error
			})
	}
}

/**
 * This method does not affect the products module state.
 * It is only used to fetch all products for product export feature and return them directly.
 *
 * @see ProductSearchExporter.js
 */
const fetchAll = () => {
	return (dispatch, getState) => {
		const { filters, searchQuery, sortType } =
			getState()[PRODUCT_FILTERS_MODULE_NAME]
		const payload = {
			fts_string: searchQuery,
			filters,
		}
		const query = { select: FETCH_PAGE_SELECT }

		if (searchQuery === '') {
			if (sortType) {
				query['order'] = `${sortType},${PRODUCT_NUMBER_SORT_TYPE}`
			} else {
				query['order'] = PRODUCT_NUMBER_SORT_TYPE
			}
		}

		return postgrest
			.fetchAllWithPost(API.FILTER_PRODUCTS.PATH, query, payload)
			.then((responseBody) => withClassifierValsPromise(responseBody))
			.then((responseBody) => {
				return new ProductCollection(responseBody)
			})
			.catch((error) => {
				return error
			})
	}
}

/**
 * This method does not affect the products module state.
 * It is only used to fetch all product numbers for alta view and return them directly.
 *
 * @see ALTAView.js
 */
const fetchNumberAll = () => {
	return (dispatch, getState) => {
		const payload = {
			fts_string: '',
			filters: [],
		}
		const query = { select: 'number' }

		return postgrest
			.fetchAllWithPost(API.FILTER_PRODUCTS.PATH, query, payload)
			.then((response) => {
				return new ProductCollection(response)
			})
			.catch((error) => {
				return error
			})
	}
}

/**
 * This method does not affect the products module state.
 * It is only used to fetch a list of products for the
 * product comparison print feature.
 *
 * @see ProductComparePrintView.js
 */
const fetchList = (ids) => {
	return (dispatch, getState) => {
		return postgrest
			.fetchList(API.PRODUCTS.PATH, ids, 'number', FETCH_SELECT)
			.then((responseBody) => withClassifierValsPromise(responseBody))
			.then((responseBody) => {
				return new ProductCollection(responseBody)
			})
			.catch((error) => {
				throw error
			})
	}
}

/**
 * This method does not affect the products module state.
 * It is only used to fetch a list of products for the
 * system comparison feature.
 *
 * @see SystemCompareTable.js, SystemComparePrintView.js
 */

// const fetchSystemList = ids => {
// 	return (dispatch, getState) => {
// 		return postgrest
// 			.fetchList(API.PRODUCTS.PATH, ids, 'system', FETCH_SELECT)
//			.then(responseBody => withClassifierValsPromise(responseBody))
// 			.then(responseBody => {
// 				return new ProductCollection(responseBody)
// 			})
// 			.catch(error => {
// 				throw error
// 			})
// 	}
// }

/**
 * Create single resource
 * thunk
 */
const create = () => {
	return (dispatch, getState) => {
		dispatch(model_request())

		return new Promise((resolve, reject) => {
			const refs = getState()[TAXONOMIES_MODULE_NAME].refsToIds
			const classifierDefs = getState()[TAXONOMIES_MODULE_NAME].classifiers
			const fields = getState()[MODULE_NAME].pendingFields

			if (!fields.product_classifier_vals) {
				throw new Error(CREATE_REQUIRED_ERROR_TEXT)
			}

			// Check to make sure a product_number and product_type_id exist
			const productNumberVal = fields.product_classifier_vals.find((val) => {
				return val.def_id === refs[ID_PRODUCT_NUMBER]
			})
			const productNumber = productNumberVal ? productNumberVal.val : null
			const productTypeVal = fields.product_classifier_vals.find((val) => {
				return val.def_id === refs[ID_PRODUCT_TYPE]
			})
			const productTypeId = productTypeVal ? productTypeVal.val : null

			if (!productNumber || !productTypeId) {
				throw new Error(CREATE_REQUIRED_ERROR_TEXT)
			}

			// Remove locally set IDs
			// Add the `product_number` val to ALL classifier_vals
			let payload = fields.product_classifier_vals.map((val) => {
				return {
					def_id: val.def_id,
					val: val.val,
					product_number: productNumber,
				}
			})

			// Remove "Product Type" vals that do not match the currently selected Product Type
			payload = payload.filter((val) => {
				const { def_id } = val
				const def = classifierDefs.findById(def_id)
				const ptId = def.get('product_type_id')

				if (ptId && ptId !== productTypeId) {
					return false
				} else {
					return true
				}
			})

			// --

			return postgrest
				.create(API.NEW_PRODUCT.PATH, { p_classifier_vals: payload })
				.then((response) => {
					dispatch(
						receive(CREATED, {
							product_classifier_vals: response,
							number: productNumber,
						}),
					)
					dispatch(
						alertActions.showAlert({
							message: `Product ${productNumber} created`,
							type: ALERT_SUCCESS,
						}),
					)
					resolve(productNumber)
				})
				.catch((error) => {
					dispatch(fail(error))
					dispatch(
						alertActions.showAlert({
							message: error.message || DEFAULT_CREATE_ERROR,
							type: ALERT_ERROR,
						}),
					)
				})
		})
	}
}

/**
 * Destroy single resource
 * thunk
 */
const destroy = (id) => {
	return (dispatch, getState) => {
		dispatch(model_request())

		return postgrest
			.destroy(API.PRODUCTS.PATH, id, 'number')
			.then(() => {
				dispatch(receive(DESTROYED, id))
			})
			.catch((error) => {
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Persist state of pending Product data
 * Pretty much the same as 'updateVal' but does not
 * call the server.
 */
const saveEdit = (classifierValId, updatedFields) => {
	return (dispatch, getState) => {
		const { pendingFields } = getState()[MODULE_NAME]
		let pendingModel = new ProductModel(cloneDeep(pendingFields))

		if (classifierValId) {
			if (!isEmpty(updatedFields)) {
				pendingModel.updateVal({ id: classifierValId, ...updatedFields })
			} else {
				pendingModel.removeVal(classifierValId)
			}
		} else {
			const uuid = uuidv4()
			pendingModel.addVal(Object.assign({ ...updatedFields, id: uuid }))
		}

		return new Promise((resolve) => {
			dispatch({
				type: EDIT_SAVED,
				payload: {
					pendingFields: pendingModel.fields,
				},
			})
			resolve()
		})
	}
}

const saveBulkEdit = (updatedVals) => {
	return (dispatch, getState) => {
		dispatch(model_request())

		let payload
		const { filters, searchQuery, existingUse } =
			getState()[PRODUCT_FILTERS_MODULE_NAME]
		const { totalResults } = getState()[MODULE_NAME]

		payload = {
			fts_string: searchQuery,
			filters,
			p_classifier_data: updatedVals,
		}
		switch (existingUse) {
			case YES:
				payload['p_existing_use'] = true
				break
			case NO:
				payload['p_existing_use'] = false
				break
			case ALL:
				delete payload.p_existing_use
				break
			default:
				delete payload.p_existing_use
				break
		}

		return postgrest
			.fetchAllWithPost(API.PRODUCT_BULK_CLASSIFIERS_UPDATE.PATH, '', payload)
			.then((responseBody) => {
				dispatch(receive(DESTROYED))
				dispatch(fetchPage(0))
				dispatch(
					alertActions.showAlert({
						message: `Edits Applied to ${totalResults} Products`,
						type: ALERT_SUCCESS,
					}),
				)
			})
			.catch((error) => {
				dispatch(fail(error))
				dispatch(
					alertActions.showAlert({
						message: 'Error Applying Edits.',
						type: ALERT_ERROR,
					}),
				)
				// throw error
			})
	}
}

const updateVal = (classifierValId, updatedFields) => {
	if (classifierValId) {
		if (!isEmpty(updatedFields)) {
			return saveVal(classifierValId, updatedFields)
		} else {
			return destroyVal(classifierValId)
		}
	} else {
		return createVal(updatedFields)
	}
}

/**
 * Create single product classifier val
 * thunk
 */
const createVal = (fields) => {
	const payload = fields

	return (dispatch, getState) => {
		dispatch(model_request())

		return postgrest
			.create(VALS_ENDPOINT, payload)
			.then((response) => {
				dispatch(receive(CREATED_VAL, response))
			})
			.catch((error) => {
				// @todo move to postgrest service?
				// Conflict. User is attempting to create classifier val that
				// already exists on same classifier def
				if (error.status && error.status === 409) {
					dispatch(
						alertActions.showAlert({
							message: 'Value already exists.',
							type: ALERT_ERROR,
						}),
					)
				} else {
					dispatch(
						alertActions.showAlert({
							message: 'Error creating value.',
							type: ALERT_ERROR,
						}),
					)
				}

				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Save single product classifier val
 * thunk
 */
const saveVal = (classifierValId, fields) => {
	let payload = fields // val, link

	return (dispatch, getState) => {
		dispatch(model_request())

		return postgrest
			.save(VALS_ENDPOINT, classifierValId, payload)
			.then((response) => {
				dispatch(receive(SAVED_VAL, response))
			})
			.catch((error) => {
				// @todo move to postgrest service?
				// Conflict. User is attempting to create classifier val that
				// already exists on same classifier def
				if (error.response && error.response.status === 409) {
					dispatch(
						alertActions.showAlert({
							message: 'Value already exists.',
							type: ALERT_ERROR,
						}),
					)
				} else {
					dispatch(
						alertActions.showAlert({
							message: 'Error saving value.',
							type: ALERT_ERROR,
						}),
					)
				}

				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Update Product Number
 * thunk
 */
const updateProductNumber = (oldProductNumber, newObj) => {
	return (dispatch, getState) => {
		dispatch(model_request())
		return postgrest
			.save(
				API.PRODUCTS.PATH,
				oldProductNumber,
				{ number: newObj.val },
				null,
				'number',
			)
			.then((response) => {
				dispatch(receive(SAVED_VAL, newObj))
			})
			.catch((error) => {
				if (error.response && error.response.status === 409) {
					dispatch(
						alertActions.showAlert({
							message: 'Value already exists.',
							type: ALERT_ERROR,
						}),
					)
				} else {
					dispatch(
						alertActions.showAlert({
							message: 'Error saving value.',
							type: ALERT_ERROR,
						}),
					)
				}
				dispatch(fail(error))
				throw error
			})
	}
}

/**
 * Delete single product classifier val
 * thunk
 */
const destroyVal = (id) => {
	return (dispatch, getState) => {
		dispatch(model_request())

		return postgrest
			.destroy(VALS_ENDPOINT, id)
			.then(() => {
				dispatch(receive(DESTROYED_VAL, id))
			})
			.catch((error) => {
				dispatch(
					alertActions.showAlert({
						message: 'Error removing value.',
						type: ALERT_ERROR,
					}),
				)
				dispatch(fail(error))
				throw error
			})
	}
}

const ACTION_HANDLERS = {
	[RESET]: (state, action) => initialState,
	[REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isFetching: true,
	}),
	[MODEL_REQUESTED]: (state, action) => ({
		...state,
		fetchError: null,
		isModelFetching: true,
	}),
	[EDIT_SAVED]: (state, { payload }) => {
		return {
			...state,
			...payload,
		}
	},
	[EDIT_CLEARED]: (state, action) => ({
		...state,
		pendingFields: {},
	}),
	[FETCHED_PAGE]: (state, { payload }) => ({
		...state,
		results: new ProductCollection(payload.results),
		page: payload.page,
		totalResults: payload.totalResults,
		fetchError: null,
		isFetching: false,
		hasFetched: true,
	}),
	[FETCHED]: (state, { payload }) => ({
		...state,
		currentModel: new ProductModel(payload),
		fetchError: null,
		isModelFetching: false,
		hasFetched: true,
	}),
	[REPLACED]: (state, { payload }) => ({
		...state,
		currentModel: payload,
		isModelFetching: false,
		fetchError: null,
	}),
	[CREATED]: (state, { payload }) => ({
		...state,
		currentModel: new ProductModel({ ...payload }),
		pendingFields: {},
		fetchError: null,
		isModelFetching: false,
	}),
	[DESTROYED]: (state, action) => ({
		...state,
		currentModel: new ProductModel(),
		fetchError: null,
		isModelFetching: false,
	}),
	[CREATED_VAL]: (state, { payload }) => {
		let currentModel = state.currentModel.clone()
		currentModel.addVal(payload)

		return {
			...state,
			currentModel,
			fetchError: null,
			isModelFetching: false,
		}
	},
	[SAVED_VAL]: (state, { payload }) => {
		let currentModel = state.currentModel.clone()
		currentModel.updateVal(payload)

		return {
			...state,
			currentModel,
			fetchError: null,
			isModelFetching: false,
		}
	},
	[DESTROYED_VAL]: (state, { payload }) => {
		let currentModel = state.currentModel.clone()
		currentModel.removeVal(payload)

		return {
			...state,
			currentModel,
			fetchError: null,
			isModelFetching: false,
		}
	},
	[FAILED]: (state, { payload }) => ({
		...state,
		fetchError: payload,
		isFetching: false,
		isModelFetching: false,
		hasFetched: true,
	}),
}

export default (state = initialState, action) => {
	const handler = ACTION_HANDLERS[action.type]
	return handler ? handler(state, action) : state
}

export const actions = {
	fetch,
	fetchPage,
	fetchAll,
	fetchNumberAll,
	fetchList,
	saveEdit,
	saveBulkEdit,
	clearEdit,
	create,
	destroy,
	updateVal,
	updateProductNumber,
	reset,
}
